import './ExternalForgot.css'

import React, { useState } from 'react';
//Assets
import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg'

//Services
import registerService from '../../../services/register-service';

//MuiComponents
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import { Divider, Typography } from "@mui/material";
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';



//Services





function ExternalForgot(props) {
    // const handleValueChange = (e) => {
    //     let inputVal = e.target.value;
    //     let inputProperty = e.target.getAttribute('data-property')
    //     console.log(inputProperty,inputVal)
    //     setValues({
    //         ...values,
    //         [inputProperty]:inputVal
    //     })
    // }

    const [forgotState, setForgotState] = useState({
        verificationCode: (Math.round(Math.random(0) * 1000) + 1000).toString(),
        emailSent: false,
    })

    const [credentialState, setCredentialState] = useState({
        email: "",
        code: "",
        newPassword: "",
    })


    const sendResetLink = async () => {
        let emailValue = document.getElementById("email-forgot").value;

        setForgotState({
            ...forgotState,
            emailSent: true,
        })

        setCredentialState({
            ...credentialState,
            email: emailValue,
            code: forgotState.verificationCode,
        })
        await registerService.forgotPassword(emailValue, forgotState.verificationCode);
    }

    const tryOtherEmail = () => {
        setForgotState({
            ...forgotState,
            emailSent: false,
        })
    }

    const backToLogin = () => {
        props.setForgotPassword(false)
    }

    const isValidEmail = (email) => {
        if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
            return false
        } else return true
    }


    return (
        <>

            {!forgotState.emailSent &&
                <>
                    <div className="flex-row" style={{ marginBottom: '21px' }} >
                        <Typography variant="subtitle" fontWeight="600">Forgot your password?</Typography>
                    </div>

                    <div className="flex-row" style={{ marginBottom: '25px', textAlign: 'left' }} >
                        <Typography variant="body1">Please enter the email address associated with your UnionHub account to receive a password reset link. </Typography>
                    </div>

                    <div className="flex-row" style={{ marginBottom: '25px' }} >
                        <TextField fullWidth id='email-forgot' sx={{ maxWidth: '685px' }}
                            label="Email" variant="outlined"
                            // value={values.userName} onChange={handleValueChange}
                            inputProps={{ 'data-property': 'userName' }}
                        />
                    </div>

                    <div className="flex-row" style={{ marginBottom: '25px' }} >
                        <Button variant='contained' className='send-password-btn' fullWidth
                        disabled={isValidEmail(credentialState.email)}
                            sx={{ height: 50, maxWidth: '575px' }}
                            onClick={sendResetLink}
                        >
                            Send link
                        </Button>

                    </div>
                    <div className="flex-row" style={{ marginBottom: '25px' }} >
                        <Button variant='outlined' className='send-password-btn' fullWidth
                            sx={{ height: 50, maxWidth: '575px' }}
                            onClick={backToLogin}
                        >
                            Back To Log In
                        </Button>
                    </div>
                </>
            }

            {forgotState.emailSent &&
                <>
                    <div>
                        <Alert severity="success">
                            <AlertTitle className="email-alert-title">Email Sent</AlertTitle>
                            Check your email and open the link we sent to begin resetting your password.
                        </Alert>
                    </div>
                    <div className="try-other-email">
                        Did not receive the email? Check your spam filter or
                        <Link onClick={tryOtherEmail}> try another email address.</Link>
                    </div>
                </>
            }

        </>
    );
};

export default ExternalForgot;
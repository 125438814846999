import React, { useEffect, useLayoutEffect, useState, useContext, Suspense, lazy } from "react";
// import { useHistory } from "react-router-dom";
import { useHistory } from "react-router";
// import { format, differenceInCalendarYears } from "date-fns";

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";


//Muicons
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Close from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";


//MuiComponents
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlerTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";
import MobileStepper from "@mui/material/MobileStepper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import Home from "@mui/icons-material/Home";
import Icon from "@mui/material/Icon";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Collapse, Link, ListItem } from "@mui/material";



//Services
// import enrollmentService from "../../../services/enrollment-service";
// import attachmentService from "../../../services/attachment-service";
import authService from "../../../services/auth-service";

//Our Components
// import ExternalEnrollmentHeader from "./ExternalEnrollmentHeader";
import PoweredBy from "../external-enrollments/PoweredBy";
import InfinityLoader from "../../UH-loading-animation/InfinityLoader";
import DrawerMenuItem from "./portal-home/DrawerMenuItem";
import PortalHome from "./portal-home/PortalHome";


import { ColorModeContext } from "../../../ExternalApp";
import { set } from "date-fns";
import PortalPlanInfo from "./portal-planinfo/PortalPlanInfo";
import PortalSupport from "./portal-support/PortalSupport";
import PortalSettings from "./portal-settings/PortalSettings";

// const DrawerMenuItem = lazy(() => import('./DrawerMenuItem'));

// const DashHome = React.lazy(() => import('../../external-dashboard/dash-home/DashHome'));
// const DashPersonal = React.lazy(() => import('../../external-dashboard/dash-personal/DashPersonal'));
// // const DashBenefits = React.lazy(() => import('../../external-dashboard/dash-benefits/DashBenefits'));
// const DashWallet = React.lazy(() => import('../../external-dashboard/dash-wallet/DashWallet'));
// const DashDocuments = React.lazy(() => import('../../external-dashboard/dash-documents/DashDocuments'));
// const DashClaims = React.lazy(() => import('../../external-dashboard/dash-claims/DashClaims'));
// const DashSettings = React.lazy(() => import('../../external-dashboard/dash-settings/DashSettings'));
// const DashHelp = React.lazy(() => import('../../external-dashboard/dash-help/DashHelp'));



export default function MemberPortal(props) {



   //potentially we can do one call to get all the data we need for the dashboard, so
   //actively covered benefits | some personal info | payment info | claims and more

   const history = useHistory();

   let colorMode = useContext(ColorModeContext);

   const [loading, setLoading] = useState(false);

   const [dashPageState, setDashPageState] = useState({
      home: true,
      planInfo: false,
      support: false,
      settings: false,
   })

   const [pageSummaryObj, setPageSummaryObj] = useState({
      thisMember: {

      },
      thisMemberBenefits: {

      },
      thisMemberPayment: {

      },
   })

   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   // useEffect(() => {
   //    setLoading(true);
   //    setTimeout(() => {
   //       setLoading(false);
   //    }, 2000);
   //    // return () => {
   //    //    setLoading(false);
   //    // }
   // }, [anchorEl]);



   const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const goTo = (page) => {
      setDashPageState({
         // ...dashPageState,
         [page]: true
      })
      handleClose();
   }

   const swapTheme = () => {
      colorMode.ToggleColorMode();

   }

   // const [collapse, setCollapse] = useState(false);
   const [enrollmentAlert, setEnrollmentAlert] = useState(true);
   const onEnrollmentAlertClose = () => {
      setEnrollmentAlert(false);
   }

   const handleLogoutClick = async () => {
      // console.log('logout clicked');
      await authService.signout()
      history.push('/login');
   }



   return (
      <>
         {loading && (
            <>
               <InfinityLoader
                  style={{
                     position: "absolute",
                     zIndex: 5,
                     width: "100%",
                     height: "100vh",
                     backgroundColor: "#00000050",
                     backdropFilter: "blur(10px)",
                  }}
               />
               <style>
                  {`
                     div.lottie-div > svg {
                        height:55px!important;
                        position:absolute;
                        top:50%;
                     }
                  `}
               </style>
            </>
         )}
         <AppBar component={'nav'} sx={{ display: 'flex', backgroundColor: 'primary.main' }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
               <IconButton
                  size="large"
                  edge="start"
                  // sx={{ color: 'background.paper', mr: 2 }}
                  aria-label="menu"
                  onClick={handleMenu}
                  // disableRipple={true}
                  sx={{
                     color: 'background.paper', ml: 0,
                     'svg': { height: '35px', width: '35px' }, padding: '0px',
                     // transition: 'transform 0.2s ease-in-out',
                     // transform: collapse ? 'rotate(-180deg)' : 'rotate(0deg)',
                  }}
               >
                  <MenuIcon />
               </IconButton>

               <Box sx={{position:'relative'}}>
                  <IconButton
                     size="large"
                     // aria-label="account of current user"
                     // aria-controls="menu-appbar"
                     aria-haspopup="true"
                     sx={{ color: 'background.paper', pr: 0, position: 'absolute', right: '0px',bottom:'-29px'}}
                     disableRipple={true}
                     onClick={swapTheme}
                  >
                     unionhub
                  </IconButton>

               </Box>
            </Toolbar>
         </AppBar>
         <Drawer
            open={open}
            onClose={handleClose}
            anchor={"left"}
         >
            <AppBar position="static" sx={{ display: 'flex', backgroundColor: 'primary.main' }}>
               <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IconButton
                     size="large"
                     edge="start"
                     color="inherit"
                     // aria-label="menu"
                     onClick={handleClose}
                     sx={{ color: 'background.paper', mr: 2 }}
                     disableRipple={true}
                  >
                     <Close />
                  </IconButton>

                  <Box>
                     <IconButton
                        size="large"
                        // aria-label="account of current user"
                        // aria-controls="menu-appbar"
                        // aria-haspopup="true"
                        sx={{ color: 'background.paper' }}
                        disableRipple={true}
                     >
                        unionhub
                     </IconButton>

                  </Box>
               </Toolbar>
            </AppBar>
            <Box
               className="flex-container"
               flex={1}
               overflow="auto"
               style={{
                  flexWrap: "wrap", minHeight: "calc(100vh)", margin: "auto", padding: '8px', width: '100vw',
                  // maxWidth: '500px' 
               }}
               sx={{
                  bgcolor: "background.default",
                  color: "text.primary",
               }}
            >
               <DrawerMenuItem
                  NavigateClick={() => goTo('home')}
                  text="Home"
               />
               <DrawerMenuItem
                  NavigateClick={() => goTo('planInfo')}
                  text="Plan Information"
               />
               <DrawerMenuItem
                  NavigateClick={() => goTo('support')}
                  text="Support"
               />
               <DrawerMenuItem
                  NavigateClick={() => goTo('settings')}
                  text="Settings"
               />
               <DrawerMenuItem
                  NavigateClick={handleLogoutClick}
                  text="Logout"
               />
            </Box>
         </Drawer>
         {dashPageState.home && 
            <>
               <PortalHome />
            </>
         }
         {dashPageState.planInfo &&
            <>
               <PortalPlanInfo />
            </>
         }
         {dashPageState.support &&
            <>
               <PortalSupport />
            </>
         }
         {dashPageState.settings &&
            <>
               <PortalSettings />
            </>
         }


         <PoweredBy />


         {/* {!isAuthenticated && history.push('/login')} */}
      </>
   );
};


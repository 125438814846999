import Settings from "../settings"
import authService, { authHeader } from "./auth-service"
import { baseService } from "./baseService"

class internalTimelineService extends baseService {
  getHistoryForMember(memberId, startDate, endDate) {
    const url =
      Settings.API_URL + `timeline/${memberId}/${startDate}/${endDate}`
    return this.get(url)
  }
}

const timelineService = new internalTimelineService()
export default timelineService

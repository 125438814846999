import React, { useContext, useEffect, useRef, useState } from "react"
import { format, formatDistance, set } from "date-fns"

//services
import memberService from "../../../../../../services/member-service"

// Assets

//Mui Components
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import TextField from "@mui/material/TextField"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"

//Mui icons
import CloseIcon from "@mui/icons-material/Close"
import { Divider, Typography } from "@mui/material"

//Our Components
import ButtonInfinityLoader from "../../../../../UH-loading-animation/ButtonInfinityLoader"

export default function CreateNoteDrawer(props) {
  //PROPS
  let {
    member,
    noteDrawerOpen,
    setNoteDrawerOpen,
    refreshTickets,
    refreshHistoryAfterNoteCreation,
  } = props

  //Ticket Logic
  const [note, setNote] = useState("")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const createNote = async () => {
    setLoading(true)
    let _note
    try {
      _note = await memberService.createMemberNote(member?.id, note)
    } catch (error) {
      console.error("Error creating note", error)
      setError(true)
    }

    //successful note creation
    if (_note) {
      //if drawer accessed from notes tab only
      if (refreshTickets) {
        await refreshTickets()
      }
      //if drawer accessed from history tab
      if (refreshHistoryAfterNoteCreation) {
        setTimeout(() => {
          refreshHistoryAfterNoteCreation()
        }, 500)
      }
      setNote("")
      setNoteDrawerOpen(false)
    }
    setLoading(false)
  }

  //Drawer Logic
  const handleDrawerClose = (e) => {
    setNoteDrawerOpen(false)
    setError(false)
  }

  return (
    <>
      <Drawer
        sx={{ position: "relative" }}
        hideBackdrop={true}
        anchor="right"
        open={noteDrawerOpen}
        onClose={handleDrawerClose}
      >
        <Box
          sx={{
            width: "545px",
            padding: "19px 38px",
            backgroundColor: "background.default",
            height: "100%",
          }}
        >
          <Box className="flex-row">
            <Box
              className="col-6"
              sx={{ fontFamily: "Poppins", fontWeight: 600 }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontWeight: 400,
                  color: "text.primary",
                }}
              >
                New Note
              </Typography>
            </Box>

            <Box
              className="col-6"
              sx={{ display: "flex", justifyContent: "flex-end" }}
              role="button"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </Box>
          </Box>
          <Divider
            sx={{
              color: "primary.main",
              margin: "17px -38px 30px",
              opacity: 1,
              height: "2px",
            }}
          />

          <TextField
            fullWidth
            multiline
            minRows={5}
            variant="standard"
            placeholder="Write your note here"
            InputProps={{ disableUnderline: true }}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            sx={{
              marginBottom: "40px",
              border: "1px solid",
              borderRadius: "4px",
              padding: "16px",
              // input:{'&::placeholder':{fontFamily:'Archivo'}}
            }}
          />

          <Box
            className="flex-row"
            sx={{ justifyContent: "center", marginBottom: "50px" }}
          >
            <Button
              variant="contained"
              disableRipple
              disabled={loading}
              sx={{
                height: 40,
                minWidth: 200,
                borderRadius: "8px",
                width: "152px",
                fontWeight: "500!important",
              }}
              onClick={createNote}
              // startIcon={<AddIcon/>}
            >
              {loading ? (
                <ButtonInfinityLoader buttonTitle={"Create Note"} />
              ) : (
                "Create Note"
              )}
            </Button>
          </Box>

          {error && (
            <Alert sx={{ paddingRight: "20px" }} severity="error">
              <AlertTitle>Error creating new note</AlertTitle>
              There was an error creating this note. Please try again and if the
              problem persists, contact your system administrator.
            </Alert>
          )}
        </Box>
      </Drawer>
    </>
  )
}

import React, { useEffect,useLayoutEffect, useState } from "react";
import { Switch, useHistory, useLocation, Route } from 'react-router-dom';


//Mui Components
import Paper from '@mui/material/Paper'
import LinearProgress from "@mui/material/LinearProgress";
import Button from '@mui/material/Button'

export default function StickyProgressTotal (props) {
   let UsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
   let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

   const [progress,setProgress] = useState(20)
   useLayoutEffect(()=>{
      if (props.progress) setProgress(props.progress)
   },[props.progress])
   const [timeLeft,setTimeLeft] = useState('About 3 minutes left')
   useLayoutEffect(()=>{
      setTimeLeft(props.timeLeft)
   },[props.timeLeft])

   

   // useEffect(() => {
   //    if (props.selections){
   //       let total = 0;
   //       let _progress = 1 //Start with progress
   //       let _planCount = 0;
   //       let DEBUG_otherSteps = 1;
   //       for (let [key,val] of Object.entries(props.selections)){
   //          if (key.slice(key.length-3) == 'Val') _planCount +=1
   //          else {
   //             if (typeof val == 'object') {
   //                for (let arrayVal of val){
   //                   // console.log('LADD ARRAY',arrayVal)
   //                   //Selections are made on hitting next for ladd
   //                }
   //             }
   //             else if (val != undefined && key.slice(key.length-2) != 'Id') _progress +=1
   //          } 
   //       }
   //       total = _planCount + DEBUG_otherSteps
   //       let progressPercent = (_progress / total) * 100
   //       setProgress(progressPercent)
         
         
   //       // if (props.selections.std != undefined) _progress
   //    }
   // },[props.selections])
   // useEffect(()=> {
   //    if (0<=progress && progress<33) setTimeLeft(3)
   //    else if (34<progress && progress<66) setTimeLeft(2)
   //    else if (67<progress && progress<=100) setTimeLeft(1)
   // },[progress])

   let history = useHistory()
   let location = useLocation()

   const backClickHandler = () => {
      if (props.agentEnroller){
         props.setAgentEnrollmentPageState({[props.lastLocation]:true})
      } else {
         history.push(props.lastLocation, { ...history.location.state })
      }
   }

   const [position, setPosition] = useState("sticky")

   const handlePosition = () => {
      if (props.position == "fixed"){
         setPosition("fixed")
      } else {
         setPosition("sticky")
      }
   }

   useEffect(() => {
      handlePosition()
   },[props.position])

   return (
      <>
      <style>
         {`
         div.enrollment-sticky-bottom {
            align-self: flex-start;
            // min-width:100%;
            //min-width:-webkit-fill-available;
            // margin-left:-27px;
            padding:10px 27px;
            display:block!important;
            position:fixed;
            bottom:0px;
            color:#1000000DE;
            text-align:center;
            font-size:20px;
            overflow-y:hidden;
            font-weight:500;
            height:160px;   
            box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.1);
         }
         .MuiLinearProgress-bar1Determinate{
            background:linear-gradient(90deg, #5421C9 0%, #195FFB 100%)
         }
         .clickableDisabled {
            color: rgba(0, 0, 0, 0.26);
            background-color: rgba(0, 0, 0, 0.12);
         }
         `}
      </style>
      <Paper className='enrollment-sticky-bottom'
      elevation={0} sx={{zIndex:1, minWidth:props.agentEnroller?'-webkit-fill-available':'100%'}}
      >
         {(props.runningTotal != null && props.runningTotal != undefined) &&
         <div style={{marginBottom:'15px'}}>
            {`${UsdFormatterDec.format(props.runningTotal)} Monthly Total`}
         </div>
         }

         <LinearProgress variant='determinate' value={progress}
         sx={{
            boxShadow: '0px 4px 4px 0px #00000040 inset',
            background:'#f0f0f0',
            height:'12px',
            borderRadius:'100px',
            marginBottom: '20px'
         }}/>

         {/* <div style={{fontSize:'15px',fontWeight:700,marginBottom:'10px'}}>

            {timeLeft}
         </div> */}

         
         <div className="flex-row" style={{gap:'15px'}}>
            {props.allowBack &&
            <div className='col' style={{textAlign:'start'}}>
               <Button variant='outlined'  
               sx={{ 
               height:50,width:'152px',border:'1px solid #195ffb!important',fontWeight:'700!important'}}
               onClick={backClickHandler} 
               >
                  Back
               </Button>
            </div>
            }
            <div className='col' style={{textAlign:'end'}} >
               <Button variant='contained'
                  sx={{height:50,width:'152px',fontWeight:'600!important',fontSize:'15px', backgroundColor: (props?.nextLocation == 'payment' && !props?.nextValidation) ? 'rgba(0, 0, 0, 0.12)' : 'auto', color: (props?.nextLocation == 'payment' &&  !props?.nextValidation) ? 'rgba(0, 0, 0, 0.12)' : 'auto', boxShadow: (props?.nextLocation == 'payment' &&  !props?.nextValidation) ? 'none' : 'auto', '&:hover':{backgroundColor: (props?.nextLocation == 'payment' &&  !props?.nextValidation) ? 'rgba(0, 0, 0, 0.12)' : 'auto'} }}
                  disabled={props?.nextLocation != 'payment' ? props?.nextValidation : false}
                  // onClick={() => {
                  //    if (!props?.notActivelyWorking){
                  //       props.nextClickHandler(props.nextLocation)
                  //    } else {
                  //       if (props.handleNawExit) props.handleNawExit()
                  //    }
                  // }}
                  onClick={() => props.nextClickHandler(props.nextLocation)} 
                  >
                  
                     {/* {(props?.notActivelyWorking && props?.nextLocation) == 'payment' ? 'Save & Exit' : 'Next'} */}
                     Next
               </Button>
            </div>
         </div>
      </Paper>
      </>
   )
}
import React, { useEffect, useLayoutEffect, useState, useContext, Suspense, lazy } from "react";
// import { useHistory } from "react-router-dom";
import { useHistory } from "react-router";
// import { format, differenceInCalendarYears } from "date-fns";

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";


//Muicons
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Close from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


//MuiComponents
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlerTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";
import MobileStepper from "@mui/material/MobileStepper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";



//Services
// import enrollmentService from "../../../services/enrollment-service";
// import attachmentService from "../../../services/attachment-service";

//Our Components
// import ExternalEnrollmentHeader from "./ExternalEnrollmentHeader";
// import PoweredBy from "./PoweredBy";
import InfinityLoader from "../../../UH-loading-animation/InfinityLoader";
import Home from "@mui/icons-material/Home";
import { Collapse, Link, ListItem } from "@mui/material";


import { ColorModeContext } from "../../../../ExternalApp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

export default function HeaderCollapseContainer (props) {

   const [collapse, setCollapse] = useState(false);

   console.log('HeaderCollapseContainer props:', props);

   return (
      <>
         <Box className="col-12" sx={{ backgroundColor: 'background.another', width: '100%', padding: '22px 16px', marginBottom: '16px', maxHeight: '77px', justifyContent: 'space-between', display: 'flex' }}
         onClick={() => setCollapse(collapse => !collapse)}
         >
            <Typography variant="" component="span"
               sx={{
                  flexGrow: 1,
                  fontFamily: 'Poppins',
                  fontWeight: '600',
                  fontSize: '25px',
                  letterSpacing: '0.15px',
                  // lineHeight:'150%',

                  fontFeatureSettings: '"clig" off, "liga" off'
               }}
            >
               {props.title}
            </Typography>

            <IconButton
               // size="large"
               edge="start"
               sx={{
                  'svg': { height: '35px', width: '35px' }, padding: '0px',
                  transition: 'transform 0.2s ease-in-out',
                  transform: collapse ? 'rotate(-180deg)' : 'rotate(0deg)',
               }}
               aria-label="menu"
               // onClick={() => setCollapse(collapse => !collapse)}
            // disableRipple={true}
            >
               <ExpandMoreIcon />
            </IconButton>
         </Box>

         <Collapse
            in={collapse} orientation="vertical"
            unmountOnExit={false}
            mountOnEnter={true}
            sx={{ width: '100%'}}
         >
            <Box className="col-12"
               sx={{ width: '100%', padding: '0px 16px 22px', display: 'flex', flexWrap:'wrap', gap:'24px', ...props.childBoxSx}}>
               {props.children}
            </Box>
         </Collapse>
      </>
   );
};


import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';



//services
import memberService from '../../../../services/member-service';
import organizationService from '../../../../services/organization-service';

// Assets

//Mui Components

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FormControl, InputLabel, Typography, Divider } from '@mui/material';


//Mui icons
import CloseIcon from '@mui/icons-material/Close';

//Our Components

export default function CreateMemberDrawer(props) {


   // Organization Logic

   const [organizations, setOrganizations] = useState([])

   const getOrganizations = async () => {
      let organizationArray = await organizationService.getOrganizations()
      setOrganizations(organizationArray)
      // console.log(organizations)
   }


   let history = useHistory()

   const [newMember, setNewMember] = useState({
      firstName: '',
      lastName: ''
   })

   const [newOrganization, setNewOrganization] = useState({
      topParentOrganizationId: '',
      organizationId: '',
      lowestOrganizationId: ''
   })

   useEffect(() => {
      // console.log(newOrganization)
   },[newOrganization])

   //Drawer Logic
   const handleDrawerClose = (e) => {
      props.setNewMemberDrawerOpen(false)
   }

   const handleMemberInformation = (e, property) => {
      let value = e.target.value;
      setNewMember({
         ...newMember,
         [property]: value
      })
   }

   const sendCreateMember = async (e) => {
      let insertMember = {
         firstName: newMember.firstName,
         lastName: newMember.lastName,

         suffix: "",
         middleInitial: "",
         timezoneId: 0
      }
      let memberPostReturn = await memberService.insertMember(insertMember)
      let insertMemberOrganization = {
         memberId: memberPostReturn.id,
         isPrimary: true,
         organizationId: (newOrganization.organizationId == '' && newOrganization.lowestOrganizationId == '') ? newOrganization.topParentOrganizationId : (newOrganization.lowestOrganizationId == '' ? newOrganization.organizationId : newOrganization.lowestOrganizationId)
      }
      let memberOrganizationReturn = await memberService.addMemberOrganization(insertMemberOrganization)
      // console.log(memberOrganizationReturn)
      history.push(`/members/${memberPostReturn.id}`, { profile: true, profileId: memberPostReturn.id })
   }


   useEffect(() => {
      getOrganizations()
   }, [props.newMemberDrawerOpen])

   const handleOrganizationInformation = (e, property) => {
      let value = e.target.value;
      if (property == 'topParentOrganizationId') {
         setNewOrganization({
            ...newOrganization,
            [property]: value,
            organizationId: '',
            lowestOrganizationId: ''
         })
      } else if (property == 'organizationId') {
         setNewOrganization({
            ...newOrganization,
            [property]: value,
            lowestOrganizationId: ''
         })
      } else {
         setNewOrganization({
            ...newOrganization,
            [property]: value
         })
      }
   }

   const handleHasChildren = (id) => {
      let children = organizations?.find(organization => organization.parentId == id)
      if (children) {
         return true
      } else return false
   }

   return (
      <>
         <Drawer
            PaperProps={{
               sx: { marginTop: '56px!important' }

            }}
            hideBackdrop={true}
            anchor='right'
            open={props.newMemberDrawerOpen}
            onClose={handleDrawerClose}
         >
            <div style={{ width: '560px', padding: '19px 38px' }}>
               <div className='flex-row' >
                  <div className='col-6'>
                     <Typography variant="body1" fontFamily="Poppins" fontWeight="600">New Member</Typography>
                  </div>

                  <div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end' }} role='button' onClick={handleDrawerClose}>
                     <CloseIcon />
                  </div>
               </div>
               <Divider sx={{ color: 'primary.main' }} style={{ margin: '17px -38px 30px', opacity: 1, height: '2px' }} />

               {/* {props.showMemberInput &&
            <Autocomplete 
            disablePortal
            options={['figgy','wiggy']}
            
            renderInput={(params) => {
               <TextField {...params} fullWidth
               variant='outlined' label='Member'
               sx={{marginBottom:'20px'}}
               value={createdTicket.memberId} onChange={e=>handleTicketChange(e,'memberId')}
               />

            }}
            />
            } */}

               <TextField fullWidth
                  variant='outlined' label='First Name'
                  sx={{ marginBottom: '20px' }}
                  value={newMember.firstName} onChange={e => handleMemberInformation(e, 'firstName')}
               />

               <TextField fullWidth
                  variant='outlined' label='Last Name'
                  sx={{ marginBottom: '20px' }}
                  value={newMember.lastName} onChange={e => handleMemberInformation(e, 'lastName')}
               />

               {/* <TextField fullWidth
                  variant='outlined' label='Organization'
                  sx={{ marginBottom: '20px' }}
                  value={newMember.organization} onChange={e => handleMemberInformation(e, 'organization')}
               /> */}

               <FormControl fullWidth>
                  <InputLabel id='topParentOrganizationId' sx={{ fontFamily: "Archivo" }}>Organization</InputLabel>
                  <Select variant='outlined' labelId='topParentOrganizationId' label='Organizationn' size='small'
                     sx={{ fontSize: '15px', height: '50px', borderRadius: '8px', marginBottom: '20px' }}
                     value={organizations && organizations?.find(x => x.id == newOrganization?.topParentOrganizationId)?.id}
                     onChange={e => handleOrganizationInformation(e, 'topParentOrganizationId')}
                  >

                     {organizations && organizations.map(organization => {
                        if (!organization.parentId) {
                           return <MenuItem value={organization.id} key={organization.id}>
                              <span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}>{organization.name}</span>
                           </MenuItem>
                        }

                     })}
                  </Select>
               </FormControl>


               {handleHasChildren(newOrganization?.topParentOrganizationId) &&
                  <FormControl fullWidth>
                     <InputLabel id='organizationId' sx={{ fontFamily: "Archivo" }}>Organization</InputLabel>
                     <Select variant='outlined' labelId='organizationId' label='Organization' size='small'
                        sx={{ fontSize: '15px', height: '50px', borderRadius: '8px', marginBottom: '20px' }}
                        value={organizations && organizations?.find(x => x.id == newOrganization?.organizationId)?.id}
                        onChange={e => handleOrganizationInformation(e, 'organizationId')}
                     >

                        {organizations && organizations.map(organization => {
                           if (organization.parentId == newOrganization?.topParentOrganizationId) {
                              return <MenuItem value={organization.id}>
                                 <span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}>{organization.name}</span>
                              </MenuItem>
                           }

                        })}
                     </Select>
                  </FormControl>
               }

               {handleHasChildren(newOrganization?.organizationId) &&
                  <FormControl fullWidth>
                     <InputLabel id='lowestOrganizationId' sx={{ fontFamily: "Archivo" }}>Organization</InputLabel>
                     <Select variant='outlined' labelId='lowestOrganizationId' label='Organization' size='small'
                        sx={{ fontSize: '15px', height: '50px', borderRadius: '8px', marginBottom: '20px' }}
                        value={organizations && organizations?.find(x => x.id == newOrganization?.lowestOrganizationId)?.id}
                        onChange={e => handleOrganizationInformation(e, 'lowestOrganizationId')}
                     >

                        {organizations && organizations.map(organization => {
                           if (organization.parentId == newOrganization?.organizationId) {
                              return <MenuItem value={organization.id}>
                                 <span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}>{organization.name}</span>
                              </MenuItem>
                           }

                        })}
                     </Select>
                  </FormControl>
               }



               <div className='flex-row' style={{ justifyContent: 'center', marginBottom: '50px' }}>
                  <Button variant='contained' disableRipple
                     sx={{ height: 40, minWidth: 200, borderRadius: 100, width: '152px', fontWeight: '500!important' }}
                     onClick={sendCreateMember}
                  // startIcon={<AddIcon/>}
                  >
                     Create Member
                  </Button>
               </div>
            </div>
         </Drawer>

      </>
   )
}
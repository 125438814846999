import { keyframes } from "@emotion/react"

//INPUT BORDER
let inputFieldBorder = {
  borderColor: "primary.main",
  borderWidth: "1.5px",
}
let inputFieldBorderHover = {
  borderColor: "primary.main !important",
  borderWidth: "1.8px !important",
}

var fadeIn = keyframes`
  0% {
    opacity: 0;
    color: #195FFB;
    transform: scale(1.9);
  }
  100% {
    opacity: 1;
    color: inherit;
    transform: scale(1);
  }
`

const enrollmentPlanStyles = {
  planContainer: (expanded) => ({
    display: "flex",
    flexDirection: "row",
    minHeight: "57px",
    fontSize: "20px",
    fontWeight: "600",
    lineHeight: "30px",
    position: "relative",
    alignItems: "center",
    padding: "0px",
    fontFamily: "Poppins",
    color: expanded ? "primary.main" : "text.primary",
  }),
  expandAndCollapseButton: {
    position: "absolute",
    right: "0%",
    top: "0%",
    color: "#195ffb",
    width: " 18%",
    height: "100%",
    cursor: "pointer",
  },
  planTitle: {
    cursor: "pointer",
    position: "relative",
  },
  collapseSection: {
    marginBottom: "18px",
  },

  planExplanationInnerHtmlContainer: {
    fontFamily: "Archivo",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "25px",
    textAlign: "left",
  },

  /////////// Plan Options //////////////////
  optionRow: {
    display: "flex",
    flexDirection: "row",
    margin: "20px 0px 0px",
    padding: "0px 10px 0px 0px",
    textAlign: "left",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Archivo",
    fontSize: "15px",
  },
  optionAndRequiredInputsContainer: {
    //display as column with input fields below radio button option row
    display: "flex",
    flexDirection: "column",
  },
  optionRadioAndTextContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  radioButtonIcon: (isDisabled) => ({
    height: "19px",
    color: !isDisabled ? "primary.main" : "",
  }),
  planSelectRadioButton: {
    padding: "0px",
    marginRight: "8px",
  },
  singleOptionTextContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: "15px",
    alignItems: "center",
  },
  singleOptionText: (isSelected) => ({
    fontSize: "15px",
    fontWeight: isSelected ? 700 : 500,
  }),
  animateOnChanges: (varExists) => ({
    animation: !varExists ? `${fadeIn} 0.5s ease 0s` : "",
  }),
  recommendedText: {
    fontSize: "15px",
    marginLeft: "5px",
  },
  blurbTextContainer: {
    display: "block",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: "15px",
    alignItems: "center",
    marginLeft: "32px",
  },
  blurbText: {
    // Coverage does not apply to.... spouses over the age of 75.
    // display: "block",
    variant: "body2",
    color: "#646464",
    fontSize: "14px",
    fontFamily: "Archivo",
    fontWeight: "500",
    lineHeight: "21.98px",
    letterSpacing: "0.10px",
    wordWrap: "break-word",
    // for newline
    // wordBreak: "break-word",
  },
  optionExplanationOfBenefits: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "0rem",
    fontFamily: "Archivo",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "25px",
    textAlign: "left",
    marginLeft: "15px",
  },

  //Incremental Plan and LADD Additional Styles
  explanationOfBenefitsTextIncremental: {
    fontFamily: "Archivo",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "20px",
    textAlign: "left",
    margin: "0 15px 20px 25px",
  },

  selectInput: {
    width: "48%",
    marginTop: "10px",
    "& fieldset": inputFieldBorder,
    "&:hover fieldset": {
      borderColor: "#195ffb !important",
      borderWidth: "1.8px",
    },
  },
  elimAndDurRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: " 0px 15px 0px 25px",
    textAlign: "left",
    margin: "0px auto 10px",
    maxWidth: "500px",
  },
  elimAndDurSingleStaticContainer: {
    display: "flex",
    flexDirection: "column",
  },
  elimAndDurStaticTitle: {
    fontSize: "15px",
    fontFamily: "Archivo",
    fontWeight: 600,
    lineHeight: "25px",
  },
  elimAndDurationStaticText: {
    fontSize: "15px",
    fontFamily: "Archivo",
    fontWeight: 400,
    lineHeight: "25px",
    marginLeft: "3px",
  },
  incSliderSection: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 0px",
    margin: "0px auto",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    width: "98%",
    // border: '1px solid red',
  },
  incSliderContainer: {
    margin: "0 auto",
    width: "100%",
    // border: '1px solid red',
  },
  incSlider: {
    width: "90%",
    marginLeft: "5%",
    marginBottom: "0px",
  },
  incSliderLowAmount: {
    flex: 1,
    fontFamily: "Archivo",
    fontWeight: 500,
    fontSize: "12px",
    position: "relative",
    alignSelf: "center",
    top: "-2px",
    textAlign: "right",
  },
  incSliderHighAmount: {
    flex: 1,
    fontFamily: "Archivo",
    fontWeight: 500,
    fontSize: "12px",
    position: "relative",
    alignSelf: "center",
    textAlign: "left",
    top: "-2px",
  },
  benefitAmountDropdownContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    margin: "20px auto 10px",
    padding: "0px",
    fontFamily: "Archivo",
    // border: '1px solid red'
  },
  activeBlueDropdownField: (isDisabled = false) => ({
    "& fieldset": !isDisabled ? inputFieldBorder : "",
    "&:hover fieldset": {
      borderColor: !isDisabled ? "#195ffb !important" : "",
      borderWidth: !isDisabled ? "1.8px" : "",
    },
  }),
  // benefitAmountDropdownLabel: {
  //   color: 'red'
  // },
  noOptionsAvailableMsg: {
    margin: "25px 0px 10px 0px",
  },
  notNowToolTip: {
    display: "flex",
    alignItems: "center",
  },
  toolTipIcon: {
    height: "20px",
    color: "#717171",
    marginLeft: "5px",
  },

  //Spouse and Dependent Info Inputs:
  componentContentContainer: {
    position: "relative",
    marginBottom: "10px",
  },
  inputSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "25px auto 0px",
    fontFamily: "Archivo",
  },
  inputField: {
    width: "100%",
    marginBottom: "15px",
    "& fieldset": inputFieldBorder,
  },
  inputLabelProps: { sx: { color: "primary.main" } },
  datePickerInput: { width: "100%", color: "primary.main" },

  //Decline Option

  declineOptionRow: {
    display: "flex",
    flexDirection: "row",
    margin: "20px 0px 0px",
    padding: " 0px",
    textAlign: "left",
  },
  declineOptionText: (isSelected) => ({
    alignSelf: "center",
    fontFamily: "Archivo",
    fontSize: "15px",
    fontWeight: isSelected ? 700 : 500,
  }),
  collapsedPlanSection: {
    display: "flex",
    flexDirection: "row",
    margin: "0px 0px 18px 0px",
    fontSize: "15px",
    fontWeight: "500",
    padding: "0px",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Archivo",
    color: "text.lightGray",
  },
  radioButtionIconCollapse: {
    marginRight: "8px",
    height: "19px",
  },
  electedOptionCollapsePlusSymbol: {
    marginRight: "15px",
    fontWeight: "700",
  },
  notElectedZeroAmount: {
    float: "right",
    marginRight: "15px",
  },
  endSectionDivider: {
    position: "relative",
    color: "#D9D9D9",
  },
  sectionClickFieldForSnackbarDisplay: {
    width: "104%",
    height: "100%",
    position: "absolute",
    marginLeft: "-4%",
    zIndex: 5,
    opacity: 0,
  },
}

export default enrollmentPlanStyles

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


//MuiIcons

//Services
import authService from '../../../services/auth-service';
import userService from '../../../services/user-service';
import dashboardService from "../../../services/dashboard-service";

//MuiComponents
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Fade from "@mui/material/Fade";
import Chip from "@mui/material/Chip";

//Assets 

//Our components
import { thisUserContext } from "../nav-container/NavContainer";
import EnrollmentReport from "../Reporting/individual-reports/EnrollmentReport";


export default function Dashboard(props) {

   let myContext = useContext(thisUserContext)
   const [thisUser, setThisUser] = useState()
   let getThisUser = async () => {
      //  let _thisUser = await userService.getMe()

      setThisUser(myContext.thisUser)
      // console.log('thisUser ',myContext)
   }

   useEffect(() => {
      if (myContext) getThisUser()
      getGroupPlans()
   }, [myContext.thisUser])

   const [groupPlans, setGroupPlans] = useState([])
   const [thisGroupPlan, setThisGroupPlan] = useState(0)

   const getGroupPlans = async () => {
      let _groupPlans = await dashboardService.getGroupPlans()
      setGroupPlans(_groupPlans.filter(groupPlan => groupPlan.isOpen == true))
      // console.log(groupPlans)
   }

   useEffect(() => {
      if (groupPlans?.length == 0) getGroupPlans()
   }, [])

   //reset group plan selection to first in list
   useEffect(() => {
      setThisGroupPlan(groupPlans[0]?.id)
   }, [groupPlans])

   const handleChange = (event) => {
      setThisGroupPlan(event.target.value);
   };

   
   return (
      <>
         <Box className="flex-row" sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            borderColor: 'background.border',
         }} style={{ height: "calc(100vh - 56px)", padding: "60px" }}>
            <div className="col-6">
               <h3>Welcome back<Fade in={thisUser?.firstName ? true : false}><span>, {thisUser?.firstName}!</span></Fade></h3>
               <p>Welcome to Unionhub Peak!</p>
               <p>Have feedback on UnionHub?  Let us know by emailing us at feedback@unionhub.com!</p>
               <p>Thanks,</p>
               <p>The UnionHub Team</p>
            </div>

            {/* Reporting */}
            <div className="col-6" display="flex">
               <Paper elevation={0} sx={{ bgColor: 'background.paper', padding: '20px', height: '100%', overflowY: 'auto' }}>
                  <Box className="flex-row" sx={{ margin: 'auto', marginBottom: '20px' }}>
                     <Typography className="col" variant="subtitle">Enrollments</Typography>
                     <Box className="col" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <FormControl variant="standard" fullWidth>
                           <InputLabel id="group-plan-report">Group Plan</InputLabel>
                           <Select
                              labelId="group-plan-report"
                              id="group-plan-report-select"
                              value={thisGroupPlan}
                              label="Group Plan"
                              onChange={handleChange}
                           >
                              {groupPlans?.map(groupPlan => {
                                 return <MenuItem value={groupPlan?.id}>{groupPlan?.name}</MenuItem>
                              })}

                           </Select>
                        </FormControl>
                     </Box>
                  </Box>

                  <Box className="row" style={{ justifyContent: 'center' }}>
                     <EnrollmentReport groupPlanId={thisGroupPlan} goals={groupPlans && groupPlans?.find(groupPlan => groupPlan?.id == thisGroupPlan)?.goals}/>
                  </Box>


               </Paper>
            </div>
         </Box>
      </>
   )
}
import './PasswordStrength.css';
import React, { useState, useEffect } from 'react';

import Chip from '@mui/material/Chip'
import CheckIcon from '@mui/icons-material/Check';



export function PasswordStrength(props) {
    const [lengthCheck, setLengthCheck] = useState(0);
    const [lowercaseCheck, setLowercaseCheck] = useState(0);
    const [uppercaseCheck, setUppercaseCheck] = useState(0);
    const [numberCheck, setNumberCheck] = useState(0);
    const [passwordStrength, setPasswordStrength] = useState(0);

    const passInput = props.passwordValue;


    useEffect(() => {

        //lengthCheck
        if (passInput.length >= 8) setLengthCheck(1);
        if (passInput.length <= 7) setLengthCheck(0);

        //caseCheck
        const caseLowRx = new RegExp('[a-z]+')
        if (caseLowRx.test(passInput)) setLowercaseCheck(1)
        if (!caseLowRx.test(passInput)) setLowercaseCheck(0)
        const caseHighRx = new RegExp('[A-Z]+')
        if (caseHighRx.test(passInput)) setUppercaseCheck(1);
        if (!caseHighRx.test(passInput)) setUppercaseCheck(0);

        //numberCheck
        const numRx = new RegExp('[0-9]+');
        if (numRx.test(passInput)) setNumberCheck(1);
        if (!numRx.test(passInput)) setNumberCheck(0);

    }, [passInput])

    useEffect(() => {
        setPasswordStrength(lengthCheck + lowercaseCheck + uppercaseCheck +numberCheck )
    }, [lengthCheck, lowercaseCheck, uppercaseCheck, numberCheck])

    //^^^ [changes] trigger vvv

    useEffect(() => {
        if (passwordStrength === 4) props.strengthPassed()
        else props.strengthNotPassed()
    }, [passwordStrength])

    let passingStyle = {
        // fontWeight:'500!important',
        color:'#4E9A51',
        backgroundColor:'#07FB1124',
        border:'2px solid #4E9A51'
    }

    return (
        <>
        <div className="strength-div flex-row" style={{margin:'auto'}}>
            <Chip className='col-6 strength-bar' label='One upper case'
            sx={uppercaseCheck?passingStyle:{}}
            icon={uppercaseCheck?<CheckIcon sx={{color:'#4E9A51!important',width:'15px'}}/>:null}/>

            <Chip className='col-6 strength-bar' label='One lower case' 
            sx={lowercaseCheck?passingStyle:{}}
            icon={lowercaseCheck?<CheckIcon sx={{color:'#4E9A51!important',width:'15px'}}/>:null}/>
            </div>
        <div className="strength-div flexrow" style={{margin:'auto'}} >
            <Chip className='col-6 strength-bar' label='8 characters' 
            sx={lengthCheck?passingStyle:{}}
            icon={lengthCheck?<CheckIcon sx={{color:'#4E9A51!important',width:'15px'}}/>:null}/>

            <Chip className='col-6 strength-bar' label='One number' 
            sx={numberCheck?passingStyle:{}}
            icon={numberCheck?<CheckIcon sx={{color:'#4E9A51!important',width:'15px'}}/>:null}/>
        </div>
        </>
    );

}
export default PasswordStrength;
// import './BenefitsWizardAccident.css';

import React, { useState, useEffect } from "react";

//Assets
import BletLogo2 from "../../../../../assets/images/BLETLogo 2.png";

//Services
import enrollmentService from "../../../../../services/enrollment-service";

//MuiComponents
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

//Mui icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonIcon from "@mui/icons-material/RadioButtonUnchecked";

//Our Components
import OptionsCardMulti from "../../../external-dashboard/dash-benefits/options-card/options-card-multi/OptionsCardMulti";
import AccidentSpouseInputs from "./accident-components/AccidentSpouseInputs";
import AccidentDependentsInputs from "./accident-components/AccidentDependentsInputs";
import AccidentFamilyInputs from "./accident-components/AccidentFamilyInputs";
// import AssistanceFooter from '../assistance-footer/AssistanceFooter';
// import SaveChangesSnackbar from '../../../../save-changes-snackbar/SaveChangesSnackbar'
// import UmpMobileStepper from '../../../mobile-stepper/UmpMobileStepper';
// import BenefitsWizardBeneficiary from '../benefits-wizard-beneficiary/BenefitsWizardBeneficiary';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from "../enrollmentPlanStyles";

export default function EnrollmentHospital(props) {
  let componentStyles = {
    optionDivider: {
      color: "#0000001F",
      marginBottom: "10px",
    },
    optionSectionTitle: {
      marginTop: "15px",
      color: "#195FFB"  
    },
    optionExplanationBenefits: {
      fontFamily: "Archivo",
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "25px",
      textAlign: "left",
    }
  };

  let UsdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  let results = props.results;
  let planInfo = results?.hospitalPlan;
  let options = planInfo?.options;
  console.log("planInfo", options);
  let option = {
    memberOptionId: 916,
    memberAndSpouseOptionId: 0,
    memberAndDependentsOptionId: 0,
    familyOptionId: 917,
    groupId: 913,
    name: "High Option Benefits",
    explanationOfBenefits: "High Coverage",
    benefitOffsets: "",
    memberPremiumAmount: 13,
    memberAndSpousePremiumAmount: 0,
    memberAndDependentsPremiumAmount: 0,
    familyPremiumAmount: 19,
    isSelected: false,
    displayOrder: 2,
    isDecline: false,
    benefitAmount: 40000,
    benefitType: "Fixed",
    benefitTypeId: 1,
    benefitTypeFriendly: "Fixed",
    benefitDuration: 0,
    benefitDurationTypeId: 0,
    eliminationPeriod: 0,
    eliminationPeriodTypeId: 0,
    eliminationPeriodType: null,
    eliminationPeriodFriendly: null,
    benefitDurationType: null,
    benefitDurationFriendly: null,
  };

  let option2 = {
    memberOptionId: 9116,
    memberAndSpouseOptionId: 0,
    memberAndDependentsOptionId: 0,
    familyOptionId: 9117,
    groupId: 9113,
    name: "Low Option Benefits",
    explanationOfBenefits: "Low Coverage",
    benefitOffsets: "",
    memberPremiumAmount: 13,
    memberAndSpousePremiumAmount: 0,
    memberAndDependentsPremiumAmount: 0,
    familyPremiumAmount: 19,
    isSelected: false,
    displayOrder: 2,
    isDecline: false,
    benefitAmount: 40000,
    benefitType: "Fixed",
    benefitTypeId: 1,
    benefitTypeFriendly: "Fixed",
    benefitDuration: 0,
    benefitDurationTypeId: 0,
    eliminationPeriod: 0,
    eliminationPeriodTypeId: 0,
    eliminationPeriodType: null,
    eliminationPeriodFriendly: null,
    benefitDurationType: null,
    benefitDurationFriendly: null,
  };
  // options = [option,option2]
  let helpText = planInfo?.helpText;
  // helpText = `<list>
  //    <li>Hospital bla bla</li>
  //    <li>Indemnity bla bla</li>

  // </list>`

  // console.log('add plan props',props)

  // const handleFormChange = (e) => {
  //    e.persist();
  //    let newValue = e.target.value;
  //    let handledId = e.target.id.replace("Updater", "");
  //    console.log(handledId, newValue)

  //    props.setBenefitsMemberObj({
  //       ...props.benefitsMemberObj,
  //       [handledId]: newValue
  //    })
  // };
  const [spouseOpacity, setSpouseOpacity] = useState(
    props.recommendations.ShowSpouse ? 1 : 0.6
  );
  const [dependentOpacity, setDependentOpacity] = useState(
    props.recommendations.ShowDependent ? 1 : 0.6
  );
  const [familyOpacity, setFamilyOpacity] = useState(
    props.recommendations.ShowSpouse && props.recommendations.ShowDependent
      ? 1
      : 0.6
  );

  const [expanded, setExpanded] = useState(); //closed initially
  
  const handleExpandSection = () => {
    setExpanded(!expanded)
  }
    //plan to use this to check if enroller has returned to this section to edit from final summary page
  const checkExpanded = () => {
    // console.log('checkExpanded',props.expanded)
    let thisPlanId = planInfo.planId;
    let planInExpandArray = props.expanded.find(
      (expandObj) => Object.keys(expandObj)[0] == thisPlanId
    );
    if (planInExpandArray) {
      let bool = planInExpandArray[thisPlanId];
      setExpanded(bool);
    }
  };


  let expandIcon = expanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "white",
        backgroundColor: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  );

  const [currRecc, setCurrRec] = useState(0);

  return (
    <>
      <style>
        {`
         .Mui-disabled.MuiButton-root.enroll-survey-btn {
            color:#195FFB;
            background-color: white;
            border: #195FFB 1px solid;
         }
       
         `}
      </style>
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <span
          style={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
         Hospital Confinement
        </span>

        <Box
          onClick={handleExpandSection}
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          {expandIcon}
        </Box>
      </Box>

      <Collapse sx={enrollmentPlanStyles.collapseSection} in={expanded} unmountOnExit>
        
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: helpText }}
          />
        </Box>

        <RadioGroup
          onChange={props.selectionHandler}
          value={props.selections.hospital || currRecc}
        >
          <>
            {options &&
              options
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((option) => {
                  let memberIsSelected =
                    props.selections.hospital == option.memberOptionId;
                  let memberAndSpouseIsSelected =
                    props.selections.hospital == option.memberAndSpouseOptionId;
                  let memberAndDependentsIsSelected =
                    props.selections.hospital ==
                    option.memberAndDependentsOptionId;
                  let familyIsSelected =
                    props.selections.hospital == option.familyOptionId;
                  let onlyFamilyAndMemberAvailable =
                    !option.memberAndDependentsAreEnabled &&
                    !option.memberAndSpouseOptionsAreEnabled &&
                    option.familyOptionsAreEnabled &&
                    option.memberOptionsAreEnabled;

                  if (!option.isDecline) {
                    return (
                      //Option A or B Benefits
                      <Box
                        key={option.memberOptionId}
                        sx={{ paddingBottom: "10px" }}
                      >
                        <Box sx={componentStyles.optionSectionTitle}>
                          <Typography variant="h6">
                            {option?.name && (
                              <>
                                {`${option.name} Benefits`}{" "}
                                {option.name.includes("%") &&
                                  option?.benefitAmount &&
                                  `(Max ${UsdFormatterDec.format(
                                    option.benefitAmount
                                  )})`}
                              </>
                            )}
                          </Typography>
                          
                          <Divider sx={componentStyles.optionDivider} />  
                        </Box>

                        {option?.explanationOfBenefits && (
                          <Box
                            sx={componentStyles.optionExplanationBenefits}
                          >
                            <span
                              className="explanationOfBenefitsHospital-innerHTML"
                              dangerouslySetInnerHTML={{
                                __html: option.explanationOfBenefits,
                              }}
                            />
                          </Box>
                        )}

                        {/* MEMBER */}
                        {planInfo.memberOptionsAreEnabled &&
                          option.memberOptionId > 0 && (
                            <Box sx={enrollmentPlanStyles.optionRow}>
                               <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                                  <Radio
                                    sx={enrollmentPlanStyles.planSelectRadioButton}
                                    value={option.memberOptionId}
                                    id={`${option.memberOptionId}`}
                                    name="hospital"
                                    icon={
                                      <RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                      sx={enrollmentPlanStyles.radioButtonIcon(false)}
                                      />
                                    }
                                    onClick={() => {
                                      props.handleEnrollClick(
                                        option.memberOptionId
                                      );
                                    }}
                                    inputProps={{
                                      "data-amount": option.memberPremiumAmount,
                                    }}
                                  />

                                  <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                                    <Typography sx={enrollmentPlanStyles.singleOptionText(memberIsSelected)}>
                                      Member
                                    </Typography>
                                  </Box>
                              </Box>

                              {/* premium amount */}
                              <Box sx={enrollmentPlanStyles.singleOptionText(memberIsSelected)}>
                                  {memberIsSelected && "+"}
                                  {UsdFormatterDec.format(
                                    option.memberPremiumAmount
                                  )}
                              </Box>
                            </Box>
                          )}

                        {/* MEMBER AND SPOUSE */}
                        {planInfo.memberAndSpouseOptionsAreEnabled &&
                          option.memberAndSpouseOptionId > 0 && (
                            <Box sx={enrollmentPlanStyles.optionAndRequiredInputsContainer}>
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                                    <Radio
                                      sx={enrollmentPlanStyles.planSelectRadioButton}
                                      value={option.memberAndSpouseOptionId}
                                      id={`${option.memberAndSpouseOptionId}`}
                                      name="hospital"
                                      icon={
                                        <RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                      }
                                      checkedIcon={
                                        <RadioButtonCheckedIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(false)}
                                        />
                                      }
                                      onClick={() => {
                                        props.handleEnrollClick(
                                          option.memberAndSpouseOptionId
                                        );
                                      }}
                                      inputProps={{
                                        "data-amount":
                                          option.memberAndSpousePremiumAmount,
                                      }}
                                    />

                                    <Box 
                                        sx={{...enrollmentPlanStyles.singleOptionTextContainer, opacity:memberAndSpouseIsSelected ? 1 : spouseOpacity }}
                                        >
                                      <Typography  sx={enrollmentPlanStyles.singleOptionText(memberAndSpouseIsSelected)}>
                                        Member and spouse
                                      </Typography>
                                      
                                    </Box>
                                </Box>

                                  {/* premium amount */} 
                                 <Box 
                                    sx={enrollmentPlanStyles.singleOptionText(memberAndSpouseIsSelected)}  
                                 >
                                    {memberAndSpouseIsSelected && "+"}
                                    {UsdFormatterDec.format(
                                      option.memberAndSpousePremiumAmount
                                    )}
                                 </Box>
                              </Box>

                                {/* spouse inputs */}
                                  {memberAndSpouseIsSelected && (
                                    <AccidentSpouseInputs
                                      spouse={props.spouse}
                                      setSpouse={props.setSpouse}
                                      genderTypes={props?.genderTypes}
                                    />
                                  )}
                            </Box>
                          )}
                        {/* MEMBER AND DEPENDENTS  */}
                        {planInfo.memberAndDependentsAreEnabled &&
                          option.memberAndDependentsOptionId > 0 && (
                          <Box sx={enrollmentPlanStyles.optionAndRequiredInputsContainer}> 
                            <Box sx={enrollmentPlanStyles.optionRow}>
                              <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                                <Radio
                                  sx={enrollmentPlanStyles.planSelectRadioButton}
                                  value={option.memberAndDependentsOptionId}
                                  id={`${option.memberAndDependentsOptionId}`}
                                  name="hospital"
                                  icon={
                                    <RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                  }
                                  checkedIcon={
                                    <RadioButtonCheckedIcon
                                    sx={enrollmentPlanStyles.radioButtonIcon(false)}
                                    />
                                  }
                                  onClick={() => {
                                    props.handleEnrollClick(
                                      option.memberAndDependentsOptionId
                                    );
                                  }}
                                  inputProps={{
                                    "data-amount":
                                      option.memberAndDependentsPremiumAmount,
                                  }}
                                />

                                <Box sx={{...enrollmentPlanStyles.singleOptionTextContainer, opacity:memberAndDependentsIsSelected ? 1 : dependentOpacity }}>
                                  <Typography sx={enrollmentPlanStyles.singleOptionText(memberAndDependentsIsSelected)}>
                                    Member and dependents
                                  </Typography>
                                </Box>
                              </Box>
                               
                                <Box sx={enrollmentPlanStyles.singleOptionText(memberAndDependentsIsSelected )} >
                                    {memberAndDependentsIsSelected && "+"}
                                    {UsdFormatterDec.format(
                                      option.memberAndDependentsPremiumAmount
                                    )}
                                </Box>
                            </Box>

                            {/* dependent inputs */}
                            {memberAndDependentsIsSelected && (
                              <AccidentDependentsInputs
                                dependents={props.dependents}
                                setDependents={props.setDependents}
                                genderTypes={props?.genderTypes}
                              />
                            )}
                          </Box>
                          )}

                        {/* FAMILY */}
                        {planInfo.familyOptionsAreEnabled &&
                          option.familyOptionId > 0 && (
                            <Box sx={enrollmentPlanStyles.optionAndRequiredInputsContainer}> 
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                                  <Radio
                                    sx={enrollmentPlanStyles.planSelectRadioButton}
                                    value={option.familyOptionId}
                                    id={`${option.familyOptionId}`}
                                    name="hospital"
                                    icon={
                                      <RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                      sx={enrollmentPlanStyles.radioButtonIcon(false)} 
                                      />
                                    }
                                    onClick={() => {
                                      props.handleEnrollClick(
                                        option.familyOptionId
                                      );
                                    }}
                                    inputProps={{
                                      "data-amount": option.familyPremiumAmount,
                                    }}
                                  />

                                  <Box sx={{...enrollmentPlanStyles.singleOptionTextContainer, opacity: familyIsSelected ? 1 : familyOpacity }}>
                                    <Typography  sx={enrollmentPlanStyles.singleOptionText(familyIsSelected)}>
                                        Member and family
                                    </Typography>
                                  </Box>

                                </Box>
                                
                                {/* premium amount */} 
                                <Box
                                    sx={enrollmentPlanStyles.singleOptionText(familyIsSelected)} 
                                >
                                    {familyIsSelected && "+"}
                                    {UsdFormatterDec.format(
                                      option.familyPremiumAmount
                                    )}
                                </Box>
                              </Box>
                                
                                {/* family inputs */}
                                {familyIsSelected && (
                                  <AccidentFamilyInputs
                                    spouse={props.spouse}
                                    setSpouse={props.setSpouse}
                                    dependents={props.dependents}
                                    setDependents={props.setDependents}
                                    genderTypes={props?.genderTypes}
                                  />
                                )}
                           </Box>
                          )}
                  </Box>
                  );
              } else {
                    return (
                      <React.Fragment key={option.memberOptionId}>
                        <Box sx={componentStyles.optionSectionTitle}>
                          <Typography variant="h6" >
                            {option.name == "Declined"
                              ? "Decline Benefits"
                              : option.name}
                          </Typography>
                            <Divider sx={componentStyles.optionDivider} />
                        </Box>

                        <Box
                          key={option.memberPremiumAmount}
                          sx={enrollmentPlanStyles.declineOptionRow}
                        >
                          <Radio
                            sx={enrollmentPlanStyles.planSelectRadioButton}
                            value={option.memberOptionId}
                            name="hospital"
                            icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                            checkedIcon={
                              <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                            }
                            onClick={() => {
                              props.handleEnrollClick(option.memberOptionId);
                            }}
                            inputProps={{
                              "data-amount": option.memberPremiumAmount,
                            }}
                          />
                          <Box sx={enrollmentPlanStyles.declineOptionText(memberIsSelected)}>
                            Not Now
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  }
                })}
          </>
        </RadioGroup>
      </Collapse>

      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.hospital && props.selections.hospitalVal > 0 && (
            <>
              <Box>
                <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtionIconCollapse}/>
                {planInfo.options.map((option) => {
                  console.log(option.name)
                  if (props.selections.hospital == option.memberOptionId)
                    return `${option?.name} - Member`;
                  else if (
                    props.selections.hospital == option.memberAndSpouseOptionId
                  )
                    return `${option?.name} - Member and Spouse`;
                  else if (
                    props.selections.hospital ==
                    option.memberAndDependentsOptionId
                  )
                    return `${option?.name} - Member and Dependents`;
                  else if (props.selections.hospital == option.familyOptionId)
                    return `${option?.name} - Family`;
                })}
              </Box>

              <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                + {UsdFormatterDec.format(props.selections.hospitalVal)}
              </Box>
            </>
          )}
          {props.selections.hospital && props.selections.hospitalVal == 0 && (
            <>
              <span>
                <em>not elected</em>
              </span>
              <span style={enrollmentPlanStyles.notElectedZeroAmount}>
                $0.00
              </span>
            </>
          )}
          {!props.selections.hospital && "No plan selected"}
        </Box>
      )}

<Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}

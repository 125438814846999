
import React, { useEffect, useState, useRef } from 'react';

//MuiComponents
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material';


//Our Components

export default function DeclineDrawer(props) {



    return (
        <>
            <Drawer open={props.open} onClose={props.handleClose} anchor='bottom' sx={{ maxHeight: '200px' }}
                PaperProps={{
                    sx: {
                        borderRadius: '12px 12px 0px 0px', padding: '16px 24px', bgcolor: 'background.default',
                        color: 'text.primary', maxHeight: '75%'
                    }
                }} disableScrollLock={true}
            >
                <Box sx={{
                    bgcolor: 'paper.background.default',
                    color: 'text.primary',
                    maxWidth: '600px',
                    margin: 'auto'
                }}>
                    <div className="row" style={{ marginBottom: '20px' }}>
                        <Typography className="col-10" variant='blueAccent'>Decline all coverage?</Typography>
                    </div>
                    <div className='col-12' style={{ marginBottom: "20px" }}>
                        <Typography variant='body1'>It looks like you’ve declined all coverage options. If you didn’t mean to do this, you can go back and change that now. Otherwise, select “Decline” below to submit your enrollment. </Typography>
                    </div>
                    <div className="flex-row">
                        <div className="col-6" style={{padding:'5px'}}>
                            <Button fullWidth variant="outlined" sx={{height: 50}} onClick={props.handleClose}>Cancel</Button>
                        </div>
                        <div className="col-6" style={{padding:'5px'}}>
                            <Button fullWidth variant="contained"  sx={{height: 50}} onClick={props.handleDecline}>Decline</Button>
                        </div>
                    </div>



                </Box>
            </Drawer>
        </>
    )
}
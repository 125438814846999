import React, { useState, useEffect } from "react";

//services
import walletService from "../../../../../../../services/wallet-service";
// Assets

//Mui Components
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Alert, Box, Card, Checkbox, Chip, Typography } from "@mui/material";

//Mui icons
import CloseIcon from "@mui/icons-material/Close";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

//Our Components
import UmpDatePicker from "../../../../../../app-external/external-dashboard/dash-benefits/ump-date-picker/UmpDatePicker";
import ButtonInfinityLoader from "../../../../../../UH-loading-animation/ButtonInfinityLoader";
import { set } from "date-fns";

export default function ManagePaymentScheduleDrawer(props) {
  //Component Styles
  const componentStyles = {
    componentBody: {
      fontFamily: "Poppins",
      minWidth: "538px",
      height: "100%",
      backgroundColor: "background.default",
      overflowY: "auto",
    },
    headerContainer: {
      width: "100%",
      padding: "10px 20px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0 auto",
      borderBottom: "1px solid",
      borderColor: "primary.main",
    },

    title: {
      color: "text.primary",
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: "400",
    },

    closeDrawerButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
    editBody: {
      width: "100%",
      padding: "10px 20px",
    },
    paymentScheduelCard: {
      border: "background.border",
      backgroundColor: "background.default",
      padding: "8px",
      width: "98%",
      margin: "15px auto 20px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "top",
      fontFamily: "Poppins",
      minHeight: '93px'
    },
    oneTimePaymentCard: {
      border: "background.border",
      backgroundColor: "background.default",
      padding: "8px",
      width: "95%",
      margin: "10px auto",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
      fontFamily: "Poppins",
    },
    iconContainer: {
      display: "flex",
      flex: "0.25",
      margin: "0 auto",
    },
    chipDesign: {
      backgroundColor: "background.paymentScheduleChip",
      color: "primary.paymentScheduleChip",
      height: "24px",
      fontWeight: "500",
      lineHeight: "18px",
      fontFamily: "Archivo",
      fontSize: "14px",
      borderRadius: "4px",
      marginRight: "5px",
    },
    paymentInfo: {
      flex: "2",
      paddingLeft: "6px",
      // border: "2px solid red",
    },
    amountTotalContainer: {
      display: "flex",
      flex: "flex-end",
      padding: "0 8px",
      // border: "2px solid red",
    },
    amountTotal: {
      fontSize: "20px",
      fontWeight: "500",
      height: "24px",
      fontFamily: "Poppins",
      alignSelf: "bottom",
    },
    perMonth: {
      fontSize: "12px",
      fontWeight: "400px",
      height: "24px",
      fontFamily: "Poppins",
      alignSelf: "bottom",
      marginTop: "10px",
      paddingLeft: "5px",
    },
    dropDownContainers: {
      marginBottom: "20px",
    },

    paymentSelector: {
      width: "50%", 
    },
    saveButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
    saveAndCancelButtons: {
      marginTop: "30px",
      height: "42px",
      fontSize: "15px",
    },
    errorMessage: {
      padding: "0px 20px",
      width: "60%",
      margin: "20px auto",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      fontSize: "20px",
    },
  };


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  //payment schedule options
  let schedules = [
    { id: 1, name: "Monthly" },
    { id: 2, name: "Semi-Monthly" },
  ];

  const [paymentScheduleSelected, setPaymentScheduleSelected] = useState({});


  useEffect(() => {
    //set full paymentSchedule selected and paymentOne and paymentTwo depending on scheduled payment dates
    if (
      props?.editScheduleClicked &&
      props?.editScheduleClicked?.scheduledPaymentDates?.length === 1
    ) {
      let firstPayment =
        props?.editScheduleClicked?.scheduledPaymentDates[0].dayOfMonth;
      console.log("first payment", firstPayment);
      setPaymentScheduleSelected({
        ...props?.editScheduleClicked,
        paymentOne: firstPayment,
      });
    } else if (
      props?.editScheduleClicked &&
      props?.editScheduleClicked?.scheduledPaymentDates?.length === 2
    ) {
      let firstPayment =
        props?.editScheduleClicked?.scheduledPaymentDates[0].dayOfMonth;
      let secondPayment =
        props?.editScheduleClicked?.scheduledPaymentDates[1].dayOfMonth;
      console.log("first payment second block", firstPayment);
      setPaymentScheduleSelected({
        ...props?.editScheduleClicked,
        paymentOne: firstPayment,
        paymentTwo: secondPayment,
      });
    }
  }, [props?.editScheduleClicked?.id]);


  //determine Chip fill (monthly vs semi-monthly based on paymentFrequencyId)
  let memberPaymentFrequency = props.paymentFrequencies?.find((id) => {
    //if member paymentFrequencyId = 1, frequency is monthly, if 2, semi-monthly
    return id.id === paymentScheduleSelected?.paymentFrequencyId;
  })

  const dayFormatter = (number) => {
    if (number > 0) {
      let formattedNumber =
        parseInt(number) % 10 == 1 && parseInt(number) != 11
          ? number + "st"
          : parseInt(number) % 10 == 2 && parseInt(number) != 12
          ? number + "nd"
          : parseInt(number) % 10 == 3 && parseInt(number) != 13
          ? number + "rd"
          : number + "th";

      return formattedNumber;
    } else return "Select A Date";
  };

  const payScheduleHandler = (date, oneOrTwo) => {
    let key = `payment${oneOrTwo}`;
    setPaymentScheduleSelected({
      ...paymentScheduleSelected,
      [key]: date,
    });
  };

  //update frequency and schedule payments listed accordingly
  const handlePaymentFrequency = (e) => {
    let value = e.target.value;
    if (value == 1) {
      setPaymentScheduleSelected({
        ...paymentScheduleSelected,
        paymentFrequencyId: value,
        paymentOne: 1,
        paymentTwo: null,
      });
    } else if (value == 2) {
      setPaymentScheduleSelected({
        ...paymentScheduleSelected,
        paymentFrequencyId: value,
        paymentOne: 1,
        paymentTwo: 15,
      });
    }
  };

  const handleSaveNewSchedule = async (e) => {
    setLoading(true)
    let _newSchedule;
    //check if schedule is  monthly or semi-monthly
    //copy original schedule form props.editScheduleclicked and update paymentFrequencyId and SchedulePaymentDates
    if (paymentScheduleSelected?.paymentFrequencyId === 1) {
      _newSchedule = {
        ...props?.editScheduleClicked,
        paymentFrequencyId: 1,
        scheduledPaymentDates: [
          {
            ...props?.editScheduleClicked?.scheduledPaymentDates[0],
            scheduledPaymentId: paymentScheduleSelected?.id,
            dayOfMonth: paymentScheduleSelected?.paymentOne,
          },
        ],
      };
      console.log("new schedule", _newSchedule);
    } else if (paymentScheduleSelected?.paymentFrequencyId === 2) {
      //if previous schedule was semi-monthly, only need to udate the date of month, else add additional object to scheduled payment dates array
      if(props?.editScheduleClicked?.paymentFrequencyId === 2){
        _newSchedule = {
            ...props?.editScheduleClicked,
            paymentFrequencyId: 2,
            scheduledPaymentDates: [
              {
                ...props?.editScheduleClicked?.scheduledPaymentDates[0],
                scheduledPaymentId: paymentScheduleSelected?.id,
                dayOfMonth: paymentScheduleSelected?.paymentOne,
              },
              {
                ...props?.editScheduleClicked?.scheduledPaymentDates[1],
                scheduledPaymentId: paymentScheduleSelected?.id,
                dayOfMonth: paymentScheduleSelected?.paymentTwo,
              }, 
            ],
          };
      } else {
        _newSchedule = {
            ...props?.editScheduleClicked,
            paymentFrequencyId: 2,
            scheduledPaymentDates: [
              {
                ...props?.editScheduleClicked?.scheduledPaymentDates[0],
                scheduledPaymentId: paymentScheduleSelected?.id,
                dayOfMonth: paymentScheduleSelected?.paymentOne,
              },
              {
                scheduledPaymentId: paymentScheduleSelected?.id,
                dayOfMonth: paymentScheduleSelected?.paymentTwo,
              }, 
            ],
          };
      }
    }

    try {
      let updatedSchedule = await walletService.updatePaymentScheduleForMember(_newSchedule);
      if(updatedSchedule){
        props?.setEditScheduleSuccess(true)
        setTimeout(() => {
          props?.setEditScheduleSuccess(false)
        }, 4000);
        
      }
    } catch (error) {
      console.error("error updating payment schedule", error);
      setError(error?.message)
      setLoading(false)
    }

    if(!error){
        setTimeout(() => {
            handleCloseDrawer()
            setLoading(false)    
        }, 1000);
    }
    props?.getPaymentSchedule()   
    
  };

  //Drawer Logic
  const handleCloseDrawer = () => {
    props?.setEditScheduleClicked(false);
    props?.setOpenEditScheduleDrawer(false);
    setError(false)
    //clear and close form
  };

  //Cancel and exit drawer
  const handleCancelClick = () => {
    props?.setEditScheduleClicked(false);
    props?.setOpenEditScheduleDrawer(false);
    setError(false)
    //clear and close form
  };


  return (
    <>
      <Drawer
        anchor="right"
        open={props?.openEditScheduleDrawer}
        onClose={handleCloseDrawer}
      >
        <Box sx={componentStyles.componentBody}>
          <Box className="flex-row" sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.title}>
              Manage Schedule
            </Typography>

            <Box
              className="col-6"
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleCloseDrawer}
            >
              <CloseIcon sx={{ height: "34px" }} />
            </Box>
          </Box>

          {/* Schedule Card */}
          <Box sx={componentStyles.editBody}>
            <Card
              elevation={0}
              variant="outlined"
              sx={componentStyles.paymentScheduelCard}
            >
              <Box style={componentStyles.iconContainer}>
                <CalendarTodayIcon
                  sx={{
                    color: "primary.main",
                    margin: "6px auto",
                  }}
                />
              </Box>

              {/* Payment Schedule & amount(s) - looking at paymentOne and paymentTwo amounts on the */}

              <Box style={componentStyles.paymentInfo}>
                <Box className="flex-row">
                  <Chip
                    sx={componentStyles.chipDesign}
                    label={memberPaymentFrequency?.name.toUpperCase() || ""}
                  />
                  {/* Single Date - make sure paymentFrequencyId is 1*/}
                  {paymentScheduleSelected?.scheduledPaymentDates && paymentScheduleSelected?.scheduledPaymentDates?.length > 0 && paymentScheduleSelected?.paymentFrequencyId === 1 &&(
                    <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
                      {paymentScheduleSelected?.paymentOne && props?.getDaySuffix(paymentScheduleSelected?.paymentOne)}
                    </Typography>
                  )}

                  {/* Multiple dates */}
                  {paymentScheduleSelected?.scheduledPaymentDates && paymentScheduleSelected?.paymentTwo && paymentScheduleSelected?.paymentFrequencyId === 2 &&
                    <>
                        <Typography
                          variant="body2"
                          sx={{ fontFamily: "Poppins", marginRight: '5px' }}
                        >
                          {props?.getDaySuffix(paymentScheduleSelected?.paymentOne)} & 
                        </Typography>
                
                        <Typography
                          variant="body2"
                          sx={{ fontFamily: "Poppins" }}
                        >
                          {props?.getDaySuffix(paymentScheduleSelected?.paymentTwo)}
                        </Typography>
                  </>
                 }
                   
                </Box>

                {/* Payment amounts - split if multiple payments */}
                <Box>
                  {paymentScheduleSelected?.scheduledPaymentDates && !paymentScheduleSelected?.paymentTwo &&
                        <Typography
                      
                          variant="body2"
                          sx={{ color: "text.secondary", fontWeight: "400" }}
                        >
                          Payment -{" "}
                          {(paymentScheduleSelected?.paymentAmount && paymentScheduleSelected?.transactionFee &&
                            props.UsdFormatterDec.format(
                              paymentScheduleSelected?.paymentAmount + paymentScheduleSelected?.transactionFee
                            )) ||
                            ""}
                        </Typography>
                    }
                   

                    {/* // if 2 or MORE dates -checking the paymentOne and paymentTwo values */}
                    {paymentScheduleSelected?.scheduledPaymentDates && paymentScheduleSelected?.paymentTwo &&
                    <>
                        <Typography
                      
                          variant="body2"
                          sx={{ color: "text.secondary", fontWeight: "400" }}
                        >
                          First Payment -{" "}
                          {(paymentScheduleSelected?.payments[1] && paymentScheduleSelected?.transactionFee &&
                            props.UsdFormatterDec.format((paymentScheduleSelected?.payments[1] + paymentScheduleSelected?.transactionFee))) ||
                            ""}
                        </Typography>
                  
                        <Typography
                      
                          variant="body2"
                          sx={{ color: "text.secondary", fontWeight: "400" }}
                        >
                          Second Payment -{" "}
                          {(paymentScheduleSelected?.payments[2] && paymentScheduleSelected?.transactionFee &&
                            props.UsdFormatterDec.format(paymentScheduleSelected?.payments[2] + paymentScheduleSelected?.transactionFee))  ||
                            ""}
                        </Typography>

                      </>
                      }
                </Box>
              </Box>

              {/* Total Monthly Payment Amount */}
              <Box
                className="flex-row"
                sx={componentStyles.amountTotalContainer}
              >
                <Typography variant="body1" sx={componentStyles.amountTotal}>
                  {(paymentScheduleSelected?.paymentAmount && paymentScheduleSelected?.transactionFee &&
                    props.UsdFormatterDec.format(
                      paymentScheduleSelected?.paymentAmount + paymentScheduleSelected?.paymentFrequencyId
                    )) ||
                    ""}
                </Typography>
                <Typography variant="body1" sx={componentStyles.perMonth}>
                  /per&nbsp;month
                </Typography>
              </Box>
            </Card>

            {/* Edit fields */}
           
              <Box >
                {/* ACTION */}
                <TextField
                  fullWidth
                  select
                  id="action"
                  sx={{
                    "& fieldset": { borderRadius: "4px" },
                    marginBottom: "20px",
                  }}
                  label="Action"
                  disabled={true}
                  value={"Edit Payment Schedule"}
                >
                  <MenuItem value={"Edit Payment Schedule"}>
                    Edit Payment Schedule
                  </MenuItem>
                  {/* <MenuItem disabled={true} value={""}>
                    Delay Upcoming Payment
                  </MenuItem> */}
                </TextField>
              </Box>

          <Box sx={{...componentStyles.dropDownContainers, display: 'flex', flexDirection: paymentScheduleSelected?.paymentFrequencyId === 1 ? 'row' : 'column',}}>
                  {/* FREQUENCY */}
                  <Box sx={{width:paymentScheduleSelected?.paymentFrequencyId === 1 ? '50%' : '100%', marginBottom: '20px'}}>
                    <TextField
                      fullWidth
                      select
                      id="frequency"
                      sx={{
                        "& fieldset": { borderRadius: "4px" },
                      }}
                      // onBlur={checkRoutingNumber}
                      label="Frequency"
                      onChange={handlePaymentFrequency}
                      value={paymentScheduleSelected?.paymentFrequencyId}
                    >
                      {schedules?.map((schedule) => (
                        <MenuItem key={schedule.id} value={schedule.id}>
                          {schedule.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                {/* Monthly or semi-monthly schedules depending on frequency */}

                {paymentScheduleSelected?.paymentFrequencyId == 1 ? (
                  <Box
                    className="flex-row"
                    sx={{...componentStyles.paymentSelector, marginLeft: "15px",  "& fieldset": { borderRadius: "4px" },}}
                  >
                    <UmpDatePicker
                      bank={paymentScheduleSelected}
                      oneOrTwo="One"
                      payScheduleHandler={payScheduleHandler}
                      schedule={paymentScheduleSelected?.paymentFrequencyId}
                      dayFormatter={dayFormatter}
                    />
                  </Box>
                ) : (
                  <Box
                    className="flex-row"
                    sx={componentStyles.multiPaymentSchedule}
                  >
                    <Box sx={{...componentStyles.paymentSelector, marginRight: "15px", "& fieldset": { borderRadius: "4px"}}}>
                      <UmpDatePicker
                        bank={paymentScheduleSelected}
                        otherVal={paymentScheduleSelected?.paymentTwo}
                        oneOrTwo="One"
                        payScheduleHandler={payScheduleHandler}
                        schedule={paymentScheduleSelected?.paymentFrequencyId}
                        dayFormatter={dayFormatter}
                      />
                    </Box>

                    <Box sx={{...componentStyles.paymentSelector, "& fieldset": { borderRadius: "4px"}}}>
                      <UmpDatePicker
                        bank={paymentScheduleSelected}
                        otherVal={paymentScheduleSelected?.paymentOne}
                        oneOrTwo="Two"
                        payScheduleHandler={payScheduleHandler}
                        schedule={paymentScheduleSelected?.paymentFrequencyId}
                        dayFormatter={dayFormatter}
                      />
                    </Box>
                </Box>
              )}
            </Box>

{/* 
            <Box
              className="flex-row container"
              sx={{
                padding: "20px 0px",
                position: "relative",
                marginBottom: "15px",
                fontFamily: "Archivo",
              }}
            >
              <Checkbox
                // onChange={}
                // value={}
                // checked={}
                // disabled={true}
                name={"generateNewForm"}
                sx={{
                  padding: "0px",
                  marginRight: "12px",
                }}
                icon={<CheckBoxOutlineBlankIcon sx={{ height: "19px" }} />}
                checkedIcon={<CheckBoxIcon sx={{ height: "19px" }} />}
              />
              Disable scheduled payments
            </Box> */}
          </Box>

          {/* Cancel and Save Buttons */}
          <Box className="flex-row" sx={componentStyles.saveButtonContainer}>
            <Button
              sx={{
                ...componentStyles.saveAndCancelButtons,
                marginRight: "15px",
              }}
              onClick={handleCancelClick}
            >
              CANCEL
            </Button>

            <Button
              variant="contained"
              sx={componentStyles.saveAndCancelButtons}
              //   disabled={disableSave}
              // size="large"
              onClick={handleSaveNewSchedule}
              didsabled = {loading}
            >
                {loading ? (
                <ButtonInfinityLoader buttonTitle={"SAVE"} />
              ) : (
                "SAVE"
              )}
            </Button>
          </Box>

          {/* display error if save new or update existing account fails */}
          {error && (
            <Alert sx={componentStyles.errorMessage} severity="error">
              <Typography> {error} </Typography>
            </Alert>
          )}
        </Box>
      </Drawer>
    </>
  );
}

import React, { useState, useEffect } from "react";

//MuiIcons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//MuiComponents
import Collapse from "@mui/material/Collapse";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider, { dividerClasses } from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip, Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";

//Our Components
import InfinityLoader from "../../../../UH-loading-animation/InfinityLoader";
import RecommendedBenefitDisplay from "../../RecommendedBenefitDisplay";

//Our icons
import CustomCheckIcon from "../../../../../assets/images/CustomCheckIcon.svg";

//Our Services
import optionService from "../../../../../services/optionService";

//Our Enrollment Plan Styles
import enrollmentPlanStyles from "../enrollmentPlanStyles";

export default function EnrollmentLtdIncremental(props) {
  //DEBUG
  //  useEffect(() => {
  //     let fiveYear = []
  //     let twoYear = []
  //     let ltdPlan = props.results?.ltdPlan
  //     for (let option of ltdPlan.options){
  //        if (option.benefitDuration == 2) twoYear.push(option)
  //        if (option.benefitDuration == 5) fiveYear.push(option)
  //     }
  //     // console.log(twoYear,fiveYear)
  //  },[props.results?.ltdPlan])


  /////USD formatting
  let UsdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  //Expand and Collapse view
  const [expanded, setExpanded] = useState(true)
  const handleExpandSection = () => {
    setExpanded(!expanded)
  }

  //Data from ltd plan from props:
  // console.log("Props form Enrollment Plans\n\n", props);
  let results = props.results;
  let ltdPlan = results.ltdPlan.isIncremental ? results.ltdPlan : "";

  let ltdDeclinePlan = ltdPlan?.options?.find((option) => option.isDecline);
  let previousPlanChosen =
    props.selections.ltdVal > 0
      ? ltdPlan?.options?.find(
        (plan) => plan.memberOptionId == props.selections.ltd
      )
      : null;

  let ltdHelpText = ltdPlan?.helpText ? ltdPlan.helpText : "";

  ///// ELIMINATION PERIOD & BENEFIT DURATION LOGIC /////

  //Data from services
  const [eliminationPeriodTypes, setEliminationPeriodTypes] = useState();
  const [benefitDurTypes, setBenefitDurTypes] = useState();
  // calling to services for elim period and benefit option type ids
  // const getElimPeriodList = async () => {
  //   let elimPeriodTypeList;
  //   try {
  //     elimPeriodTypeList = await optionService.getEliminationPeriodTypes();
  //   } catch (error) {
  //     console.log("error getting elimination periods", error);
  //   }

  //   // console.log("ElilminationPeriod List \n\n", elimPeriodTypeList);
  // };

  // const getBenefitDurationList = async () => {
  //   let benefitDurTypeList;
  //   try {
  //     benefitDurTypeList = await optionService.getBenefitDurationTypes();
  //   } catch (error) {
  //     console.log("error getting elimination periods", error);
  //   }

  //   // console.log("BenefitDuration List \n\n", benefitDurTypeList);
  // };

  // useEffect(() => {
  //   //getting lists from option service
  //   getElimPeriodList();
  //   getBenefitDurationList();
  // }, []);

  //Data from a member's options

  //selected elimination period
  const [selectedEliminationPeriod, setSelectedEliminationPeriod] =
    useState(null);

  //get only unique values of elimination period from member options excluding duplicates
  const getUniqueEliminationPeriodList = () => {
    let elimPeriodInfo = [];

    for (let option of ltdPlan?.options) {
      if (option.eliminationPeriod > 0 && option.benefitTypeId != 2) {
        let object = {
          eliminationPeriod: option.eliminationPeriod,
          type: option.eliminationPeriodType,
          typeId: option.eliminationPeriodTypeId,
        };
        elimPeriodInfo.push(object);
      }
    }

    let uniqueElimPeriodArr = [
      ...new Set(elimPeriodInfo.map((obj) => obj.eliminationPeriod)),
    ];

    let uniqueElimPeriodOptions = uniqueElimPeriodArr.map(
      (eliminationPeriod) => {
        return elimPeriodInfo.find(
          (obj) => obj.eliminationPeriod === eliminationPeriod
        );
      }
    );

    setEliminationPeriodTypes(uniqueElimPeriodOptions);
    setSelectedEliminationPeriod(uniqueElimPeriodOptions[0]?.eliminationPeriod);
  };

  ////// selected benefit duration /////
  const [selectedBenefitDuration, setSelectedBenefitDuration] = useState();

  const getUniqueBenefitDurationList = () => {
    let benefitDurationInfo = [];

    for (let option of ltdPlan?.options) {
      if (option.benefitDuration > 0 && option.benefitTypeId != 2) {
        let object = {
          duration: option.benefitDuration,
          type: option.benefitDurationType,
          typeId: option.benefitDurationTypeId,
        };
        benefitDurationInfo.push(object);
      }
    }

    let uniqueBenefitDurList = [
      ...new Set(benefitDurationInfo.map((obj) => obj.duration)),
    ];

    let uniqueBenefitDurOptions = uniqueBenefitDurList.map((duration) => {
      return benefitDurationInfo.find((obj) => obj.duration === duration);
    });

    //set member benefit options off of unique options only
    setBenefitDurTypes(uniqueBenefitDurOptions);
    setSelectedBenefitDuration(uniqueBenefitDurOptions[0]?.duration);
  };

  const handleSelectBenefitDuration = (e) => {
    // console.log("benefit e.target", e.target);
    setSelectedBenefitDuration(e.target.value);
    setRadioSelected(null);
  };

  useEffect(() => {
    if (ltdPlan?.options?.length > 0) {
      getUniqueEliminationPeriodList();
      getUniqueBenefitDurationList();
    }
  }, [ltdPlan.options]);

  const [ltdOptionsExcludingDecline, setLtdOptionsExcludingDecline] = useState(
    []
  );
  const [nonIncrementalLtdOptions, setNonIncrementalLtdOptions] = useState([]);

  useEffect(() => {
    if (ltdOptionsExcludingDecline?.length > 0 && props.selections && expanded) {
      initialPlanAmountDisplay();
    }
  }, [props.recommendations, props.results, props.selections]);
  // console.log('props.results in ltd',props.results, props.selections)

  useEffect(() => {
    if (ltdPlan?.options?.length > 0) {
      //array of all options excluding the $0 decline amount & benefit duration selected
      let filteredLtdOptions = ltdPlan?.options?.filter((option) => {
        return (
          option.isDecline === false &&
          option.benefitDuration === selectedBenefitDuration &&
          option.eliminationPeriod === selectedEliminationPeriod &&
          option.benefitTypeId != 2
        );
      });

      let filteredNonIncrementLtdOptions = ltdPlan?.options?.filter((option) => {
        return (
          option.isDecline === false &&
          option.benefitTypeId == 2
        );
      });

      setNonIncrementalLtdOptions(filteredNonIncrementLtdOptions);

      setLtdOptionsExcludingDecline(filteredLtdOptions);

    }
  }, [ltdPlan.options, selectedBenefitDuration, selectedEliminationPeriod]);

  //AUTO SELECT DECLINE if decline option is the only option that exists
  useEffect(() => {
    if (props?.results && props?.results?.ltdPlan?.options?.length === 1 && ltdDeclinePlan?.memberOptionId) {
      setLtdPlanChosen(ltdDeclinePlan);
      setRadioSelected(ltdDeclinePlan.memberOptionId);
      setLtdSlider(0);
      //update selection in db
      props?.handleEnrollClick(ltdDeclinePlan?.memberOptionId)
    }

  }, [props?.results?.ltdPlan?.options?.length, ltdOptionsExcludingDecline?.length, ltdDeclinePlan?.memberOptionId])


  //change value of elim period w/ select drop down
  const handleSelectEliminationPeriod = (e) => {
    //update value w/ selected elimination period value
    setSelectedEliminationPeriod(e.target.value);
  };
  const [loading, setLoading] = useState(false);
  const [ltdPlanChosen, setLtdPlanChosen] = useState(previousPlanChosen);
  const [currRec, setCurrRec] = useState(0); //hold the rec position in state to render radio w/ recommended option
  const [ltdSlider, setLtdSlider] = useState(0);
  const [radioSelected, setRadioSelected] = useState(null);

  //set recommendation amount from rec data
  useEffect(() => {
    if (props?.recommendations) {
      let recommendationPosition;
      if (ltdOptionsExcludingDecline.length > 1) {
        props.recommendations.Lowest
          ? (recommendationPosition = 1)
          : (recommendationPosition = ltdOptionsExcludingDecline.length - 2);
      } else if (ltdOptionsExcludingDecline.length == 1) {
        recommendationPosition = 0
      }
      //set recommendation amount to display 

      setCurrRec(ltdOptionsExcludingDecline[recommendationPosition]?.benefitAmount);
    }
  }, [props?.recommendations, ltdOptionsExcludingDecline?.length])


  useEffect(() => {
    if (ltdSlider > ltdOptionsExcludingDecline?.length - 1 && ltdOptionsExcludingDecline?.length > 0) {
      setLtdSlider(ltdOptionsExcludingDecline?.length - 1);
    }

    //if there is only one option, set slider to 0
    if (ltdOptionsExcludingDecline?.length === 1) {
      setLtdSlider(0);
    }

  }, [ltdOptionsExcludingDecline?.length, ltdSlider])

  // console.log("SELECTED PLAN \n\n", ltdPlanChosen);
  // console.log("Slider POsition \n\n", ltdSlider);
  // console.log("Benefit Duration \n\n", selectedBenefitDuration);

  //set initial rec of either lowest amount above $0 or recommended amount based on calculator

  const initialPlanAmountDisplay = () => {
    //IF a user has already saved a selection and is going back to this section, display saved selection
    let previousSelectedOption;
    let previousSelectedOptionValid = false;
    if (props.selections.ltdVal > 0) {
      //need to look at all options, and not just filtered
      previousSelectedOption = ltdPlan?.options?.find(
        (option) => option.memberOptionId === props.selections.ltd
      );
      //at this point in the code, ltdOptionsExcludingDecline is filtered by benefit duration, which is then set below, so it won't be able to find a previous selection if it's not in the list with default duration (2 year)
      if (previousSelectedOption) previousSelectedOptionValid = true
      // previousSelectedOptionValid = ltdOptionsExcludingDecline.includes(previousSelectedOption)
      // console.log('checking previous selection', previousSelectedOptionValid, ltdOptionsExcludingDecline, previousSelectedOption)
      if (previousSelectedOptionValid && benefitDurTypes?.length > 0 && benefitDurTypes.some(benDur => benDur.duration == previousSelectedOption?.benefitDuration)) {
        setSelectedBenefitDuration(previousSelectedOption?.benefitDuration);

      }
      //filter by benefit duration before displaying previous option selected
    }
    //db selection > 0
    if (previousSelectedOptionValid) {
      if (previousSelectedOption?.benefitAmount > 0) {
        for (let i = 0; i < ltdOptionsExcludingDecline.length; i++) {
          let optionId = ltdOptionsExcludingDecline[i]?.memberOptionId;
          if (optionId == previousSelectedOption.memberOptionId) {
            setLtdSlider(i);
            setLtdPlanChosen(ltdOptionsExcludingDecline[i]);
            setRadioSelected(ltdOptionsExcludingDecline[i].memberOptionId);
          }
        }
      }
      //no value saved in db
    } else if (props?.selections?.ltdVal == 0 && !props?.selections?.ltd) {
      let recommendationPosition;
      if (ltdOptionsExcludingDecline.length > 1) {
        props.recommendations.Lowest
          ? (recommendationPosition = 1)
          : (recommendationPosition = ltdOptionsExcludingDecline.length - 2);
      }
      //set recommendation to display
      setCurrRec(ltdOptionsExcludingDecline[recommendationPosition]?.benefitAmount);
      setLtdSlider(recommendationPosition);

      //decline saved in db
    } else if (props?.selections?.ltdVal == 0 && props?.selections?.ltd) {
      setLtdSlider(0);
      setLtdPlanChosen(ltdDeclinePlan);
      setRadioSelected(ltdDeclinePlan.memberOptionId);
    }

  };

  ////// SLIDER LOGIC

  const handleLtdSlider = (e) => {
    setLtdSlider(e.target.value);
    setLtdPlanChosen(ltdOptionsExcludingDecline[e.target.value]);
    setRadioSelected(ltdOptionsExcludingDecline[e.target.value].memberOptionId);
    //update sticky progress total
    if (ltdOptionsExcludingDecline[e.target.value].memberPremiumAmount && ltdOptionsExcludingDecline[e.target.value].memberOptionId) {
      props.setSelections({
        ...props.selections,
        ltdVal: ltdOptionsExcludingDecline[e.target.value].memberPremiumAmount,
        ltd: ltdOptionsExcludingDecline[e.target.value].memberOptionId,
      });

    }
  };

  const handleSliderComplete = (ltdSliderIndex) => {
    //update selection in db with new slider option only when slider is released
    props?.handleEnrollClick(ltdOptionsExcludingDecline[ltdSliderIndex]?.memberOptionId)
  }

  /////// RADIO BUTTON LOGIC

  const handleRadioSelection = (e) => {
    //handle selection to update sticky progress bar
    props.selectionHandler(e);
    //check if selection is the recommended or 'not now' option w/ the e.target
    //set value of radioButton state to update checked value
    let radioAmount = Number(e.target.getAttribute("data-amount"));
    let radioValue = Number(e.target.value);
    setRadioSelected(Number(e.target.value));

    //LOOP TO TEST E.TARGET.VALUE?
    let chosenPlan = ltdPlan?.options?.find((option) => {
      return option.memberOptionId == radioValue;
    });

    //set chosen plan to selected
    setLtdPlanChosen(chosenPlan);

    //find index of chosenPlan to update slider value
    if (radioAmount > 0) {
      let index = ltdOptionsExcludingDecline.indexOf(chosenPlan);
      setLtdSlider(index);
    } else {
      let index = ltdPlan?.options?.indexOf(chosenPlan);
      setLtdSlider(index);
    }

    //update selection in db
    props?.handleEnrollClick(chosenPlan?.memberOptionId)
  };

  const handleNonIncrementalRadioSelection = (e) => {
    //handle selection to update sticky progress bar
    props.selectionHandler(e);
    //check if selection is the recommended or 'not now' option w/ the e.target
    //set value of radioButton state to update checked value
    let radioAmount = Number(e.target.getAttribute("data-amount"));
    let radioValue = Number(e.target.value);
    setRadioSelected(Number(e.target.value));

    //LOOP TO TEST E.TARGET.VALUE?
    let chosenPlan = ltdPlan?.options?.find((option) => {
      return option.memberOptionId == radioValue;
    });

    //set chosen plan to selected
    setLtdPlanChosen(chosenPlan);

    // //find index of chosenPlan to update slider value
    // if (radioAmount > 0) {
    //   let index = ltdOptionsExcludingDecline.indexOf(chosenPlan);
    //   setLtdSlider(index);
    // } else {
    //   let index = ltdPlan?.options?.indexOf(chosenPlan);
    //   setLtdSlider(index);
    // }

    //update selection in db
    props?.handleEnrollClick(chosenPlan?.memberOptionId)
  };

  ///// BENEFIT DROPDOWN SELECT LOGIC

  const handleDropdownSelection = (e) => {
    //set radio button state to true if slider is moved - radioSelected value is only false if Not Now radio is clicked

    //handle the string value from dropdown list
    let tagName = e.target.tagName.toLowerCase();
    if (tagName === "li") {
      let value = e.target.innerHTML.slice(1).replace(",", "");
      //remove strings 'max' and '(recommended)' from value
      if (value.includes('(max)')) value = value.replace('(max)', '')
      if (value.includes('(recommended)')) value = value.replace('(recommended)', '')

      for (let i = 0; i < ltdOptionsExcludingDecline.length; i++) {
        let benefitAmount = ltdOptionsExcludingDecline[i]?.benefitAmount;
        if (benefitAmount == value) {
          setLtdSlider(i);
          setLtdPlanChosen(ltdOptionsExcludingDecline[i]);
          setRadioSelected(ltdOptionsExcludingDecline[i].memberOptionId);

          //update selection in db
          props?.handleEnrollClick(ltdOptionsExcludingDecline[i]?.memberOptionId)

          //update sticky progress total
          if (ltdOptionsExcludingDecline[i].memberPremiumAmount && ltdOptionsExcludingDecline[i].memberOptionId) {
            props.setSelections({
              ...props.selections,
              ltdVal: ltdOptionsExcludingDecline[i].memberPremiumAmount,
              ltd: ltdOptionsExcludingDecline[i].memberOptionId,
            });

          }
        }
      }
    } else if (tagName === "input") {
      let value = e.target.value.slice(1).replace(",", "");
      //remove strings 'max' and '(recommended)' from value
      if (value.includes('(max)')) value = value.replace('(max)', '')
      if (value.includes('(recommended)')) value = value.replace('(recommended)', '')

      for (let i = 0; i < ltdOptionsExcludingDecline.length; i++) {
        let benefitAmount = ltdOptionsExcludingDecline[i]?.benefitAmount;
        if (benefitAmount == value) {
          setLtdSlider(i);
          setLtdPlanChosen(ltdOptionsExcludingDecline[i]);
          setRadioSelected(ltdOptionsExcludingDecline[i].memberOptionId);

          //update selection in db
          props?.handleEnrollClick(ltdOptionsExcludingDecline[i]?.memberOptionId)

          //update sticky progress total
          if (ltdOptionsExcludingDecline[i].memberPremiumAmount && ltdOptionsExcludingDecline[i].memberOptionId) {
            props.setSelections({
              ...props.selections,
              ltdVal: ltdOptionsExcludingDecline[i].memberPremiumAmount,
              ltd: ltdOptionsExcludingDecline[i].memberOptionId,
            });

          }
        }
      }
    }
  };

  let maxBenefit = ltdOptionsExcludingDecline?.length > 1 ? ltdOptionsExcludingDecline[ltdOptionsExcludingDecline.length - 1]?.benefitAmount : ltdOptionsExcludingDecline[0]?.benefitAmount

  //display value logic for dropdown select
  let benefitSelectionValue = () => {
    if (ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount === maxBenefit && maxBenefit !== currRec) { //last but not rec amount
      return `${UsdFormatter.format(ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount)} (max)`
    } else if (ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount === maxBenefit && maxBenefit === currRec) { //last and rec amount
      return `${UsdFormatter.format(ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount)} (recommended)`
    } else if (ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount === currRec && currRec !== maxBenefit) { //rec amount but not last
      return `${UsdFormatter.format(ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount)} (recommended)`
    } else {
      return UsdFormatter.format(ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount)
    }
  }

  let expandIcon = expanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "white",
        backgroundColor: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  );


  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: "absolute",
              zIndex: 5,
              width: "100%",
              height: "100vh",
              backgroundColor: "#00000050",
              backdropFilter: "blur(10px)",
            }}
          />
          <style>
            {`
              div.lottie-div > svg {
                  height:55px!important;
                  position:absolute;
                  top:50%;
              }
              .plan-explanation-innerHTML ul {
                padding-left: 1.3rem;
              }
            `}
          </style>
        </>
      )}
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <span
          style={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          Long-Term Disability
        </span>

        <Box
          onClick={handleExpandSection}
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          {expandIcon}
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: ltdHelpText }}
          />
        </Box>

        
        {ltdOptionsExcludingDecline?.length > 0 ?
          (<>

            {/* Elimination Period & Benefit Duration select*/}
            {ltdOptionsExcludingDecline?.length > 0 &&
              <Box sx={enrollmentPlanStyles.elimAndDurRowContainer}>

                {/* Elimination Period */}
                <TextField
                  disabled={eliminationPeriodTypes?.length <= 1}
                  select
                  variant="outlined"
                  label="Elimination Period"
                  InputLabelProps={{
                    sx: {
                      '&.Mui-disabled': {
                        color: 'unset'
                      },
                      color: 'primary.main'
                    }
                  }}
                  InputProps={{
                    sx: {
                      pointerEvents: eliminationPeriodTypes?.length <= 1 ? 'none' : ''
                    }
                  }}
                  value={selectedEliminationPeriod || ""}
                  sx={enrollmentPlanStyles.selectInput}
                  onChange={handleSelectEliminationPeriod}
                  SelectProps={{
                    IconComponent: () => eliminationPeriodTypes?.length <= 1 ? null : <ArrowDropDownIcon sx={{ position: 'absolute', right: 7, color: 'primary.main' }} />
                  }}

                >
                  {/* select options base on unique elimination period options */}
                  {eliminationPeriodTypes?.map((option) => (
                    <MenuItem
                      key={option.eliminationPeriod}
                      value={option.eliminationPeriod}
                    >
                      {option.eliminationPeriod} {option.type}
                    </MenuItem>
                  ))}
                </TextField>


                {/* Benefit Duration */}
                <TextField
                  disabled={benefitDurTypes?.length <= 1}
                  select
                  variant="outlined"
                  label="Benefit Duration"
                  InputLabelProps={{
                    sx: {
                      '&.Mui-disabled': {
                        color: 'unset'
                      },
                      color: 'primary.main'
                    }
                  }}
                  InputProps={{
                    sx: {
                      pointerEvents: benefitDurTypes?.length <= 1 ? 'none' : ''
                    }
                  }}
                  value={selectedBenefitDuration || ""}
                  sx={enrollmentPlanStyles.selectInput}
                  onChange={handleSelectBenefitDuration}
                  SelectProps={{
                    IconComponent: () => benefitDurTypes?.length <= 1 ? null : <ArrowDropDownIcon sx={{ position: 'absolute', right: 7, color: 'primary.main' }} />
                  }}
                >
                  {benefitDurTypes?.map((option) => (
                    <MenuItem key={option.duration} value={option.duration}>
                      {option.duration} {option.type}
                    </MenuItem>
                  ))}
                </TextField>


              </Box>
            }

            

            {/* //////// Benefit amount select and slider - only show if more than decline option exists /////////// */}

            {/* Radio Button and Option Row */}
            <Box sx={enrollmentPlanStyles.optionRow} >
              <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                <Radio
                  sx={enrollmentPlanStyles.planSelectRadioButton}
                  value={ltdOptionsExcludingDecline[ltdSlider]?.memberOptionId}
                  name="ltd"
                  icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                  checkedIcon={<RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                  onClick={handleRadioSelection}
                  checked={
                    radioSelected !== ltdDeclinePlan.memberOptionId &&
                    radioSelected != null &&
                    radioSelected == ltdOptionsExcludingDecline[ltdSlider]?.memberOptionId
                  }
                  inputProps={{
                    "data-amount":
                      ltdOptionsExcludingDecline[ltdSlider]?.memberPremiumAmount,
                    "data-property": "ltdMemberTrue_radio",
                  }}
                />

                <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                  <Typography sx={enrollmentPlanStyles.singleOptionText(ltdOptionsExcludingDecline.some(opt => opt?.memberOptionId == ltdPlanChosen?.memberOptionId))}>
                    {`Member (${UsdFormatter.format(
                      ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount || 0
                    )})`}
                  </Typography>
                </Box>
              </Box>

              {/* premium amount */}
              <Box sx={enrollmentPlanStyles.singleOptionText(ltdOptionsExcludingDecline.some(opt => opt?.memberOptionId == ltdPlanChosen?.memberOptionId))}>
                {ltdPlanChosen?.benefitAmount > 0 && "+"}
                {!props.dummyDisplay &&
                  UsdFormatterDec.format(
                    ltdOptionsExcludingDecline[ltdSlider]?.memberPremiumAmount ||
                    0
                  )}
                {props.dummyDisplay && "$0.00"}
              </Box>
            </Box>

            <RecommendedBenefitDisplay currRec={currRec} />

            {/* Explanation of Benefits */}
            <Box sx={enrollmentPlanStyles.explanationOfBenefitsTextIncremental}>
              {ltdOptionsExcludingDecline[ltdSlider]?.explanationOfBenefits}
            </Box>

            {ltdOptionsExcludingDecline[ltdSlider]?.benefitOffsets && (
              <Box sx={enrollmentPlanStyles.explanationOfBenefitsTextIncremental}>
                {ltdOptionsExcludingDecline[ltdSlider]?.benefitOffsets}
              </Box>
            )}

            

            {/* DROPDOWN - display if more than one option */}
            {ltdOptionsExcludingDecline?.length > 1 &&
              <Box sx={enrollmentPlanStyles.benefitAmountDropdownContainer}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  autoComplete
                  options={ltdOptionsExcludingDecline.map((option, i) => {
                    if (ltdOptionsExcludingDecline?.length > 1) {
                      let isLast = (i == ltdOptionsExcludingDecline.length - 1)
                      // last but not rec amount
                      if (isLast && currRec != option?.benefitAmount) { // last but not rec amount
                        return `${UsdFormatter.format(option.benefitAmount)} (max)`
                      } else if (isLast && currRec === option?.benefitAmount) { //last and = rec amount
                        return `${UsdFormatter.format(option.benefitAmount)} (recommended)`
                      } else if (!isLast && currRec === option?.benefitAmount) { //not last and = rec amount
                        return `${UsdFormatter.format(option.benefitAmount)} (recommended)`
                      } else {
                        return UsdFormatter.format(option.benefitAmount)
                      }
                    } else if (ltdOptionsExcludingDecline?.length == 1) {
                      return UsdFormatter.format(ltdOptionsExcludingDecline[0].benefitAmount)
                    }
                  })}
                  value={benefitSelectionValue() || 0}
                  onChange={handleDropdownSelection}
                  componentsProps={{
                    popupIndicator: { sx: { color: 'primary.main' } }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} InputLabelProps={{ sx: { color: 'primary.main' } }} label="Benefit Amount" sx={enrollmentPlanStyles.activeBlueDropdownField(false)} />
                  )}
                />
              </Box>
            }

            {/* SLIDER */}

            {ltdOptionsExcludingDecline?.length > 1 &&
              <Box sx={enrollmentPlanStyles.incSliderSection}>

                {/* Slider min */}
                <Box sx={enrollmentPlanStyles.incSliderLowAmount}>
                  {UsdFormatter.format(ltdOptionsExcludingDecline[0]?.benefitAmount || 0)}
                </Box>

                {/* Slider container */}
                <Box sx={enrollmentPlanStyles.incSliderContainer}>
                  <Slider
                    value={ltdSlider || 0}
                    onChange={handleLtdSlider}
                    onChangeCommitted={() => handleSliderComplete(ltdSlider)}
                    step={1}
                    min={0}
                    max={ltdOptionsExcludingDecline.length - 1}
                    sx={{ width: '90%', marginLeft: '5%', marginBottom: '0px' }}
                    valueLabelDisplay="auto"
                    valueLabelFormat={UsdFormatter.format(
                      ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount
                    )}
                  />
                </Box>

                {/* Slider max */}
                <Box sx={enrollmentPlanStyles.incSliderHighAmount}>
                  {UsdFormatter.format(ltdOptionsExcludingDecline[ltdOptionsExcludingDecline.length - 1]?.benefitAmount || 0)}
                </Box>
              </Box>
            }
          </>) : (
             nonIncrementalLtdOptions?.length == 0 && 
            <Box sx={enrollmentPlanStyles.noOptionsAvailableMsg}>
              You do not have any Long-Term Disability options available.
            </Box>
          )
        }

        <RadioGroup
        // onChange={handleNonIncrementalRadioSelection}
        value={props.selections.ltd || currRec}
        >
        {results &&
          ltdPlan.options
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map((option, index) => {
              let isSelected =
                props.selections.ltd == option.memberOptionId ||
                (!props.selections.ltd && currRec == option.memberOptionId);

              if (!option.isDecline && option.benefitTypeId == 2) {
                return (
                  <React.Fragment key={`${JSON.stringify(option)}`}>
                    <Box sx={enrollmentPlanStyles.optionRow}>
                      {/* radio button, benefit amount, type */}
                      <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                        <Radio
                          sx={enrollmentPlanStyles.planSelectRadioButton}
                          value={option.memberOptionId}
                          id={`${option.memberOptionId}`}
                          name="ltd"
                          icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                          checkedIcon={
                            <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                          }
                          onClick={
                            handleNonIncrementalRadioSelection
                            //   // props.setExpanded({...props.expanded,  ladd: true })
                          }
                          inputProps={{
                            "data-amount": option.memberPremiumAmount,
                          }}
                        />

                        <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                          <Typography sx={enrollmentPlanStyles.singleOptionText(isSelected)}>
                            {option?.name &&
                              <>
                                {option.name} {option.name.includes('%') && option?.benefitAmount && `(Max ${UsdFormatter.format(option.benefitAmount)})`}
                              </>
                            }
                          </Typography>

                          {option?.benefitAmount == currRec && <Typography sx={enrollmentPlanStyles.recommendedText}>{` (recommended)`}</Typography>}
                        </Box>
                      </Box>

                      {/* premium amount */}
                      <Box
                        sx={enrollmentPlanStyles.singleOptionText(isSelected)}
                      >
                        {isSelected && "+"}
                        {!props.dummyDisplay &&
                          UsdFormatterDec.format(
                            option.memberPremiumAmount
                          )}
                        {props.dummyDisplay && "$0.00"}
                      </Box>


                    </Box>

                    {/* explanation of benefits */}
                    {option.explanationOfBenefits && (
                      <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits}>
                        {option.explanationOfBenefits}
                      </Box>
                    )}

                    {/* benefit offsets */}
                    {option.benefitOffsets && (
                      <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits} >
                        {option.benefitOffsets}
                      </Box>
                    )}
                  </React.Fragment>
                );
              }
            })}
        </RadioGroup>

        {/* NOT NOW */}
        <Box sx={enrollmentPlanStyles.declineOptionRow}>
          <Radio
            sx={enrollmentPlanStyles.planSelectRadioButton}
            value={ltdDeclinePlan?.memberOptionId || 0}
            name="ltd"
            className=""
            // disabled={ltdOptionsExcludingDecline?.length == 0 && ltdDeclinePlan?.memberOptionId} //auto select and disable radio if only decline option exists
            checked={radioSelected == ltdDeclinePlan.memberOptionId}
            icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
            checkedIcon={<RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
            onClick={handleRadioSelection}
            inputProps={{
              "data-amount": ltdDeclinePlan?.memberPremiumAmount,
            }}
          />

          <Box sx={enrollmentPlanStyles.declineOptionText(radioSelected === ltdDeclinePlan.memberOptionId)}  >
            Not Now
          </Box>

          <Tooltip
            title={
              ltdPlan?.infoTipText ||
              "If you decline coverage, you may not be able to enroll in the future."
            }
            placement="bottom-end"
            arrow
            enterTouchDelay={0}
          >
            <Box sx={enrollmentPlanStyles.notNowToolTip}>
              <InfoOutlinedIcon
                sx={enrollmentPlanStyles.toolTipIcon}
              />
            </Box>
          </Tooltip>
        </Box>

      </Collapse>

      {/* Display when not expanded */}

      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.ltd && props.selections.ltdVal > 0 && (
            <>
              <Box>
                <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtionIconCollapse} />
                {
                  ltdPlan.options.find(
                    (option) => option.memberOptionId == props.selections.ltd
                  )?.name
                }
              </Box>

              <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                + {UsdFormatterDec.format(props.selections.ltdVal)}
              </Box>
            </>
          )}

          {props.selections.ltd && props.selections.ltdVal == 0 && (
            <>
              <span>
                <em>not elected</em>
              </span>
              <span
                style={enrollmentPlanStyles.notElectedZeroAmount}
              >
                $0.00
              </span>
            </>
          )}
          {!props.selections.ltd && "No plan selected"}
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}

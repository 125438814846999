import React, { useState, useEffect } from "react";

//MuiIcons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonIcon from "@mui/icons-material/RadioButtonUnchecked";

//MuiComponents
import Collapse from "@mui/material/Collapse";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

//Our icons
import CustomCheckIcon from "../../../../../assets/images/CustomCheckIcon.svg";
import { Box } from "@mui/system";

//Our components
import RecommendedBenefitDisplay from "../../RecommendedBenefitDisplay";

//Our Enrollment Plan Styles
import enrollmentPlanStyles from "../enrollmentPlanStyles";

export default function EnrollmentStd(props) {

  let UsdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  let results = props.results;
  let stdPlan = results?.stdPlan;
  // console.log('stdPlan', stdPlan)
  let stdHelpText = stdPlan?.helpText;
  // let stdHelpText = stdPlan?.helpText.split(`\n`);

  const [expanded,setExpanded] = useState(true)
  const handleExpandSection = () => {
    setExpanded(!expanded)
  }

  let expandIcon = expanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "white",
        backgroundColor: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  );

  const [currRec, setCurrRec] = useState(0);

  useEffect(() => {
    //if recs exist, check rec
    if(props?.results?.stdPlan?.options?.length > 0){
      //check each option against rec w/ the selectionRec function - function returns true if option = rec
    let _recValue = results?.stdPlan?.options?.find((option, index) => {
      let _rec = props?.selectionRec(props?.results?.stdPlan?.options, index)
      if(_rec) return _rec
    });

    //if rec exists, set currRec to rec value
    if(_recValue) setCurrRec(_recValue.benefitAmount)
  }

  }, [props?.recommendations, props?.results?.stdPlan?.options]);
  

  // console.log(stdHelpText)

  return (
    <>
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <span
          style={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          Short-Term Disability
          {/* bigger clickable area */}
        </span>

        <Box 
          sx={enrollmentPlanStyles.expandAndCollapseButton} 
          onClick={handleExpandSection}
        >
          {expandIcon}
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: stdHelpText }}
          />
        </Box>

        <RecommendedBenefitDisplay currRec={currRec}/>

        <RadioGroup
          onChange={props.selectionHandler}
          value={props.selections.std || currRec}
        >
          {results &&
            stdPlan.options
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((option, index) => {
                let isSelected =
                  props.selections.std == option.memberOptionId ||
                  (!props.selections.std && currRec == option.memberOptionId);

                if (!option.isDecline) {
                  return (
                    <React.Fragment key={`${JSON.stringify(option)}`}>
                      <Box sx={enrollmentPlanStyles.optionRow}>

                        {/* radio button, benefit amount, type */}
                          <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                          
                            <Radio
                              sx={enrollmentPlanStyles.planSelectRadioButton}
                              value={option.memberOptionId}
                              id={`${option.memberOptionId}`}
                              name="std"
                              icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                              checkedIcon={
                                <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                              }
                              onClick={() => { 
                                props.handleEnrollClick(option.memberOptionId);
                                // props.setExpanded({ ...props.expanded, ltd: true })
                              }}
                              inputProps={{
                                "data-amount": option.memberPremiumAmount,
                              }}
                            />

                            <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                              <Typography sx={enrollmentPlanStyles.singleOptionText(isSelected)}>
                                {option?.name &&
                                <>
                                  {option.name} {option.name.includes('%') && option?.benefitAmount && `(Max ${UsdFormatter.format(option.benefitAmount)})` }
                                </>
                                }
                              </Typography>{' '}
                              {option?.benefitAmount == currRec && <Typography sx={enrollmentPlanStyles.recommendedText}>{`(recommended)`}</Typography>}
                            </Box>
                          </Box>

                        {/* premium amount */}
                        <Box sx={enrollmentPlanStyles.singleOptionText(isSelected)}>
                            {isSelected && "+"}
                            {!props.dummyDisplay &&
                              UsdFormatterDec.format(
                                option.memberPremiumAmount
                              )}
                            {props.dummyDisplay && "$0.00"}
                          </Box>
                      </Box>
                      
                      {/* explanation of benefits */}
                      {option.explanationOfBenefits && (
                        <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits} >
                          {option.explanationOfBenefits}
                        </Box>
                      )}
                      
                      {/* benefit offsets */}
                      {option.benefitOffsets && (
                        <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits}>
                          {option.benefitOffsets}
                        </Box>
                      )}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <Box
                      key={option.memberPremiumAmount}
                      sx={enrollmentPlanStyles.declineOptionRow}
                    >
                      <Radio
                        sx={enrollmentPlanStyles.planSelectRadioButton}
                        value={option.memberOptionId}
                        name="std"
                        icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)}/>}
                        checkedIcon={
                          <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                        }
                        onClick={() => {
                          props.handleEnrollClick(option.memberOptionId);
                        }}
                        inputProps={{
                          "data-amount": option.memberPremiumAmount,
                        }}
                      />
                      <Box sx={enrollmentPlanStyles.declineOptionText(isSelected)}>
                        Not Now
                      </Box>
                    </Box>
                  );
                }
              })}
        </RadioGroup>
      </Collapse>
      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.std && props.selections.stdVal > 0 && (
            <>
              <Box>
                <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtionIconCollapse} />
                {
                  stdPlan.options.find(
                    (option) => option.memberOptionId == props.selections.std
                  )?.name
                }
              </Box>

              <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                + {UsdFormatterDec.format(props.selections.stdVal)}
              </Box>
            </>
          )}
          {props.selections.std && props.selections.stdVal == 0 && (
            <>
              <span>
                <em>not elected</em>
              </span>
              <span
                style={enrollmentPlanStyles.notElectedZeroAmount}
              >
                $0.00
              </span>
            </>
          )}
          {!props.selections.std && "No plan selected"}
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}

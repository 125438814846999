import React from 'react';

import Typography from '@mui/material/Typography';

export default function PortalPaymentSummary(props) {
   return (
      <>
         <Typography component={'div'} className="col-12" sx={{ fontFamily: 'Archivo', textTransform: 'uppercase', fontSize: '12px', letterSpacing: '1px', color: 'primary.main' }} >
            Teamsters Local 728
         </Typography>

         <Typography component={'div'} className="col-12" sx={{ color: 'text.primary', fontFamily: 'Poppins', fontSize: '20px', fontWeight: 600, letterSpacing: '.15px' }} >
            $132.19
            <Typography component={'span'} sx={{ fontSize: '14px', fontWeight: 400, color: 'text.secondary', letterSpacing: '.17px', fontFamily: 'Archivo' }} >
               &nbsp;/mo
            </Typography>
         </Typography>

         <Typography component={'div'} className="col-12" sx={{ fontSize: '14px', fontWeight: 400, color: 'text.primary', letterSpacing: '.17px', fontFamily: 'Archivo' }} >
            Next payment 03/01/2024
         </Typography>
      </>
   );
};


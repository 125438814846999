import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react"
import { useHistory } from "react-router-dom"
import { format } from "date-fns"
import { jsPDF } from "jspdf"
import html2canvas from "html2canvas"

//MuiIcons
import InfoIcon from "@mui/icons-material/Info"
import DownloadIcon from "@mui/icons-material/FileDownload"

//Services
import authService from "../../../services/auth-service"
import userService from "../../../services/user-service"
import dashboardService from "../../../services/dashboard-service"
import organizationService from "../../../services/organization-service"

//MuiComponents
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import { Button, Paper, Switch, Typography } from "@mui/material"
import { Box, width } from "@mui/system"
import Fade from "@mui/material/Fade"
import Divider from "@mui/material/Divider"
import TextField from "@mui/material/TextField"
// import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton"
import Slide from "@mui/material/Slide"

//Assets
import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png"

//Our components
import { thisUserContext } from "../nav-container/NavContainer"
import { AgentColorModeContext } from "../../../App"
import EnrollmentReport from "../Reporting/individual-reports/EnrollmentReport"
import GoalOdometer from "./individual-reports/goalOdometerComponents/GoalOdometer"
import ReportCard from "./layout/ReportCard"
import EnrollmentMethods from "./individual-reports/EnrollmentMethods"
import BigReportCard from "./layout/BigReportCard"
import EnrollmentActivity from "./individual-reports/EnrollmentActivity"
import PostEnrollmentCoverage from "./individual-reports/post-enroll-reports/PostEnrollmentCoverage"
import PostEnrollmentWorkStatus from "./individual-reports/post-enroll-reports/PostEnrollmentWorkStatus"
import PostEnrollmentTickets from "./individual-reports/post-enroll-reports/PostEnrollmentTickets"
import PaymentActivity from "./individual-reports/PaymentActivity"
import PostEnrollmentPayments from "./individual-reports/post-enroll-reports/PostEnrollmentPayments"
import PostEnrollmentOptionSelection from "./individual-reports/post-enroll-reports/PostEnrollmentOptionSelections"
import EnrollmentsByDateAndCoverage from "./individual-reports/EnrollmentsByDateAndCoverage"
import InfinityLoader from "../../UH-loading-animation/InfinityLoader"

//Our Styles
import reportingDashboardStyles from "./reportingDashboardStyles"

export default function ReportingDashboard(props) {
  let myContext = useContext(thisUserContext)
  const [permissions, setPermissions] = useState() //this will contain all permissions from permissionsEnabler for the user
  const colorMode = useContext(AgentColorModeContext)
  const [thisUser, setThisUser] = useState()

  let getThisUser = async () => {
    //  let _thisUser = await userService.getMe()

    setThisUser(myContext.thisUser)
    // console.log('thisUser ',myContext)
  }
  // console.log("myContext", myContext?.permissionsEnabler)

  useEffect(() => {
    if (myContext) {
      getThisUser()
      setPermissions(myContext?.permissionsEnabler)
    }
    // getGroupPlans()
  }, [myContext.thisUser])

  //useLayoutEffect(() => {
  //  if (props.match.params.GroupPlanId > 0) {
  //    setThisGroupPlan(props.match.params.GroupPlanId)
  //  }
  //  if (props.match.params.EnrollmentWindowId > 0) {
  //    setThisEnrollmentWindow(props.match.params.EnrollmentWindowId)
  //  }
  //}, [])

  const [organizations, setOrganizations] = useState([{}])
  const getOrganizationsByGroupPlan = async () => {
    let _organizations = await organizationService.getOrganizationsByGroupPlan(
      thisGroupPlan
    )

    setOrganizations(_organizations)
  }
  // console.log("organizations", organizations)

  
  const formatNumWithCommas = (number) => { //need to replace from utils folder in future, best practice to store in one spot and not prop drill
    if(number === 0 || !number) return 0 //default to 0 if 0 or undefined
    if (number) {
      return number.toLocaleString("en-Us")
    }
  }

  const [groupPlans, setGroupPlans] = useState([])
  const [closedGroupPlans, setClosedGroupPlans] = useState([])
  const [selectableGroupPlans, setSelectableGroupPlans] = useState([])
  const [thisGroupPlan, setThisGroupPlan] = useState(null)
  const [loading, setLoading] = useState(false)

  const getGroupPlans = async () => {
    let _groupPlans = await dashboardService.getGroupPlans()
    let openPlans = _groupPlans.filter((groupPlan) => groupPlan.isOpen == true)
    let closedPlans = _groupPlans.filter(
      (groupPlan) => groupPlan.isOpen == false
    )
    setGroupPlans(openPlans)
    setClosedGroupPlans(closedPlans)
    if (openOrClosed == "open") setSelectableGroupPlans(openPlans)
    else setSelectableGroupPlans(closedPlans)
  }
  // console.log(groupPlans, closedGroupPlans)

  useEffect(() => {
    getGroupPlans()
  }, [])

  //reset group plan selection to first in list
  useEffect(() => {
    if (selectableGroupPlans.length > 0) {
      //console.log('setting group plan',selectableGroupPlans,props.match)
      //if (props.match.params.GroupPlanId > 0 && !selectableGroupPlans.includes(props.match.params.GroupPlanId)){
      //  setThisGroupPlan(selectableGroupPlans[0]?.id)
      //}
      //else if (!props.match.params.GroupPlanId){
      let _groupPlan = localStorage.getItem("groupPlan");
      _groupPlan = Number(_groupPlan);
      let valid = false;
      if (_groupPlan > 0){
        for (let plan of selectableGroupPlans) {
          if (plan.id == _groupPlan) {
            valid = true;
            break;
          }
        }
      }
      if (valid) {
        setThisGroupPlan(_groupPlan)
      } else {
        localStorage.setItem("groupPlan", selectableGroupPlans[0]?.id)
        setThisGroupPlan(selectableGroupPlans[0]?.id)
      }

      //}
    }
  }, [selectableGroupPlans])

  // console.log("group plan selected: ", thisGroupPlan)

  const handleGroupPlanChange = (event) => {
    localStorage.setItem("groupPlan", event.target.value)
    setThisGroupPlan(event.target.value)
  }

  const [thisEnrollmentWindow, setThisEnrollmentWindow] = useState(null)
  const [formattedStartEnrollmentWindow, setFormattedStartEnrollmentWindow] =
    useState("")
  const [enrollmentWindows, setEnrollmentWindows] = useState([])
  const getEnrollmentWindows = async () => {
    let _enrollmentWindows = await dashboardService.getEnrollmentWindows(
      thisGroupPlan
    )
    // console.log("enrollment windows", _enrollmentWindows)
    setEnrollmentWindows(_enrollmentWindows)
  }
  useEffect(() => {
    if (thisGroupPlan > 0) {
      getEnrollmentWindows()
      getOrganizationsByGroupPlan()
    }
  }, [thisGroupPlan])

  useEffect(() => {
    if (enrollmentWindows.length > 0) {
      //console.log('setting enrollment window',enrollmentWindows,props.match)
      //if (props.match.params.EnrollmentWindowId > 0 && !enrollmentWindows.includes(props.match.params.EnrollmentWindowId)){
      //  console.log('param not in list')
      //  setThisEnrollmentWindow(enrollmentWindows[0]?.id)
      //}
      //else if (!props.match.params.EnrollmentWindowId > 0){
      //  console.log('no param found',props.match.params.EnrollmentWindowId)
      let _enrollmentWindow = localStorage.getItem("enrollmentWindow")
      _enrollmentWindow = Number(_enrollmentWindow);
      let valid = false;
      if (_enrollmentWindow > 0){
        for (let window of enrollmentWindows) {
          if (window.id == _enrollmentWindow) {
            valid = true;
            break;
          }
        }
      }
      // console.log("enrollment window local storage", _enrollmentWindow)
      if (valid) {
        setThisEnrollmentWindow(_enrollmentWindow)
      } else {
        localStorage.setItem("enrollmentWindow", enrollmentWindows[enrollmentWindows.length - 1]?.id)
        setThisEnrollmentWindow(enrollmentWindows[enrollmentWindows.length - 1]?.id)
      }
      //}
    }
  }, [enrollmentWindows])

  //set current enrollment window selected and format it for display
  useEffect(() => {
    if (thisEnrollmentWindow && enrollmentWindows?.length > 0) {
      let currentEnrollmentWindow = enrollmentWindows.find(
        (enrollmentWindow) => enrollmentWindow.id === thisEnrollmentWindow
      )
      let _formattedStartEnrollmentWindow = `${formatDateString(
        currentEnrollmentWindow?.openEnrollmentStart
      )}`
      setFormattedStartEnrollmentWindow(_formattedStartEnrollmentWindow)
    }
  }, [thisEnrollmentWindow])

  const handleWindowChange = (e) => {
    localStorage.setItem("enrollmentWindow", e.target.value)
    setThisEnrollmentWindow(e.target.value)
  }

  // console.log('this enrollment window', enrollmentWindows)

  const [openOrClosed, setOpenOrClosed] = useState("open")
  const handleOpenClosedSwitch = (e) => {
    setLoading(true)
    let value = e.target.value

    setTimeout(() => {
      switch (value) {
        case "open":
          setOpenOrClosed("open")
          setSelectableGroupPlans(groupPlans)
          break
        case "closed":
          setOpenOrClosed("closed")
          setSelectableGroupPlans(closedGroupPlans)
          break
        default:
          break
      }
      setLoading(false)
    }, 800)
  }
  // console.log(
  //   "open group plans",
  //   groupPlans,
  //   "\n\n closed group plans",
  //   closedGroupPlans,
  //   "\n\n selectable group plans",
  //   selectableGroupPlans
  // )

  const formatDate = (rawDate) => {
    // console.log('formatDate',rawDate)
    // if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
    try {
      let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/)
      let date = new Date()
      date.setUTCFullYear(+yyyy)
      date.setUTCDate(+dd)
      date.setUTCMonth(mm - 1)
      date.setUTCHours(+hh)
      date.setUTCMinutes(+m + date.getTimezoneOffset())
      date.setUTCSeconds(+s)
      // console.log('formatDate after',date)
      date = format(date, "M/d/yy")
      return date
    } catch (error) {
      console.log("error formatting date", error)
      return new Date(rawDate)
    }
  }

  const formatDateString = (rawDate) => {
    try {
      const date = new Date(rawDate)
      const options = { year: "numeric", month: "long", day: "numeric" }
      return date?.toLocaleDateString(undefined, options)
    } catch (error) {
      console.log("error formatting date", error)
      return new Date(rawDate)
    }
  }

  // console.log('thisGroupPlan', thisGroupPlan, 'thisEnrollmentWindow', thisEnrollmentWindow)

  const [dateOrCoverage, setDateOrCoverage] = useState("date")

  //DOWNLOAD full snapshot of the dashboard

  const downloadFullDashboard = () => {
    //get group plan selected and enrollment window to display as title
    let groupPlanName = selectableGroupPlans.find(
      (groupPlan) => groupPlan.id === thisGroupPlan
    )?.name
    let currentEnrollmentWindow = enrollmentWindows.find(
      (enrollmentWindow) => enrollmentWindow.id === thisEnrollmentWindow
    )
    let formattedEnrollmentWindow = `${formatDate(
      currentEnrollmentWindow?.openEnrollmentStart
    )} - ${formatDate(currentEnrollmentWindow?.openEnrollmentEnd)}`

    // Get the DOM element to be captured
    const elementToCapture = document.getElementById("full-dashboard-download")

    // Use html2canvas to capture the element as an image
    html2canvas(elementToCapture).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [297, 210], // Specify custom page dimensions
      })
      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()

      // Set the width and height for the image
      const imageWidth = pageWidth - 25 // Adjust this value as needed
      const imageHeight = (imageWidth / canvas.width) * canvas.height

      // Calculate the center position for the image on the page
      const xPosition = (pageWidth - imageWidth) / 2
      const yPosition = (pageHeight - imageHeight) / 2

      pdf.setTextColor(79, 79, 79)

      // Add the title centered above image - if group plan name exists OR enrollment window data exists, display as title
      const title =
        groupPlanName ||
        (currentEnrollmentWindow?.openEnrollmentStart &&
          currentEnrollmentWindow?.openEnrollmentEnd)
          ? `${groupPlanName}  ${formattedEnrollmentWindow}`
          : "Enrollment Dashboard Summary"
      const titleX =
        (pdf.internal.pageSize.getWidth() - pdf.getTextWidth(title)) / 2
      const titleY = yPosition - 20 // Adjust the vertical position as needed
      pdf.text(title, titleX, titleY)

      // Set font
      // pdf.setFont("Archivo", "normal")

      pdf.addImage(
        imgData,
        "PNG",
        xPosition,
        yPosition,
        imageWidth,
        imageHeight
      )
      pdf.save(`${groupPlanName}-dashboard-summary.pdf`)
    })
  }

  const downloadCsvFile = (data, reportName) => {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: "text/csv" })

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a")

    // Passing the blob downloading url
    a.setAttribute("href", url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", `${reportName}.csv`)

    // Performing a download with click
    a.click()

    a.remove()
  }

  const createCsv = (data, reportName) => {
    // Empty array for storing the values
    let csvRows = []

    if (data.length == 0) console.log("no data to download")
    else {
      const headers = Object.keys(data[0])

      csvRows.push(headers.join(","))

      for (let memberObj of data) {
        const values = Object.values(memberObj).join(",")
        csvRows.push(values)
      }

      let file = csvRows.join("\n")
      if (file) downloadCsvFile(file, reportName)
    }
  }

  const [autoRefresh, setAutoRefresh] = useState(false)
  const [swapFade, setSwapFade] = useState(false)
  let fadeAnim = () => {
    setSwapFade((prev) => !prev)
  }

  //TimerId
  const [timer, setTimer] = useState(0)
  useEffect(() => {
    clearInterval(timer)
    if (autoRefresh) {
      let swapIntervalId = setInterval(fadeAnim, 1800)
      setSwapFade(true)
      setTimer(swapIntervalId)
    }
    if (!autoRefresh) {
      clearInterval(timer)
      setSwapFade(false)
    }
    // intervalFunc
  }, [autoRefresh])

  //useEffect(() => {
  // if (thisGroupPlan > 0 && thisEnrollmentWindow > 0) {
  //   // window.location.href = `/dashreports/${thisGroupPlan}/${thisEnrollmentWindow}`
  //   window.history.replaceState(null, null, `/dashreports/${thisGroupPlan}/${thisEnrollmentWindow}`)
  // }
  //},[thisGroupPlan,thisEnrollmentWindow])
  //console.log("params",props.match, props.params, thisGroupPlan,thisEnrollmentWindow)

  const [imgFetchAttempts, setImgFetchAttempts] = useState(0)
  const handleLogoError = (e) => {
    // console.log('logo img error',FallbackLogo)
    setImgFetchAttempts((prev) => prev++)
    if (imgFetchAttempts <= 10) e.target.src = defaultLogo
  }


  

  return (
    <>
      <Box
        // Box for the whole dashboard
        sx={{
          maxHeight: "calc(100vh - 56px)",
          maxWidth: "calc(100vw - 71px)",
          bgcolor: "background.border",
          color: "text.primary",
          borderColor: "background.border",
          display: "flex",
          flexDirection: "column",
          //overflow: "hidden",
        }}
      >
        {loading && (
          <>
            <InfinityLoader
              style={reportingDashboardStyles.individualReportInfinityLoader}
            />
            <style>
              {`
                    div.lottie-div > svg {
                      height:55px!important;
                      position:absolute;
                      top:30%;
                    }
                `}
            </style>
          </>
        )}
        <Box // Report card header bar
          sx={{
            flex: "1 0 90px",
            //height: "87px",
            width: "100%",
            backgroundColor: "background.default",
            //marginBottom: "0px",
            alignItems: "center",
            justifyContent: "space-between",
            //position: "relative",
            display: "flex",
            //flexWrap: "wrap",
            flexDirection: "row",
            paddingLeft: "25px",
          }}
        >
          <Box // Title and dropdown selections
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              //flexWrap: "wrap",
              justifyContent: "flex-start",
              width: "75%",
            }}
          >
            {/* 68 */}
            <Box sx={{ flex: "0 0 68px", marginRight: "5px" }}>
              <img
                alt="logo"
                src={organizations[0]?.id > 0 ? `api/image/organization/${organizations[0]?.id}` : defaultLogo}
                style={{
                  maxHeight: "68px",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
                onError={handleLogoError}
              />
            </Box>
            <Box sx={{ flex: "0 0 200px" }}>
              <Typography
                variant="h4"
                //onClick={() => colorMode.ToggleColorMode()}
              >
                Reporting
              </Typography>
            </Box>
            <Box sx={{ flex: "1 1 200px", paddingTop: "7px" }}>
              <TextField
                variant="standard"
                select
                value={openOrClosed}
                // label="Group Plan"
                onChange={handleOpenClosedSwitch}
                //fullWidth
                sx={{ width: "200px" }}
              >
                <MenuItem value={"open"}>Enrollment Report</MenuItem>
                <MenuItem value={"closed"}>Post-Enrollment Report</MenuItem>
              </TextField>
            </Box>
            <Box sx={{ flex: "1 1 375px", paddingTop: "7px" }}>
              <TextField
                variant="standard"
                select
                value={thisGroupPlan}
                // label="Group Plan"
                sx={{
                  "& fieldset > legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  width: "375px",
                }}
                onChange={handleGroupPlanChange}
                //fullWidth
              >
                {selectableGroupPlans?.map((groupPlan) => {
                  return (
                    <MenuItem value={groupPlan?.id}>{groupPlan?.name}</MenuItem>
                  )
                })}
              </TextField>
            </Box>
            <Box sx={{ flex: "1 1 170px", paddingTop: "7px" }}>
              <TextField
                variant="standard"
                select
                value={thisEnrollmentWindow}
                // label="Group Plan"
                //fullWidth
                sx={{
                  width: "170px",
                  visibility: openOrClosed == "open" ? "visible" : "hidden",
                }}
                onChange={handleWindowChange}
              >
                {enrollmentWindows?.map((enrollmentWindow) => {
                  return (
                    <MenuItem value={enrollmentWindow?.id}>
                      {formatDate(enrollmentWindow.openEnrollmentStart)} -{" "}
                      {formatDate(enrollmentWindow.openEnrollmentEnd)}
                    </MenuItem>
                  )
                })}
                {/* <MenuItem value={5}>2022-2023</MenuItem> */}
              </TextField>
            </Box>
          </Box>

          {/* <Box
            sx={{
              marginRight: "20px",
              paddingTop: '12px',
              fontSize:'12px',
              color:autoRefresh ? 'primary.main' : '',
              display:'flex',
            }}
          >
            <Switch
              onClick={() => setAutoRefresh(!autoRefresh)}
            />
            <Box sx={{height:'50px'}} component={'div'}>
              <Fade in={swapFade} timeout={900} 
              style={{position:'absolute'}}>
                  <div>
                    <InfinityLoader style={{
                        zIndex:5,
                        height:'41px',
                    }}/> 
                  </div>
              </Fade>
              <Fade in={!swapFade} timeout={900} >
                  <div style={{fontSize:'12px',height:'41px',displayu:'flex',alignSelf:'end'}}>
                    Auto Refresh
                  </div>
              </Fade>
            </Box>
          </Box> */}
          <Box
            sx={{
              marginRight: "20px",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<DownloadIcon sx={{ color: "primary.main" }} />}
              onClick={() => {
                downloadFullDashboard()
              }}
            >
              DOWNLOAD PDF
            </Button>
          </Box>
        </Box>

        <Divider
          sx={{
            width: "100",
            //marginLeft: ".8%",
            marginBottom: "0px",
            color: "background.border",
            opacity: 0.5,
            //bottom: 0,
            //position: "absolute",
          }}
        />

        {/* Full dashboard download container*/}
        <Box id="full-dashboard-download">
          <Box // Small Report Cards
            sx={{
              gap: "1px",
              //flex: "1 1 25%",
              //margin: "0px 10px 0px 0px",
              position: "relative",
              display: "flex",
              flexDirection: "row",
              //overflow: "auto",
              // width: "100%",
              //justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                backgroundColor: "background.default",
                position: "relative",
                flex: "1 0 25%",
                //maxHeight: "293px",
              }}
            >
              <Box
                sx={{
                  //  height: "100%",
                  //  width: "100%",
                  display: openOrClosed == "open" ? "" : "none",
                }}
              >
                <Fade in={openOrClosed == "open"} /* Enrollment Report*/>
                  <Box>
                    <ReportCard
                      title="Enrollments"
                      subtitle={`Enrollment activity from ${formattedStartEnrollmentWindow}`}
                      infoTip="View Details"
                      createCsv={createCsv}
                    >
                      <EnrollmentActivity
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>

              <Box
                sx={{
                  //  height: "100%",
                  flex: "1 0 25%",
                  display: openOrClosed == "closed" ? "" : "none",
                }}
              >
                <Fade
                  in={openOrClosed == "closed"} /* Post-Enrollment Report */
                >
                  <Box>
                    <ReportCard
                      title="Coverage"
                      subtitle={"Current coverage statuses"}
                      infoTip="View Details"
                    >
                      <PostEnrollmentCoverage
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                      {/* <EnrollmentActivity groupPlanId={thisGroupPlan} enrollmentWindowId={thisEnrollmentWindow} /> */}
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: "background.default",
                position: "relative",
                flex: "1 0 25%",
              }}
            >
              <Box
                sx={{
                  //  height: "100%",
                  //  width: "100%",
                  display: openOrClosed == "open" ? "" : "none",
                }}
              >
                <Fade in={openOrClosed == "open"} /* Enrollment Report*/>
                  <Box>
                    <ReportCard
                      title="Goal Odometer"
                      subtitle="Goals for this enrollment period"
                      infoTip="View Details"
                      createCsv={createCsv}
                    >
                      <GoalOdometer
                        myContext={myContext}
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        permissions={permissions}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>

              <Box
                sx={{
                  //  height: "100%",
                  //  width: "100%",
                  display: openOrClosed == "closed" ? "" : "none",
                }}
              >
                <Fade
                  in={openOrClosed == "closed"} /* Post-Enrollment Report */
                >
                  <Box>
                    <ReportCard
                      title="Working Status"
                      subtitle="Working status of enrolled members"
                      infoTip="View Details"
                    >
                      <PostEnrollmentWorkStatus
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                      {/* <GoalOdometer groupPlanId={thisGroupPlan} enrollmentWindowId={thisEnrollmentWindow} /> */}
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: "background.default",
                position: "relative",
                flex: "1 0 25%",
              }}
            >
              <Box
                sx={{
                  //  height: "100%",
                  //  width: "100%",
                  display: openOrClosed == "open" ? "" : "none",
                }}
              >
                <Fade in={openOrClosed == "open"} /* Enrollment Report*/>
                  <Box>
                    <ReportCard
                      title="Enrollment Method"
                      subtitle="How members have enrolled"
                      infoTip="View Details"
                    >
                      <EnrollmentMethods
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>

              <Box
                style={{
                  //  height: "100%",
                  //  width: "100%",
                  display: openOrClosed == "closed" ? "" : "none",
                }}
              >
                <Fade
                  in={openOrClosed == "closed"} /* Post-Enrollment Report */
                >
                  <Box>
                    <ReportCard
                      title="Tickets"
                      subtitle={`Ticket activity from ${formattedStartEnrollmentWindow}`}
                      infoTip="View Details"
                    >
                      <PostEnrollmentTickets
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                      {/* <EnrollmentMethods groupPlanId={thisGroupPlan} enrollmentWindowId={thisEnrollmentWindow} /> */}
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>
            </Box>

            <Box
              sx={{
                backgroundColor: "background.default",
                position: "relative",
                flex: "1 1 25%",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: openOrClosed == "open" ? "" : "none",
                }}
              >
                <Fade in={openOrClosed == "open"} /* Enrollment Report*/>
                  <Box>
                    <ReportCard
                      title="Payments"
                      subtitle={`Projected payments for this enrollment`}
                      infoTip="View Details"
                    >
                      <PaymentActivity
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>

              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: openOrClosed == "closed" ? "" : "none",
                }}
              >
                <Fade
                  in={openOrClosed == "closed"} /* Post-Enrollment Report */
                >
                  <Box>
                    <ReportCard
                      title="Payments"
                      subtitle={`Payments made in the last 30 days`}
                      infoTip="View Details"
                      createCsv={createCsv}
                    >
                      <PostEnrollmentPayments
                        groupPlanId={thisGroupPlan}
                        enrollmentWindowId={thisEnrollmentWindow}
                        enrollmentWindows={enrollmentWindows}
                        formatNumWithCommas={formatNumWithCommas}
                      />
                      {/* <PaymentActivity groupPlanId={thisGroupPlan} enrollmentWindowId={thisEnrollmentWindow} /> */}
                    </ReportCard>
                  </Box>
                </Fade>
              </Box>
            </Box>

            <Divider
              sx={{
                width: "100%",
                marginBottom: "0px",
                color: "background.border",
                opacity: 0.5,
                bottom: 0,
                position: "absolute",
              }}
            />
          </Box>

          <Box
            sx={{
              flex: "4 1 30%",
              //height: "calc(100vh - 436px)",
              backgroundColor: "background.default",
            }}
          >
            <Box
              sx={{
                display: openOrClosed == "open" ? "" : "none",
              }}
            >
              <Fade in={openOrClosed == "open"} /* Enrollment Report*/>
                <Box>
                  <BigReportCard
                    openOrClosed={"open"}
                    dateOrCoverage={dateOrCoverage}
                    setDateOrCoverage={setDateOrCoverage}
                    formatDate={formatDate}
                    createCsv={createCsv}
                  >
                    <EnrollmentsByDateAndCoverage
                      groupPlanId={thisGroupPlan}
                      enrollmentWindowId={thisEnrollmentWindow}
                      dateOrCoverage={dateOrCoverage}
                      enrollmentWindows={enrollmentWindows}
                      formatNumWithCommas={formatNumWithCommas}
                    />
                  </BigReportCard>
                </Box>
              </Fade>
            </Box>
            <Box
              sx={{
                display: openOrClosed == "closed" ? "" : "none",
              }}
            >
              <Fade in={openOrClosed == "closed"} /* Post-Enrollment Report */>
                <Box>
                  <BigReportCard
                    openOrClosed={"closed"}
                    dateOrCoverage={dateOrCoverage}
                    setDateOrCoverage={setDateOrCoverage}
                    formatDate={formatDate}
                    handleWindowChange={handleWindowChange}
                    enrollmentWindows={enrollmentWindows}
                    thisEnrollmentWindow={thisEnrollmentWindow}
                  >
                    <PostEnrollmentOptionSelection
                      groupPlanId={thisGroupPlan}
                      enrollmentWindowId={thisEnrollmentWindow}
                      dateOrCoverage={dateOrCoverage}
                      enrollmentWindows={enrollmentWindows}
                      formatDate={formatDate}
                      formatNumWithCommas={formatNumWithCommas}
                    />
                    {/* <EnrollmentsByDateAndCoverage groupPlanId={thisGroupPlan} enrollmentWindowId={thisEnrollmentWindow} dateOrCoverage={dateOrCoverage}  enrollmentWindows={enrollmentWindows} formatDate={formatDate}/> */}
                  </BigReportCard>
                </Box>
              </Fade>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

import React, { useEffect, useLayoutEffect, useState, useContext, Suspense, lazy } from "react";
import { useHistory } from "react-router";

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";


//Muicons
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Close from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


//MuiComponents
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlerTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";
import MobileStepper from "@mui/material/MobileStepper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import Home from "@mui/icons-material/Home";
import Icon from "@mui/material/Icon";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import { Collapse, Link, ListItem, TextField } from "@mui/material";
import PortalPlanSummary from "./PortalPlanSummary";

export default function PortalPlanContainer(props) {

   return (
      <>
         <PortalPlanSummary />

         <PortalPlanSummary />

         <PortalPlanSummary />
      </>
   )
};


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { format, addYears } from "date-fns";

//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//services
import paymentsService from "../../../../../../../../services/payments-service";

// Assets

//Mui Components

import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  Divider,
  Radio,
  Tooltip,
} from "@mui/material";

//Mui icons
import CloseIcon from "@mui/icons-material/Close";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";


export default function PayoutInputs (props) {
   const formatDate = (rawDate) => {
      let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
      let date = new Date();
      date.setUTCFullYear(+yyyy);
      date.setUTCDate(+dd);
      date.setUTCMonth(mm - 1);
      date.setUTCHours(+hh);
      date.setUTCMinutes(+m + date.getTimezoneOffset());
      date.setUTCSeconds(+s);
      return date.toJSON();
   };

  
   

   // // console.log("BALANCE OWED", props.outstandingBalance?.balanceAmount);

   

   // // console.log("newPaymentObj on page load", newPaymentObj);
   // console.log("outstandingBalance", props.outstandingBalance);

   // //Component Styles
   // const componentStyles = {
   // componentBody: {
   //    fontFamily: "Poppins",
   //    minWidth: "538px",
   //    maxWidth: "538px",
   //    height: "100%",
   //    backgroundColor: "background.default",
   // },
   // headerContainer: {
   //    width: "100%",
   //    padding: "13px 38px",
   //    display: "flex",
   //    flexWrap: "wrap",
   //    justifyContent: "space-between",
   //    alignItems: "center",
   //    margin: "0 auto",
   //    borderBottom: "2px solid",
   //    borderColor: "primary.main",
   // },
   // title: {
   //    color: "text.primary",
   //    fontFamily: "Poppins",
   //    fontSize: "24px",
   //    fontWeight: "400",
   // },
   // description: {
   //    color: "text.primary",
   //    fontFamily: "Archivo",
   //    fontSize: "14px",
   //    fontWeight: "500",
   //    padding: "10px 16px",
   // },
   // closeDrawerButton: {
   //    display: "flex",
   //    justifyContent: "flex-end",
   // },
   // interactionBody: {
   //    padding: "34px 32px 8px",
   //    flexWrap: "wrap",
   // },
   // };
   // const [newPaymentObj,setNewPaymentObj] = useState(props.newPaymentObj)
   // useEffect(() => {
   //    setNewPaymentObj(props.newPaymentObj)
   // },[props.newPaymentObj])

   // const balanceInputHandler = (e) => {
   // let value = Number(e.target.value);

   // let property = e.target.getAttribute("data-property");
   // // value=value.toFixed(2)
   // console.log(value, property, value === NaN, typeof value);
   // if (!isNaN(value)) {
   //    props.setNewPaymentObj({
   //       ...props.newPaymentObj,
   //       [property]: value,
   //    });
   // }
   // };

   // const newPaymentObjRadioHandler = (property, bool) => {
   // //Swap amount between custom amount and balance here

   // console.log("RADIO selected", bool);
   // props.setIsCustomDate(bool);
   // props.setNewPaymentObj({
   //    ...props.newPaymentObj,
   //    [property]: bool,
   // });
   // };

   // let dateMin = new Date();
   // let dateMax = addYears(new Date(), 1);

   // const handlePaymentDateChange = (e) => {
   // console.log("Payment date change", e.target);
   // // let newDate = e.target.value
   // let newDate = new Date(e);
   // if (newDate >= dateMin && newDate <= dateMax) {
   //    props.setNewPaymentObj({
   //       ...props.newPaymentObj,
   //       paymentDate: newDate,
   //    });
   // }
   // };

   // const [customAmountError, setCustomAmountError] = useState(false);
   // const customAmountCheck = () => {
   // console.log(
   //    "checking amount",
   //    props.outstandingBalance?.balanceAmount,
   //    props.newPaymentObj.paymentAmount
   // );
   // if (
   //    props.outstandingBalance &&
   //    props.newPaymentObj.paymentAmount > props.outstandingBalance?.balanceAmount
   // ) {
   //    setCustomAmountError("Must be less than or equal to current balance");
   // } else {
   //    setCustomAmountError();
   // }
   // };

   // //select plan to payment is associated with
   // const handlePlanSelection = (e, plan) => {
   // console.log("plan selected", plan);
   // props.setNewPaymentObj({
   //    ...props.newPaymentObj,
   //    enrollmentId: plan.enrollmentId,
   // });
   // };

   const balanceInputHandler = (e) => {
      let value = e.target.value
      console.log(value)
      // use the unary '+' to cast to number
      let valAsNum = +value
      if (!isNaN(valAsNum)){
         props.setNewPayoutObj({
            ...props.newPayoutObj,
            paymentAmount:value
         })
      }
      console.log(value) 
   }
   const descriptionInputHandler = (e) => {
      let value = e.target.value
      props.setNewPayoutObj({
         ...props.newPayoutObj,
         description: value
      })
   }


   return (
      <>
         <div className="col-12" style={{ marginBottom: '20px'}}>
            <TextField variant="outlined"
               fullWidth
               required
               sx={{
                 "& fieldset": { borderRadius: "4px" },
                 "& legend": { width:0 }, //Gets rid of label if empty
                 "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                   border: "2px solid #195ffb",
                   borderRadius: "4px",
                 },
               }}
               InputProps={{
                  startAdornment: <AttachMoneyIcon fontSize="small"/>,
               }}
               value={props.newPayoutObj?.paymentAmount || 0}
               onFocus={e=>e.target.select()}
               onClick={e=>e.target.select()}
               onChange={balanceInputHandler}
            />
         </div>

         <div className="col-12" style={{marginBottom:'20px'}}>
            <TextField fullWidth multiline minRows={5}
               variant='outlined' placeholder='Description'
               InputProps={{ disableUnderline: true }}
               label="Description"
               value={props.newPayoutObj?.description} onChange={descriptionInputHandler}
               // sx={{
               //    marginBottom: '40px', border: '1px solid #79747E', borderRadius: '4px', padding: '16px',
               //    // input:{'&::placeholder':{fontFamily:'Archivo'}}
               // }}
               sx={{
                  "& fieldset": { borderRadius: "4px" },
                  // "& legend": { width:0 }, //Gets rid of label if empty
                  "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                    border: "2px solid #195ffb",
                    borderRadius: "4px",
                  },
                }}
            />
         </div>
      </>
   )
}
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";


//Services
//import roleService from '../../../../services/role-service'

// Assets


//MuiIcons



//MuiComponents
import Button from "@mui/material/Button";
import { Avatar, InputLabel, TextField, makeStyles } from "@mui/material";
import { Select, Divider } from '@mui/material';
import Box from "@mui/material/Box";
import { NavLink } from 'react-router-dom';

//Our Components
import OurDataGrid from "../../DataGrid/OurDataGrid";
import { Icon, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import { grid, textTransform } from "@mui/system";
import { thisUserContext } from "../../nav-container/NavContainer"

//Our styles
import styles from './settings-team.module.css';
import settingsTeamStyles from "./settingsTeamStyles";

import AddIcon from '@mui/icons-material/Add';
const MuiAdd = <AddIcon fontSize="large" />;



export default function SettingsProfile(props) {

    function titleCase(str) {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }
   // let history = useHistory()
   // const [thisUser,setThisUser] = useState() 
   //  let getThisUser = async () => {
   //      let _thisUser = await memberService.getMe()
   //      setThisUser(_thisUser)
   //      // console.log('thisUser ',_thisUser)
   //  }
   //  useEffect(() => {
   //      getThisUser()
   //  },[])
    // let thisUser = props.thisUser;


   //let roles = roleService.getAllRoles()
   //console.log(roles)

    let profileSummaryContent = { fullName: "" };


    

   const [disableSave, setDisableSave] = useState(true)
   const [userInfo, setUserInfo] = useState()
   const [profileSummary, setProfileSummary] = useState(profileSummaryContent)


   let thisContext = useContext(thisUserContext)


   useEffect(() => {
      if (props.thisUser) {
         let _userInfo = {
            firstName: props.thisUser.firstName,
            lastName: props.thisUser.lastName,
            email: props.thisUser.email,
            id: props.thisUser.id,
            role: "Developer",
            timezone: "Mountain Standard Time (MST) UTC-07:00",
            permissions: "Admin"
         }

         setUserInfo(_userInfo)
      }
   }, [props.thisUser])



    useEffect(() => {
      getGridCols();
      buildRows();
    }, [])

    useEffect(() => {
        if (props.roles?.length > 0 && roleDict.size == 0) {
            buildRoleDict();
        }
    }, [props.roles])


    const [roleDict, setRoleDict] = useState(new Map());
    const buildRoleDict = () => {
        let _roleDict = new Map();
        for (let i = 0; i < props.roles.length; i++) {
            _roleDict.set(props.roles[i].roleId, props.roles[i].roleName)
        }
        if (!_roleDict.get[0]) {
            _roleDict.set(0, "Enroller") // [TODO] Fix the DB.  Sometimes DB returns 0, which shouldn't be an actual role.
        }
        setRoleDict(_roleDict);
    };


   let handleInfoChange = (e, property) => {
      let value = e.target.value
      setUserInfo({
         ...userInfo,
         [property]: value
      })
      setDisableSave(false)
   }



   const [gridCols, setGridCols] = useState({})

   const [rows, setRows] = useState([]);
   const buildRows = () => {
      let _rows = [];

      //_rows = [];
       thisContext.allUsers.forEach((user) => {
           var existingUser = _rows.some((element) => element.id == user.id);
           if (existingUser) {
               // do nothing  [TODO: This is currently necessary, because allUsers can return duplicate users.  We should fix the DB query.]
           } else {
               let builtRow = {
                   id: user.id,
                   icon: "",
                   fullName: user.firstName + " " + user.lastName,
                   firstName: user.firstName,
                   lastName: user.lastName,
                   role: "Agent",
                   permissions: user.roleId,
                   email: user.email,
                   isEnabled: user.isEnabled,
               };
               _rows.push(builtRow);
           }
      });

      setRows(_rows);
      setProfileSummary(_rows[0]);
    };

  // Returns all filters from local storage or builds new
  const getGridCols = () => {
   //   let _gridCols = localStorage.getItem("memberSearchGridCols")
   //   if (_gridCols) {
   //    console.log(_gridCols)
   //    _gridCols = JSON.parse(_gridCols)
   //      setGridCols(_gridCols)
   //   } else {
        setGridCols({
         id: false,
         fullName: true,
         role: true,
         permissions: true,
         email: true,
         enrollmentId: false,
         association: false,
         phoneNumber: false,
         dob: false,
         // email: false
         //  role: true,
         //  permissions: true,
         //  email: true
        })
   //   }
  }


  const columns = [
   // { field: "id", headerName: "ID", flex: 90, hide: true },
   // {
   //   field: "isVip",
   //   headerName: "",
   //   flex: 20,
   //   headerClassName: "hide-right-sep",
   //   renderCell: (params) => {
   //     return <div>{params.value && <StarIcon color="warning" />}</div>;
   //     // console.log('renderparam',params)
   //   },
   // },
   {
     field: "fullName",
     headerName: "User",
     flex: 180,
     renderCell: (params) => {
       return (
         <Box sx={{display: 'flex', flexDirection:'row', alignItems: 'center'}}>
               <Box sx={{marginRight:'10px'} }>
                <Badge
                 style={{ display: "flex", verticalAlign:"middle"}}
                       variant="dot">
                       <Avatar sx={{ bgcolor: "primary.light", color: "primary.main", fontFamily: "Archivo", fontWeight: "600", width: 40, height: 40, fontSize: 14 }} src={params.row.id > 0 ? `api/image/user/${params.row.id}` : ''}>
                           {params.row.firstName && `${params.row.firstName[0].toUpperCase()}${params.row.lastName[0].toUpperCase()}`}
                       </Avatar>
                   </Badge>
             </Box>
             <Box>
                <span style={{ display: "inline-block", verticalAlign:"middle"}}>
                <Typography
                       variant="body1"
                       fontWeight="400"
                       sx={settingsTeamStyles.teamEmployeeColumnText}
                >
                  {titleCase(params.row.fullName)}
                </Typography>
                </span>
             </Box>
         </Box>
       );
     },
   },
   {
      field: "role",
      headerName: "Role",
      flex: 80,
      renderCell: (params) => {
        return (
          <Box style={{ display: "block" }}>
            <Typography
              variant="body1"
              fontWeight="400"
              sx={settingsTeamStyles.teamEmployeeColumnText}
            >
              {params.row.role}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "permissions",
      headerName: "Permissions",
      flex: 100,
      renderCell: (params) => {
        return (
          <Box style={{ display: "block" }}>
            <Typography
              variant="body1"
              fontWeight="400"
              sx={settingsTeamStyles.teamEmployeeColumnText}
            >
                    {roleDict.get(params.row.permissions)}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 200,
      renderCell: (params) => {
        return (
          <Box style={{ display: "block" }}>
            <Typography
              variant="body1"
              fontWeight="400"
              sx={settingsTeamStyles.teamEmployeeColumnText}
            >
              {params.row.email}
            </Typography>
          </Box>
        );
      },
    },
 ];


const handleRowClick = (e) => {
     setSelectedRowId(e.id);
     const found = thisContext.allUsers.find((element) => element.id == e.row.id);
     if (found)
     {
         found.fullName = found.firstName + " " + found.lastName;
         found.permissions = found.roleId;
         setProfileSummary(found);
     }
   // history.push(`/members/${e.id}`, { profile: true, profileId: e.id });
    };
    const [selectedRowId, setSelectedRowId] = React.useState(null);
    const getRowClassName = (params) => {
        //return params.id === selectedRowId ? 'highlighted-row' : '';
        return params.id === selectedRowId ? 'highlighted-row' : params.id % 2 === 0 ? 'even' : 'odd';
    };



   return (
       <>
           <Box sx={{position:'absolute', top:99, right:33}}>
           <Button variant='contained'
                // onClick={() => handleTest2()}
                disabled={true}
                sx={settingsTeamStyles.employeeCardButton}
           >
               <AddIcon fontSize="medium" />
                    ADD USER
               </Button>
           </Box>
           <Box sx={{ height: 'calc(100vh - 208px)', display: "flex", flexDirection: "row", overflow: "hidden", overflowX: "hidden", alignItems: "center", border: 'solid 1px', borderColor: 'background.border', marginLeft: "15px", marginRight: "15px", marginBottom: "15px" }}>
            {/* DATA GRID */}
               <Box sx={{ height: "100%", width: "100%"}}>
               <OurDataGrid
                       manualHeight
                       rows={rows}
                       columnVisibilityModel={gridCols}
                       setGridCols={setGridCols}
                       columns={columns}
                       handleRowClick={handleRowClick}
                       getRowClassName={getRowClassName}
                       manualStyle={{
                           minHeight: "52px",
                           borderRadius: "0px",
                           borderTopLeftRadius: "0px",
                           borderTopRightRadius: "0px",
                           padding: "0px 10px",
                           margin: "0px 15px",
                           width: "100%",
                           border: "0px",
                           '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '6px' },
                           '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
                       }}
                      
                       autoPageSize
                       disableSelectionOnClick
                       //pageSize={15}
                  // noRowsComp={NoMemberRows}
                  // noResultsComp={NoMemberResults}
                  // filterModel={filterModel}
                  // setFilterModel={setFilterModel}
                  // loadingState={loadingState}
                  // showSaveFilterModel={false}
                   />
                   <style>
                        {`
                              .highlighted-row {
                                background-color: rgba(25,95,251, 0.02); /* Light blue */
                                border-left: 4px solid #195ffb;
                              }
                        `}
                   </style>
               </Box>
               {/*<Box sx={{ height: '95%', width: '20px', bgcolor: 'background.border', borderLeft: "thick solid #ff0000" }} />*/}
               <Box sx={{ height: '95%', width: '1px', bgcolor: 'background.border', position: 'relative', display: 'block', marginLeft:'15px'}} />
               {/*<hr width="1" size="500" style="0 auto" />*/}
            {/* RIGHT PANEL */}
            <Box style={{display:"flex", flexDirection:"column", minWidth: "365px", overflow:"hidden", margin: "15px 15px", height:"100%"}}>
               <Box style={{textAlign: "center"}}>
                       <Box sx={{marginTop:"10px"}}>
                           <Badge variant="dot" >
                               <Avatar src={profileSummary.id > 0 ? `api/image/user/${profileSummary.id}` : ''} sx={{ bgcolor: "primary.light", color: "primary.main", fontFamily: "Archivo", fontWeight: "600", fontSize: 64, width: "124px", height: "124px" }}>
                                   {profileSummary.firstName && `${profileSummary.firstName[0].toUpperCase()}${profileSummary.lastName[0].toUpperCase()}`}
                               </Avatar>
                           </Badge>
                  </Box>
                     <Typography
                           sx={settingsTeamStyles.employeeCardHeader}>
                           {titleCase(profileSummary.fullName)}
                     </Typography>
                  <Box>
                     <Typography 
                      variant="overline"
                      sx={settingsTeamStyles.employeeCardItemLabels}>
                               {profileSummary.isEnabled ? 'Active ': 'Inactive' } | Agent
                     </Typography>
                  </Box>
               </Box>
                   <Divider />

                   <Box style={{ margin: "15px 15px", display: "flex", flexDirection: "column", height:'100%'}}>
                       <Box style={{ marginBottom:'auto'}}> 
                            <Box>
                                <Typography sx={settingsTeamStyles.employeeCardSubHeader}>
                                User Information
                                </Typography>
                            </Box>

                            <Box sx={settingsTeamStyles.settingsTeamEmployeeCardItemContainer}>
                                <Box>
                                <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>
                                    Email
                                </Typography>
                                </Box>
                                <Box>
                                <Typography sx={settingsTeamStyles.employeeCardItemData}>
                                    {profileSummary.email}
                                </Typography>
                                </Box>
                            </Box>
                            <Box sx={settingsTeamStyles.settingsTeamEmployeeCardItemContainer}>
                                <Box>
                                <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>
                                    Permissions
                                </Typography>
                                </Box>
                                <Box>
                                <Typography sx={settingsTeamStyles.employeeCardItemData}>
                                        {roleDict.get(profileSummary.permissions)}
                                </Typography>
                                </Box>
                            </Box>
                       </Box>


               {/*   The below content is a more filled out version of the design that we may want to bring back once we have more user data pulling from the backend.  */ }
               {/*   <Box style={{margin: "15px 15px"}}>*/}
               {/*      <Box>*/}
               {/*       <Typography sx={settingsTeamStyles.employeeCardSubHeader}>*/}
               {/*         Employment*/}
               {/*       </Typography>*/}
               {/*      </Box>*/}
               {/*      <Box sx={settingsTeamStyles.settingsTeamEmployeeCardColumnContainer}>*/}
               {/*            <Box sx={{...settingsTeamStyles.settingsTeamEmployeeCardItemContainer}}>*/}
               {/*           <Box>*/}
               {/*             <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>*/}
               {/*               Last login*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*           <Box>*/}
               {/*             <Typography sx={settingsTeamStyles.employeeCardItemData}>*/}
               {/*               No Data*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*         </Box>*/}
               {/*         <Box sx={settingsTeamStyles.settingsTeamEmployeeCardItemContainer}>*/}
               {/*           <Box>*/}
               {/*             <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>*/}
               {/*               Permissions*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*           <Box>*/}
               {/*             <Typography sx={settingsTeamStyles.employeeCardItemData}>*/}
               {/*                  {roleDict.get(profileSummary.permissions)}*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*         </Box>*/}
               {/*            <Box sx={{...settingsTeamStyles.settingsTeamEmployeeCardItemContainer}}> */}
               {/*           <Box>*/}
               {/*             <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>*/}
               {/*               Activation Date*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*           <Box>*/}
               {/*             <Typography sx={settingsTeamStyles.employeeCardItemData}>*/}
               {/*               No Data*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*         </Box>*/}
               {/*            <Box sx={{...settingsTeamStyles.settingsTeamEmployeeCardItemContainer}}>*/}
               {/*           <Box>*/}
               {/*             <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>*/}
               {/*               Activated By*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*           <Box>*/}
               {/*             <Typography sx={settingsTeamStyles.employeeCardItemData}>*/}
               {/*               No Data*/}
               {/*             </Typography>*/}
               {/*           </Box>*/}
               {/*         </Box>*/}
               {/*      </Box>*/}
               {/*   </Box>*/}
               {/*<Divider/>*/}
               {/*<Box style={{margin: "10px 15px"}}>*/}
               {/*   <Typography sx={settingsTeamStyles.employeeCardSubHeader}>*/}
               {/*     Contact Information*/}
               {/*   </Typography>*/}
               {/*   <Box sx={settingsTeamStyles.settingsTeamEmployeeCardItemContainer}>*/}
               {/*       <Box>*/}
               {/*         <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>*/}
               {/*           Email*/}
               {/*         </Typography>*/}
               {/*       </Box>*/}
               {/*       <Box>*/}
               {/*         <Typography sx={settingsTeamStyles.employeeCardItemData}>*/}
               {/*             {profileSummary.email}*/}
               {/*         </Typography>*/}
               {/*       </Box>*/}
               {/*   </Box>*/}

               {/*   <Box sx={settingsTeamStyles.settingsTeamEmployeeCardColumnContainer} >*/}
               {/*            <Box sx={{...settingsTeamStyles.settingsTeamEmployeeCardItemContainer}}>*/}
               {/*       <Box>*/}
               {/*         <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>*/}
               {/*           Phone*/}
               {/*         </Typography>*/}
               {/*       </Box>*/}
               {/*       <Box>*/}
               {/*         <Typography sx={settingsTeamStyles.employeeCardItemData}>*/}
               {/*             No Data*/}
               {/*         </Typography>*/}
               {/*       </Box>*/}
               {/*     </Box>*/}
               {/*            <Box sx={{...settingsTeamStyles.settingsTeamEmployeeCardItemContainer}}>*/}
               {/*       <Box>*/}
               {/*         <Typography variant="overline" sx={settingsTeamStyles.employeeCardItemLabels}>*/}
               {/*           Ext.*/}
               {/*         </Typography>*/}
               {/*       </Box>*/}
               {/*       <Box>*/}
               {/*         <Typography sx={settingsTeamStyles.employeeCardItemData}>*/}
               {/*             No Data*/}
               {/*         </Typography>*/}
               {/*       </Box>*/}
               {/*     </Box>*/}
               {/*   </Box>*/}
                   {/* </Box>*/}
                   {/*<Box sx={{ height: '100%' }}/>*/}
                    <Box sx={settingsTeamStyles.settingsTeamEmployeeCardButtonContainer}>
                    <Button variant='outlined' 
                            // onClick={() => handleTest()}
                                disabled={true}
                                sx={settingsTeamStyles.employeeCardButton}
                        style={{float:"left"}}
                        >
                        SEND PASSWORD RESET LINK
                    </Button>
                        <Button variant='contained'
                            // onClick={() => handleTest2()}
                                disabled={true}
                            sx={settingsTeamStyles.employeeCardButton}
                            style={{float:"right"}}
                        >
                        EDIT
                    </Button>
                    </Box>
              </Box>
            </Box>
        </Box>

         


      </>
   )
}
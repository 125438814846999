import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

//Mui icons
import AddIcon from "@mui/icons-material/Add";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";

//Our Components
import EnrollmentSummarySTD from "./EnrollmentSummarySTD";
import EnrollmentSummaryLTD from "./EnrollmentSummaryLTD";
import EnrollmentSummaryLADD from "./EnrollmentSummaryLADD";
import EnrollmentSummaryAccident from "./EnrollmentSummaryAccident";
import EnrollmentSummaryCancer from "./EnrollmentSummaryCancer";
import EnrollmentSummaryADD from "./EnrollmentSummaryADD";
import EnrollmentSummaryHospital from "./EnrollmentSummaryHospital";

export default function EnrollmentSummaryBlock(props) {
  const history = useHistory();

  const handleExpandClick = (plan) => {
    let historyState = { ...history.location.state, planId : plan.planId }
    if (props.agentEnroller) {
      props.setAgentEnrollmentPageState({ plans: true });
      history.push(history.location.pathname,historyState);
    } else {
        history.push("plans", historyState);
    }
  };

  const formatDate = (rawDate) => {
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
    let date = new Date();
    date.setUTCFullYear(+yyyy);
    date.setUTCDate(+dd);
    date.setUTCMonth(mm - 1);
    date.setUTCHours(+hh);
    date.setUTCMinutes(+m + date.getTimezoneOffset());
    date.setUTCSeconds(+s);
    date = format(date,"MM/dd/yyyy");
    return date; 
  }




  const [summaryList,setSummaryList] = useState([])
  const buildOrderedSummaryList = (results) => {
    // let results = props.results
    //Build array of nodes with sort order, then map over to display
    let unorderedList = [
        // {plan: ReactNode, order: int}
    ]
    if (results?.stdPlan) {
        unorderedList.push(
          {
              plan: <EnrollmentSummarySTD
              results={props.results}
              selections={props.selections}
              handleExpandClick={handleExpandClick}
              formatDate={formatDate}
              />, 
              order: results.stdPlan.sortOrder,
              planId: results.stdPlan.planId
          }
        )
    }
    if (results?.ltdPlan) {
        unorderedList.push(
          {
              plan: <EnrollmentSummaryLTD
              results={props.results}
              selections={props.selections}
              handleExpandClick={handleExpandClick}
              formatDate={formatDate}
              />,
              order: results.ltdPlan.sortOrder,
              planId: results.ltdPlan.planId
          }
        )
    }
    if (results?.accidentPlan) {
        unorderedList.push(
          {
              plan: <EnrollmentSummaryAccident 
              results={props.results}
              selections={props.selections}
              handleExpandClick={handleExpandClick}
              formatDate={formatDate}
              />,
              order: results.accidentPlan.sortOrder,
              planId: results.accidentPlan.planId
          }
        )
    }
    if (results?.cancerPlan) {
      unorderedList.push(
        {
          plan: <EnrollmentSummaryCancer 
          results={props.results}
          selections={props.selections}
          handleExpandClick={handleExpandClick}
          formatDate={formatDate}
          />,
          order: results.cancerPlan.sortOrder,
          planId: results.cancerPlan.planId
        }
      )
    }
    if (results?.addPlan) {
      unorderedList.push(
        {
          plan: <EnrollmentSummaryADD 
          results={props.results}
          selections={props.selections}
          handleExpandClick={handleExpandClick}
          formatDate={formatDate}
          />,
          order: results.addPlan.sortOrder,
          planId: results.addPlan.planId
        }
      )
    }
    if (results?.hospitalPlan) {
      unorderedList.push(
        {
          plan: <EnrollmentSummaryHospital
          results={props.results}
          selections={props.selections}
          handleExpandClick={handleExpandClick}
          formatDate={formatDate}
          />,
          order: results.hospitalPlan.sortOrder,
          planId: results.hospitalPlan.planId
        }
      )
    }
    if (results?.lifeADDPlan) {
        unorderedList.push(
          {
            plan: <EnrollmentSummaryLADD
            results={props.results}
            selections={props.selections}
            handleExpandClick={handleExpandClick}
            formatDate={formatDate}
            />,
            order: results.lifeADDPlan.sortOrder,
            planId: results.lifeADDPlan.planId
          }
        )
    }
    
    let orderedList = unorderedList.sort((a, b) => a.order - b.order)
    setSummaryList(orderedList)

  }

  useEffect(() => {
    if (props.results) buildOrderedSummaryList(props.results)
  },[props.results])


  return (
    <>
      {/* STD */}
      {/* {props.results?.stdPlan && (
        <EnrollmentSummarySTD
          results={props.results}
          selections={props.selections}
          handleExpandClick={handleExpandClick}
        />
      )} */}

      {/* LTD */}
      {/* {props.results?.ltdPlan && (
        <EnrollmentSummaryLTD
          results={props.results}
          selections={props.selections}
          handleExpandClick={handleExpandClick}
        />
      )} */}

      {/* Accident */}
      {/* {props.results?.accidentPlan &&(
        <EnrollmentSummaryAccident 
          results={props.results}
          selections={props.selections}
          handleExpandClick={handleExpandClick}
        />
      )} */}

      {/* LADD */}
      {/* {props.results?.lifeADDPlan && (
        <EnrollmentSummaryLADD
          results={props.results}
          selections={props.selections}
          handleExpandClick={handleExpandClick}
        />
      )} */}

      {summaryList.map(summaryObj => summaryObj.plan)}
    </>
  );
}

import styles from './ExternalLoginAccount.module.css'

import React, { useEffect, useLayoutEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
//Assets
import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg'
import FallbackLogo from '../../../assets/images/SourceFileonly icon.png'

//Services
import authService from '../../../services/auth-service';
import memberService from '../../../services/member-service';

//MuiComponents
import { useTheme, useMediaQuery } from "@mui/material";
import Button from '@mui/material/Button';
import { Divider, FormControlLabel, Typography } from "@mui/material";
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

//MuiIcons
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Our Components
//const ExternalForgot = lazy(() => import('../external-forgot/ExternalForgot'))
import ExternalForgot from '../external-forgot/ExternalForgot';
import PoweredBy from '../external-enrollments/PoweredBy';
// import ExternalGlobalFooter from '../../app-marketing/external-global/global-footer/ExternalGlobalFooter';
// import ExternalGlobalNavigation from '../../app-marketing/external-global/global-navigation/ExternalGlobalNavigation';
import { ColorModeContext } from '../../../ExternalApp';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';


function ExternalLoginAccount(props) {



    const colorMode = useContext(ColorModeContext);

    const [values, setValues] = useState({
        userName: '',
        password: '',
    });
    const [stayLoggedIn, setStayLoggedIn] = useState(true)

    const history = useHistory();
    const [displayLoginError, setDisplayLoginError] = useState(false);


    //Forgot password
    const [forgotPassword, setForgotPassword] = useState(false)

    const forgetClick = () => {
        setForgotPassword(true)
    }


    //Password Visibility
    const [displayPassword, setDisplayPassword] = useState(false)

    const swapIcon = (e) => {
        //e.preventDefault();
        let inputElement = e.target.parentNode.children[0];
        if (displayPassword) {
            inputElement.setAttribute("type", "password");
            setDisplayPassword(false);
        } else {
            inputElement.setAttribute("type", "text");
            setDisplayPassword(true);
        }
    }

    const visibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onMouseDown={swapIcon} onMouseUp={swapIcon} onTouchStart={swapIcon} onTouchEnd={swapIcon}><VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
    const noVisibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onClick={swapIcon}><NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>

    const passwordIcon = displayPassword ? noVisibilityIcon : visibilityIcon;


    //Handlers
    const handleValueChange = (e) => {
        let inputVal = e.target.value;
        let inputProperty = e.target.getAttribute('data-property')
        // console.log(inputProperty, inputVal)
        setValues({
            ...values,
            [inputProperty]: inputVal
        })
    }
    const passwordKeyPressHandler = (e) => {
        if (e.key === 'Enter') loginAsync()
        if (e.key === ' ') e.preventDefault()
    }


    const getCookie = (cookieName) => {
        let name = `${cookieName}=`
        let cookieArray = document.cookie.split(';')
        let value = ''
        cookieArray.forEach(cookie => {
            if (cookie.includes(name)) value = cookie.replace(name, '').trim()
        })
        return value
    }
    const removeCookie = (cookieName) => {
        let hostArray = window.location.hostname.split('.').reverse()
        let domain = `.${hostArray[1]}.${hostArray[0]}`

        let name = `${cookieName}=`
        let cookieArray = document.cookie.split(';')

        cookieArray.forEach(cookie => {
            if (cookie.includes(name)) document.cookie = `${name};Domain=${domain};expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        })
    }

    const loginAsync = async (e) => {
        props.setLoading(true)
        //e.preventDefault();

        let isAuthenticated = false;
        const credentials = {
            username: values.userName,
            password: values.password
        }
        isAuthenticated = await authService.authenticate(credentials);
        // props.checkAuthentication()
        // console.log('isAuthenticated',isAuthenticated)

        if (isAuthenticated) {
            setDisplayLoginError(false);
            let domain = getCookie('subdomain')
            console.log('domain', domain)
            if (!domain.includes('cornerstone')) {
                window.location.href = `https://${domain}/enrollments`
            }
            else {
                window.location.href = `https://${domain}`
            }
            props.setLoading(false)
        }
        else {
            // console.log('not successful');
            props.setLoading(false)
            setDisplayLoginError(true);
        }

    }

    const registerClickFromLogin = () => {
        props.setLoading(true)
        setTimeout(() => {
            history.push('/register')
            props.setLoading(false)
        }, 250);

    }

    const [organization, setOrganization] = useState({})

    const getThisOrganization = async () => {
        try {
            let thisOrganization = await props.getOrganization()
            setOrganization(thisOrganization)
        }
        catch (e) {
            setOrganization("")
        }

    }

    // Check if the user is already logged in, and if the token is still valid, redirect them.
    const checkToken = async () => {
        let domain = getCookie('subdomain')
        let expiration = getCookie('expiration')
        let currentDate = new Date();
        console.log("expiration:", expiration, "currentDate:", currentDate, "domain:", domain)
        if (domain.length > 0 && expiration.length > 0) {
            if (new Date(expiration) < currentDate) {
                console.log("Token expired.");
            } else {
                console.log("Valid token");
                if (!domain.includes('cornerstone')) {
                    window.location.href = `https://${domain}/enrollments`
                }
                else {
                    window.location.href = `https://${domain}`
                }
            }
        }
    }

    useEffect(() => {
        getThisOrganization()
        checkToken()
    }, [])


    const getOrganizationLogo = () => {
        if (organization.id !== null) {
            return `/api/image/organization/${organization.id}`
        } else return FallbackLogo
    }

    let theme = useTheme()

    const debugTheme = () => {
        // console.log('THEME DEBUG',theme)
        // console.log('COLOR MODE DEBUG',colorMode)
        colorMode.ToggleColorMode()
    }

    const [imgFetchAttempts, setImgFetchAttempts] = useState(0)
    const handleLogoError = (e) => {
        console.log('logo img error', FallbackLogo)
        setImgFetchAttempts(prev => prev++)
        if (imgFetchAttempts <= 10) e.target.src = FallbackLogo
    }


    return (
        <>
            <>
                <style>
                    {`
                    #member-landing-container > div{
                        display:flex;
                        justify-content:center;
                        text-align:center;
                    }
                    .MuiFormHelperText-root.password-helper {
                        text-align: right;
                        // margin:15px 0px;
                        // line-height:50px;
                        cursor:pointer
                    }

                    `}
                </style>
                {props.loading &&
                    <>
                        <InfinityLoader style={{
                            position: 'absolute',
                            zIndex: 5,
                            width: '100%',
                            height: '100vh',
                            backgroundColor: '#00000050',
                            backdropFilter: 'blur(10px)',

                        }} />
                        <style>
                            {`
                        div.lottie-div > svg {
                            height:55px!important;
                            position:absolute;
                            top:50%;
                        }
                    `}
                        </style>
                    </>
                }

                <Box className="flex-container flex-row" id='enrollment-login' flex={1} overflow="auto" style={{ padding: '40px 40px', flexWrap: 'wrap', height: '100vh', margin: '0 auto' }} sx={{
                    bgcolor: 'background.default',
                    color: 'text.primary'
                }}>
                    <div className="row" style={{ maxWidth: '575px', margin: '0 auto', maxHeight: '600px' }}>
                        <div className="flex-row" style={{ margin: '6px', justifyContent: 'center' }} >
                            <img src={getOrganizationLogo()} style={{ maxHeight: '100px' }} onError={handleLogoError} />
                        </div>
                        {!forgotPassword ?
                            <>

                                <div className={`flex-row`} style={{ marginBottom: '20px', textAlign: 'center' }} >
                                    {organization?.name != 'Teamsters' &&
                                        <Typography variant="subtitle" fontWeight="600" width={'100%'}>
                                            <div>
                                                Log in to your {organization.name}
                                            </div>
                                            <div>
                                                UnionHub Account
                                            </div>
                                        </Typography>
                                    }
                                    {organization?.name == 'Teamsters' &&
                                        <Typography variant="subtitle" fontWeight="600" width={'100%'}>
                                            <div>
                                                Log in to your Teamsters VIP+
                                            </div>
                                            <div>
                                                UnionHub Account
                                            </div>
                                        </Typography>
                                    }
                                </div>

                                <div className="flex-row" style={{ marginBottom: '20px' }} >
                                    <TextField fullWidth
                                        sx={{ maxWidth: '685px' }}
                                        label="Email" variant="outlined" value={values.userName} onChange={handleValueChange}
                                        inputProps={{ 'data-property': 'userName' }}
                                        autoComplete='email'
                                        type='email'
                                        pattern=".+@.+\.com"
                                    />
                                </div>

                                <div className="flex-row" style={{ marginBottom: '10px' }}>
                                    <TextField fullWidth type='password'
                                        sx={{ maxWidth: '685px' }}
                                        label="Password" variant="outlined" value={values.password} onChange={handleValueChange}
                                        inputProps={{ 'data-property': 'password' }}
                                        onKeyPress={passwordKeyPressHandler} InputProps={{ endAdornment: passwordIcon, sx: { paddingRight: '0px' } }}
                                    />
                                </div>
                                <div className='row' style={{ justifyContent: "space-between", maxWidth: '685px', margin: "auto", marginBottom: '30px' }}>
                                    <FormHelperText style={{ fontSize: '14px', textAlign: 'right', fontFamily: 'Archivo', fontWeight: 500, padding: '0px', height: '35px', position: 'relative' }} className="password-helper">
                                        <Link sx={{ textDecoration: 'none', position: 'absolute', top: 0, right: 0 }} onClick={forgetClick}>Forgot Password</Link>
                                    </FormHelperText>

                                    <FormControlLabel className={styles.keepLoggedIn} sx={{ '.MuiFormControlLabel-label': { justifySelf: "start" } }} label="Keep me logged in" control={<Checkbox sx={{ 'svg': {} }} checked={stayLoggedIn} onClick={() => setStayLoggedIn(prev => !prev)} />} />


                                </div>

                                {displayLoginError &&
                                    <Alert severity="error" sx={{ marginBottom: '20px' }}>Invalid Email or Password</Alert>
                                }

                                <div className="flex-row" style={{ marginBottom: '25px' }} >
                                    <Button variant='contained' className={`continue-login-btn ${styles.btn}`} fullWidth
                                        sx={{ height: 50, maxWidth: '574px' }}
                                        onClick={loginAsync}
                                        color="primary"
                                    >
                                        Log in
                                    </Button>
                                </div>

                                <Divider sx={{ color: '#989898', fontWeight: 400, fontSize: '15px', fontFamily: 'Poppins', maxWidth: '900px ', margin: 'auto', ':before, :after': { top: '0%' } }}>Or</Divider>

                                <div className="flex-row" style={{ marginTop: '25px', marginBottom: '100px' }}>
                                    <Button variant='outlined' className={`continue-register-btn ${styles.btn}`} fullWidth
                                        sx={{ height: 50, maxWidth: '574px' }}
                                        onClick={registerClickFromLogin}
                                    >
                                        Register
                                    </Button>
                                </div>


                            </>
                            :
                            <>
                                <ExternalForgot setForgotPassword={setForgotPassword} />
                            </>}

                        <PoweredBy />
                    </div>



                </Box>
            </>
        </>
        //
    );
};

export default ExternalLoginAccount;
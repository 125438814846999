import React, { useEffect, useState, useRef } from "react";
import { format, differenceInCalendarYears } from "date-fns";


//Assets
import FallbackLogo from '../../../../../../../assets/images/SourceFileonly icon.png'

//Services
import planService from "../../../../../../../services/plan-service";
import enrollmentService from "../../../../../../../services/enrollment-service";


//Mui icons
import EventIcon from '@mui/icons-material/Event';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Mui Components
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";

//Our Components
import EnrollmentSummaryCardLTD from "./enrollment-summary-plans/EnrollmentSummaryCardLTD";
import EnrollmentSummaryCardSTD from "./enrollment-summary-plans/EnrollmentSummaryCardSTD";
import EnrollmentSummaryCardLADD from "./enrollment-summary-plans/EnrollmentSummaryCardLADD";
import EnrollmentSummaryCardAccident from "./enrollment-summary-plans/EnrollmentSummaryCardAccident";
import EnrollmentSummaryCardCancer from "./enrollment-summary-plans/EnrollmentSummaryCardCancer";
import EnrollmentSummaryCardADD from "./enrollment-summary-plans/EnrollmentSummaryCardADD";
import EnrollmentSummaryCardLTDIncremental from "./enrollment-summary-plans/EnrollmentSummaryCardLTDIncremental";
import EnrollmentSummaryCardSTDIncremental from "./enrollment-summary-plans/EnrollmentSummaryCardSTDIncremental";
import EnrollmentSummaryCardHospital from "./enrollment-summary-plans/EnrollmentSummaryCardHospital";
import CancelCoverageDrawer from "./CancelCoverageDrawer";
import CancelAllCoverageModal from "./CancelAllCoverageModal";



export default function PlanSummaryCard (props) {

   const componentStyles = {
      menuOptions: {
         color: "text.primary", 
         fontFamily: "Archivo", 
         fontWeight: 400, 
         fontSize:'14px',
         lineHeight:'24px',
         letterSpacing:'.17px', 
         justifyContent:'center', 
         height:'48px',
         '&:hover': { backgroundColor: 'primary.light' }
      },
      planNameAndDate: {
         fontFamily:'Archivo',
         fontSize:'14px',
         fontWeight:500,
         lineHeight:'22px',
         letterSpacing:'.1px', 
         color:'text.gray'
      }
   }

   let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
   let formatDate = props?.formatDate


   const [planInfo, setPlanInfo] = useState(null);
   const getThisPlan = async () => {
      let planInfo;

      try {
         planInfo = await enrollmentService.getEnrollmentById(props.singlePlan.enrollmentId);
         if (planInfo) {
            setPlanInfo(planInfo);
         } else {
             setPlanInfo(props.singlePlan);
         }
      } catch (error) {
          console.log(`Error grabbing enrollment ${props.singlePlan.enrollmentId}`, error)
          setPlanInfo(props.singlePlan)
      }
   }

   useEffect(() => {
      if (props.singlePlan && !planInfo) {
         getThisPlan()
      }
   },[props.singlePlan])

   //date formatter - takes in date string
   const dateFormatter = (date) => {
      let dateObj = new Date(date);

      let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      let day = dateObj.getDate().toString().padStart(2, "0");
      let year = dateObj.getFullYear();

      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
   };   

   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
   ];

   const formatEffective = (date) => {
      // let fullDate = new Date(props?.enrollmentResults?.coverageEffectiveDate);
      let sliceDate = date.slice(
        0,
        10
      );
      let thisYear = Number(sliceDate.slice(0, 4));
      let thisMonth = Number(sliceDate.slice(5, 7)) - 1;
      // console.log(sliceYear,sliceMonth)
      // let thisMonth = fullDate.getMonth();
      // let thisYear = fullDate.getFullYear();
      // console.log("first payment", thisMonth, thisYear);
      return `${months[thisMonth]} ${thisYear} Effective`;
      // setFirstPayment({
      //   ...firstPayment,
      //   month: months[thisMonth],
      //   year: thisYear,
      // });
      // console.log(firstPayment);
    };

   const [nextPayment, setNextPayment] = useState()

   // useEffect(() => {
   //    console.log('NEXT PAY',props.paymentSchedule.nextOccurance)
   //    if(props.paymentSchedule?.nextOccurance){
   //             // let _nextPayment = props.paymentSchedule.nextOccurance
   //             let _nextPayment = format(new Date(`${props?.paymentSchedule?.nextOccurance?.slice(0,10)}T00:00:00`), 'MM-dd-yyyy')
   //    setNextPayment(_nextPayment)
   //    }

   // }, [props.paymentSchedule.nextOccurance])

   const [plans, setPlans] = useState([]);

   useEffect(() => {
      if (planInfo){
         let _plans = []
         for (let [key,value] of Object.entries(planInfo)) {
            if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
              _plans.push({[key]:value})
            }
         }
         _plans = _plans.sort((a,b) => Object.values(a)[0].sortOrder > Object.values(b)[0].sortOrder ? 1 : -1)
         setPlans(_plans)
      }

   },[planInfo])

   //Single line of coverage Cancellations List
   const [enrollmentCancellations, setEnrollmentCancellations] = useState([]);

   const getEnrollmentCancellations = async (enrollmentId) => {
      let _enrollmentCancellations = await enrollmentService.getEnrollmentPlanCancellations(enrollmentId);
      setEnrollmentCancellations(_enrollmentCancellations);
   }
   useEffect(()=>{
      if (planInfo) {
         getEnrollmentCancellations(planInfo.enrollmentId);
      }
   },[planInfo])
   
   console.log('enrollmentCancellations',enrollmentCancellations)

   //filter out all selected lines of coverage that are not pending cancellation
   const [activePlansNotPendingCancel, setActivePlansNotPendingCancel] = useState([])

   useEffect(() => {
      //if plans are not empty, create array of active plans that are not decline and pending cancelation
      if(plans?.length > 0 ){
         let _activePlans = plans.filter(plan => {
            let planKey = Object.keys(plan)[0]
            let planValue = Object.values(plan)[0]

            let planName = planKey.slice(0,planKey.length-4)

            switch(planName.toUpperCase()){
               case 'LIFEADD':
                  planName = 'Life & AD&D'
               break;
            }
            
            //filter out options selected that are not decline 
            //does not matter if some of these declines are due to cancelation, we just want array of NOT DECLINE
            let declinePlanOptionSelected = false
            if(planValue?.options){
               declinePlanOptionSelected = planValue?.options?.find(option => {
                  if(option?.isDecline && option?.memberOptionId === planValue?.selectedOptionId) {
                     return option
                  }
               })
            }

            //check if plan was not selected (this is also considered a decline) by checking selectedOptionId:0 for all plans excluding ladd
            if(planName != 'Life & AD&D' && planValue?.selectedOptionId == 0 ){
               declinePlanOptionSelected = true
            } 

            //check Life & AD&D plan for decline - different data structure
            if(planName == 'Life & AD&D' && planValue.totalPremiumAmount == 0 ){
                declinePlanOptionSelected = true
             }

            //if declinePlanOptionSelected is false, add to array of active plans
            if(!declinePlanOptionSelected){
               return plan
            } 
            console.log('declinePlanOptionSelected',planName, declinePlanOptionSelected)

         })

         setActivePlansNotPendingCancel(_activePlans)
 
      }
   },[enrollmentCancellations?.length, plans])

  console.log('activePlansNotPendingCancel',activePlansNotPendingCancel)



   const [resumeAnchorEl, setResumeAnchorEl] = useState(null)


   const [collapse, setCollapse] = useState(false)

   useEffect(() => {
      if (props.selectedPlan?.enrollmentId !== planInfo?.enrollmentId){
         setCollapse(false)
      }
   },[props.selectedPlan, planInfo])

   const handleMenuClick = () => {
      setCollapse(!collapse)
      // setResumeAnchorEl(null)
   }

   const handleRegenerateClick = async () => {
      let sendEmail = false
      try {

         await enrollmentService.generateEnrollmentReport(planInfo.enrollmentId,sendEmail)
      } catch (error) {
         console.log('error generating enrollment report',error)
      }
      setTimeout( async() => {
         await props.getAllDocuments()

      }, 200);
      setResumeAnchorEl(null)
   }

   const handleLogoError = (e) => {
      e.target.src = FallbackLogo
   }

 
  // Cancel Coverage Drawer - logic for ALL plans
  const [cancelCoverageDrawerOpen, setCancelCoverageDrawerOpen] = useState(false);
  const [planToCancel, setPlanToCancel] = useState(null);

  const handleOpenDrawer = (planSelected, planName) => {
    console.log("planSelected", planSelected);
    if(planSelected) setPlanToCancel({...planSelected, planName})
    setCancelCoverageDrawerOpen(true);
  };
  
  

   let today = new Date();

   let pendingCancellationWarning = (pendingCancellationObj) => {
      let cancelationDate = new Date(pendingCancellationObj?.effectiveDate)
      cancelationDate = format(cancelationDate, "MM/d/yy");
      return (
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '0px 5px 10px 0px'}}>
            <Chip 
            sx={{
               // minWidth: "200px",
               backgroundColor: 'background.notification.critical',
               color: 'error.main',
               fontFamily: "Archivo",
               margin: "0",
               fontWeight: "500",
               alignItems: "center",
               fontSize: "13px",

            }}
               label={
                  `Cancelation scheduled for ${cancelationDate}` || ""
               }
            />
         </Box>
     
      )
   }
  
   
   //CANCEL ALL COVERAGE LOGIC
   
   const [cancelAllCoverageModalOpen, setCancelAllCoverageModalOpen] = useState(false)
   const [loading, setLoading] = useState(false)
   const [cancelAllError, setCancelAllError] = useState(false)

   const openCancelAllCoverageModal = () => {
      setCancelAllCoverageModalOpen(true)
      //close menu
      setResumeAnchorEl(null)
   }

   const handleCloseCancelAllModal = () => {
      setCancelAllCoverageModalOpen(false)
      //set any cancel error to false
      setCancelAllError(false)
   }
  
   const handleCancelAllCoverage = async() => {
      setLoading(true)
      let memberId = props?.member?.id
      let enrollmentId = props?.singlePlan?.enrollmentId
      let errorCanceling = false

      try {
         let cancelAllCoverage = await enrollmentService.cancelEnrollment(enrollmentId, memberId);

      } catch (error) {
         console.error('error canceling all coverage',error)
         setCancelAllError(error)
         //local error value for conditional rendering of modal
         errorCanceling = true
         setLoading(false)
      }

      setTimeout(() => {
        //refresh enrollment data
      //   props?.getOneEnrollment(props?.selectedPlan)
        props?.getEnrollments()
        //refresh payment schedules
        props?.getPaymentSchedule()
        setLoading(false) 
        //close modal if no error, otherwise display error
        if(!errorCanceling){
           setCancelAllCoverageModalOpen(false)
        }
      }, 500);
   }

   // HFK - this is the old method, most likely can be deprecated 
   const createSinglePolicySummary = () => {
        let returnedComponent;

        returnedComponent = <Box sx={{ paddingBottom: '4px', width: '100%' }}>
            <Typography
                sx={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: 400, lineHeight: '28px', letterSpacing: '.15px', color: 'text.gray', }}
            >
                {props.singlePlan && props.singlePlan?.policyNumber?.length > 0 &&
                    `Policy #${props.singlePlan?.policyNumber}`
                    || "Policy Number"
                }
            </Typography>
            {/* <br/> */}
            <Collapse in={plans.length > 0}>
                {plans.length > 0 && plans.map(plan => {
                    let planKey = Object.keys(plan)[0]
                    let planValue = Object.values(plan)[0]
                    // console.log('plan value from plan summary', planValue)

                    let planName = planKey.slice(0, planKey.length - 4)
                    switch (planName.toUpperCase()) {
                        case 'LIFEADD':
                            planName = 'Life & AD&D'
                            break;

                    }

                    //find any declined plan options elected, otherwise this value is false
                    let declinePlanOptionSelected = false
                    if (planValue?.options) {
                        declinePlanOptionSelected = planValue?.options?.find(option => {
                            if (option?.isDecline && option?.memberOptionId === planValue?.selectedOptionId) {
                                return option
                            }
                        })
                    }

                    //check if plan was not selected (this is also considered a decline) by checking selectedOptionId:0 for all plans excluding ladd
                    if (planName != 'Life & AD&D' && planValue?.selectedOptionId == 0) declinePlanOptionSelected = true

                    let pendingCancellation = false
                    // console.log('enrollmentCancellations',enrollmentCancellations,planInfo.enrollmentId,planValue?.planId)
                    let pendingCancellationObj = enrollmentCancellations.find(cancellation => cancellation.enrollmentId === planInfo.enrollmentId && cancellation.planId === planValue?.planId)

                    let cancelationDate = new Date(pendingCancellationObj?.effectiveDate)

                    if (enrollmentCancellations.length > 0 && pendingCancellationObj) {
                        //want to show something has been canceled even after the cancelation date passed (do not want ot hide from UI)
                        pendingCancellation = true
                        //Set declinePlanOptionSelected to false so that the plan will show up in the summary
                        declinePlanOptionSelected = false
                        console.log('hitting the pending', cancelationDate, today)

                    }

                    return (
                        declinePlanOptionSelected || (planValue?.totalPremiumAmount == 0 && !pendingCancellation) ? "" :
                            (
                                <Box key={planName} className='flex-row'>

                                    <Typography
                                        variant='body2'
                                        sx={componentStyles.planNameAndDate}
                                    >
                                        {planName.toUpperCase()}:
                                    </Typography>


                                    {/* show effective date if not pending cancellation */}
                                    {!pendingCancellation && planValue?.effectiveDate &&
                                        <Typography
                                            variant='body2'
                                            sx={{ ...componentStyles.planNameAndDate, marginLeft: '5px' }}
                                        >
                                            {formatEffective(planValue?.effectiveDate)}
                                        </Typography>}

                                    {/* show pending cancelation*/}
                                    {pendingCancellation && cancelationDate > today && planValue?.effectiveDate &&
                                        <Typography
                                            variant='body2'
                                            sx={{ ...componentStyles.planNameAndDate, color: '#C62828', marginLeft: '5px' }}
                                        >
                                            PENDING CANCELATION {dateFormatter(pendingCancellationObj?.effectiveDate)}
                                        </Typography>}

                                    {/* show canceled line of coverage*/}
                                    {pendingCancellation && cancelationDate < today && planValue?.effectiveDate &&
                                        <Typography
                                            variant='body2'
                                            sx={{ ...componentStyles.planNameAndDate, marginLeft: '5px' }}
                                        >
                                            CANCELED {dateFormatter(pendingCancellationObj?.effectiveDate)}
                                        </Typography>
                                    }

                                </Box>
                            )
                    )
                })}
            </Collapse>
        </Box>

        return returnedComponent;
    } 

   // HFK - helper method to create individual policy summary
   //     - input is expected to be an array of plans where each has the same policy number
    const createIndividualPolicySummary = (planArray) => {
        let returnedComponent;
        let numRenderedPolicies = 0;

        // extract the policy number
        let planName = Object.keys(planArray[0])
        let polNum = planArray[0][planName].policyNumber

        // initialize
        let renderedPolNum = "Policy Number"

        // replace text if applicable
        if (polNum.length > 0) {
            renderedPolNum = `Policy #${polNum}`
        }

        // this is the component which renders the policy number itself
        let polNumComponent = <Typography
            sx={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: 400, lineHeight: '28px', letterSpacing: '.15px', color: 'text.gray', }}
        >
            {renderedPolNum}
        </Typography>

        // this is the component which renders the status of each invidual plan
        // this also accumulates the number of plans which are valid (will be rendered) for the policy
        let polSummaryComponent = <Collapse in={planArray.length > 0}>
            {planArray.length > 0 && planArray.map(plan => {
                let planKey = Object.keys(plan)[0]
                let planValue = Object.values(plan)[0]
                // console.log('plan value from plan summary', planValue)

                let planName = planKey.slice(0, planKey.length - 4)
                switch (planName.toUpperCase()) {
                    case 'LIFEADD':
                        planName = 'Life & AD&D'
                        break;

                }

                //find any declined plan options elected, otherwise this value is false
                let declinePlanOptionSelected = false
                if (planValue?.options) {
                    declinePlanOptionSelected = planValue?.options?.find(option => {
                        if (option?.isDecline && option?.memberOptionId === planValue?.selectedOptionId) {
                            return option
                        }
                    })
                }

                //check if plan was not selected (this is also considered a decline) by checking selectedOptionId:0 for all plans excluding ladd
                if (planName != 'Life & AD&D' && planValue?.selectedOptionId == 0) declinePlanOptionSelected = true

                let pendingCancellation = false
                // console.log('enrollmentCancellations',enrollmentCancellations,planInfo.enrollmentId,planValue?.planId)
                let pendingCancellationObj = enrollmentCancellations.find(cancellation => cancellation.enrollmentId === planInfo.enrollmentId && cancellation.planId === planValue?.planId)

                let cancelationDate = new Date(pendingCancellationObj?.effectiveDate)

                if (enrollmentCancellations.length > 0 && pendingCancellationObj) {
                    //want to show something has been canceled even after the cancelation date passed (do not want ot hide from UI)
                    pendingCancellation = true
                    //Set declinePlanOptionSelected to false so that the plan will show up in the summary
                    declinePlanOptionSelected = false
                    console.log('hitting the pending', cancelationDate, today)

                }

                // identical if case, just to check how many plans are going to be rendered
                if (declinePlanOptionSelected || (planValue?.totalPremiumAmount == 0 && !pendingCancellation)) {

                }
                else {
                    numRenderedPolicies++
                }

                return (
                    declinePlanOptionSelected || (planValue?.totalPremiumAmount == 0 && !pendingCancellation) ? "" :
                        (
                            <Box key={planName} className='flex-row'>

                                <Typography
                                    variant='body2'
                                    sx={componentStyles.planNameAndDate}
                                >
                                    {planName.toUpperCase()}:
                                </Typography>


                                {/* show effective date if not pending cancellation */}
                                {!pendingCancellation && planValue?.effectiveDate &&
                                    <Typography
                                        variant='body2'
                                        sx={{ ...componentStyles.planNameAndDate, marginLeft: '5px' }}
                                    >
                                        {formatEffective(planValue?.effectiveDate)}
                                    </Typography>}

                                {/* show pending cancelation*/}
                                {pendingCancellation && cancelationDate > today && planValue?.effectiveDate &&
                                    <Typography
                                        variant='body2'
                                        sx={{ ...componentStyles.planNameAndDate, color: '#C62828', marginLeft: '5px' }}
                                    >
                                        PENDING CANCELATION {dateFormatter(pendingCancellationObj?.effectiveDate)}
                                    </Typography>}

                                {/* show canceled line of coverage*/}
                                {pendingCancellation && cancelationDate < today && planValue?.effectiveDate &&
                                    <Typography
                                        variant='body2'
                                        sx={{ ...componentStyles.planNameAndDate, marginLeft: '5px' }}
                                    >
                                        CANCELED {dateFormatter(pendingCancellationObj?.effectiveDate)}
                                    </Typography>
                                }

                            </Box>
                        )
                )
            })}
        </Collapse>


        // okay now do we even need to render any of this? this policy might be full of declined / canceled plans
        //console.log("number of policies to render = " + numRenderedPolicies)

        if (numRenderedPolicies > 0) {
            returnedComponent = <Box sx={{ paddingBottom: '4px', width: '100%' }}>
                {polNumComponent}
                {polSummaryComponent}
            </Box>
        }

        return returnedComponent;
    }


   // HFK - organize all plans into an array of arrays,
   //     - where each subarray is composed of plans with matching policy numbers
   const createMultiPolicySummary = () => {
       let returnedComponent;

       //console.log("length of plans object = " + plans.length)

       // okaaayyy there is some weird stuff that happens where plans length is zero initially...
       // until it corrects itself to actualy contain everything? fine, just check the length first
       if (plans.length > 0) {
           var policyNumbersList = [];

           // get all policy numbers for all plans
           for (var i = 0; i < plans.length; i++) {
               let planName = Object.keys(plans[i])[0]
               let policyNum = plans[i][planName].policyNumber

               //console.log("plan name = " + planName + ", policy number = " + policyNum)
               policyNumbersList.push(policyNum)
           }

           //console.log("complete list of policy numbers = " + policyNumbersList)
           // get uniques / remove duplicate policy numbers 
           let uniquePolicyNumbers = [...new Set(policyNumbersList)]
           //console.log("unique policy numbers = " + uniquePolicyNumbers)

           let arrayOfArrays = []
           // ugh, scary looking loop but its really not that many items to iterate over...
           // what this is doing is assembling an array of arrays, where each subarray 
           // contains the policies which have an identical policy number
           for (var i = 0; i < uniquePolicyNumbers.length; i++) {
               let matchingPlans = []
               for (var j = 0; j < plans.length; j++) {
                   // need to access the plan by its key...
                   let planName = Object.keys(plans[j])[0]
                   if (uniquePolicyNumbers[i] == plans[j][planName].policyNumber) {
                       matchingPlans.push(plans[j])
                   }
               }
               arrayOfArrays.push(matchingPlans)
           }

           //console.log("length of AoA = " + arrayOfArrays.length)

           returnedComponent = arrayOfArrays.map(singleArray => createIndividualPolicySummary(singleArray))

       }       

       return returnedComponent

    }



   return (
      <>
      <CancelAllCoverageModal open={cancelAllCoverageModalOpen} handleCloseCancelAllModal={handleCloseCancelAllModal} handleCancelAllCoverage={handleCancelAllCoverage}loading={loading} cancelAllError={cancelAllError} handleActivePageChange={props?.handleActivePageChange}/>

      <CancelCoverageDrawer 
      cancelCoverageDrawerOpen={cancelCoverageDrawerOpen} setCancelCoverageDrawerOpen={setCancelCoverageDrawerOpen} planToCancel={planToCancel} planEnrollmentId={planInfo?.enrollmentId} setPlanToCancel={setPlanToCancel} 
      formatDate={formatDate} getThisPlan={getThisPlan} getAllDocuments={props?.getAllDocuments} cancellationReasons={props.cancellationReasons}
      updateSinglePlan={props.updateSinglePlan}
      member={props.member} 
      groupPlanId={planInfo?.groupPlanId}
      getPaymentSchedule={props?.getPaymentSchedule}
      />

         <Box className="flex-row" sx={{minHeight:'119px', padding:'8px 18px',}}>
            <Box className="col-2" sx={{marginTop:'9px',textAlign:'center'}}>
               <Fade in={props.member.organizationId?true:false}>
                  <img src={props.member && `api/image/organization/${props.member?.organizationId}`} width='100%' onError={handleLogoError}/>
               </Fade>
            </Box>

            <Box sx={{padding:'20px 0px 5px 15px', flex: 4}}>
               <Box className="flex-row" sx={{marginBottom:'10px',justifyContent: 'space-between'}}>
                  <Typography variant="h6">
                    {planInfo?.name ? planInfo.name : <Skeleton width='16rem' />}
                  </Typography>

                  <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'flex-end',textAlign:'end'}}>
                     <ButtonGroup  ref={resumeAnchorEl}
                     // disabled={!planInfo} 
                     variant="text"
                     sx={{width:'127px', height:'36px'}}
                     >
                        <Button 
                           // endIcon={<ArrowDropDownIcon  />}
                           sx={{fontFamily:'Archivo',fontWeight:500,fontSize:'14px',lineHeight:'24px',letterSpacing:'.4px'}}
                           onClick={() =>{
                              setCollapse(!collapse)
                              props.setSelectedPlan(planInfo)
                           }}
                           disabled={!planInfo}
                        >
                           {!collapse?'SHOW':'HIDE'} 
                        </Button>

                        <Button size="small"
                        sx={{minWidth:'32px!important',width:'32px!important',padding:'0px'}}
                        onClick={(e)=>setResumeAnchorEl(e.currentTarget)}
                        disabled={!planInfo}
                        >
                           <ArrowDropDownIcon  />
                        </Button>
                     </ButtonGroup>

                     <Menu
                        anchorEl={resumeAnchorEl}
                        open={resumeAnchorEl?true:false}
                        fullWidth
                        onClose={()=>setResumeAnchorEl(null)}
                        sx={{'ul':{padding:'0px'}}}
                        PaperProps={{
                           style: {
                              borderRadius: "4px",
                              // width:'127px',
                              padding:'0px'
                           }
                        }}
                        anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'right',
                        }}
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'right',
                        }}
                     >
                        <MenuItem  
                           onClick={handleRegenerateClick}
                           sx={componentStyles.menuOptions} 
                        >
                           REGENERATE PDF
                        </MenuItem>
                        <MenuItem
                           onClick={() => openCancelAllCoverageModal()}
                           sx={componentStyles.menuOptions} 
                        >
                           CANCEL ALL COVERAGE
                        </MenuItem>
                     </Menu>
                </Box>
            </Box>

                   <Box sx={{ paddingBottom: '4px', width: '100%' }}>
                       {/*createSinglePolicySummary()*/}
                       {createMultiPolicySummary()}
               </Box>
               
               {/* <Typography variant="body2" sx={{marginTop: "10px"}}>
                  Coverage Effective Date: {(props?.singlePlan?.coverageEffectiveDate && dateFormatter(props?.singlePlan?.coverageEffectiveDate)) || ""}
               </Typography> */}
            </Box>
 
         </Box>
         <Collapse in={collapse}>
         {plans.length > 0 && plans.map(plan => {
            let planKey = Object.keys(plan)[0]
            let planValue = Object.values(plan)[0]

            // let selectedOptionId = planValue?.selectedOptionId
            let returnedPlanComponent;

            let planName = planKey.slice(0,planKey.length-4)
            switch(planName.toUpperCase()){
               case 'LIFEADD':
                  planName = 'Life & AD&D'
               break;
            
            }
            
            //find any declined plan options elected, otherwise this value is false
            let declinePlanOptionSelected = false

            if(planValue?.options){
               declinePlanOptionSelected = planValue?.options?.find(option => {
                  if(option?.isDecline && option?.memberOptionId === planValue?.selectedOptionId) {
                     return option
                  }
               })
            }
            let pendingCancellation = false
            // console.log('enrollmentCancellations',enrollmentCancellations,planInfo.enrollmentId,planValue?.planId)
            let pendingCancellationObj = enrollmentCancellations.find(cancellation => cancellation.enrollmentId === planInfo.enrollmentId && cancellation.planId === planValue?.planId)

            if (enrollmentCancellations.length > 0 && pendingCancellationObj) {
               let cancelationDate = new Date(pendingCancellationObj?.effectiveDate)
               if (cancelationDate > today) {
                  pendingCancellation = true
                  //Set declinePlanOptionSelected to false so that the plan will show up in the summary
                  declinePlanOptionSelected = false
                  console.log('hitting the pending')

               }
            }

            //check if plan was not selected (this is also a decline) - do not use this value to display ladd here so do not need to check for ladd plan selection as we do up above
            if(planValue?.selectedOptionId === 0) declinePlanOptionSelected = true

            switch (planKey.slice(0,planKey.length-4).toUpperCase()){
               case 'LTD':
                  if (planValue?.isIncremental && !declinePlanOptionSelected){
                     // need incremental
                     returnedPlanComponent = <EnrollmentSummaryCardLTDIncremental 
                     plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                     updateSinglePlan={props.updateSinglePlan}
                     currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                     allowModify={props.allowModify}
                     formatDate={formatDate}
                     getThisPlan={getThisPlan}
                     handleOpenDrawer={handleOpenDrawer}
                     activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                     pendingCancellation={pendingCancellation}
                     pendingCancellationWarning={pendingCancellationWarning}
                     pendingCancellationObj={pendingCancellationObj}
                     activePlansNotPendingCancel={activePlansNotPendingCancel}
                     openCancelAllCoverageModal={openCancelAllCoverageModal}
                     />
                  } else if(!declinePlanOptionSelected){
                     returnedPlanComponent = <EnrollmentSummaryCardLTD 
                     plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                     updateSinglePlan={props.updateSinglePlan}
                     currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                     allowModify={props.allowModify}
                     formatDate={formatDate}
                     getThisPlan={getThisPlan}
                     handleOpenDrawer={handleOpenDrawer}
                     activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                     pendingCancellation={pendingCancellation}
                     pendingCancellationWarning={pendingCancellationWarning}
                     pendingCancellationObj={pendingCancellationObj}
                     activePlansNotPendingCancel={activePlansNotPendingCancel}
                     openCancelAllCoverageModal={openCancelAllCoverageModal}
                     />
                  }
               break;
               case 'STD':
                  if (planValue?.isIncremental && !declinePlanOptionSelected){
                     //need incremental
                     returnedPlanComponent = <EnrollmentSummaryCardSTDIncremental
                     plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                     updateSinglePlan={props.updateSinglePlan}
                     currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                     allowModify={props.allowModify}
                     formatDate={formatDate}
                     getThisPlan={getThisPlan}
                     handleOpenDrawer={handleOpenDrawer}
                     activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                     pendingCancellation={pendingCancellation}
                     pendingCancellationWarning={pendingCancellationWarning}
                     pendingCancellationObj={pendingCancellationObj}
                     activePlansNotPendingCancel={activePlansNotPendingCancel}
                     openCancelAllCoverageModal={openCancelAllCoverageModal}
                     />
                  } else if(!declinePlanOptionSelected) {
                     returnedPlanComponent = <EnrollmentSummaryCardSTD 
                     plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                     updateSinglePlan={props.updateSinglePlan}
                     currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                     allowModify={props.allowModify}
                     formatDate={formatDate}
                     getThisPlan={getThisPlan}
                     handleOpenDrawer={handleOpenDrawer}
                     activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                     pendingCancellation={pendingCancellation}
                     pendingCancellationWarning={pendingCancellationWarning}
                     pendingCancellationObj={pendingCancellationObj}
                     activePlansNotPendingCancel={activePlansNotPendingCancel}
                     openCancelAllCoverageModal={openCancelAllCoverageModal}
                     />
                  }
               break;
               case 'LIFEADD':
                  if (planValue?.totalPremiumAmount > 0 || pendingCancellation) {
                  returnedPlanComponent = <EnrollmentSummaryCardLADD 
                  plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                  groupPlanId={planInfo.groupPlanId}
                  planEnrollmentId={planInfo.enrollmentId}
                  dependents={props?.dependents}
                  spouse={props?.spouse}
                  currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                  allowModify={props.allowModify}
                  allowEditEnrollment={props.allowEditEnrollment}
                  updateSinglePlan={props.updateSinglePlan}
                  formatDate={formatDate}
                  getThisPlan={getThisPlan}
                  handleOpenDrawer={handleOpenDrawer}
                  activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                  pendingCancellation={pendingCancellation}
                  pendingCancellationWarning={pendingCancellationWarning}
                  pendingCancellationObj={pendingCancellationObj}
                  getPaymentSchedule={props?.getPaymentSchedule}
                  activePlansNotPendingCancel={activePlansNotPendingCancel}
                  openCancelAllCoverageModal={openCancelAllCoverageModal}
                  />
                  }
               break;
               case 'ACCIDENT':
                  if(!declinePlanOptionSelected) {
                  returnedPlanComponent = <EnrollmentSummaryCardAccident 
                  plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                  currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                  allowModify={props.allowModify} updateSinglePlan={props.updateSinglePlan} formatDate={formatDate}
                  getThisPlan={getThisPlan}
                  handleOpenDrawer={handleOpenDrawer}
                  activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                  pendingCancellation={pendingCancellation}
                  pendingCancellationWarning={pendingCancellationWarning}
                  pendingCancellationObj={pendingCancellationObj}
                  activePlansNotPendingCancel={activePlansNotPendingCancel}
                  openCancelAllCoverageModal={openCancelAllCoverageModal}
                  />
                  }
               break;
               case 'CANCER':
                  if(!declinePlanOptionSelected) {
                  returnedPlanComponent = <EnrollmentSummaryCardCancer 
                  plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}    updateSinglePlan={props.updateSinglePlan}
                  currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                  allowModify={props.allowModify}
                  formatDate={formatDate}
                  getThisPlan={getThisPlan}
                  handleOpenDrawer={handleOpenDrawer}
                  activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                  pendingCancellation={pendingCancellation}
                  pendingCancellationWarning={pendingCancellationWarning}
                  pendingCancellationObj={pendingCancellationObj}
                  activePlansNotPendingCancel={activePlansNotPendingCancel}
                  openCancelAllCoverageModal={openCancelAllCoverageModal}
                  />
                  } 
               break;
               case 'ADD':
                  if(!declinePlanOptionSelected) {
                  returnedPlanComponent = <EnrollmentSummaryCardADD 
                  plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                  currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit} updateSinglePlan={props.updateSinglePlan}
                  allowModify={props.allowModify}
                  formatDate={formatDate}
                  getThisPlan={getThisPlan}
                  handleOpenDrawer={handleOpenDrawer}
                  activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                  pendingCancellation={pendingCancellation}
                  pendingCancellationWarning={pendingCancellationWarning}
                  pendingCancellationObj={pendingCancellationObj}
                  activePlansNotPendingCancel={activePlansNotPendingCancel}
                  openCancelAllCoverageModal={openCancelAllCoverageModal}
                  />
                  }
               break;
               case 'HOSPITAL':
                  if(!declinePlanOptionSelected) {
                  returnedPlanComponent = <EnrollmentSummaryCardHospital 
                  plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                  currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit} 
                  allowModify={props.allowModify}
                  updateSinglePlan={props.updateSinglePlan}
                  formatDate={formatDate}
                  getThisPlan={getThisPlan}
                  handleOpenDrawer={handleOpenDrawer}
                  activeCoveragePlanSummary={props.activeCoveragePlanSummary}
                  pendingCancellation={pendingCancellation}
                  pendingCancellationWarning={pendingCancellationWarning}
                  pendingCancellationObj={pendingCancellationObj}
                  activePlansNotPendingCancel={activePlansNotPendingCancel}
                  openCancelAllCoverageModal={openCancelAllCoverageModal}
                  />
                  }
               break;
               default: console.log('no plan name match')
            }
            return (
               returnedPlanComponent
            // <Box className="flex-row" sx={{padding:'8px 18px', flexWrap:'wrap'}}>
            //    <div className="flex-row col-12" style={{marginBottom:'15.5px'}}>
            //       <div className="col-6">
            //          <Typography component={'span'} sx={{fontFamily:'Poppins', fontWeight:500, fontSize:'20px', lineHeight:'32px', letterSpacing:'.15px', color:'#195FFB'}}>
            //             {planName.toUpperCase()}:
            //          </Typography>
            //       </div>

            //       <div className="col-6" style={{display:'flex',justifyContent:'flex-end', alignItems:'center'}}>
            //          <Typography component={'span'} sx={{fontFamiily:'Poppins', fontWeight:400, fontSize:'24px', lineHeight:'32px', color:'#195FFB'}}>
            //             {UsdFormatterDec.format(props?.planCosts["Monthly Total"] )}
            //          </Typography>
            //          <Typography component={'span'} sx={{fontFamily:'Archivo', fontWeight:400, fontSize:'14px', lineHeight:'20px', letterSpacing:'.17px', color:'text.secondary'}}>
            //             /per month
            //          </Typography>
            //       </div>
            //    </div>

            //    <div className="flex-row col-12" style={{marginBottom:'16px'}}>
            //       <div className="col-6">
            //          <Typography variant="body1">
            //             Benefit Amount
            //          </Typography>
            //       </div>

            //       <div className="col-6" style={{textAlign:'end'}}>
            //          <Typography variant="body1">
            //             Benefit amount
            //          </Typography>
            //       </div>
            //    </div>

            //    <div className="flex-row col-12" style={{marginBottom:'16px'}}>
            //       <div className="col-6">
            //          <Typography variant="body1">
            //             Elimination Period
            //          </Typography>
            //       </div>

            //       <div className="col-6" style={{textAlign:'end'}}>
            //          <Typography variant="body1">
            //             Benefit amount
            //          </Typography>
            //       </div>
            //    </div>

            //    <div className="flex-row col-12" style={{marginBottom:'16px'}}>
            //       <div className="col-6">
            //          <Typography variant="body1">
            //             Benefit Duration
            //          </Typography>
            //       </div>

            //       <div className="col-6" style={{textAlign:'end'}}>
            //          <Typography variant="body1">
            //             Benefit amount
            //          </Typography>
            //       </div>
            //    </div>

            //    <div className="flex-row col-12" style={{marginBottom:'16px'}}>
            //       <div className="col-6">
            //          <Typography variant="body1">
            //             Offset
            //          </Typography>
            //       </div>

            //       <div className="col-6" style={{textAlign:'end'}}>
            //          <Typography variant="body1">
            //             Benefit amount
            //          </Typography>
            //       </div>
            //    </div>
            // </Box>
            )
         })}
         </Collapse>
         {!collapse &&
         <>
         {/* Collapsed */}
         </>
         }
      </>

   )
}
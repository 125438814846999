import React, { useEffect, useState } from "react";


//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//MuiComponents
import { Box, Button, FormControl, MenuItem, InputLabel, Select, TextField } from "@mui/material";

//Mui Icons
import ClearIcon from "@mui/icons-material/Clear";

//utils
import { checkMobileDevice } from "../../../../../utils";

//Our styles
import enrollmentPlanStyles from "../../enrollmentPlanStyles";

export default function AccidentDependentsInputs(props) {
  const isMobileDevice = checkMobileDevice()
  let results = props.results;

  // const handleDobFocus = (e) => {
  //   e.target.setAttribute("type", "date");
  // };
  // const handleDobBlur = (e) => {
  //   // if (!props.userInfo.dob) e.target.setAttribute('type','')
  // };
  // const handleDobChange = (e) => {
  //   let inputVal = e.target.value;
  //   // props.setUserInfo({02
  //   //    ...props.userInfo,
  //   //    dob:inputVal
  //   // })
  // };
  const handleMobileDobChange = (e, i, key) => {
    console.log('dep dob', e)
    let value = e
    // value = format(new Date(e), "yyyy-MM-dd");
    let index = i;
    let _dependents = [...dependents];
    // console.log(_dependents[index][key])
    // console.log('depDOBmobile',value)
    _dependents[index][key] = value;
    _dependents[index].isDeleted = false;
    props.setDependents(_dependents);
    // console.log(_dependents[index])
  };

  const [dependents, setDependents] = useState([]);

  useEffect(() => {
    if (props.dependents && props.dependents.length > 0)
      setDependents(props.dependents);
    else addNewDependent();
  }, [props.dependents]);

  const handleDeleteClick = (e) => {
    let value = e.target.getAttribute("value");
    // console.log(value)
    let _dependents = dependents.map((x) => x);
    // console.log(_dependents)
    _dependents.find((dependent) => {
      if (dependent?.id) {
        return dependent.id == value;
      } else return dependent.uid == value;
    }).isDeleted = true;
    props.setDependents(_dependents);
  };

  const handleDependentChange = (e, i, key) => {
    let value = e.target.value;
    let index = i;
    let _dependents = [...dependents].filter(
      (dependent) => !dependent.isDeleted
    );
    // console.log(_dependents[index][key])
    _dependents[index][key] = value;
    _dependents[index].isDeleted = false;
    props.setDependents(_dependents);
    // console.log(_dependents[index])
  };

  const addNewDependent = () => {
    let _dependent = {
      uid: new Date().getTime(),
      firstName: "",
      lastName: "",
      dob: null,
      gender: 0,
      isDeleted: false,
    };
    let _dependents = [...dependents];
    _dependents.push(_dependent);
    // console.log("depCheck", dependents, _dependents);
    props.setDependents(_dependents);
  };


  //Error handling for required info input
  const [fNameError, setFNameError] = useState(false);
  const [lNameError, setLNameError] = useState(false);

  const [disableSaveClick, setDisableSaveClick] = useState(false)
  const checkMissingInfo = () => {
    props.dependents?.filter((dep) => !dep.isDeleted)
            .forEach((dep) => {
              if (
                dep.firstName?.length > 0 &&
                dep.lastName?.length > 0 &&
                dep.dob
              ) {
                setDisableSaveClick(false)
              } else {
               setDisableSaveClick(true)
              }
            });
  }

  useEffect(() => {
   if(dependents?.length > 0) checkMissingInfo()
  }, [dependents])
  

  return (
    <>
      {dependents?.length > 0 &&
        dependents
          .filter((dependent) => !dependent.isDeleted)
          .map((dependent, i) => {
            return (
              <Box key={dependent?.id || dependent?.uid} sx={enrollmentPlanStyles.inputSection}>
                <Box>
                  <Box
                    sx={{ fontSize: "15px", textAlign: "left", marginBottom: '10px'}}
                  >
                    {i == 0 && (
                      <>
                        <span style={{ fontWeight: 600 }}>
                          Dependent {i > 0 && `${i + 1}`}
                        </span>
                      </>
                    )}
                    {i > 0 && (
                      <>
                        <span style={{ fontWeight: 600 }}>
                          Dependent {i > 0 && `${i + 1}`}
                        </span>
                        <span
                          value={dependent?.id || dependent?.uid}
                          onClick={handleDeleteClick}
                          style={{ fontWeight: 500, float: "right" }}
                        >
                          <ClearIcon />
                        </span>
                      </>
                    )}
                  </Box>
                </Box>

                <Box sx={enrollmentPlanStyles.inputField}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    error={fNameError[dependent?.id || dependent?.uid]}
                    required
                    value={dependent.firstName || ""}
                    onChange={(e) => {
                      handleDependentChange(e, i, "firstName");
                      setFNameError({
                        ...fNameError,
                        [dependent?.id || dependent?.uid]: false,
                      });
                    }}
                    onBlur={(e) =>
                      !e.target.value &&
                      setFNameError({
                        ...fNameError,
                        [dependent?.id || dependent?.uid]: true,
                      })
                    }
                    InputLabelProps={enrollmentPlanStyles.inputLabelProps} 
                  />
                </Box>

                <Box sx={enrollmentPlanStyles.inputField}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    error={lNameError[dependent?.id || dependent?.uid]}
                    required
                    value={dependent.lastName || ""}
                    onChange={(e) => {
                      handleDependentChange(e, i, "lastName");
                      setLNameError({
                        ...lNameError,
                        [dependent?.id || dependent?.uid]: false,
                      });
                    }}
                    onBlur={(e) =>
                      !e.target.value &&
                      setLNameError({
                        ...lNameError,
                        [dependent?.id || dependent?.uid]: true,
                      })
                    }
                    InputLabelProps={enrollmentPlanStyles.inputLabelProps} 
                  />
                </Box>

                {/* {isMobileDevice ? (
                  <Box className="flex-row" sx={{ marginBottom: "15px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        openTo="year"
                        views={["year", "month", "day"]}
                        value={dependent.dob || ""} //matches ladd dependent dob format
                        // value={
                        //   (dependent.dob &&
                        //     !dependent.dob.toString().includes("T00:00:00") &&
                        //     `${dependent.dob}T00:00:00`) ||
                        //   dependent.dob
                        // }
                        onChange={(e) => handleMobileDobChange(e, i, "dob")}
                        format="MM/dd/yyyy"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Date of birth"
                            placeholder="mm/dd/yyyy"
                            fullWidth
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                ) : (
                  <Box className="flex-row" sx={{ marginBottom: "15px" }}>
                    <TextField
                      fullWidth
                      type={dependent.dob ? "date" : ""}
                      label="Date of Birth"
                      variant="outlined"
                      value={
                        (dependent.dob &&
                          dependent.dob.toString().includes("T00:00:00") &&
                          dependent.dob.replace("T00:00:00", "")) ||
                        dependent.dob ||
                        ""
                      }
                      onChange={(e) => handleDependentChange(e, i, "dob")}
                      // onChange={handleDobChange}
                      onFocus={handleDobFocus}
                      onBlur={handleDobBlur}
                    />
                  </Box>
                )} */}

                <Box sx={enrollmentPlanStyles.inputField}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disableFuture
                            openTo='year'
                            views={['year', 'month', 'day']}
                            format='MM/dd/yyyy'
                            label='Date of birth'
                            value={dependent.dob || null}
                            onChange={(e)=>handleMobileDobChange(e,i,'dob')}
                            // onAccept={}
                            // onError={(error) => setDobError(error)} //will need to use this in the future and connect to error messaging
                            sx={enrollmentPlanStyles.datePickerInput}
                            slotProps={{
                                textField: {
                                  // helperText: dobErrorMessage,
                                  InputLabelProps: enrollmentPlanStyles.inputLabelProps
                                },
                                openPickerButton: {sx:{visibility: 'hidden'}} //hide calendar icon calandar 
                            }}
                          />
                    </LocalizationProvider>
                </Box>

                  {/* Gender */}
                  <Box sx={enrollmentPlanStyles.inputField}>
               
                    <FormControl fullWidth>
                        <InputLabel sx={enrollmentPlanStyles.inputLabelProps.sx}>Dependent Gender</InputLabel>
                        <Select
                          variant="outlined"
                          label="Dependent Gender"
                          value={dependent?.gender || ''} 
                          onChange={e=>{
                              handleDependentChange(e, i,'gender')
                          }}
                        >
                          {(props?.genderTypes?.length > 0 &&
                          props?.genderTypes.map((option) => {
                              return (
                                <MenuItem value={option.id} key={option.id}>{option.name}</MenuItem>
                              );
                          }))}
                        </Select>
                    </FormControl>
                  </Box>

              </Box>
            );
          })}

      <Box
        className="flex-row"
        sx={{ marginBottom: "15px", justifyContent: "center" }}
      >
        <Button
          variant="text"
          sx={{ fontSize: "15px", height: "24px" }}
          onClick={addNewDependent}
        >
          + add another dependent
        </Button>
      </Box>
    </>
  );
}

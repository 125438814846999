import React, {useState, useEffect} from 'react'

//Services
import walletService from '../../../services/wallet-service';
import authService from '../../../services/auth-service';
import memberService from '../../../services/member-service';
import optionService from '../../../services/optionService'

//Assets
import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";

//Mui Icons
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreateIcon from '@mui/icons-material/CreateOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Mui Components
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

//Our components
import PoweredBy from '../external-enrollments/PoweredBy'



export default function ExternalPrenoteFailure (props) {
   //Utility functions
   const [imgFetchAttempts,setImgFetchAttempts] = useState(0)
    const handleLogoError = (e) => {
      //   console.log('logo img error',FallbackLogo)
        setImgFetchAttempts(prev => prev++)
        if (imgFetchAttempts <= 10) e.target.src = defaultLogo
    }
   const titleCase = (str) => {
      if (str) {
         str = str[0].toUpperCase() + str.slice(1)
         return str
      }
      return str
   }
   const maskAccountNumber = (accountNumber) => {
      if (accountNumber && accountNumber.length > 4) {
         let length = accountNumber.length
         let lastFour = accountNumber.slice(length - 4,length)
         // console.log(accountNumber,length,lastFour)
         return lastFour
      }
      return accountNumber
   }
   const [isAuthenticated, setIsAuthenticated] = useState(false);
   const checkAuthentication = async () => {
      let _isAuthenticated = await props.checkAuthentication();
      console.log('authed',_isAuthenticated)
      setIsAuthenticated(_isAuthenticated)
   }
   useEffect(() => {
      checkAuthentication()
   }, []);


   const [login, setLogin] = useState({
      userName: '',
      password: '',
   });

   const handleLoginChange = (e) => {
      let inputVal = e.target.value;
      let inputProperty = e.target.getAttribute('data-property')
      // console.log(inputProperty, inputVal)
      setLogin({
          ...login,
          [inputProperty]: inputVal
      })
   }
   const passwordKeyPressHandler = (e) => {
      if (e.key === 'Enter') loginAsync()
      if (e.key === ' ') e.preventDefault()
   }

   const loginAsync = async (e) => {
      // setLoading(true)
      //e.preventDefault();

      let _isAuthenticated = false;
      const credentials = {
          username: login.userName,
          password: login.password
      }
      _isAuthenticated = await authService.authenticate(credentials);
      if (_isAuthenticated) getThisMember()
      setIsAuthenticated(_isAuthenticated)
      // console.log('isAuthenticated',isAuthenticated)

      // if (isAuthenticated) {
      //    //  setDisplayLoginError(false);
      //    //  let domain = getCookie('subdomain')

      // }
      // else {
      //     // console.log('not successful');
      //    //  setDisplayLoginError(true);
      // }
      // setLoading(false)
  }


   
   
   const [thisMember,setThisMember] = useState()
   const getThisMember = async() => {
      let _thisMember = await memberService.getMe()
      if (_thisMember) setThisMember(_thisMember)
   }
   useEffect(() => {
      getThisMember()
   },[])

   const [prenoteFound,setPrenoteFound] = useState()
   const [failedPrenote,setFailedPrenote] = useState()
   const getFailedPrenote = async() => {
      try {
         let _failedPrenote = await walletService.getFailedPrenote(thisMember.id)
         setFailedPrenote(_failedPrenote)
         setPrenoteFound(true)
         
      } catch (error) {
         setFailedPrenote()
         setPrenoteFound(false)
         // setPrenoteError(true)
      }
   }
   useEffect(() => {
      if (thisMember) getFailedPrenote()
   },[thisMember])
      
   const [disableSave, setDisableSave] = useState(true)
   const [addNewAccount,setAddNewAccount] = useState(false)
   const openNewAccount = () => {
      setRoutingBankInfo([])
      setAddNewAccount({})
      setDisplayedValue({})
      setMaskedInput({})
      setEditAccount(false)
   }
   const newAccountHandler = (e) => {
      const regex = /^[0-3]+[0-9]*$/;
      let newValue = e.target.value;
      let handledId = e.target.id.replace("Updater", "");
      let maskedValue = maskInputVal(newValue)
      if (e.target.id == "routingNumberUpdater") {
         if (regex.test(newValue)) {
            let routingNumTruncated = newValue.slice(0, 9);
            maskedValue = maskInputVal(routingNumTruncated)
            setMaskedInput({
               ...maskedInput,
               [handledId]:maskedValue
            })
            setAddNewAccount({
               ...addNewAccount,
               [handledId]:routingNumTruncated
            })
            setDisplayedValue({
               ...displayedValue,
               [handledId]:routingNumTruncated
            })
         }
      } else {
         setMaskedInput({
            ...maskedInput,
            [handledId]:maskedValue
         })
         setAddNewAccount({
            ...addNewAccount,
            [handledId]:newValue
         })
         setDisplayedValue({
            ...displayedValue,
            [handledId]:newValue
         })
      }
   }
   const saveNewAccount = async (e) => {
      console.log(addNewAccount)
      let builtNewBank = {
         description: addNewAccount.description,
         accountNumber: addNewAccount.accountNumber,
         routingNumber: addNewAccount.routingNumber,
         // isPrimary:true,
         memberId: thisMember.id,
         bankName:'',
         bankAccountTypeId: addNewAccount.bankAccountTypeId,
         // prenoteStatusId: ''
      }
      try {
         let newId = await walletService.insertBankAccountForPrenote(builtNewBank)
         console.log(newId)
         setSuccessPage(true)
         
      } catch (error) {
         console.log('saveNew error',error)
      }
   }
   


   const [disableEdit,setDisableEdit] = useState(true)
   const [editAccount,setEditAccount] = useState(false)
   const openEditAccount = () => {
      setRoutingBankInfo([])
      setEditAccount(failedPrenote)
      setDisplayedValue({
         routingNumber:maskInputVal(failedPrenote.routingNumber),
         accountNumber:maskInputVal(failedPrenote.accountNumber)
      })
      setMaskedInput({
         routingNumber:maskInputVal(failedPrenote.routingNumber),
         accountNumber:maskInputVal(failedPrenote.accountNumber)
      })
      setAddNewAccount(false)
   }
   const editAccountHandler = (e) => {
      const regex = /^[0-3]+[0-9]*$/;
      let newValue = e.target.value;
      let handledId = e.target.id.replace("Updater", "");
      let maskedValue = maskInputVal(newValue)
      if (e.target.id == "routingNumberUpdater") {
         if (regex.test(newValue)) {
            let routingNumTruncated = newValue.slice(0, 9);
            maskedValue = maskInputVal(routingNumTruncated)
            setMaskedInput({
               ...maskedInput,
               [handledId]:maskedValue
            })
            setEditAccount({
               ...editAccount,
               [handledId]:routingNumTruncated
            })
            setDisplayedValue({
               ...displayedValue,
               [handledId]:routingNumTruncated
            })
         }
      } else {
         setMaskedInput({
            ...maskedInput,
            [handledId]:maskedValue
         })
         setEditAccount({
            ...editAccount,
            [handledId]:newValue
         })
         setDisplayedValue({
            ...displayedValue,
            [handledId]:newValue
         })
      }
      
   }
   const saveEditAccount = async (e) => {
       try {
          let response = await walletService.updateBankAccount(editAccount)
          console.log(response)
          setSuccessPage(true)
       } catch (error) {
          console.log('update bank error',error)
       }
   }
   

   let accounts = [
      { id: 1, name: "Checking" },
      { id: 2, name: "Savings" },
   ];
   const bankAccountHandler = (e,addOrEdit) => {
      let value = e.target.value;
      if (addOrEdit == 'Add'){
         setAddNewAccount({
            ...addNewAccount,
            bankAccountTypeId:value
         })
      } else {
         setEditAccount({
            ...editAccount,
            bankAccountTypeId:value
         })
      }
   };

   const [routingBankInfo, setRoutingBankInfo] = useState([]);
   const checkRoutingNumber = async (e) => {
      let _bankInfo;
      if (addNewAccount){
         _bankInfo = await walletService.getBankFromRouting(addNewAccount.routingNumber);
      }
      else if (editAccount){
         _bankInfo = await walletService.getBankFromRouting(editAccount.routingNumber);
      }

      setRoutingBankInfo(_bankInfo);

      console.log("banking info", _bankInfo);
   };

   const [matchingAccountError, setMatchingAccountError] = useState(false);
   const checkMatchingAccount = () => {
      if (addNewAccount){
         if (addNewAccount?.accountNumber?.length > 0 && addNewAccount.accountNumber != addNewAccount.confirmAccount)
            setMatchingAccountError(true);
         else setMatchingAccountError(false);
      } else if (editAccount){
         if (editAccount?.accountNumber?.length > 0 && editAccount.accountNumber != editAccount.confirmAccount)
            setMatchingAccountError(true);
         else setMatchingAccountError(false);
      }
   };

   

   useEffect(() => {
      if (addNewAccount){
         let nameProvided = addNewAccount?.description?.length > 0
         let routingProvided = addNewAccount?.routingNumber?.length > 0
         let accountProvided = addNewAccount?.accountNumber?.length > 0
         let confirmProvided = addNewAccount?.confirmAccount?.length > 0
         if (nameProvided && routingProvided && accountProvided && confirmProvided && !matchingAccountError){
            setDisableSave(false)
         } else setDisableSave(true)
      }
   },[addNewAccount,matchingAccountError])

   useEffect(() => {
      if (editAccount){
         let _editAccount = {...editAccount}
         delete _editAccount.confirmAccount
         let nameProvided = editAccount?.description?.length > 0
         let routingProvided = editAccount?.routingNumber?.length > 0
         let accountProvided = editAccount?.accountNumber?.length > 0
         let confirmProvided = editAccount?.confirmAccount?.length > 0
         if (nameProvided && routingProvided && accountProvided && confirmProvided && !matchingAccountError && JSON.stringify(_editAccount) != JSON.stringify(failedPrenote)){
            setDisableSave(false)
         } else setDisableSave(true)
      }
   },[editAccount,matchingAccountError])

   const [successPage,setSuccessPage] = useState(false)


   //Password Visibility
   const [displayPassword, setDisplayPassword] = useState(false)

   const swapIcon = (e) => {
       //e.preventDefault();
       let inputElement = e.target.parentNode.children[0];
       if (displayPassword) {
           inputElement.setAttribute("type", "password");
           setDisplayPassword(false);
       } else {
           inputElement.setAttribute("type", "text");
           setDisplayPassword(true);
       }
   }

   const visibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onMouseDown={swapIcon} onMouseUp={swapIcon} onTouchStart={swapIcon} onTouchEnd={swapIcon}><VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
   const noVisibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onClick={swapIcon}><NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>

   const passwordIcon = displayPassword ? noVisibilityIcon : visibilityIcon;

   const [displayedValue,setDisplayedValue] = useState({
      routingNumber:'',
      accountNumber:'',
      confirmAccount:''
   })

   const [maskedInput,setMaskedInput] = useState({
      routingNumber: '',
      showRoutingNumber:false,
      accountNumber: '',
      showAccountNumber:false,
      confirmAccount: '',
      showConfirmAccount:false
   })

   const genericSwapIcon = (e) => {
      let target = e.target
      // console.log(target)
      let maskTarget = target.getAttribute('data-name')
      // console.log(maskTarget)
      let showBool = `show${maskTarget}`
      maskTarget = maskTarget.slice(0,1).toLowerCase() + maskTarget.slice(1,maskTarget.length)
      console.log(showBool, maskedInput[showBool],!maskedInput[showBool])
      console.log(addNewAccount[maskTarget],maskedInput[maskTarget])

      

      if (maskedInput[showBool]){

         setDisplayedValue({
            ...displayedValue,
            [maskTarget]: maskedInput[maskTarget]
         })
         setMaskedInput({
            ...maskedInput,
            [showBool]: false
         })

      } else {
         setDisplayedValue({
            ...displayedValue,
            [maskTarget]: addNewAccount ? addNewAccount[maskTarget] : editAccount[maskTarget]
         })
         setMaskedInput({
            ...maskedInput,
            [showBool]: true
         })
      }
   }

   const maskOnBlur = (e) => {
      let target = e.target
      let handledId = target.id.replace("Updater", "");
      console.log('blur',target,handledId)
      setDisplayedValue({
         ...displayedValue,
         [handledId]:maskedInput[handledId]
      })
   }
   const unmaskOnFocus = (e) => {
      let target = e.target
      let handledId = target.id.replace("Updater", "");
      setDisplayedValue({
         ...displayedValue,
         [handledId]:addNewAccount ? addNewAccount[handledId] : editAccount[handledId]
      })
   }
   const maskInputVal = (unmaskedVal) => {
      let maskedVal;
      let unmaskedLength = unmaskedVal.length
      if (unmaskedLength >= 4) {
         let lastDigits = unmaskedVal.slice(unmaskedLength - 4,unmaskedLength)
         maskedVal = `•••••${lastDigits}`
      } else maskedVal = unmaskedVal

      return maskedVal
   }

   // console.log('maskedVal', maskedInput)
   // console.log('displayedVal', displayedValue)
   // console.log('actualVals',addNewAccount,editAccount)
   console.log('displayed',displayedValue)

   

   return (
   <>
      <div className='flex-box' style={{padding:'25px 20px', paddingBottom:'60px' , maxWidth:'600px', margin:'auto' }} >
         <div className='flex-row' style={{justifyContent:'center'}}>
            <img
               alt="logo"
               src={
               (props.agentEnroller && props.fullMemberObj?.organizationId)
                  ? `api/image/organization/${props.fullMemberObj?.organizationId}`
                  : "/api/image/organization"
               }
               style={{
               maxHeight: "100px",
               maxWidth: "100%",
               objectFit: "contain",
               }}
               onError={handleLogoError}
            /> 
         </div>
         {!isAuthenticated && 
         <>
            <div className='flex-row' style={{justifyContent:'center',marginBottom:'6px',textAlign:'center'}}>
               <Typography variant="subtitle1" fontWeight="600">
                  Update your payment method
               </Typography>
            </div>

            <div className='flex-row' style={{textAlign:'center',marginBottom:'15px',padding:'0px 15px'}}>
               <Typography variant="body" fontWeight="400">
                  The payment method provided at enrollment failed to process. Please log in to provide another payment method, or edit the previous one.
               </Typography>
            </div>

            <div className="flex-row" style={{ marginBottom: '20px' }} >
               <TextField fullWidth
                  sx={{ maxWidth: '685px' }}
                  label="Email" variant="outlined" 
                  value={login.userName} onChange={handleLoginChange}
                  inputProps={{ 'data-property': 'userName' }}
                  autoComplete='email'
                  type='email'
                  pattern=".+@.+\.com"
               />
            </div>

            <div className="flex-row" style={{ marginBottom: '20px' }}>
               <TextField fullWidth type='password'
                  sx={{ maxWidth: '685px' }}
                  label="Password" variant="outlined" 
                  value={login.password} onChange={handleLoginChange}
                  inputProps={{ 'data-property': 'password' }}
                  onKeyPress={passwordKeyPressHandler} 
                  InputProps={{ endAdornment: passwordIcon, sx: { paddingRight: '0px' } }}
               />
            </div>

            <div className="flex-row" style={{ marginBottom: '25px' }} >
                  <Button variant='contained'  fullWidth
                     sx={{ height: 50, maxWidth: '574px' }}
                     onClick={loginAsync}
                     color="primary"
                  >
                     Log in
                  </Button>
            </div>
         </>
         }
         {isAuthenticated &&
         <>
            {!successPage &&
            <>
               <div className='flex-row' style={{justifyContent:'center',marginBottom:'6px',textAlign:'center'}}>
                  <Typography variant="subtitle1" fontWeight="600">
                     Update your payment method
                  </Typography>
               </div>

               <div className='flex-row' style={{textAlign:'center',marginBottom:'15px',padding:'0px 15px'}}>
                  <Typography variant="body" fontWeight="400">
                     The payment method provided at enrollment failed to process. Please provide another payment method, or edit the previous one.
                  </Typography>
               </div>
               
               {!addNewAccount && 
                  <div className='flex-row' style={{justifyContent:'center', padding:'0px 20px'}}>
                     <Button fullWidth size='large'
                     sx={{
                        border:'1px solid #0000003B',borderRadius:'8px',
                        justifyContent:'space-between',height:'56px',paddingRight:'39px',paddingLeft:'12px',color:'#00000099',fontWeight:400
                     }}
                     onClick={openNewAccount}
                     >
                        Add New Account <AddIcon/>
                     </Button>
                  </div>
               }
               {addNewAccount && 
                  <div style={{padding:'0px 20px'}}>
                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="descriptionUpdater"
                        onChange={newAccountHandler}
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                        }}
                        required
                        // onBlur={checkRoutingNumber}
                        label="Name on Account"
                        value={addNewAccount.description}
                        placeholder="Name on Account"
                        InputLabelProps={{ shrink: true }}
                     />
                     </div>
                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="routingNumberUpdater"
                        onChange={newAccountHandler}
                        required
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                        }}
                        onBlur={e => {
                           checkRoutingNumber()
                           maskOnBlur(e)
                        }}
                        onFocus={unmaskOnFocus}
                        label="Routing Number"
                        value={displayedValue.routingNumber || ''}
                        placeholder="Routing Number"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ 
                           endAdornment: !maskedInput.showRoutingNumber 
                           ?  <div style={{ position: "absolute", right: "5%" }} type="button"
                              data-name='RoutingNumber'
                              onMouseDown={genericSwapIcon} onMouseUp={genericSwapIcon} onTouchStart={genericSwapIcon} onTouchEnd={genericSwapIcon}>
                                 <VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>
                           :  <div style={{ position: "absolute", right: "5%" }} type="button" 
                              data-name='RoutingNumber'
                              onClick={genericSwapIcon}>
                                 <NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>,
                           sx: { paddingRight: '0px' } 
                        }}
                     />
                     </div>

                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="accountNumberUpdater"
                        onChange={newAccountHandler}
                        required
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                        }}
                        onBlur={e => {
                           maskOnBlur(e)
                        }}
                        onFocus={unmaskOnFocus}
                        label="Account Number"
                        value={displayedValue.accountNumber}
                        placeholder="Account Number"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ 
                           endAdornment: !maskedInput.showAccountNumber 
                           ?  <div style={{ position: "absolute", right: "5%" }} type="button"
                              data-name='AccountNumber'
                              onMouseDown={genericSwapIcon} onMouseUp={genericSwapIcon} onTouchStart={genericSwapIcon} onTouchEnd={genericSwapIcon}>
                                 <VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>
                           :  <div style={{ position: "absolute", right: "5%" }} type="button" 
                              data-name='AccountNumber'
                              onClick={genericSwapIcon}>
                                 <NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>,
                           sx: { paddingRight: '0px' } 
                        }}
                     />
                     </div>

                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="confirmAccountUpdater"
                        onChange={newAccountHandler}
                        required
                        error={matchingAccountError}
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                        }}
                        onBlur={e => {
                           maskOnBlur(e)
                           checkMatchingAccount()
                        }}
                        onFocus={unmaskOnFocus}
                        label="Confirm Account Number"
                        value={displayedValue.confirmAccount}
                        placeholder="Confirm Account Number"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ 
                           endAdornment: !maskedInput.showConfirmAccount 
                           ?  <div style={{ position: "absolute", right: "5%" }} type="button"
                              data-name='ConfirmAccount'
                              onMouseDown={genericSwapIcon} onMouseUp={genericSwapIcon} onTouchStart={genericSwapIcon} onTouchEnd={genericSwapIcon}>
                                 <VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>
                           :  <div style={{ position: "absolute", right: "5%" }} type="button" 
                              data-name='ConfirmAccount'
                              onClick={genericSwapIcon}>
                                 <NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>,
                           sx: { paddingRight: '0px' } 
                        }}
                     />
                     </div>

                     <div className="flex-row" style={{ padding: "0px", textAlign: "left" }}>
                     <TextField
                        fullWidth
                        required
                        select
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                           "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                           border: "2px solid #195ffb",
                           },
                        }}
                        variant="outlined"
                        value={addNewAccount?.bankAccountTypeId || 1}
                        onChange={(e) => bankAccountHandler(e,'Add')}
                        label="Account Type"
                        InputLabelProps={{ shrink: true }}
                     >
                        {accounts.map((account) => (
                           <MenuItem key={account.id} value={account.id}>
                           {account.name}
                           </MenuItem>
                        ))}
                     </TextField>
                     </div>

                     <Fade
                     in={routingBankInfo[0]?.status != undefined}
                     mountOnEnter
                     unmountOnExit
                     >
                     <div
                        className="flex-row"
                        style={{ padding: "10px 17px", justifyContent: "center" }}
                     >
                        {routingBankInfo[0]?.status == 'success' &&
                           <Alert
                              severity="success"
                              sx={{
                              border: "2px solid #4E9A51",
                              color: "#4E9A51",
                              alignItems: "center",
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontWeight: 500,
                              textAlign: "center",
                              }}
                              icon={<CheckIcon />}
                           >
                              {routingBankInfo[0]?.data?.name} <span style={{display:'inline-block'}}>Succesfully Connected</span>
                           </Alert>
                        }
                        {routingBankInfo[0]?.status == 'fail' && 
                           <Alert
                           severity="warning"
                           sx={{
                           // border: "2px solid #4E9A51",
                           // color: "#4E9A51",
                           alignItems: "center",
                           fontSize: "14px",
                           lineHeight: "20px",
                           fontWeight: 500,
                           textAlign: "center",
                           }}
                           // icon={<CheckIcon />}
                        >
                           <span style={{display:'inline-block'}}>No Bank found</span>
                        </Alert>
                        }
                     </div>
                     </Fade>

                     <Button variant='contained' fullWidth sx={{marginTop:'30px',height:'50px',fontSize:'15px'}}
                     disabled={disableSave} size='large'
                     onClick={saveNewAccount}
                     >
                        Save Payment Method
                     </Button>
                  </div>
               }

               <Divider sx={{marginTop:'20px',marginBottom:'20px', fontWeight: 400, fontSize: '15px', fontFamily: 'Poppins', maxWidth: '900px ', color: '#989898', ':before, :after': { top: '0%', borderTop: '1px solid #d3d3d3' } }}>Or</Divider>

               <div className='flex-row' style={{justifyContent:'center',marginBottom:'15px'}}>
                  <Typography variant="body1" fontWeight="400" color='#00000099'>
                     Edit existing bank account
                  </Typography>
               </div>

               {!editAccount && prenoteFound !== false &&
                  <div className='flex-row' style={{justifyContent:'center', padding:'0px 20px'}}>
                     {failedPrenote ?
                     <Card className='flex-row' elevation={2}
                     onClick={openEditAccount}
                     sx={{width:'100%',minHeight:'75px',padding:'11px 19px 11px 19px', background:'#FFFFFF', border:'solid 2px #195ffb'}} 
                     >
                        <>
                        <div className='col-2' style={{display:'flex',alignItems:'center', color:'#195FFB'}}>
                           <AccountBalanceIcon />
                        </div>
                        <div className='col-8'>
                           <Typography variant='subtitle3' sx={{fontWeight:600,fontSize:'14px',lineHeight:'20px',color:'#000000DE'}}>
                              {thisMember?.firstName && titleCase(thisMember.firstName)} {thisMember?.lastName && titleCase(thisMember.lastName)}'s <span style={{display:'inline-block'}}>bank account</span>
                           </Typography>
                           <br/>
                           <Typography variant='subtitle3' sx={{fontSize:'14px',lineHeight:'14px',color:'#00000099'}}>
                              {console.log(failedPrenote)}
                              BOFA, N.A. *{failedPrenote?.accountNumber && maskAccountNumber(failedPrenote.accountNumber)}
                           </Typography>
                        </div>
                        <div className='col-2' style={{display:'flex',alignItems:'center',justifyContent:'flex-end',color:'#195FFB'}}>
                           <CreateIcon />
                        </div>
                        </>
                     </Card>
                     :
                     <Skeleton width='100%' height='75px' />
                     }
                  </div>
               }
               {!editAccount && prenoteFound === false &&
                  <div className='flex-row' style={{justifyContent:'center', padding:'0px 20px'}}>
                     <Card className='flex-row' elevation={0}
                     sx={{width:'100%',minHeight:'75px',padding:'11px 19px 11px 19px', background:'#FFFFFF', border:'solid 1px #d3d3d3'}} 
                     >
                        <>
                        <div className='col-2' style={{display:'flex',alignItems:'center', color:'#d3d3d3'}}>
                           <AccountBalanceIcon />
                        </div>
                        <div className='col-8'>
                           <Typography variant='subtitle3' sx={{fontWeight:600,fontSize:'14px',lineHeight:'20px',color:'#00000099'}}>
                              NO PREVIOUS BANKING INFO FOUND
                           </Typography>
                        </div>
                        <div className='col-2' style={{display:'flex',alignItems:'center',justifyContent:'flex-end',color:'#d3d3d3'}}>
                           <CreateIcon />
                        </div>
                        </>
                     </Card>
                  </div>
               }
               {editAccount && 
                  <div style={{padding:'0px 20px'}}>
                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="descriptionUpdater"
                        onChange={editAccountHandler}
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                        }}
                        // onBlur={checkRoutingNumber}
                        label="Name on Account"
                        value={editAccount.description}
                        placeholder="Name on Account"
                        InputLabelProps={{ shrink: true }}
                     />
                     </div>
                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="routingNumberUpdater"
                        onChange={editAccountHandler}
                        required
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                        }}
                        onBlur={e => {
                           checkRoutingNumber()
                           maskOnBlur(e)
                        }}
                        onFocus={unmaskOnFocus}
                        label="Routing Number"
                        value={displayedValue.routingNumber}
                        placeholder="Routing Number"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ 
                           endAdornment: !maskedInput.showRoutingNumber 
                           ?  <div style={{ position: "absolute", right: "5%" }} type="button"
                              data-name='RoutingNumber'
                              onMouseDown={genericSwapIcon} onMouseUp={genericSwapIcon} onTouchStart={genericSwapIcon} onTouchEnd={genericSwapIcon}>
                                 <VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>
                           :  <div style={{ position: "absolute", right: "5%" }} type="button" 
                              data-name='RoutingNumber'
                              onClick={genericSwapIcon}>
                                 <NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>,
                           sx: { paddingRight: '0px' } 
                        }}
                     />
                     </div>

                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="accountNumberUpdater"
                        onChange={editAccountHandler}
                        required
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                        }}
                        onBlur={e => {
                           maskOnBlur(e)
                        }}
                        onFocus={unmaskOnFocus}
                        label="Account Number"
                        value={displayedValue.accountNumber}
                        placeholder="Account Number"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ 
                           endAdornment: !maskedInput.showAccountNumber 
                           ?  <div style={{ position: "absolute", right: "5%" }} type="button"
                              data-name='AccountNumber'
                              onMouseDown={genericSwapIcon} onMouseUp={genericSwapIcon} onTouchStart={genericSwapIcon} onTouchEnd={genericSwapIcon}>
                                 <VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>
                           :  <div style={{ position: "absolute", right: "5%" }} type="button" 
                              data-name='AccountNumber'
                              onClick={genericSwapIcon}>
                                 <NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>,
                           sx: { paddingRight: '0px' } 
                        }}
                     />
                     </div>

                     <div
                     className="flex-row"
                     style={{ padding: "0px", marginBottom: "15px" }}
                     >
                     <TextField
                        fullWidth
                        id="confirmAccountUpdater"
                        onChange={editAccountHandler}
                        required
                        error={matchingAccountError}
                        onBlur={e => {
                           maskOnBlur(e)
                           checkMatchingAccount()
                        }}
                        onFocus={unmaskOnFocus}
                        label="Confirm Account Number"
                        value={displayedValue.confirmAccount}
                        placeholder="Confirm Account Number"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ 
                           endAdornment: !maskedInput.showConfirmAccount 
                           ?  <div style={{ position: "absolute", right: "5%" }} type="button"
                              data-name='ConfirmAccount'
                              onMouseDown={genericSwapIcon} onMouseUp={genericSwapIcon} onTouchStart={genericSwapIcon} onTouchEnd={genericSwapIcon}>
                                 <VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>
                           :  <div style={{ position: "absolute", right: "5%" }} type="button" 
                              data-name='ConfirmAccount'
                              onClick={genericSwapIcon}>
                                 <NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} />
                              </div>,
                           sx: { paddingRight: '0px' } 
                        }}
                     />
                     </div>

                     <div className="flex-row" style={{ padding: "0px", textAlign: "left" }}>
                     <TextField
                        fullWidth
                        required
                        select
                        sx={{
                           "& fieldset": { borderRadius: "8px" },
                           "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                           border: "2px solid #195ffb",
                           },
                        }}
                        variant="outlined"
                        value={editAccount?.bankAccountTypeId || 1}
                        onChange={(e) => bankAccountHandler(e,'Edit')}
                        label="Account Type"
                        InputLabelProps={{ shrink: true }}
                     >
                        {accounts.map((account) => (
                           <MenuItem key={account.id} value={account.id}>
                           {account.name}
                           </MenuItem>
                        ))}
                     </TextField>
                     </div>

                     <Fade
                     in={routingBankInfo[0]?.status == "success"}
                     mountOnEnter
                     unmountOnExit
                     >
                     <div
                        className="flex-row"
                        style={{ padding: "10px 17px", justifyContent: "center" }}
                     >
                        <Alert
                           severity="success"
                           sx={{
                           border: "2px solid #4E9A51",
                           color: "#4E9A51",
                           alignItems: "center",
                           fontSize: "14px",
                           lineHeight: "20px",
                           fontWeight: 500,
                           textAlign: "center",
                           }}
                           icon={<CheckIcon />}
                        >
                           {routingBankInfo[0]?.data?.name} <span style={{display:'inline-block'}}>Succesfully Connected</span>
                        </Alert>
                     </div>
                     </Fade>

                     <Button variant='contained' fullWidth sx={{marginTop:'30px',height:'50px',fontSize:'15px'}}
                     disabled={disableSave} size='large'
                     onClick={saveEditAccount}
                     >
                        Save Payment Method
                     </Button>
                  </div>
               }
            </>
            }
            {successPage &&
            <>
               <div className='flex-row' style={{textAlign:'center',marginBottom:'76px'}}>
                  <Typography variant="subtitle1" fontWeight="600">
                     Thank you for updating your payment method!
                  </Typography>
               </div>

               <div className='flex-row' style={{textAlign:'center',marginBottom:'15px',padding:'0px 15px'}}>
                  <Typography variant="body" fontWeight="400">
                     You will receive confirmation in your email that you have updated your bank account.
                  </Typography>
               </div>

               <div className='flex-row' style={{textAlign:'center',marginBottom:'15px',padding:'0px 15px'}}>
                  <Typography variant="body" fontWeight="400">
                     Please allow 3-5 business days for this account to be verified through our system.
                  </Typography>
               </div>
            </>
            }
            <footer style={{position:'absolute',bottom:'0px',left:'0px',width:'100%'}}>
               <PoweredBy />
            </footer>
         </>
         }
      </div>
   </>
   )
}
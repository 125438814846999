// import styles from './ExternalRegister.module.css'

import React, { useEffect, useLayoutEffect, useState, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { format, differenceInCalendarYears } from "date-fns";

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


//Assets
import PlaceHolderLogo from '../../../assets/images/FullColorOnlyLogo.png';
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg'
import FallbackLogo from '../../../assets/images/SourceFileonly icon.png'

//Muicons
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

//MuiComponents
import Button from '@mui/material/Button';
import { Divider, Typography, useTheme, useMediaQuery } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';

//Services
import memberService from '../../../services/member-service';

// Our components
// import ExternalGlobalFooter from '../external-global/global-footer/ExternalGlobalFooter';
// import ExternalGlobalNavigation from '../external-global/global-navigation/ExternalGlobalNavigation';
import ExternalRegisterSetup from '../../app-external/external-register-setup/ExternalRegisterSetup';
import PoweredBy from '../external-enrollments/PoweredBy';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';
import DuplicateEmailDrawer from '../external-register-setup/DuplicateEmailDrawer';

//utils
import { checkMobileDevice, userTimeZone } from '../../utils';

function ExternalRegister(props) {
    let timeZone = userTimeZone
    const isMobileDevice = checkMobileDevice()
    let params = useLocation()

    let today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    let dateMax = `${year - 18}-${month}-${day}`;
    let dateMaxObj = new Date(dateMax)
    let dateMin = `${year - 100}-${month}-${day}`;
    let dateMinObj = new Date(dateMin)

    const [disabled, setDisabled] = useState(true)

    const history = useHistory();

    const goto = (route) => {
        history.push(route)
    }

    const [values, setValues] = useState({
        enrollmentId: '',
        lastName: '',
        dob: null,
        zipCode: '',
        memberObj: {},
        memberReturned: false,
    });

    const [displayError, setDisplayError] = useState(false)

    const handleDobFocus = (e) => {
        e.target.setAttribute('type', 'date')
    }


    const handleMobileDobChange = (e) => {
        let value = e

        // value = format(new Date(e), 'yyyy-MM-dd')
        setValues({
            ...values,
            dob: value
        })
    }

    const handleValueChange = (e) => {
        let inputVal = e.target.value;
        let inputProperty = e.target.getAttribute('data-property')
    
        setValues({
            ...values,
            [inputProperty]: inputVal
        })

        if(inputProperty === "zipCode"){
            checkZipError(inputVal)
        }
    }



    const [organization, setOrganization] = useState({})

    const getThisOrganization = async () => {
        try {
            let thisOrganization = await props.getOrganization()
            setOrganization(thisOrganization)
        }
        catch (e) {
            setOrganization("")
        }

    }

    const [message, setMessage] = useState({})
    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            setMessage({
                ...message,
                messageDisplay: false,
                autoHideDuration: "",
                severity: "",
                backgroundColor: "",
                message: ""
            })
        }, 5000)

        return () => {
            clearTimeout(timeId)
        }
    }, [message.messageDisplay])

    const handleMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage({
            ...message,
            messageDisplay: false,
            autoHideDuration: "",
            severity: "",
            backgroundColor: "",
            message: ""
        })
    };

    const getEnrollmentIDFromURL = () => {
        let variables = (window.location.href.split('?'))[1].split('&')
        let thisVariable = variables.filter(name => name.includes('id'))
        let regex = /id=(.+)/;
        if (thisVariable[0]?.length <= 3) return
        let thisEnrollmentId = regex.exec(thisVariable)[1]
        // console.log(/^[\p{L}\p{N}]{6,}$/.test(thisEnrollmentId))
        if (/.{5,6}/.test(thisEnrollmentId)) {
            setValues({
                ...values,
                enrollmentId: thisEnrollmentId
            })
            setMessage({
                ...message,
                messageDisplay: true,
                autoHideDuration: 2500,
                backgroundColor: '',
                severity: "success",
                message: "Successfully Populated Enrollment ID"
            })
        }
    }

    useEffect(() => {
        getThisOrganization()
        if (params.search.length > 0) {
            getEnrollmentIDFromURL()
            
        }
    }, [])

    let existingUserError = 'Enrollment ID already registered, please log in'
    const continueRegistration = async (e) => {
        props.setLoading(true)
        e.preventDefault();
        // let response = await memberService.searchMemberEnrollment(values)
        // console.log(response)
        let enrollmentSearchObj = {}
        enrollmentSearchObj.enrollmentId = values.enrollmentId
        if (values.lastName) {
            enrollmentSearchObj.lastName = values.lastName
        } else {
            enrollmentSearchObj.lastName = "";
        }
        if (values.dob) {
            enrollmentSearchObj.dob = values.dob.length > 10 ? 
            // format(new Date(values.dob), 'yyyy-MM-dd') 
            values.dob //already formatted with system timezone
            : values.dob;
        }
        else {
            enrollmentSearchObj.dob = new Date("1/1/1900")
        }
        if (values.zipCode) {
            enrollmentSearchObj.zipCode = values.zipCode;
        }
        else {
            enrollmentSearchObj.zipCode = "";
        }

        console.log('EnrollmentSearchObj',enrollmentSearchObj)
        // // console.log(enrollmentSearchObj)
      
        await memberService.searchMemberEnrollment(enrollmentSearchObj)
        .then(succ => {
            //if a member already has registration history
            if (succ.isRegistered) {
                
                props.setLoading(false)
                setEnrollmentError(existingUserError)
            }
            else {
                //if a member does not have registration history
              
                props.setLoading(false)
                setValues((values) => ({
                    ...values,
                    memberObj: succ.member,
                    memberReturned: true,
                }))
            }


        }, error => {
            //display incorrect id error differently than enrollment id length verificaiton error
            if(error.response.status === 400 || error.response.status === 404){
            setEnrollmentIdValidError("Enrollment ID not found. Try again or register with last name, date of birth, and zip code.")
            }
            setDisplayError(true)
            props.setLoading(false)
        })

    }

    const backToLogin = () => {
        history.push("/login")
    }

    //const getOrganizationLogo = () => {
    //    if (organization) {
    //        return `/api/image/organization/${organization.id}`
    //    } else return FallbackLogo
    //}
    const [imgFetchAttempts,setImgFetchAttempts] = useState(0)
    const handleLogoError = (e) => {
        console.log('logo img error',FallbackLogo)
        setImgFetchAttempts(prev => prev++)
        if (imgFetchAttempts <= 10) e.target.src = FallbackLogo
    }

    // Enrollment ID Erroring
    const [showEnrollmentError, setShowEnrollmentError] = useState(false)
    // Only show on Blur
    const checkEnrollmentError = () => {
        if (values.enrollmentId != '' && values.enrollmentId.length <= 4) {
            setShowEnrollmentError(true)
            setEnrollmentError('Enrollment ID must be 6 digits long')
        }
        else setEnrollmentError(null)
        if (values.lastName && values.dob && values.zipCode) {
            setEnrollmentError(null)
        }
        setShowEnrollmentError(true)
    }
    const [enrollmentError, setEnrollmentError] = useState(null)
    const [enrollmentIdValidError, setEnrollmentIdValidError] = useState(false)

    useEffect(() => {
        if (values.enrollmentId && (values.enrollmentId.length == 6 || values.enrollmentId.length == 5) && showEnrollmentError) {
            setShowEnrollmentError(false) 
            setEnrollmentError(null)
            setEnrollmentIdValidError(false)
        }
    }, [values.enrollmentId])

    // DOB Erroring - old, check new method with useMemo below
    // const [dobErrorCheck, setDobErrorCheck] = useState(false)
    // const handleDobBlur = (e) => {
    //     if (!values.dob) e.target.setAttribute('type', '')
    //     else (setDobErrorCheck(true))
    // }

    const isValidDate = (date) => {
        // console.log(date)
        // First check for the pattern
        if (!/^\d{4}-\d{1,2}-\d{1,2}/.test(date))
            return false;

        // Parse the date parts to integers
        var parts = date.split("-");
        var day = parseInt(parts[2], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[0], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];


    }
    const [dobError, setDobError] = useState(null)

    //temp commented out while we test out new DatePicker for use on large AND small devices - will delete once we know it works
    // useEffect(() => {
    //     console.log('dob check',values.dob <= dateMin,dateMax <= values.dob)
    //     if (values.dob){
    //         // if (!isValidDate(values.dob)) {
    //         //     setDobError('Date of Birth must be a valid date')
    //         // }
    //         if (values.dob <= dateMin){
    //             setDobError('Must be less than 100 years old')
    //         } else if (dateMax <= values.dob) {
    //             setDobError('Must be older than 18 years old')
    //         }
    //         else setDobError(null)
    //     } else setDobError(null)
    // }, [values.dob])

    const dobErrorMessage = useMemo(() =>  {
        
        switch (dobError) {
        case 'maxDate':{
            return 'Must be older than 18 years old';
        }
        case 'minDate': {
          return 'Must be less than 100 years old';
        }
        case 'disableFuture': {
            return 'Must be greater than 0 years old';
          }
        case 'invalidDate': {
          return 'Your date is not valid';
        }
        default: {
          return '';
        }}
    }, [dobError])


    // Zip Erroring
    const [zipError, setZipError] = useState(null)
    const [showZipError, setShowZipError] = useState(false)
    const checkZipError = (value=values.zipCode) => {
        if (!/\b\d{5}\b/.test(value)) {
            setShowZipError(true)
            setZipError('Zip code must be 5 digits long')
        } else {
            setShowZipError(false)
            setZipError(null)
        }
    }



    // const error = (field) => {
    //     if (field == "enrollmentId") {
    //         if (values.enrollmentId && values.enrollmentId.length <= 5) {
    //             return true
    //         } else return false
    //     }
    //     else if (field == 'registered')
    // }

    //now doing this with helper function checkMobileDevice imported from utils folder - temp commented out while testing before full delete
    //CHECK if iPad device
    // let theme = useTheme()

    // function iOS() {
    //     return [
    //     'iPad Simulator',
    //     'iPad',
    
    //   ].includes(navigator.platform)
    //     // iPad on iOS 13 detection
    //     || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    //   }
      
    //   const isIOS = iOS();

    // //true if <= md
    // //check if iOS, if so, make breakpoint lg to work for iPad
    // let breakpointSize;

    // if(isIOS){
    //     breakpointSize = 'xl'
    // } else {
    //     breakpointSize='md'
    // }

    // let medBreakpointHit = useMediaQuery(theme.breakpoints.down(breakpointSize))

    const handleDisabled = () => {
        //they're either using an enrollment id, or newly registering
        console.log('dob values', values.dob <= dateMinObj)
        let disabled = true
        if (values.enrollmentId?.length > 0) {
            //they're using an enrollment id, check for validity
            if (values.enrollmentId != '' && values.enrollmentId.length > 4) disabled = false
        } else {
            //they're not using an enrollment id, check for validity of other fields
            let lastNameValid = false
            let dobValid = false
            let zipCodeValid = false
            if (values.lastName?.length > 0) lastNameValid = true
            if (values.dob != '' && values.dob && !(values.dob <= dateMinObj) && !(dateMaxObj <= values.dob) && !dobError){
                dobValid = true
            }
            if (values.zipCode?.length > 0 && /\b\d{5}\b/.test(values.zipCode)) zipCodeValid = true
            disabled = !(lastNameValid && dobValid && zipCodeValid)
        }
        setDisabled(disabled)
    }

    useEffect(() => {
        handleDisabled()
    }, [values, dobError])


    return <>

        <>
            <style>
                {`
        #member-landing-container > div{
            display:flex;
            justify-content:center;
            text-align:center;
        }
        .continue-register-btn.Mui-disabled {
            color:white;
            background:#646464!important
        }
        `}
            </style>
            {props.loading && 
            <>
                <InfinityLoader style={{
                    position:'absolute',
                    zIndex:5,
                    width:'100%',
                    height:'100vh',
                    backgroundColor:'#00000050',
                    backdropFilter:'blur(10px)',
                }} />
                <style>
                {`
                    div.lottie-div > svg {
                        height:55px!important;
                        position:absolute;
                        top:50%;
                    }
                `}
                </style>
            </>
            }
            <Box className="flex-container flex-row" id='enrollment-login' flex={1} overflow="auto" style={{ padding: '40px 40px', height: '100vh', margin: 'auto' }} sx={{
                bgcolor: 'background.default',
                color: 'text.primary'
            }}>
                <div className="row" style={{ maxWidth: '575px', margin: '0 auto', maxHeight: '1000px' }}>
                    <div className="flex-row" style={{ margin: '6px', justifyContent: 'center' }} >
                        <img src={organization?.id > 0 ? `/api/image/organization/${organization.id}` : '/api/image/organization'} style={{ maxHeight: '100px' }} onError={handleLogoError} />
                    </div>

                    {!values.memberReturned ?
                        <>
                            {organization?.name != "Teamsters" &&
                                <div className={`flex-row`} style={{ marginBottom: '20px', justfiyContent: 'center', textAlign: 'center' }} >
                                    <Typography variant="subtitle" fontWeight="600" width={'100%'}>
                                        <div>
                                            Register your {organization.name} 
                                        </div>
                                        <div>
                                            UnionHub account
                                        </div>
                                    </Typography>
                                </div>
                            }
                            {organization?.name == 'Teamsters' &&
                                <div className={`flex-row`} style={{ marginBottom: '20px', justfiyContent: 'center', textAlign: 'center' }} >
                                    <Typography variant="subtitle" fontWeight="600" width={'100%'}>
                                        <div>
                                            Register your Teamsters VIP+
                                        </div>
                                        <div>
                                            UnionHub Account
                                        </div>
                                    </Typography>
                                </div>
                            }

                            <div className="flex-row" style={{ marginBottom: '30px', justifyContent: 'center', textAlign: 'center' }} >
                                <Typography variant="body1">Your Enrollment ID will help you log in <span style={{ fontStyle: 'italic', fontWeight: '700' }}>faster.</span></Typography>
                            </div>

                            <div className="flex-row" style={{ marginBottom: '20px' }} >
                                <TextField sx={{ maxWidth: '685px' }} fullWidth
                                    label="Enrollment ID" variant="outlined" value={values.enrollmentId} onChange={handleValueChange}
                                    inputProps={{ 'data-property': 'enrollmentId' }}
                                    error={showEnrollmentError && enrollmentError}
                                    helperText={showEnrollmentError && enrollmentError}
                                    onBlur={checkEnrollmentError}
                                    disabled={values.lastName && values.dob && values.zipCode}
                                />
                            </div>
                            {enrollmentError == existingUserError &&
                                <div className="flex-row" style={{ marginBottom: '50px' }} >
                                    <Button fullWidth variant='contained'
                                        sx={{ height: 50, maxWidth: '574px' }}
                                        onClick={backToLogin}
                                    >
                                        Take me to log in <KeyboardArrowRightIcon />
                                    </Button>
                                </div>
                            }

                            {enrollmentIdValidError &&
                                <Alert severity="error" sx={{ margin: '0px auto 10px', width: "90%" }}>{enrollmentIdValidError}</Alert>
                            }

                            <Divider sx={{ color: '#989898', fontWeight: 700, fontFamily: 'Poppins', maxWidth: '900px', margin: 'auto', ':before, :after': { top: '0%' } }}>Or</Divider>

                            <div className="flex-row" style={{ marginTop: '25px', marginBottom: '15px' }}>
                                <TextField sx={{ maxWidth: '685px' }} fullWidth
                                    disabled={values.enrollmentId?.length > 4}
                                    label="Last Name" variant="outlined" value={values.lastName} onChange={handleValueChange}
                                    inputProps={{ 'data-property': 'lastName' }}
                                />
                            </div>

                            {/* //temp commented out while we test out new DatePicker for use on large AND small devices - will delete once we know it works  */}

                            {/* {isMobileDevice
                                ?
                                <Box sx={{ marginBottom: '15px'}}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disabled={values.enrollmentId?.length > 5}
                                            disableFuture
                                            openTo='year'
                                            views={['year', 'month', 'day']}
                                            minDate={dateMinObj}
                                            maxDate={dateMaxObj}
                                            // value={values.dob} onChange={handleDobChange}
                                            value={values.dob || ''} onChange={handleMobileDobChange}
                                            format='MM/dd/yyyy'
                                            error={dobError}
                                            helperText={dobError}
                                            sx={{width: '100%'}}
                                    
                                        />
                                    </LocalizationProvider>
                                </Box>
                                :
                                <Box sx={{ marginBottom: '15px' }}>
                                    <TextField sx={{ maxWidth: '685px' }} fullWidth type={values.dob ? 'date' : ''}
                                        disabled={values.enrollmentId?.length > 5}
                                        error={dobError}
                                        helperText={dobError}
                                        label="Date of birth"

                                        value={values.dob || ''}
                                        variant="outlined"
                                        // value={values.dob} 
                                        onFocus={handleDobFocus} onBlur={handleDobBlur} onChange={handleValueChange}
                                        inputProps={{
                                            min: dateMin,
                                            max: dateMax,
                                            'data-property': 'dob'
                                        }}
                                    />
                                </Box>
                            } */}
                            <Box sx={{ marginBottom: '15px'}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        timezone='system'
                                        disableFuture
                                        disabled={values.enrollmentId?.length > 4}
                                        label="Date of birth"
                                        openTo='year'
                                        views={['year', 'month', 'day']}
                                        format='MM/dd/yyyy'
                                        minDate={dateMinObj}
                                        maxDate={dateMaxObj}
                                        value={values.dob || null}
                                        onChange={handleMobileDobChange}
                                        onError={(error) => setDobError(error)}
                                        sx={{width: '100%', color: 'primary.main'}}
                                        slotProps={{
                                            textField: {
                                                helperText: dobErrorMessage,
                                            },
                                            openPickerButton: {sx:{visibility: 'hidden'}}
                                        }}
                                    />
                                </LocalizationProvider>
                            </Box>
                                    

                            <div className="flex-row" style={{ marginBottom: '32px' }}>
                                <TextField sx={{ maxWidth: '685px' }} fullWidth
                                    error={showZipError && zipError}
                                    helperText={showZipError && zipError}
                                    onChange={handleValueChange}
                                    disabled={values.enrollmentId?.length > 4}
                                    label="Zip Code" 
                                    variant="outlined" 
                                    value={values.zipCode} 
                                    inputProps={{ 'data-property': 'zipCode' }}
                                />
                            </div>

                            <div className="flex-row" style={{ marginBottom: '15px' }} >
                                <Button fullWidth variant='contained'
                                    disabled={disabled}
                                    sx={{ height: 50, maxWidth: '574px'}}
                                    onClick={continueRegistration}
                                >
                                    Register
                                </Button>
                            </div>


                            <div className="flex-row" style={{ marginBottom: '50px' }} >
                                <Button fullWidth variant='text'
                                    sx={{ height: 50, maxWidth: '574px' }}
                                    onClick={backToLogin}
                                >
                                    Already have an account? Log in here.
                                </Button>
                            </div>
                        </>

                        :
                        <ExternalRegisterSetup member={values.memberObj} organization={organization} setLoading={props.setLoading}  />
                    }
                    <PoweredBy />

                </div>
                <Snackbar open={message.messageDisplay} autoHideDuration={message.autoHideDuration} onClose={handleMessageClose} sx={{ opacity: .8, paddingBottom:'40px' }}>
                    <MuiAlert
                        elevation={6} variant="filled"
                        // onClose={handleClose} 
                        severity={message.severity} sx={{ width: '100%', backgroundColor: message.backgroundColor }}
                    >
                        {message.message}
                    </MuiAlert>
                </Snackbar>


            </Box>

        </>


    </>;
};

export default ExternalRegister;
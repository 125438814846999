import React, { useState } from "react";

//MuiComponents
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";

//Mui Icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

//Our components
import AccidentSpouseInputs from "./AccidentSpouseInputs";
import AccidentDependentsInputs from "./AccidentDependentsInputs";


export default function AccidentFamilyInputs(props) {
  const [expanded, setExpanded] = useState({
    spouse: true,
    dependents: true,
  });

  const handleExpandClick = (e) => {
    let key = e.target.getAttribute("data-value");
    // console.log(key);
    setExpanded({
      ...expanded,
      [key]: !expanded[key],
    });
  };

  let expandSpouseIcon = expanded.spouse ? (
    <RemoveIcon
      fontSize="medium"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "white",
        backgroundColor: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  ) : (
    <AddIcon
      fontSize="medium"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  );

  let expandDependentsIcon = expanded.dependents ? (
    <RemoveIcon
      fontSize="medium"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "white",
        backgroundColor: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  ) : (
    <AddIcon
      fontSize="medium"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  );

  return (
    <>
      <Box
        className="flex-row"
        sx={{
          minHeight: "57px",
          color: expanded.spouse ? "primary.main" : "rgba(0, 0, 0, 0.87)",
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "30px",
          position: "relative",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0px",
          // marginTop: "5px",
          fontFamily: "Poppins",
        }}
      >
        <span
          data-value="spouse"
          style={{ cursor: "pointer", position: "relative" }}
          onClick={handleExpandClick}
        >
          {/* {stdPlan.title}  */}
          Spouse
          {/* bigger clickable area */}
          {/* <Box style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />   */}
        </span>

        <Box
          onClick={handleExpandClick}
          data-value="spouse"
          sx={{
            position: "absolute",
            right: "0%",
            top: "6%",
            color: "primary.main",
            width: "18%",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {expandSpouseIcon}
        </Box>
      </Box>
      <Collapse in={expanded.spouse}>
        <AccidentSpouseInputs {...props} />
      </Collapse>

      <Box
        className="flex-row"
        sx={{
          minHeight: "57px",
          color: expanded.dependents ? "primary.main" : "rgba(0, 0, 0, 0.87)",
          fontSize: "15px",
          fontWeight: 600,
          lineHeight: "30px",
          position: "relative",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0px",
          // marginTop: "5px",
          fontFamily: "Poppins",
        }}
      >
        <span
          data-value="dependents"
          style={{ cursor: "pointer", position: "relative" }}
          onClick={handleExpandClick}
        >
          {/* {stdPlan.title}  */}
          Dependents
          {/* bigger clickable area */}
          {/* <Box style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />   */}
        </span>

        <Box
          onClick={handleExpandClick}
          data-value="dependents"
          sx={{
            position: "absolute",
            right: "0%",
            top: "6%",
            color: "primary.main",
            width: "18%",
            height: "100%",
            cursor: "pointer",
          }}
        >
          {expandDependentsIcon}
        </Box>
      </Box>
      <Collapse in={expanded.dependents}>
        <AccidentDependentsInputs {...props} />
      </Collapse>
    
    </>
  );
}

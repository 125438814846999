import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

//services
import enrollmentService from "../../../../../../../services/enrollment-service"

// Assets

//Mui Components
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { Alert, Box, Typography, Checkbox } from "@mui/material"

//Mui icons
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import CheckIcon from "@mui/icons-material/Check"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import VisibilityIcon from "@mui/icons-material/Visibility"
import NoVisibilityIcon from "@mui/icons-material/VisibilityOff"

//Our Components

import InfinityLoader from "../../../../../../UH-loading-animation/InfinityLoader"
import { set, format } from "date-fns"

export default function CancelCoverageDrawer(props) {
  const componentStyles = {
    componentBody: {
      fontFamily: "Poppins",
      minWidth: "538px",
      height: "100%",
      backgroundColor: "background.default",
      overflowY: "auto",
    },
    headerContainer: {
      width: "100%",
      padding: "10px 30px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "0 auto",
      borderBottom: "1px solid",
      borderColor: "primary.main",
    },
    drawerTitle: {
      color: "text.primary",
      fontFamily: "Poppins",
      fontSize: "22px",
      fontWeight: "400",
    },
    infoContainer: {
      width: "100%",
      padding: "10px 30px",
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      margin: "0 auto",
    },
    policyDetails: {
      fontFamily: "Archivo",
      fontSize: "13px!important",
      fontWeight: 400,
      color: "text.secondary",
      lineHeight: "20px",
    },
    description: {
      color: "text.primary",
      fontFamily: "Archivo",
      fontSize: "14px",
      fontWeight: "500",
      padding: "10px 16px 10px 20px",
    },
    closeDrawerButton: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
    },
  }

  //   console.log("Plan in CANCEL DRAWER", props.planToCancel);

  // Cancel Month Logic
  //get current date and set date of cancelation to be next month

  // Get today's date
  const today = new Date()

  // Get the current month (0-indexed, so January is 0, February is 1, etc.)
  const currentMonth = today.getMonth()

  // Get the current year
  const currentYear = today.getFullYear()

  // Create a new Date object for the following month
  const followingMonth = new Date(currentYear, currentMonth + 1, 1)

  // Get the month and year of the following month
  const followingMonthMonth = followingMonth.getMonth() // (0-indexed)
  const followingMonthYear = followingMonth.getFullYear()

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  // Get the following month's name from the array
  const followingMonthName = monthNames[followingMonthMonth]

  //REASON FOR CANCELLATION
  const [reasonForCancelation, setReasonForCancelation] = useState(1)

  const handleChangeReasonForCancelation = (e) => {
    setReasonForCancelation(e.target.value)
  }

  //Drawer Logic
  const handleCloseDrawer = () => {
    props?.setCancelCoverageDrawerOpen(false)
    //clear selected plan
    {!props?.cancelEnrollment &&     props?.setPlanToCancel(null)}
    setReasonForCancelation(1)
    setGenerateNewForm(true)
  }

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  //checkbox logic for download enrollment elections repots
  const [generateNewForm, setGenerateNewForm] = useState(true)

  const downloadEnrollmentElectionsReport = async () => {
    // console.log("downloading enrollment elections report")
    const link = document.createElement("a")
    let sendEmail = false

    try {
      let attachmentId = await enrollmentService.generateEnrollmentReport(
        props?.planEnrollmentId,
        sendEmail
      )
      if (attachmentId) {
        link.download = `/api/attachment/${attachmentId}`
        link.href = `./api/attachment/${attachmentId}`
 
      }
    } catch (error) {
      console.log("error generating enrollment report", error)
    }

    setTimeout(() => {
      link.click()
      props.getAllDocuments()
    }, 500)
  }

  //submit cancelation
  const submitCancelation = async () => {
    setLoading(true)
    // Logic for canceling coverage
    //if not ladd, find cancel option
    //set selected option id to that option id
    if (!props.cancelEnrollment){
      let plan = props.planToCancel
    console.log('plan to cancel',props.planToCancel)
    let reasonId = reasonForCancelation
    if (plan) {
      
      //if the plan has a selectedOptionId, then it isnt ladd, so handle as usual
      if (plan?.selectedOptionId){
        if (plan?.options?.length > 0){
          let cancelOption = plan?.options?.find(option => option?.isDecline == true)
          console.log('cancel option',cancelOption)
          if (cancelOption){
            plan.selectedOptionId = cancelOption.memberOptionId
          }
        }
        console.log('plan to cancel',plan)
        await props.updateSinglePlan("", plan, reasonId)


      } else {
        //handle as ladd
        let memberId = props?.member.id;
        let groupPlanId = props?.groupPlanId;
        let planId = plan.planId;
        let enrollmentId = props.planEnrollmentId;
        let memberCoverageAmount = 0
        let spouseCoverageAmount = 0
        let dependentCoverageAmount = 0

        await enrollmentService.editLADDForMember(
          memberId,
          groupPlanId,
          planId,
          enrollmentId,
          reasonId,
          memberCoverageAmount,
          spouseCoverageAmount,
          dependentCoverageAmount,
        );
        
        //refresh payment schedule after ladd coverage update
        props?.getPaymentSchedule()
      }
    
      //refresh plan and cancellation list
      await props.getThisPlan()
      handleCloseDrawer()
    }


    //set timeout to allow for cancelation update in db and then download enrollment elections report with updated coverage info
    // console.log("submitting cancel coverage")
    if (generateNewForm) {
    
      setTimeout( async() => {
        await downloadEnrollmentElectionsReport()
      }, 200)
    }
    } else {
      console.log("Cancel Enrollment")
      let memberId = props?.member?.id
      let enrollmentId = props?.planEnrollmentId;
      let errorCanceling = false

      try {
         let cancelAllCoverage = await enrollmentService.cancelEnrollment(enrollmentId, memberId);

      } catch (error) {
         console.error('error canceling all coverage',error)
         props?.setCancelAllError(error)
         //local error value for conditional rendering of modal
         errorCanceling = true
         setLoading(false)
      }

      setTimeout(() => {
        //refresh enrollment data
      //   props?.getOneEnrollment(props?.selectedPlan)
        props?.getThisPlan()
        //refresh payment schedules
        // props?.getPaymentSchedule()
        setLoading(false) 
        //close modal if no error, otherwise display error
        if(!errorCanceling){
          handleCloseDrawer()        }
      }, 500);
    }
    
    setLoading(false)
  }

  //TO DO: potentially want to download in documents tab and documents section of plans tab  so it is updated
  // pass in props.getAllDocuments
  

  return (
    <>
      <Drawer
        anchor="right"
        open={props.cancelCoverageDrawerOpen}
        onClose={handleCloseDrawer}
      >
        {loading && (
          <>
            <InfinityLoader
              style={{
                // position: "fixed",
                position: "absolute",
                zIndex: 5,
                width: "100%",
                height: "100%",
                // backgroundColor: "#00000050",
                backdropFilter: "blur(10px)",
              }}
            />
            <style>
              {`
                      div.lottie-div > svg {
                         height:55px!important;
                         position:absolute;
                         top:50%;
                      }
                   `}
            </style>
          </>
        )}
        <Box sx={componentStyles.componentBody}>
          <Box className="flex-row" sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.drawerTitle}>
              {props?.cancelEnrollment ? 'Cancel Enrollment' : 'Cancel Line of Coverage'}
            </Typography>

            <Box
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleCloseDrawer}
            >
              <CloseIcon sx={{ height: "34px" }} />
            </Box>
          </Box>

          {/* Plan Info */}
          <Box sx={componentStyles.infoContainer}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 400,
                marginBottom: "6px",
              }}
            > {!props?.cancelEnrollment ? props?.planToCancel?.title +
              " " +
              props?.planToCancel?.planName.toUpperCase() || "" : props?.singleEnrollment?.name}
            </Typography>
        {!props?.cancelEnrollment ?
        <>            
        <Typography
              variant="body2"
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 400,
                color: "text.secondary",
              }}
            >
              Policy #{props.planToCancel?.policyNumber || ""}
            </Typography>

            <Typography variant="body2" sx={componentStyles.policyDetails}>
              Carrier: {props.planToCancel?.carrierName || ""}
            </Typography>
            <Typography variant="body2" sx={componentStyles.policyDetails}>
              Effective Date:{" "}
              {props.planToCancel?.effectiveDate
                ? format(
                    props?.formatDate(props.planToCancel?.effectiveDate),
                    "MM/dd/yyyy"
                  )
                : ""}
            </Typography>
        </>
        :
        <>
        <Typography
              variant="body2"
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 400,
                color: "text.secondary",
                wordWrap: 'break-word',
                maxWidth: '538px',
                
              }}
            >
            Canceling this enrollment will prevent it from going into effect or making payments.  This will leave the enrollment in a cancelled status that you can revert back during this enrollment window.
            </Typography>

        </>}

          </Box>

          {/* Edit fields */}
          <Box sx={componentStyles.infoContainer}>
            {/* date of cancelation currently next month from cancelation, but will change in future */}
            {!props.cancelEnrollment &&
             <TextField
              fullWidth
              id="dateOfCancelation"
              sx={{
                "& fieldset": { borderRadius: "4px" },
                marginBottom: "15px",
              }}
              label="Date of Cancelation"
              value={followingMonthName + " " + followingMonthYear}
              disabled={true}
            />
            }
           
           {!props?.cancelEnrollment &&
            <TextField
              fullWidth
              id="reasonForCancelation"
              onChange={(e) => {
                handleChangeReasonForCancelation(e)
              }}
              sx={{
                "& fieldset": { borderRadius: "4px" },
              }}
              // onBlur={checkRoutingNumber}
              label="Reason for Cancelation"
              value={reasonForCancelation || ''}
              select
            >
              {props.cancellationReasons && props.cancellationReasons.map((reason) => {
                return <MenuItem value={reason.id}>{reason.name}</MenuItem>
              })}
            </TextField>
}
              {!props?.cancelEnrollment &&
               <Box
              className="flex-row container"
              sx={{
                padding: "20px 0px",
                position: "relative",
                marginBottom: "15px",
                fontFamily: "Archivo",
              }}
            >
              <Checkbox
                onChange={(e) => setGenerateNewForm(!generateNewForm)}
                // value={}
                checked={generateNewForm}
                name={"generateNewForm"}
                sx={{
                  padding: "0px",
                  marginRight: "12px",
                }}
                icon={<CheckBoxOutlineBlankIcon sx={{ height: "19px" }} />}
                checkedIcon={<CheckBoxIcon sx={{ height: "19px" }} />}
              />
              Generate Enrollment Elections Report
            </Box>
              }
           
          </Box>

          {/* Buttons */}
          <Box sx={componentStyles.buttonContainer}>
            <Button sx={{ marginRight: "10px" }} onClick={handleCloseDrawer}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              // disabled={true}
              onClick={submitCancelation}
            >
              SUBMIT CANCELATION
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

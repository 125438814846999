import React, {
  useRef,
  useLayoutEffect,
  useEffect,
  useState,
  forwardRef,
} from "react";
import { useHistory } from "react-router-dom";
import { IMaskInput, IMaskMixin, IMask } from "react-imask";
import { format, differenceInCalendarYears } from "date-fns";

//Assets
import FullColorBlackText from "../../../../assets/images/FullColorBlackText.svg";

//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//Mui Icons
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ErrorIcon from "@mui/icons-material/Error";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";

//MuiComponents
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Input,
  Link,
  RadioGroup,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import StickyProgressTotal from "../StickyProgressTotal";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import Drawer from "@mui/material/Drawer";
import IconButton from '@mui/material/IconButton';


//services
// import memberService from "../../../../services/member-service";
import optionService from "../../../../services/optionService";
import organizationService from "../../../../services/organization-service";
import questionService from "../../../../services/question-service";

//Our Components
import PoweredBy from "../PoweredBy";
import AgentNavigation from "../../../app-internal/Member/member-profile/MemberTabs/agent-enroll-tab/AgentNavigation";
import ExternalEnrollmentHeader from "../ExternalEnrollmentHeader";
import enrollmentPlanStyles from "../enrollment-plans/enrollmentPlanStyles";

export default function EnrollmentPersonalInfo(props) {

  const componentStyles = {
    inputField: {
      fontSize: "15px",
      padding: '8.5px 7px',
      marginBottom: "7px",
    },

  };

  const [permissionsEnabler, setPermissionsEnabler] = useState(false);
  useEffect(() => {
    if (props.permissionsEnabler && !permissionsEnabler) {
      setPermissionsEnabler(props.permissionsEnabler);
    } else {
      setPermissionsEnabler(false);
    }
  }, [props.permissionsEnabler]);

  const [workStatuses, setWorkStatuses] = useState([]);
  const getWorkStatuses = async () => {
    let _workStatuses = await optionService.getWorkStatus();
    setWorkStatuses(_workStatuses);
  };
  useEffect(() => {
    if (workStatuses.length == 0) getWorkStatuses();
  }, []);
  const getNotActive = () => {
    let nawId = workStatuses.find(
      (workStatus) => workStatus.name == "Not Actively Working"
    )?.id;
    return nawId;
  };
  const getActive = () => {
    let awId = workStatuses.find(
      (workStatus) => workStatus.name == "Actively Working"
    )?.id;
    return awId;
  };
  const getIrregularWorkSchedule = () => {
    let iwsId = workStatuses.find(
      (workStatus) => workStatus.name == "Irregular Work Schedule"
    )?.id;
    return iwsId;
  };
  const getInBetweenJobs = () => {
    let ibjId = workStatuses.find(
      (workStatus) => workStatus.name == "In Between Jobs"
    )?.id;
    return ibjId;
  };
  const getOnLeave = () => {
    let olId = workStatuses.find(
      (workStatus) => workStatus.name == "Leave of Absense"
    )?.id;
    return olId;
  };
  const getOther = () => {
    let otherId = workStatuses.find(
      (workStatus) => workStatus.name == "Other"
    )?.id;
    return otherId;
  };

  // const [localProps,setLocalProps] = useState()
  // useEffect(() => {
  //   if (props && localProps) {
  //     // console.log('props change', props)
  //     Object.keys(props).filter(key => {

  //       if (typeof props[key] !== 'function'){

  //         return props[key] !== localProps[key]
  //       }
  //     }).map(key => {
  //       // console.log('changed property: ', key, 'from', localProps[key], 'to', props[key])
  //     })
  //   }
  //   setLocalProps(props)
  // },[props])
  // console.log('localProps', localProps)

  const history = useHistory();
  // console.log('personalInfo hist', history.location)

  const [memberInfo, setMemberInfo] = useState(props.fullMemberObj);
  // const getMemberInfo = async() => {
  // let _memberInfo = await memberService.getMe()
  // setMemberInfo(_memberInfo)
  // }
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    // if (!mounted && props.fullMemberObj) {
    if (props.fullMemberObj) {
      setMemberInfo(props.fullMemberObj);
      setStateInputOpen(false);
      // setMounted(true);
    }
    console.log(props?.fullMemberObj)
  }, [props.fullMemberObj?.id,props.fullMemberObj?.gender, props.fullMemberObj?.workStatusId, props.fullMemberObj?.social]);
  useEffect(() => {
    setStateInputOpen(false);
  }, [props.fullMemberObj]);

  const syncPropStateToLocalState = () => {
    props.setFullMemberObj(memberInfo);
  };

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      // getMemberInfo()
      getUnitedStates();
      getOrganizations();
      // getPhones()
      // if (props.agentEnroller){
      //    getOrganizations()
      // } else {
      //    getMyOrganization()
      // }
      // getOrganizations()
      // getEmployers()
      setLoaded(true);
    }
  }, []);

  // useEffect(() => {
  //   if (props.results && memberInfo) getOrganizations();
  // }, [props.results?.enrollmentId]);

  const handleInputChange = (e) => {
    let val = e.target.value;
    let key = e.target.getAttribute("data-update");
    //if value entered is first or last name, remove numbers (if any entered)
    if (key === "firstName" || key === "lastName") {
      const inputValue = e.target.value;
      const withoutNumbers = inputValue.replace(/[0-9]/g, ""); // Remove any numbers
      props.setFullMemberObj({
        ...props.fullMemberObj,
        [key]: withoutNumbers,
      });
      setMemberInfo({
        ...memberInfo,
        [key]: withoutNumbers,
      });
    } else if (key === "zipCode") {
      let onlyNums = RegExp(/^[0-9]+$/);
      if (onlyNums.test(val) || val === "") {
        props.setFullMemberObj({
          ...props.fullMemberObj,
          [key]: val,
        });
        setMemberInfo({
          ...memberInfo,
          [key]: val,
        });
      }
    } else {
      //update all other values accordingly if they are not first or last name
      props.setFullMemberObj({
        ...props.fullMemberObj,
        [key]: val,
      });
      setMemberInfo({
        ...memberInfo,
        [key]: val,
      });
    }
    genderErrorCheck();
  };

  const [addPreferredName, setAddPreferredName] = useState(false);

  //gender Radio
  const [gender, setGender] = useState(0);
  useEffect(() => {
    setGender(memberInfo.gender);
  }, [memberInfo?.gender]);
  const handleGenderChange = (e) => {
    let value = e.target.value;
    props.setFullMemberObj({
      ...props.fullMemberObj,
      gender: value,
    });
    setGenderError(false);
  };

  const [genderError, setGenderError] = useState(false);
  const genderErrorCheck = () => {
    if (memberInfo.gender == 0) setGenderError(true);
    else setGenderError(false);
  };

  //Address 2
  const [addSecondAddress, setAddSecondAddress] = useState(false);

  //US States

  const [stateInputOpen, setStateInputOpen] = useState(false);
  const [unitedStates, setUnitedStates] = useState([]);
  const [unitedStatesAbbreviationDict, setUSAbbvDict] = useState({});
  const [unitedStatesFullPathDict, setUSFullPathDict] = useState({});
  const [stateSelectionClickedOrArrowed, setStateSelectionClickedOrArrowed] = useState(false);
  const [uSStateSelected, setUsStateSelected] = useState(
    props.fullMemberObj?.state ? props.fullMemberObj.state : ""
  );
  const [storedUSStateSelectedInput, setStoredUSStateSelectedInput] = useState("");
  const [storedUSStateTypedInput, setStoredUSStateTypedInput] = useState("");


  
  const getUnitedStates = async () => {
    let _unitedStates = await optionService.getUSStates();

    let unitedStatesNames = _unitedStates.map((state) => state.abbreviation + " - " + state.name);
    unitedStatesNames.push("");
    setUnitedStates(unitedStatesNames);

    let unitedStatesAbbvDict = {};
    let unitedStatesFullPathDict = {};
    _unitedStates.map((state) => 
    {
      unitedStatesAbbvDict[state.abbreviation] = state.abbreviation + " - " + state.name;
      unitedStatesFullPathDict[state.abbreviation + " - " + state.name] = state.abbreviation;
    });
    setUSAbbvDict(unitedStatesAbbvDict);
    setUSFullPathDict(unitedStatesFullPathDict);
    

    //prefil state selection based on previous selection if any exist
    if (props.fullMemberObj) {
      if (props.fullMemberObj?.state) {
        setUsStateSelected(unitedStatesAbbvDict[props.fullMemberObj.state]);
      } else setUsStateSelected("");
    }
  };

  // useEffect(() => {
  //   console.log(unitedStates, uSStateSelected)
  // }, [])

  // console.log("previously selected state", props.fullMemberObj?.state);
  // console.log("held in state - state", unitedStates);


  //handle local state of select options/value changes
  const handleStateInputChange = (e, value) => {
    console.log("handleStateInputChange", value);
    setStoredUSStateTypedInput(value.toUpperCase());
    setStateSelectionClickedOrArrowed(false);

    if(lastKeyPressedOverStateAutocomplete === "Unidentified" && value in unitedStatesAbbreviationDict)
    {
      setUsStateSelected(unitedStatesAbbreviationDict[value]);
    }
  };

  //handle updating the state selected in the member object
  const handleUsaChange = (e, value) => {
    setStoredUSStateSelectedInput(value);
    if(stateSelectionClickedOrArrowed)
    {
      setStateInputOpen(false);
    }
  };

  const handleStateTouch = (e) => {
    setStateSelectionClickedOrArrowed(true);
  }

  let lastKeyPressedOverStateAutocomplete = "";
  const handleStateKeyDown = (e) => {
    console.log("keyDown", e.key);
    lastKeyPressedOverStateAutocomplete = e.key;
    if(e.key === 'ArrowDown' || e.key === 'ArrowUp' )
    {
      setStateSelectionClickedOrArrowed(true);
    }
  }

  const handleStateBlur = (e, value) => {
    let storedValue = uSStateSelected;
    // if the user clicked, tapped, or arrow selected a state, then we will use that value
    if(stateSelectionClickedOrArrowed)
    {
      if(e.target.value != storedUSStateSelectedInput)
      {
        storedValue = e.target.value;
      }
      else
      {
        storedValue = storedUSStateSelectedInput;
      }

    }
    // Otherwise if we find a state code typed in, we will use that to determine the state we pick
    else if(storedUSStateTypedInput in unitedStatesAbbreviationDict)
    {
      storedValue = unitedStatesAbbreviationDict[storedUSStateTypedInput];
    }
    // Otherwise we'll to find the first state that matches the input and use that
    else if(storedUSStateTypedInput != "")
    {
      unitedStates.every(state => {
        if(state.toUpperCase().includes(storedUSStateTypedInput))
        {
          storedValue = state;
          return false;
        }
        return true;
      });
    }
    // If there was no input, or the input didn't match anything, storedValue should just eqaul usStateSelected
    props.setFullMemberObj({
      ...props.fullMemberObj,
      state: unitedStatesFullPathDict[storedValue],
    });
  
    setUsStateSelected(storedValue);
    setStoredUSStateTypedInput("");
    setStateSelectionClickedOrArrowed(false);
    genderErrorCheck();
  }

  const handleStateOpen = (e) => {
    //check if element is in view
    let element = e.target;
    let rect = element.getBoundingClientRect();
    let elemTop = rect.top;
    let elemBottom = rect.bottom;
    let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    if (isVisible) {
      setStateInputOpen(true);
    }
    // If Chrome autofilled this, we want to close the dropdown manually (since the element never actually gets focused)
    if(lastKeyPressedOverStateAutocomplete === "Unidentified")
    {
      setStateInputOpen(false);
    }
  }

  


  //Phones
  // let phoneMasker
  // const [phones, setPhones] = useState([])
  // const [primaryPhone, setPrimaryPhone] = useState()
  // const getPhones = async () => {
  //    let _phones = await memberService.getPhones()
  //    console.log('_phones from get',_phones)
  //    phoneMasker = document.getElementById('phone-mask')
  //    if (_phones[0] && phoneMasker) {
  //       // var maskOptions = {
  //       //    mask:"(#00) 000-0000",
  //       //    definitions:{
  //       //    '#': /[1-9]/,
  //       //    },
  //       //    onAccept:(value,mask) =>handlePhoneChange(value,mask),
  //       //    onComplete:(value,mask) =>handlePhoneComplete(value,mask),
  //       // };
  //       // var mask = IMask(phoneMasker, maskOptions);
  //       // mask.updateValue(_phones[0].number)
  //       setPrimaryPhone(_phones[0].number)
  //       // console.log('PHONELOAD',mask)
  //    }
  //    setPhones(_phones)
  // }

  //local state for rendering phone numbers in input
  const [primaryPhone, setPrimaryPhone] = useState({});
  const [secondaryPhone, setSecondaryPhone] = useState({});
  //   console.log('PHONE LIST', props.phones)

  // set primary and secondary phones to use and update input fields
  useEffect(() => {
    setPrimaryPhone(
      props.primaryPhoneFromWrapper ? props.primaryPhoneFromWrapper : {}
    );
    setSecondaryPhone(
      props.secondaryPhoneFromWrapper ? props.secondaryPhoneFromWrapper : {}
    );
  }, [props.primaryPhoneFromWrapper, props.secondaryPhoneFromWrapper]);

  //   console.log("PRIMARY", primaryPhone)
  //   console.log("SECONDARY", secondaryPhone)

  //////FULL SSN
  const [unmaskedSocial, setUnmaskedSocial] = useState("");
  const handleSSNChange = (val, mask) => {
    mask.updateValue(val);
  };

  const handleSSNComplete = (val, mask) => {
    mask.updateValue(val);
    let unmasked = mask._unmaskedValue;
    props.setFullMemberObj({
      ...props.fullMemberObj,
      social: unmasked,
    });
  };

  const handleSocialBlur = (e) => {
    // console.log(e.target.value);
    let value = e.target.value.replaceAll("-", "");
    // mask.updateValue(val);
    // let unmasked = mask._unmaskedValue;
    props.setFullMemberObj({
      ...props.fullMemberObj,
      social: value,
    });
  };

  /////// PRIMARY/MOBILE PHONE
  const [mobilePhoneLength, setMobilePhoneLength] = useState();

  const handleMobilePhoneChange = (val, mask) => {
    mask.updateValue(val);
    if (props.primaryPhoneFromWrapper.id) {
      let updateMobilePhone = {
        id: primaryPhone.id,
        memberId: memberInfo.id,
        phoneNumberTypeId: 1, // Mobile,home,work
        isPreferred: true,
        number: mask._unmaskedValue,
      };

      // props.setPrimaryPhoneFromWrapper(updateMobilePhone);
      //update local state
      // setPrimaryPhone({ ...primaryPhone, number: val });
    } else {
      //create new phone if one not in DB
      let insertMobilePhone = {
        memberId: memberInfo.id,
        phoneNumberTypeId: 1, // Mobile,home,work
        isPreferred: true,
        number: mask._unmaskedValue,
      };
      // props.setPrimaryPhoneFromWrapper(insertMobilePhone);

      //update local state
      // setPrimaryPhone({ ...primaryPhone, number: val });
    }
  };

  /////SECONDARY/HOME PHONE
  const [homePhoneLength, setHomePhoneLength] = useState();
  const handleHomePhoneChange = (val, mask) => {
    mask.updateValue(val);
    // console.log("hallo")
  };

  // Handle updating either primary or mobile phones onBlur event

  const handlePhoneComplete = (e) => {
    let enteredNumber = e.target.value;
    //remove mask symbols () and -
    let numericPhoneNumber = enteredNumber.replace(/\D/g, "");
    //mobile vs. home updates
    if (e.target.id === "mobile-phone-mask") {
      // console.log("mobile phone changed \n\n\n");
      //check length of number
      setMobilePhoneLength(numericPhoneNumber);
      //check if a primary mobile phone exists and add current ID of that phone
      if (props.primaryPhoneFromWrapper.id) {
        let updateMobilePhone = {
          id: primaryPhone.id,
          memberId: memberInfo.id,
          phoneNumberTypeId: 1, // Mobile,home,work
          isPreferred: true,
          number: numericPhoneNumber,
        };

        props.setPrimaryPhoneFromWrapper(updateMobilePhone);
        //update local state
        setPrimaryPhone({ ...primaryPhone, number: numericPhoneNumber });
      } else {
        //create new phone if one not in DB
        let insertMobilePhone = {
          memberId: memberInfo.id,
          phoneNumberTypeId: 1, // Mobile,home,work
          isPreferred: true,
          number: numericPhoneNumber,
        };
        props.setPrimaryPhoneFromWrapper(insertMobilePhone);

        //update local state
        setPrimaryPhone({ ...primaryPhone, number: numericPhoneNumber });
      }
    } else {
      // console.log("home phone changed \n\n\n");
      //check length of number for error messaging
      setHomePhoneLength(numericPhoneNumber);
      // check if a secondary mobile phone exists and add current ID of that phone
      if (props.secondaryPhoneFromWrapper?.id) {
        let updateHomePhone = {
          id: secondaryPhone.id,
          memberId: memberInfo.id,
          phoneNumberTypeId: 2, // home
          isPreferred: false,
          number: numericPhoneNumber,
        };
        props.setSecondaryPhoneFromWrapper(updateHomePhone);

        //update local state
        setSecondaryPhone({ ...secondaryPhone, number: numericPhoneNumber });
      } else {
        //create new phone
        let insertHomePhone = {
          memberId: memberInfo.id,
          phoneNumberTypeId: 2, // home
          isPreferred: false,
          number: numericPhoneNumber,
        };

        props.setSecondaryPhoneFromWrapper(insertHomePhone);

        //update local state
        setSecondaryPhone({ ...secondaryPhone, number: numericPhoneNumber });
      }
    }
  };

  //    let noCountryMatch = str.match(/^(\d{3})(\d{3})(\d{4})$/);
  //    // let countryMatch = str.match(/^(\d{1-3})(\d{3})(\d{3})(\d{4})$/);

  //    if (noCountryMatch) {
  //       return `(${noCountryMatch[1]}) ${noCountryMatch[2]}-${noCountryMatch[3]}`
  //    }
  //    // else if (countryMatch) {
  //    //   return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
  //    // }
  //    return str
  // };

  const FullSSNMask = IMaskMixin(({ ref, ...props }) => (
    // <TextField
    //   {...props}
    //   ref={ref}
    //   className="col-6"
    //   key={"mobilePhone mask"}
    //   autoComplete="tel-national"
    //   type={"tel"}
    //   label="Mobile Number"
    //   variant="outlined"
    //   defaultValue={primaryPhone ? primaryPhone.number : ""}
    //   // value={primaryPhone}
    // />
    <TextField
      {...props}
      sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
      fullWidth
      required
      ref={socialRef}
      error={memberInfo.social?.length < 9}
      helperText={
        memberInfo.social?.length < 9 && "Please enter a valid 9-digit SSN"
      }
      key={"fullSSNMASK"}
      label="SSN"
      variant="outlined"
      placeholder="XXX-XX-XXXX"
      defaultValue={memberInfo.social ? memberInfo.social : ""}
      onBlur={handleSocialBlur}
      InputProps={{
        type: "tel",
      }}
      inputProps={{
        "data-update": "social",
      }}
    />
  ));

  const handlePhoneAutofill = (e) => {
    //13 for full value, 10 for just numbers
    let value = e.target.value;
    let numericPhoneNumber = value.replace(/\D/g, "");
    //we have a full phone number
    if (
      numericPhoneNumber?.length == 10 &&
      numericPhoneNumber !== props.primaryPhoneFromWrapper?.number
    ) {
      handlePhoneComplete(e);
    }
    // console.log(numericPhoneNumber.length)
    // console.log('input change in textfield for mobile phone', props.primaryPhoneFromWrapper, e.target.value,  e.target.value?.length)
  };

  const MobilePhoneMask = IMaskMixin(({ ref, ...props }) => (
    <TextField
      {...props}
      ref={ref}
      className="col-6"
      key={"mobilePhone mask"}
      autoComplete="tel-national"
      type={"tel"}
      label="Mobile Number"
      variant="outlined"
      defaultValue={primaryPhone?.number || ""}
      onInput={handlePhoneAutofill}
    // value={primaryPhone}
    />
  ));

  const HomePhoneMask = IMaskMixin(({ ref, ...props }) => (
    <TextField
      {...props}
      ref={ref}
      className="col-6"
      key={"homePhone mask"}
      autoComplete="tel-national"
      type={"tel"}
      label="Home Number"
      variant="outlined"
      defaultValue={secondaryPhone?.number || ""}
      onInput={handlePhoneAutofill}
    // value={primaryPhone}
    />
  ));

  //workStatus
  const handleWorkStatusChange = (e) => {
    let newId = e.target.getAttribute("value");
    console.log('new work',e.target)
    let nawReason = workStatuses.find(
      (workStatus) => workStatus.id == newId
    )?.name;
    // console.log(newId)
    genderErrorCheck();
    nawErrorCheck();
    props.setFullMemberObj({
      ...props.fullMemberObj,
      workStatusId: newId,
      workStatus: nawReason,
    });

  };

  //Local AutoComplete Logic
  const [localObj, setLocalObj] = useState({});

  const handleLocalChange = (e, newValue) => {
    // console.log('handle local change', e, newValue)
    if (newValue?.id) {
      setMemberOrganization(newValue);
      props.setFullMemberObj({
        ...props.fullMemberObj,
        organizationId: newValue.id,
      });
    } else {
      // console.log("hitting the else")
      setMemberOrganization({});
    }
  };

  //HANDLE MEMBER ORGANIZATION INFO
  const [memberOrganization, setMemberOrganization] = useState({});
  const [localOpen, setLocalOpen] = useState(false);
  const handleLocalOpen = (e) => {
    // if (e.target.value && e.target.value.length > 0) {
    //    setLocalOpen(true)
    // }
    setLocalOpen(true);
  };
  const [organizations, setOrganizations] = useState([]);
  const [secondLevelOrganizations, setSecondLevelOrganizations] = useState([]);
  const [thirdLevelOrganizations, setThirdLevelOrganizations] = useState([]);

  const localLoading = localOpen && organizations.length === 0;

  const getOrganizations = async () => {

    let _organizations = await organizationService.getOrganizations();

    setOrganizations(_organizations);
  };

  useEffect(() => {
    //if the member has an organizationID and the organizations array has been populated, set the memberOrganization local state
    if (props?.fullMemberObj?.organizationId && organizations?.length > 0) {
      console.log('orgId ON MEMBER', props?.fullMemberObj?.organizationId)
      //fing fullMemberObj organization in organizations array based off of id listed in member object
      let firstOrg = organizations?.find(
        (org) => org?.id == props?.fullMemberObj?.organizationId
      );
      let secondOrg = organizations?.find(
        (org) => org?.id == firstOrg?.parentId
      );
      let thirdOrg = organizations?.find(
        (org) => org?.id == secondOrg?.parentId
      );

      //if only one org exists
      if (firstOrg && !firstOrg?.parentId) {
        setMemberOrganization({
          topParentOrganizationId: firstOrg?.id,
          organizationId: "",
          lowestOrganizationId: "",
        });
      }

      //if top and middle org exists
      if (firstOrg && secondOrg && !secondOrg?.parentId && !thirdOrg) {
        setMemberOrganization({
          topParentOrganizationId: secondOrg?.id,
          organizationId: firstOrg?.id,
          lowestOrganizationId: "",
        });
      }

      //if all orgs exist (first org listed in member object is actually the deepest/lowest org - we find all the rest up the pyramid based on this)
      if (firstOrg && secondOrg && thirdOrg && !thirdOrg?.parentId) {
        setMemberOrganization({
          topParentOrganizationId: thirdOrg?.id,
          organizationId: secondOrg?.id,
          lowestOrganizationId: firstOrg?.id,
        });
      }
    } else {
      setMemberOrganization({
        topParentOrganizationId: "",
        organizationId: "",
        lowestOrganizationId: "",
      });
    }
  }, [organizations, props?.fullMemberObj?.organizationId]);
  useEffect(() => {
    if (memberOrganization.topParentOrganizationId > 0) {
      let _secondLevelOrganizations = organizations.filter(
        (org) => org.parentId == memberOrganization.topParentOrganizationId
      );
      let _thirdLevelOrganizations = organizations.filter(
        (org) => _secondLevelOrganizations.some((second) => second.id == org.parentId)
      );


      setSecondLevelOrganizations(_secondLevelOrganizations);
      setThirdLevelOrganizations(_thirdLevelOrganizations);
    }
  }, [memberOrganization?.topParentOrganizationId]);

  const handleOrganizationInformation = (e, property) => {
    let orgId = e.target.value;
    let newOrg = organizations?.find((org) => org?.id === orgId);

    if (property == "topParentOrganizationId") {
      setMemberOrganization({
        ...memberOrganization,
        [property]: orgId,
        organizationId: "",
        lowestOrganizationId: "",
      });
    } else if (property == "organizationId") {
      setMemberOrganization({
        ...memberOrganization,
        [property]: orgId,
        lowestOrganizationId: "",
      });
    } else {
      setMemberOrganization({
        ...memberOrganization,
        [property]: orgId,
      });
    }

    //set global state for memberOrg held in memberProfile if it differs from current
    if (orgId != props?.fullMemberObj?.organizationId) {
      //update local state of member info
      props?.setFullMemberObj({
        ...props?.fullMemberObj,
        organizationId: orgId,
        organizationName: newOrg?.name,
      });
    }
  };

  const handleHasChildren = (id) => {
    let hasChildren = false
    let disabledCount = 0

    // Finds any children to the organization ID passed in
    let children = organizations?.filter(
      (organization) => organization?.parentId == id
    );

    if (children) {
      // If has results, initial set to true
      hasChildren = true

      // console.log(children)

      // Check if the child options are disabled
      children?.length > 0 && children?.filter(child => {
        if (child.disableSelect == true) {
          disabledCount++
        }
      })

      // If all children are disabled, set to false
      if (disabledCount == children?.length) {
        hasChildren = false
      }
    }

    return hasChildren
  };

  //Employer AutoComplete Logic
  const [employerObj, setEmployerObj] = useState({});
  const handleEmployerChange = (e, newValue) => {
    // console.log('handle employer change', e, newValue)
    nawErrorCheck();
    if (newValue?.id) {
      if (useJobTrade){
        setEmployerObj(newValue);
        props.setFullMemberObj({
          ...props.fullMemberObj,
          employerId: newValue.id,
          jobTitle: newValue.name,
        });
        setMemberInfo({
          ...memberInfo,
          jobTitle: newValue.name,
        });
      } else {
      setEmployerObj(newValue);
      props.setFullMemberObj({
        ...props.fullMemberObj,
        employerId: newValue.id,
      });
      }
    } else {
      // console.log("hitting the else")
      setEmployerObj({});
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    // if (e.target.value && e.target.value.length > 0) {
    //    setOpen(true)
    // }
    setOpen(true);
  };

  const [employers, setEmployers] = useState([]);
  useEffect(() => {
    if (memberInfo.employerId && employers) {
      let censusEmployer = employers.find(
        (employer) => employer.id == memberInfo.employerId
      );
      // console.log(censusEmployer)
      if (censusEmployer) setEmployerObj(censusEmployer);
    }
  }, [memberInfo?.employerId, employers]);

  const loading = open && employers.length === 0;

  const getEmployers = async (orgId) => {
    let _employers =[];
    // console.log('member employer org name', memberInfo?.organizationName)
    //for BLET only, HARDCODE organizationId=18 to require specific employer list
    if (memberInfo?.organizationName?.includes("BLET" || "blet")) {
      _employers = await organizationService.getEmployersByOrganization(18);
    } else {
      _employers = await organizationService.getEmployersByOrganization(orgId);
    }

    //try second call if no employers returned with first - this call uses parent org Id on back end to find all associated employers with parent org
    if (_employers?.length === 0) {
      try {
        _employers = await organizationService.getAffiliatedEmployersByOrganization(orgId)
      } catch (error) {
        console.error('error getting affiliated employers')
      }
    }

    if(_employers?.length > 0){
      //sort employers alphabetically 
      let sortedEmployers = _employers?.sort((a, b) => {
        const nameA = a.name.replace(/"/g, '').toLowerCase(); // Remove quotes and convert to lowercase
        const nameB = b.name.replace(/"/g, '').toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      setEmployers(sortedEmployers);
    } else {
      setEmployers (_employers)
    }
  };

  useEffect(() => {
    if (props.fullMemberObj) getEmployers(props.fullMemberObj?.organizationId);
  }, [props.fullMemberObj?.organizationId]);

  // useEffect(()=>{
  //    if (!loading) return undefined
  //    else getEmployers()
  // },[loading])

  // const handleNextClick = async () => {
  //    // let groupPlanId = history.location.state.currentGroupPlanId
  //    // console.log(history.location.state)
  //    await memberService.updateMember(memberInfo)
  //    // handlePhoneInsert()
  //    history.push('payment')
  // }

  const [missingFName, setMissingFName] = useState(false);

  useEffect(() => {
    if (memberInfo.firstName == "" || memberInfo.firstName == null) {
      setMissingFName(true);
    }
  }, []);

  // Not Actively Working Logic
  const [showNaw, setShowNaw] = useState(false);

  const [nawError, setNawError] = useState(false);
  const nawErrorCheck = () => {
    if (memberInfo.workStatusId == 0) setNawError(true);
    else setNawError(false);
  };

  useEffect(() => {
    if (props.fullMemberObj.workStatusId > 0 && props.fullMemberObj.workStatusId != 1) {
      setShowNaw(true);
    } else {
      setShowNaw(false);
    }
  }, [props.fullMemberObj?.workStatusId]);

  const [returnToWorkDate, setReturnToWorkDate] = useState('')

  //set local state of return to work date and format if needed
  useEffect(() => {
    if (memberInfo?.returnToWork) {
      //check if utc time from backend and format
      if (memberInfo?.returnToWork?.length > 10) {
        let formattedDate = formatDate(memberInfo?.returnToWork)
        setReturnToWorkDate(formattedDate)
      }else{
        setReturnToWorkDate(memberInfo?.returnToWork)
      }
    }
  }, [memberInfo?.returnToWork])


  const handleRtwChange = (e) => {
    let inputVal = e;
    genderErrorCheck();
    // setReturnToWorkDate(inputVal)
    props.setFullMemberObj({
      ...props.fullMemberObj,
      returnToWork: inputVal,
    });
    setMemberInfo({
      ...memberInfo,
      returnToWork: inputVal,
    });

  };
  console.log('returnToWorkDate', returnToWorkDate, memberInfo.returnToWork, props.fullMemberObj?.returnToWork)

  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;
  let dateMax = `${year - 18}-${month}-${day}`;
  let dateMaxObj = new Date(dateMax);
  let dateMin = `${year}-${month}-${day}`;
  let dateMinObj = new Date(dateMin);
  // Needs Error Messaging if date is not in the future

  const handleRtwFocus = (e) => {
    e.target.setAttribute("type", "date");
  };

  const handleRtwBlur = (e) => {
    // let dateWithinRange = ((new Date(inputVal).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(inputVal).getFullYear() <= new Date(dateMax).getFullYear()))
    // if (!dateWithinRange){
    // set error message
    // }
    if (!returnToWorkDate) {
      e.target.setAttribute("type", "")
    } else {
      // setReturnToWorkDate('')
      props.setFullMemberObj({
        ...props.fullMemberObj,
        returnToWork: returnToWorkDate,
      });
    }
  };


  // console.log(props)
  // Address Auto Complete

  const [options, setOptions] = useState([]);
  const previousController = useRef();

  let locationApi = "api/location?query=";
  const [addressValue, setAddressValue] = useState(options[0]);

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    try {
      fetch(locationApi + searchTerm, {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          setOptions(myJson);
        });
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted - Addresslookup");
      } else {
        console.error("Fetch error:", error);
      }
    }
  };

  const onInputChange = (event, value, reason) => {
    if (value && value.length > 2) {
      getData(value);
    } else {
      setOptions([]);
    }
  };

  const handleStreet1 = (e) => {
    let value = e.target.value;
    // If they keep typing and change it, save what they enter
    setMemberInfo({
      ...memberInfo,
      street1: value,
    });
    // console.log(memberInfo)
  };

  // useEffect(() => {
  //   if (addressValue?.street1 != null) {
  //     setMemberInfo({
  //       ...memberInfo,
  //       city: addressValue?.city,
  //       state: addressValue?.state,
  //       street1: addressValue?.street1,
  //       zipCode: addressValue?.zipCode,
  //     });
  //   }
  // }, [addressValue?.street1]);

  const [cdlAnswer, setCdlAnswer] = useState(null);
  const handleCdlChange = (e) => {
    let newId = e.target.getAttribute("value");
    setCdlAnswer(newId);
    saveAnswer(newId);
    // props.setFullMemberObj({
    //    ...props.fullMemberObj,
    //    workStatusId: newId
    // })
  };

  const saveAnswer = async (cdlAnswer) => {
    let answers = [];
    let bool = cdlAnswer == 1 ? true : false;
    answers.push({ questionId: 11, isYes: bool, response: "" });
    // console.log('answers',answers)
    if (props.agentEnroller) {
      try {
        await questionService.saveAnswersForMember(
          props.fullMemberObj.id,
          answers
        );
      } catch (error) {
        console.log("error saving answers AGENT", error);
      }
    } else {
      try {
        await questionService.saveAnswers(answers);
      } catch (error) {
        console.log("error saving answers MEMBER", error);
      }
    }
  };
  // useEffect(() => {
  //   saveAnswer();
  // }, [cdlAnswer]);

  const [questionAnswers, setQuestionAnswers] = useState([]);
  const getQuestionAnswers = async () => {
    let answers = [];
    if (props.agentEnroller) {
      try {
        answers = await questionService.getAnswersForMember(
          props.fullMemberObj.id
        );
      } catch (error) {
        console.log("error getting question answers AGENT", error);
      }
    } else {
      try {
        answers = await questionService.getAnswers();
      } catch (error) {
        console.log("error getting question answers MEMBER", error);
      }
    }
    setQuestionAnswers(answers);
  };

  useEffect(() => {
    if (props.fullMemberObj) getQuestionAnswers();
  }, [props.fullMemberObj?.id]);

  useEffect(() => {
    if (questionAnswers.length > 0) {
      let _cdlAnswer = null;
      for (let answerObj of questionAnswers) {
        if (answerObj.questionId == 11) _cdlAnswer = answerObj.isYes ? 1 : 0;
      }
      setCdlAnswer(_cdlAnswer);
    }
  }, [questionAnswers]);

  useEffect(() => {
    //check if a plan was elected that has require9DigitSocial set to true

    if (props.agentEnroller) {
      if (
        permissionsEnabler &&
        permissionsEnabler.viewFullSocial &&
        props.fullSocial?.social
      ) {
        props.setFullMemberObj({
          ...props.fullMemberObj,
          social: props.fullSocial.social,
        });
      }
    } else if (props.fullSocial?.social) {
      props.setFullMemberObj({
        ...props.fullMemberObj,
        social: props.fullSocial.social,
      });
    }
  }, [props.fullSocial, permissionsEnabler]);

  // const [require9DigitSocial, setRequire9DigitSocial] = useState(false)
  // useEffect(() => {
  //   let plans = []
  //   for (let [key, value] of Object.entries(props.results)) {
  //     if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
  //       plans.push(value)
  //     }
  //   }
  //   let _require9DigitSocial = false
  //   for (let plan of plans) {
  //     //check for user selection (decline or otherwise)
  //     //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
  //     if (plan?.totalPremiumAmount > 0){
  //       if (plan.require9DigitSocial) _require9DigitSocial = true
  //     }
  //     else if (plan.selectedOptionId != 0 && plan?.options && plan.options.length > 0) {
  //       for (let option of plan.options) {
  //         //check that the selected option isnt a decline
  //         if (option.isDecline && option.memberOptionId != plan.selectedOptionId) {
  //           if (plan.require9DigitSocial) _require9DigitSocial = true
  //         }
  //       }

  //     }
  //   }
  //   setRequire9DigitSocial(_require9DigitSocial)
  //   props.setRequire9DigitSocial(_require9DigitSocial)
  // }, [props.results])
  // useEffect(() => {
  //   if (require9DigitSocial) props.getFullSocial()
  // }, [require9DigitSocial])
  // console.log('require9DigitSocial', require9DigitSocial)

  // define your refs
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const socialRef = useRef(null);
  const genderRef = useRef(null);
  const street1Ref = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const zipCodeRef = useRef(null);
  const mobilePhoneRef = useRef(null);
  const workStatusRef = useRef(null);
  const jobTitleRef = useRef(null);

  // scroll into view
  const highestError = () => {
    let ref;
    if (!props.personalFields?.firstName) {
      firstNameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.lastName) {
      lastNameRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
      // Need to finish defining refs for the following fields
    } else if (!props.personalFields?.social) {
      socialRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.gender) {
      genderRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.street1) {
      street1Ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.city) {
      cityRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.state) {
      stateRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.zipCode) {
      zipCodeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.mobilePhone) {
      mobilePhoneRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.workStatus) {
      workStatusRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    } else if (!props.personalFields?.jobTitle) {
      jobTitleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const buttonErrorStyles = {
    height: 50,
    borderColor: "error.main",
    backgroundColor: "#ef535008",
    color: "error.main",
    fontWeight: "700",
    "&.Mui-disabled.MuiButton-root": {
      borderColor: "error.main",
      backgroundColor: "#ef535008",
      color: "error.main",
      border: "1px solid",
    },
  };

  const buttonStyles = {
    height: 50,
    borderColor: "primary.main",
    fontWeight: "700",
    color: "primary.main",
    backgroundColor: "null",
    "&.Mui-disabled.MuiButton-root": {
      color: "primary.main",
      backgroundColor: "white",
      border: "1px solid",
      borderColor: "primary.main",
    },
  };

  // Triggered by clicking Next, if validation fails timeout is set to scroll to highest
  useEffect(() => {
    if (props.scrollIntoView && props.showErrors && props.personalFields) {
      highestError();
    }
  }, [props.scrollIntoView, props.showErrors, props.personalFields]);

  const formatDate = (rawDate) => {
    if (rawDate){
      // console.log('formatDate',rawDate)
      if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
      let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
      let date = new Date();
      date.setUTCFullYear(+yyyy);
      date.setUTCDate(+dd);
      date.setUTCMonth(mm - 1);
      date.setUTCHours(+hh);
      date.setUTCMinutes(+m + date.getTimezoneOffset());
      date.setUTCSeconds(+s);
      // console.log('formatDate after',date)
      date = format(date, "yyyy-MM-dd");
      return date;
    }
  };

  const [nawDrawerOpen, setNawDrawerOpen] = useState(false);
  const [CDLDrawerOpen, setCDLDrawerOpen] = useState(false);

  let useJobTrade = props.results?.name.includes('IUPAT')
  let onlyShowLocals = props.results?.name.includes('BLET')

  

  return (
    <>
      <style></style>
      <div
        style={{
          overflowY: "auto",
          height: props.agentEnroller ? "calc(100vh - 276px)" : "auto",
        }}
      >
        <div
          className="flex-container"
          style={{
            height: "calc(100vh - 160px)",
            maxWidth: props.agentEnroller ? "100%" : "600px",
            margin: props.agentEnroller ? "" : "0 auto",
            padding: props.agentEnroller ? "20px" : "30px",
            width: "100%",
            overflowY: "none",
          }}
        >
          {!props.agentEnroller && (
            <ExternalEnrollmentHeader
              fullMemberObj={props.thisMember}
              agentEnroller={props.agentEnroller}
              selectedEnrollment={props.results}
              basicHeader={true}
            />
          )}

        {props.agentEnroller && (
          <>
            <div
              className="flex-row"
              style={{
                textAlign: "left",
                // paddingLeft: "5px",
                marginTop: "5px",
                marginBottom: "0px",
                marginLeft: "-7px",
              }}
            >
              <Typography variant="h5" fontFamily={"Poppins"}>
                Member Information
              </Typography>
            </div>

            <div
              className="flex-row"
              style={{
                textAlign: "left",
                // paddingLeft: "5px",
                marginTop: "5px",
                // marginBottom: "38px",
                marginLeft: "-7px",
              }}
            >
              <Typography variant="body2" color="text.gray">
                Provide the members personal information.
              </Typography>
            </div>
          </>
        )}

        <div
          className="flex-row"
          style={{ marginBottom: "17px", marginTop: "17px" }}
        >
          <Typography
            variant="subtitle3"
            fontWeight="600"
            color="text.primary"
          >
            Your Information
          </Typography>
        </div>
        <>
          <div className="flex-row" style={{ marginBottom: "15px" }}>
            <TextField
              sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
              fullWidth
              required
              error={props.showErrors && !props.personalFields.firstName}
              label="Legal First Name"
              variant="outlined"
              value={props.fullMemberObj?.firstName || ""}
              helperText={
                props.showErrors &&
                !props.personalFields.firstName &&
                "First Name is required."
              }
              ref={firstNameRef}
              onChange={handleInputChange}
              inputProps={{
                "data-update": "firstName",
              }}
            />
          </div>

          {addPreferredName || memberInfo.preferredName ? (
            <div className="flex-row" style={{ marginBottom: "15px" }}>
              <TextField
                sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
                fullWidth
                label="Preferred Name"
                variant="outlined"
                value={
                  memberInfo.preferredName ? memberInfo.preferredName : ""
                }
                onChange={handleInputChange}
                inputProps={{
                  "data-update": "preferredName",
                }}
              />
            </div>
          ) : (
            <div className="flex-row" style={{ marginBottom: "15px" }}>
              <Button
                variant="text"
                sx={{ height: 50 }}
                onClick={() => setAddPreferredName(true)}
              >
                + add preferred name
              </Button>
            </div>
          )}
          <div className="flex-row" style={{ marginBottom: "20px" }}>
            <TextField
              sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
              fullWidth
              required
              error={props.showErrors && !props.personalFields.lastName}
              helperText={
                props.showErrors &&
                !props.personalFields.lastName &&
                "Last Name is required."
              }
              ref={lastNameRef}
              label="Last Name"
              variant="outlined"
              value={memberInfo.lastName ? memberInfo.lastName : ""}
              onChange={handleInputChange}
              inputProps={{
                "data-update": "lastName",
              }}
            />
          </div>

          <div className="flex-row" style={{ marginBottom: "15px" }}>
            {!props.require9DigitSocial && (
              <TextField
                sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
                fullWidth
                required
                error={props.showErrors && !props.personalFields.social}
                helperText={
                  props.showErrors &&
                  !props.personalFields.social &&
                  "Social Security Number is required."
                }
                ref={socialRef}
                label="last 4 digits of SSN"
                variant="outlined"
                value={memberInfo.social ? memberInfo.social.slice(-4) : ""}
                onChange={(e) =>
                  e.target.value?.length <= 4 ? handleInputChange(e) : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">XXX-XX-</InputAdornment>
                  ),
                  type: "tel",
                }}
                inputProps={{
                  "data-update": "social",
                }}
              />
            )}
            {props.require9DigitSocial && (
              <FullSSNMask
                id="ssn-mask"
                required
                key={"ssn mask wrap"}
                mask="000-00-0000"
                definitions={{
                  "#": /[1-9]/,
                }}
                onAccept={(value, mask) => handleSSNChange(value, mask)}
                onComplete={(value, mask) => handleSSNComplete(value, mask)}
                overwrite
                // autoComplete="tel-national"
                type="tel"
              />
            )}
          </div>
        </>

        <div
          className="flex-row"
          style={{ marginBottom: "17px", justifyContent: "space-between" }}
        >
          <Typography
            variant="subtitle3"
            fontWeight="500"
            sx={{
              color:
                props.showErrors && !props.personalFields?.gender
                  ? "error.main"
                  : "text.primary",
            }}
          >
            Gender *
          </Typography>

          {/* <Chip label='Required' sx={{ justifyContent: 'end', color: genderError ? '#D32F2F' : '', backgroundColor: genderError ? '#D32F2F1F' : '', fontWeight: 500, height: '26px' }} icon={genderError ? <ErrorIcon sx={{ fill: '#D32F2F' }} /> : null} /> */}
        </div>
        <RadioGroup
          row
          sx={{
            flexWrap: "wrap",
            fontFamily: "Poppins",
            marginBottom: "30px",
            gap: "0px 15px",
          }}
          value={gender}
          onChange={handleGenderChange}
          ref={genderRef}
        >
          <Radio
            className="col"
            sx={{ padding: 0, borderRadius: "0px" }}
            value={1}
            icon={
              <Button
                variant="outlined"
                fullWidth
                sx={
                  props.showErrors && !props.personalFields?.gender
                    ? buttonErrorStyles
                    : buttonStyles
                }
              >
                Male
              </Button>
            }
            checkedIcon={
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: 50,
                  border: "1px solid",
                  borderColor: "primary.main",
                  fontWeight: "700",
                }}
              >
                Male
              </Button>
            }
          />

          <Radio
            className="col"
            sx={{ padding: 0, borderRadius: "0px" }}
            value={2}
            icon={
              <Button
                variant="outlined"
                fullWidth
                sx={
                  props.showErrors && !props.personalFields?.gender
                    ? buttonErrorStyles
                    : buttonStyles
                }
              >
                Female
              </Button>
            }
            checkedIcon={
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: 50,
                  border: "1px solid",
                  borderColor: "primary.main",
                  fontWeight: "700",
                }}
              >
                Female
              </Button>
            }
          />

          <Radio
            className="col"
            sx={{ padding: 0, borderRadius: "0px" }}
            value={3}
            icon={
              <Button
                variant="outlined"
                fullWidth
                sx={
                  props.showErrors && !props.personalFields?.gender
                    ? buttonErrorStyles
                    : buttonStyles
                }
              >
                Other
              </Button>
            }
            checkedIcon={
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: 50,
                  border: "1px solid",
                  borderColor: "primary.main",
                  fontWeight: "700",
                }}
              >
                Other
              </Button>
            }
          />
          <div
            className="row"
            style={{ display: "contents", textAlign: "right" }}
          >
            {" "}
            <FormHelperText
              hidden={!(props.showErrors && !props.personalFields?.gender)}
              error={true}
              sx={{ marginTop: "3px", paddingRight: "14px" }}
              fontSize="0.75rem"
            >
              Gender is required.
            </FormHelperText>
          </div>
        </RadioGroup>

        {/* <div className='flex-row' style={{ marginBottom: '10px' }}>
                <Autocomplete
                   id="address-autocomplete"
                   freeSolo
                   fullWidth
                   required
                   options={options}
                   defaultValue={{ street1: memberInfo.street1 ? memberInfo.street1 : '', id: 1, freeFormAddress: memberInfo.street1 ? memberInfo.street1 : '' }}
                   onInputChange={onInputChange}
                   getOptionLabel={(option) => option.street1}
                   renderOption={(props, option) => {
                      return (
                         <li {...props} key={option.id ?? 0}>
                            {option.freeFormAddress}
                         </li>
                      );
                   }}
                   renderInput={(params) => (
                      <TextField required {...params} variant='outlined' size="medium" label='Address' fullWidth id='street1'
                         sx={{ marginBottom: '7px', }} onChange={handleStreet1}
                      // inputProps={{
                      //    'data-update': 'street1'
                      // }}
                      />
                   )}
                   onChange={(event, newValue) => {
                      if (newValue?.street1 != null) {
                         setAddressValue(newValue);

                      }
                      console.log("clicky")
                   }}
                />
             </div> */}

        <div className="flex-row" style={{ marginBottom: "10px" }}>
          <TextField
            sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
            fullWidth
            required
            error={props.showErrors && !props.personalFields.street1}
            helperText={
              props.showErrors &&
              !props.personalFields.street1 &&
              "Address is required."
            }
            ref={street1Ref}
            label="Address"
            variant="outlined"
            autoComplete="address-line1"
            value={memberInfo.street1 ? memberInfo.street1 : ""}
            onChange={handleInputChange}
            inputProps={{
              "data-update": "street1",
            }}
          />
        </div>
        <div
          className="flex-row"
          style={{
            marginBottom: "25px",
            display: addSecondAddress ? "" : "none",
          }}
        >
          <TextField
            sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
            fullWidth
            label="Address line 2"
            variant="outlined"
            autoComplete="address-line2"
            value={memberInfo.street2 ? memberInfo.street2 : ""}
            onChange={handleInputChange}
            inputProps={{
              "data-update": "street2",
            }}
          />
        </div>
        <div
          className="flex-row"
          style={{
            marginBottom: "25px",
            display: !addSecondAddress ? "" : "none",
          }}
        >
          {/* <Link underline='none' sx={{ fontSize: '15px', color: '#195FFB' }}
                  onClick={() => setAddSecondAddress(true)}
              >
                  + add address line 2
              </Link> */}
          <Button
            variant="text"
            sx={{ height: 50 }}
            onClick={() => setAddSecondAddress(true)}
          >
            + add address line 2
          </Button>
        </div>

        <div className="flex-row" style={{ marginBottom: "15px" }}>
          <TextField
            sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
            fullWidth
            required
            error={props.showErrors && !props.personalFields.city}
            helperText={
              props.showErrors &&
              !props.personalFields.city &&
              "City is required."
            }
            ref={cityRef}
            label="City"
            variant="outlined"
            value={memberInfo.city ? memberInfo.city : ""}
            onChange={handleInputChange}
            inputProps={{
              "data-update": "city",
            }}
          />
        </div>

        <div
          className="flex-row"
          style={{
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "15px",
            marginBottom: "15px",
          }}
        >
          <Box className="col" onClick={handleStateTouch}>
            <Autocomplete
              className="col"
              options={unitedStates}
              open={stateInputOpen}
              onOpen={handleStateOpen}
              // onOpen={() => setStateInputOpen(true)}
              // onClose={() => setStateInputOpen(false)}
              autoComplete
              autoHighlight
              autoSelect
              onInputChange={handleStateInputChange}
              onBlur={handleStateBlur}
              // onFocus={handleStateFocus}
              value={uSStateSelected}
              onChange={handleUsaChange}
              onKeyDown={handleStateKeyDown}
              renderInput={(params, index) => (
                <>
                  <TextField
                    sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
                    {...params}
                    key={index}
                    required
                    label="State"
                    error={props.showErrors && !props.personalFields.state}
                    helperText={
                      props.showErrors &&
                      !props.personalFields?.state &&
                      "State is required."
                    }
                    ref={stateRef}
                  />
                </>
              )}
            />
          </Box>

          <TextField
            sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
            label="Zip"
            className="col"
            required
            error={props.showErrors && !props.personalFields.zipCode}
            helperText={
              props.showErrors &&
              !props.personalFields?.zipCode &&
              "ZipCode is required."
            }
            ref={zipCodeRef}
            autoComplete="postal-code"
            // type="number"
            value={memberInfo.zipCode ? memberInfo.zipCode : ""}
            onChange={handleInputChange}
            inputProps={{
              "data-update": "zipCode",
            }}
          />
        </div>

        <div
          className="flex-row"
          style={{
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "15px",
            marginBottom: "15px",
          }}
          ref={mobilePhoneRef}
        >
          {/* Mobile Phone - required */}
          <MobilePhoneMask
            id="mobile-phone-mask"
            required
            key={"mobile phone mask wrap"}
            mask="(#00)000-0000"
            definitions={{
              "#": /[1-9]/,
            }}
            onAccept={(value, mask) => handleMobilePhoneChange(value, mask)}
            // onComplete={(value, mask) =>
            //   handleMobilePhoneComplete(value, mask)
            // }
            onBlur={(e) => {
              handlePhoneComplete(e);
            }}
            error={props.showErrors && !props.personalFields.mobilePhone}
            helperText={
              props.showErrors &&
              !props.personalFields.mobilePhone &&
              "Mobile Phone is required."
            }
            overwrite
            autoComplete="tel-national"
            type="tel"
            // sx={{ flex: "1" }}
            sx={{ flex: '1', '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
          />

          {/* <TextField label='Mobile' className="col-5"
                InputProps={{ readOnly: true }} InputLabelProps={{ shrink: false }} /> */}

          {/* <FormControl required className="col-5">
            <InputLabel id="personal-info-phone-type">Type</InputLabel>
            <Select
              labelId="personal-info-phone-type"
              label="Type"
              value={0}
              disabled
            >
              <MenuItem key={0} value={0}>
                Mobile
              </MenuItem>
            </Select>
          </FormControl> */}

          {/* Home Phone - not required */}
          <HomePhoneMask
            id="home-phone-mask"
            key={"home phone mask wrap"}
            mask="(#00)000-0000"
            definitions={{
              "#": /[1-9]/,
            }}
            onAccept={(value, mask) => handleHomePhoneChange(value, mask)}
            // onComplete={(value, mask) => handleHomePhoneComplete(value, mask)}
            onBlur={(e) => handlePhoneComplete(e)}
            error={homePhoneLength && homePhoneLength?.length < 10}
            overwrite
            autoComplete="tel-national"
            type="tel"
            sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
          />

          {/* <TextField label='Mobile' className="col-5"
                InputProps={{ readOnly: true }} InputLabelProps={{ shrink: false }} /> */}

          {/* <FormControl className="col-5">
            <InputLabel id="personal-info-phone-type">Type</InputLabel>
            <Select
              labelId="personal-info-phone-type"
              label="Type"
              value={0}
              disabled
            >
              <MenuItem key={0} value={0}>
                Home
              </MenuItem>
            </Select>
          </FormControl> */}
        </div>
        {props.agentEnroller && (
          <div className="flex-row" style={{ marginBottom: "15px" }}>
            <TextField
              sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' } }}
              fullWidth
              required
              // error={props.showErrors && !props.personalFields.city}
              // helperText={props.showErrors && !props.personalFields.city && 'City is required.'}
              label="Email Address"
              variant="outlined"
              value={memberInfo.email ? memberInfo.email : ""}
              onChange={handleInputChange}
              inputProps={{
                "data-update": "email",
              }}
            />
          </div>
        )}

        {/* TODO: SET UP SECONDARY PHONE API */}
        {/* <div className="flex-row" style={{ marginBottom: '35px' }}>
             <Button variant="text" sx={{height:50}}>+ add a secondary phone</Button>
          </div> */}

        <div className="flex-row" style={{ margin: "30px 0px 15px 0px" }}>
          <Typography
            variant="subtitle3"
            fontWeight="600"
            color="text.primary"
          >
            Union/Employment Information
          </Typography>
        </div>

        {/* if this is a BLET enrollment, give option to select organization locals, otherwise, hide this section */}
        <div className="col-12" style={{ marginBottom: "15px" }}>
          {/* <Autocomplete
            disablePortal
            fullWidth
            disabled={localDisable}
            loading={localLoading}
            options={organizations}
            open={localOpen}
            onOpen={handleLocalOpen}
            onClose={() => setLocalOpen(false)}
            onClick={() => setLocalOpen(true)}
            forcePopupIcon={true}
            popupIcon={<ArrowDropDownIcon />}
            value={memberOrganization}
            onChange={handleLocalChange}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Organization"
                variant="outlined"
              />
            )}
            getOptionLabel={(option) => (option.name ? option.name : "")}
            renderOption={(params) => {
              return (
                <Stack
                  direction="row"
                  spacing={1}
                  {...params}
                  key={params["data-option-index"] + 1}
                >
                  <span
                    style={{
                      fontFamily: "Archivo",
                      paddingLeft: "10px",
                      margin: "auto 0px",
                    }}
                  >
                    {params.key}
                  </span>
                </Stack>
              );
            }}
          /> */}

          {/* Top Parent Organization */}
          <FormControl fullWidth>
            <InputLabel
              id="topParentOrganizationId"
              sx={{ fontFamily: "Archivo" }}
            >
              {" "}
              Organization
            </InputLabel>
            <Select
              variant="outlined"
              labelId="topParentOrganizationId"
              label="Organization"
              size="small"
              fullWidth
              disabled={true}
              sx={componentStyles.inputField}
              value={memberOrganization?.topParentOrganizationId || ""}
              onChange={(e) =>
                handleOrganizationInformation(e, "topParentOrganizationId")
              }
            >
              {organizations &&
                organizations.map((organization) => {
                  if (!organization.parentId) {
                    return (
                      <MenuItem
                        key={organization.id}
                        value={organization.id}
                      // sx={{ color: "yellow" }}
                      >
                        {organization.displayName}
                      </MenuItem>
                    );
                  }
                })}
            </Select>
          </FormControl>

          {/* Organization 2nd */}
            {!onlyShowLocals &&
            (memberInfo?.organizationName?.includes("BLET" || "blet") || props?.results?.name?.includes("Teamsters") || props?.results?.name?.includes("IUPAT"))  && handleHasChildren(memberOrganization?.topParentOrganizationId) && (
              <FormControl
                fullWidth
                variant="outlined"
                sx={{ marginTop: "10px" }}
              >
                <InputLabel id="organizationId">Organization</InputLabel>
                <Select
                  variant="outlined"
                  labelId="organizationId"
                  label="Organization"
                  size="small"
                  sx={componentStyles.inputField}
                  value={memberOrganization?.organizationId || ""}
                  onChange={(e) =>
                    handleOrganizationInformation(e, "organizationId")
                  }
                  inputProps={{
                    'data-property':'Organization_select'
                  }} 
                >
                  {organizations &&
                    organizations.map((organization) => {
                      if (
                        organization.parentId ==
                        memberOrganization?.topParentOrganizationId && organization.disableSelect != true
                      ) {
                        return (
                          <MenuItem
                            value={organization.id}
                            key={organization.id}
                          >
                            {organization.name}
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
              </FormControl>
            )
            }

          {/* Organization - 3rd */}
          {!onlyShowLocals 
            ? (handleHasChildren(memberOrganization?.organizationId) &&
            <FormControl fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel
                id="lowestOrganizationId"
                sx={{ fontFamily: "Archivo" }}
              >
                Organization
              </InputLabel>
              <Select
                variant="outlined"
                labelId="lowestOrganizationId"
                label="Organization"
                size="small"
                sx={componentStyles.inputField}
                value={memberOrganization?.lowestOrganizationId || ""}
                onChange={(e) =>
                  handleOrganizationInformation(e, "lowestOrganizationId")
                }
              >
                  {organizations && organizations.map((organization) => {
                    if (
                      organization.parentId ==
                      memberOrganization?.organizationId && organization.disableSelect != true
                    ) {
                      return (
                        <MenuItem value={organization.id}>
                          {organization.name}
                        </MenuItem>
                      );
                    }
                    })
                  }
              </Select>
            </FormControl>
          )
        :
          <FormControl fullWidth sx={{ marginTop: "10px" }}>
              <InputLabel
                id="lowestOrganizationId"
                sx={{ fontFamily: "Archivo" }}
              >
                Local Organization
              </InputLabel>
              <Select
                variant="outlined"
                labelId="lowestOrganizationId"
                label="Local Organization"
                size="small"
                sx={componentStyles.inputField}
                value={memberOrganization?.lowestOrganizationId || ""}
                onChange={(e) =>
                  handleOrganizationInformation(e, "lowestOrganizationId")
                }
              >
                
                  {thirdLevelOrganizations && thirdLevelOrganizations.map((organization) => {
                      if (
                        organization.disableSelect != true
                      ) {
                        return (
                          <MenuItem value={organization.id}>
                            {organization.displayName}
                          </MenuItem>
                        );
                      }
                    })
                  }
              </Select>
            </FormControl>
            }
        </div>
        <div
          className="flex-row"
          style={{ marginBottom: "17px", justifyContent: "space-between" }}
        >
          <Typography
            variant="body1"
            fontWeight="400"
            sx={{
              color:
                props.showErrors && !props.personalFields?.workStatus
                  ? "error.main"
                  : "text.secondary",
            }}
          >
            Are you actively working for your &nbsp;
            <span style={{display:'inline-block'}}>
              employer?
              <Box sx={{display:'inline', alignItems:'center'}} type='button' onClick={() => setNawDrawerOpen(true)}>
                <InfoOutlinedIcon
                  sx={{...enrollmentPlanStyles.toolTipIcon, color:'#195ffb', height:'24px', width:'24px'}}
                  />
              </Box>
            </span> 
          </Typography>
        </div>

          <RadioGroup
            row
            sx={{
              flexWrap: "wrap",
              fontFamily: "Poppins",
              marginBottom: "30px",
              gap: "0px 15px",
            }}
            value={memberInfo?.workStatusId}
            onChange={handleWorkStatusChange}
            ref={workStatusRef}
          >
            <Radio
              className="col"
              sx={{ padding: 0, borderRadius: "0px" }}
              value={getActive()}
              checked={getActive() == memberInfo?.workStatusId}
              inputProps={{
                'data-property':'currentlyWorkingTrue_radio'
             }} 
              icon={
                <Button
                  variant="outlined"
                  fullWidth
                  sx={
                    props.showErrors && !props.personalFields?.workStatus
                      ? buttonErrorStyles
                      : buttonStyles
                  }
                >
                  Yes
                </Button>
              }
              checkedIcon={
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 50,
                    border: "1px solid",
                    borderColor: "primary.main",
                    fontWeight: "700",
                  }}
                >
                  Yes
                </Button>
              }
            />

          <Radio
            className="col"
            sx={{ padding: 0, borderRadius: "0px" }}
            value={getNotActive()}
            checked={memberInfo?.workStatusId > 0 && getActive() != memberInfo?.workStatusId}
            icon={
              <Button
                variant="outlined"
                fullWidth
                sx={
                  props.showErrors && !props.personalFields?.workStatus
                    ? buttonErrorStyles
                    : buttonStyles
                }
              >
                No
              </Button>
            }
            checkedIcon={
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: 50,
                  border: "1px solid",
                  borderColor: "primary.main",
                  fontWeight: "700",
                }}
              >
                No
              </Button>
            }
          />
          <div
            className="row"
            style={{ display: "contents", textAlign: "right" }}
          >
            {" "}
            <FormHelperText
              hidden={
                !(props.showErrors && !props.personalFields?.workStatus)
              }
              error={true}
              sx={{ marginTop: "3px", paddingRight: "14px" }}
              fontSize="0.75rem"
            >
              Working Status is required.
            </FormHelperText>
          </div>
        </RadioGroup>

        {/* <Typography variant="subtitle3" fontWeight="500" sx={{color: props.showErrors && !props.personalFields?.workStatus ? 'error.main' : 'auto' }} >
            Are you currently working? *
          </Typography> */}

        {/* <Chip label='Required' sx={{ justifyContent: 'end', color: nawError ? '#D32F2F' : '', backgroundColor: nawError ? '#D32F2F1F' : '', fontWeight: 500, height: '26px' }} icon={nawError ? <ErrorIcon sx={{ fill: '#D32F2F' }} /> : null} /> */}

        {/* <div
          className="flex-row"
          style={{ gap: "15px", marginBottom: "24px" }}
          ref={workStatusRef}
        >
          <div
            className="col"
            onClick={handleWorkStatusChange}
            value={getActive()}
          >
            <Button
              variant="contained"
              fullWidth
              sx={props.showErrors && !props.personalFields?.workStatus ? buttonErrorStyles : buttonStyles}
              disabled={memberInfo.workStatusId != getActive()}
              value={getActive()}
            >
              YES
            </Button>
          </div>
          <div
            className="col"
            onClick={handleWorkStatusChange}
            value={getNotActive()}
            style={{ textAlign: "end" }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={props.showErrors && !props.personalFields?.workStatus ? buttonErrorStyles : buttonStyles}
              disabled={memberInfo.workStatusId != getNotActive()}
              value={getNotActive()}
            >
              NO
            </Button>
          </div>
        </div> */}

        {showNaw && (
          <>
            <div
              className="flex-row"
              style={{ gap: "15px", marginBottom: "24px" }}
            >
              <Alert fullWidth severity="warning"
                sx={{lineHeight:'20.02px', letterSpacing:'.17px'}}
              >
                You must be working or scheduled to work without restrictions for your coverage to take effect. Please provide more details about your current work status.
              </Alert>
            </div>

            <FormControl>
              <FormLabel id='NAW-reasons'>
                <Typography 
                variant="body1"
                sx={{ color: "text.secondary", marginBottom:'3px' }}
                >
                  Please select the reason for not working:
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="NAW-reasons"
                defaultValue={getIrregularWorkSchedule()}
                value={memberInfo?.workStatusId}
                onChange={handleWorkStatusChange}
                name="NAW-radioGroup"
                sx={{paddingLeft:'10px', marginBottom:'25px'}}
              >
                <FormControlLabel value={getIrregularWorkSchedule()} control={<Radio />} label="I am currently employed but not working on a regular basis." />
                <FormControlLabel value={getInBetweenJobs()} control={<Radio />} label="I am currently in between jobs." />
                <FormControlLabel value={getOnLeave()} control={<Radio />} label="I am on leave (disability, FMLA, disciplinary, etc.)" 
                  sx={{marginBottom:'10px'}}
                />
                <FormControlLabel value={getOther()} control={<Radio />} 
                  slotProps={{
                    typography:{
                      sx:{width:'100%'}
                    }
                  }}
                  label={
                    <TextField
                      sx={{ '& input:-internal-autofill-selected': { WebkitTextFillColor: 'none !important', WebkitBoxShadow: '0 0 0 1000px white inset !important' },
                      }}
                      fullWidth
                      multiline
                      // error={props.showErrors && !props.personalFields.firstName}
                      label="Other"
                      variant="outlined"
                      size="small"
                      value={props.nawReason}
                      // helperText={
                      //   props.showErrors &&
                      //   !props.personalFields.firstName &&
                      //   "First Name is required."
                      // }
                      onChange={(e) => props.setNawReason(e.target.value)}
                      // inputProps={{
                      //   "data-update": "firstName",
                      // }}
                    />
                  } 
                />
              </RadioGroup>
            </FormControl>


            {/* <Box
              className="flex-row"
              style={{ gap: "15px", marginBottom: "24px" }}
            >
              <TextField
                fullWidth
                required
                label="Estimated Return To Work Date"
                variant="outlined"
                error = {returnToWorkDate < dateMin}
                helperText = {returnToWorkDate < dateMin ? "Return to work date cannot be in the past." : ""}
                value={
                  returnToWorkDate 
                    ? returnToWorkDate
                    : ""
                }
                // error={returnToWorkDate < dateMin.date}
                type={props.fullMemberObj?.returnToWork ? "date" : ""}
                onChange={handleRtwChange}
                onFocus={handleRtwFocus}
                onBlur={handleRtwBlur}
                inputProps={{
                  min: dateMin,
                  max: dateMax,
                }}
              />
            </Box> */}
            {console.log(
              returnToWorkDate < dateMinObj,
              dateMinObj

            )}
            <Box 
              className="flex-row"
              sx={{ marginBottom: "15px", padding: "0px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                required
                label="Estimated Return To Work Date"
                // variant="outlined"
                // error = {returnToWorkDate }
                // helperText = {returnToWorkDate < dateMinObj ? "Return to work date cannot be in the past." : ""}
                openTo="year"
                views={["year", "month", "day"]}
                format="MM/dd/yyyy"
                minDate={dateMinObj}
                // maxDate={dateMaxObj}
                value={returnToWorkDate || null}
                onChange={handleRtwChange}
                // onError={(error) => setDobError(error)}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    helperText: `${returnToWorkDate < dateMinObj ? "Return to work date cannot be in the past." : ""}`,
                    error: returnToWorkDate < dateMinObj,
                  },
                  openPickerButton: { sx: { visibility: 'hidden' } } //hide calendar icon calandar 
                }}
              />
            </LocalizationProvider>
          </Box>
          </>
        )}

        {props.results 
          && props.results?.name 
          && !(props.results.name.includes("VIP+") || props.results.name.includes("IATSE")) 
          && (
            <div className="flex-row" style={{ marginBottom: "15px" }}>
              <Autocomplete
                disablePortal
                fullWidth
                loading={loading}
                options={employers}
                open={open}
                onOpen={handleOpen}
                onClose={() => setOpen(false)}
                onClick={(e) => setOpen(true)}
                forcePopupIcon={true}
                popupIcon={<ArrowDropDownIcon />}
                value={employerObj}
                onChange={handleEmployerChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={useJobTrade ? 'Job Trade' :"Employer"}
                    variant="outlined"
                  />
                )}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                renderOption={(params) => {
                  return (
                    <Stack
                      direction="row"
                      spacing={1}
                      {...params}
                      key={params["data-option-index"] + 1}
                    >
                      <span
                        style={{
                          fontFamily: "Archivo",
                          paddingLeft: "10px",
                          margin: "auto 0px",
                        }}
                      >
                        {params.key}
                      </span>
                    </Stack>
                  );
                }}
              />
            </div>
          )}
        {/* //HARDCODE */}
        {props.results &&
          props.results?.organizations?.length > 0 &&
          props.results.name.includes('Teamsters') && (
            <>
              <div
                className="flex-row"
                style={{
                  marginBottom: "17px",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight="400"
                  sx={{ color: "text.secondary" }}
                >
                  Do you have a Commercial Driver's License &nbsp;
                  <span style={{display:'inline-block'}}>
                    (CDL)? *
                    <Box sx={{display:'inline', alignItems:'center'}} type='button' onClick={() => setCDLDrawerOpen(true)}>
                      <InfoOutlinedIcon
                        sx={{...enrollmentPlanStyles.toolTipIcon, color:'#195ffb', height:'24px', width:'24px'}}
                      />
                    </Box>
                  </span>
                </Typography>
              </div>

                <RadioGroup
                  row
                  sx={{
                    flexWrap: "wrap",
                    fontFamily: "Poppins",
                    marginBottom: "30px",
                    gap: "15px",
                  }}
                  value={cdlAnswer}
                  onChange={handleCdlChange}
                >
                  <Radio
                    className="col"
                    sx={{ padding: 0, borderRadius: "0px" }}
                    value={1}
                    inputProps={{
                      'data-property':'cdlTrue_radio'
                   }} 
                    icon={
                      <Button variant="outlined" fullWidth sx={buttonStyles}>
                        Yes
                      </Button>
                    }
                    checkedIcon={
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          height: 50,
                          border: "1px solid",
                          borderColor: "primary.main",
                          fontWeight: "700",
                        }}
                      >
                        Yes
                      </Button>
                    }
                  />

                <Radio
                  className="col"
                  sx={{ padding: 0, borderRadius: "0px" }}
                  value={0}
                  icon={
                    <Button variant="outlined" fullWidth sx={buttonStyles}>
                      No
                    </Button>
                  }
                  checkedIcon={
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        height: 50,
                        border: "1px solid",
                        borderColor: "primary.main",
                        fontWeight: "700",
                      }}
                    >
                      No
                    </Button>
                  }
                />
                {/* <div className="row" style={{ display: 'contents', textAlign: 'right' }}>   
             <FormHelperText hidden={!(props.showErrors && !props.personalFields?.workStatus)} error={true} sx={{ marginTop: '3px', paddingRight: '14px' }} fontSize='0.75rem'>CDL Status is required.</FormHelperText>
            </div> */}
              </RadioGroup>

              {/* <div
                className="flex-row"
                style={{
                  marginBottom: "15px",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle3" fontWeight="500">
                  Do you have a Commercial Driver's License (CDL)? *
                </Typography>

                <Chip label='Required' sx={{ justifyContent: 'end', color: nawError ? '#D32F2F' : '', backgroundColor: nawError ? '#D32F2F1F' : '', fontWeight: 500, height: '26px' }} icon={nawError ? <ErrorIcon sx={{ fill: '#D32F2F' }} /> : null} />
              </div> */}

              {/* <div
                className="flex-row"
                style={{ gap: "15px", marginBottom: "24px" }}
              >
                <div className="col" onClick={handleCdlChange} value={1}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={buttonStyles}
                    disabled={cdlAnswer != 1}
                    value={1}
                  >
                    YES
                  </Button>
                </div>
                <div
                  className="col"
                  onClick={handleCdlChange}
                  value={0}
                  style={{ textAlign: "end" }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    sx={buttonStyles}
                    disabled={cdlAnswer != 0}
                    value={0}
                  >
                    NO
                  </Button>
                </div>
              </div> */}
            </>
          )}
          <div className="flex-row" style={{ marginBottom: "15px", display:useJobTrade ? 'none' : '' }}>
          <TextField
            fullWidth
            required
            error={props.showErrors && !props.personalFields.jobTitle}
            helperText={
              props.showErrors &&
              !props.personalFields.jobTitle &&
              "Job Title is required."
            }
            ref={jobTitleRef}
              label="Job Title"
            variant="outlined"
            value={memberInfo.jobTitle ? memberInfo.jobTitle : ""}
            onChange={(e) => {
              nawErrorCheck();
              handleInputChange(e);
            }}
            inputProps={{
              "data-update": "jobTitle",
            }}
          />
        </div>
        {!props.agentEnroller && <PoweredBy />}
        {props.agentEnroller && (
          <AgentNavigation
            agentEnrollmentPageState={props.agentEnrollmentPageState}
            setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
            nextLocation={props.nextLocation}
            lastLocation={props.lastLocation}
            // notActivelyWorking={props.notActivelyWorking}
            // handleNawExit={props.handleNawExit}
            allowBack={true}
            nextClickHandler={props.nextClickHandler}
            nextValidation={props.nextValidation}
          />
        )}
      </div>
    </div>
    <Drawer 
      // open={props.open} onClose={props.handleClose}
      open={nawDrawerOpen} onClose={() => setNawDrawerOpen(false)}
      anchor='bottom' sx={{ maxHeight: '200px' }}
        PaperProps={{
            sx: {
                borderRadius: '12px 12px 0px 0px', padding: '0px 16px 24px', bgcolor: 'background.default',
                color: 'text.primary', maxHeight: '75%'
            }
        }} disableScrollLock={true}
    >
        <Box sx={{
            bgcolor: 'paper.background.default',
            color: 'text.primary',
            maxWidth: '600px',
            margin: 'auto'
        }}>
            <div className="row" style={{ marginBottom: '20px',backgroundColor:'white',position:'sticky',top:0,paddingTop:'16px' }}>
                <Typography className="col-10" variant='blueAccent' sx={{fontSize:'24px'}}>Not Actively Working</Typography>
                <div className="col-2" style={{textAlign:'end'}}>
                    <IconButton onClick={()=>setNawDrawerOpen(false)} >
                        <CloseIcon sx={{ color: '#195ffb' }} />
                    </IconButton>
                </div>
            </div>
            <div className='col-12' style={{ marginBottom: "20px" }}>
                <Typography variant='body1' sx={{fontSize:'14px'}}>
                What does 'actively working' mean? To qualify for coverage, you must be a union member in good standing and actively working under a union contract on the effective date of coverage. This means performing your job duties for your employer on that specific date. If you are not actively working, your coverage will only start once you return to work.
                </Typography>
            </div>
        </Box>
    </Drawer>

    <Drawer 
      // open={props.open} onClose={props.handleClose}
      open={CDLDrawerOpen} onClose={() => setCDLDrawerOpen(false)}
      anchor='bottom' sx={{ maxHeight: '200px' }}
        PaperProps={{
            sx: {
                borderRadius: '12px 12px 0px 0px', padding: '0px 16px 24px', bgcolor: 'background.default',
                color: 'text.primary', maxHeight: '75%'
            }
        }} disableScrollLock={true}
    >
        <Box sx={{
            bgcolor: 'paper.background.default',
            color: 'text.primary',
            maxWidth: '600px',
            margin: 'auto'
        }}>
            <div className="row" style={{ marginBottom: '20px',backgroundColor:'white',position:'sticky',top:0,paddingTop:'16px' }}>
                <Typography className="col-10" variant='blueAccent' sx={{fontSize:'24px'}}>CDL</Typography>
                <div className="col-2" style={{textAlign:'end'}}>
                    <IconButton onClick={()=>setCDLDrawerOpen(false)} >
                        <CloseIcon sx={{ color: '#195ffb' }} />
                    </IconButton>
                </div>
            </div>
            <div className='col-12' style={{ marginBottom: "20px" }}>
                <Typography variant='body1' sx={{fontSize:'14px'}}>
                A Commercial Driver’s License (CDL) is required to operate large or hazardous vehicles. CDL holders must meet medical qualifications and undergo regular medical reviews. This plan includes special provisions to protect CDL holders if they lose their license due to medical conditions.
                </Typography>
            </div>
        </Box>
    </Drawer>
  </>)
}

import React, { useEffect, useState } from "react";

//Mui Components
import Chip from "@mui/material/Chip";

export default function TicketPriorityChips(props) {
  const [chip, setChip] = useState({
    backgroundColor: "",
    color: "",
    border: "",
    borderColor: "",
    label: "",
  });

  const buildChip = () => {
    switch (props.ticketPriority) {
      case 1:
        setChip({
          backgroundColor: "background.onMedicalLeaveChip",
          color: "primary.onMedicalLeaveChip",
          label: "Low",
        });
        break;
      case 2:
        setChip({
          backgroundColor: "background.onDisabilityChip",
          color: "primary.enrollmentSubmittedChip",
          label: "Medium",
        });
        break;
      case 3:
        setChip({
          backgroundColor: "background.inProgressChip",
          color: "primary.inProgressChip",
          label: "High",
        });
        break;
      case 4:
        setChip({
          backgroundColor: "#FBEAEA",
          color: "#D32F2F",
          label: "Critical",
        });
        break;
      default:
        setChip({
          backgroundColor: "background.notActivelyWorkingChip",
          color: "primary.notActivelyWorkingChip",
          label: "None",
        });
    }
  };

  useEffect(() => {
    buildChip();
  }, [props.ticketPriority]);

  return (
    <>
      <Chip
        sx={{
          backgroundColor: chip.backgroundColor,
          color: chip.color,
          border: chip.border,
          borderColor: chip.borderColor,
          fontWeight: "500",
          fontFamily: "Archivo",
          fontSize: "13px",
          height: "24px",
        }}
        label={chip.label}
      />
    </>
  );
}

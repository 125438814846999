import React, { useEffect, useState, useRef } from "react"

//Services
import walletService from "../../../../../../services/wallet-service"

//Mui Components
import Typography from "@mui/material/Typography"
import Box from "@mui/system/Box"

//Our components
import EnrollmentBankBlock from "./EnrollmentBankBlock"
import EnrollmentSummaryBlock from "../summary-components/EnrollmentSummaryBlock"
import SignatureModal from "../../../signature-modal/SignatureModal"
import LegalDrawer from "../LegalDrawer"
import ExternalEnrollmentHeader from "../../../ExternalEnrollmentHeader"

export default function EnrollmentPayment(props) {
  const [loading, setLoading] = useState(false)
  let sigPic = useRef("")

  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })
  const centCalc = (value, number) => {
    if (number === 2 && (value * 100) % 2 != 0) {
      return Math.floor((value / 2) * 100) / 100
    } else {
      return value / 2
    }
  }

  // let enrollmentContainer = document.getElementById('single-enrollment-container')
  // useEffect(() => {
  //    if (enrollmentContainer) enrollmentContainer.style.height = ''

  //    return () => enrollmentContainer.style.height = '100vh'
  // },[enrollmentContainer])

  //Modal Logic
  const [open, setOpen] = useState(false)
  const [paper, setPaper] = useState({
    isMail: false,
    signatureDate: "",
  })
  const [verbalAuth, setVerbalAuth] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  // const displayPay = (signature) => {
  //    sigPic.current = signature;
  // }

  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (props.userInfo && !loaded) {
      props.updateResults()
      setLoaded(true)
    }
  }, [props.userInfo])

  const [legalObj, setLegalObj] = useState({
    open: false,
    document: "",
  })

  const handleOpenLegal = (e) => {
    // console.log(e)
    setLegalObj({
      open: true,
      document: e.target.text,
    })
  }
  const handleCloseLegal = (e) => {
    setLegalObj({
      open: false,
      document: "",
    })
  }

  //logic for checking blet groupPlanId = 18 - this group requires restricted payment date options
  const [bletGroupPlanId18, setBletOrgTrue] = useState(null)
  useEffect(() => {

    if(props?.results?.groupPlanId){
      setBletOrgTrue(props?.results?.groupPlanId && parseInt(props?.results?.groupPlanId) === 18 ? true : false)
    }
  }, [props?.results?.groupPlanId])
  
  let emptyBank = {
    memberId: props.fullMemberObj?.id || 0,
    paymentFrequencyId: 1, //1 monthly | 2 semi | 3 annual
    bankAccountTypeId: 1, //1 checking | 2 savings
    routingNumber: "",
    accountNumber: "",
    // confirmAccount: '',
    paymentOne: bletGroupPlanId18 ? 10 : 1,
    paymentTwo: 15,
    bankName: "",
    description:
      props?.fullMemberObj?.firstName &&
      `${props?.fullMemberObj?.firstName} ${props?.fullMemberObj?.lastName}`,
  }

  let emptySchedule = {
    memberId: props.fullMemberObj?.id || 0,
    paymentAmount: "",
    beginPaymentDate: props?.results?.paymentStart,
    endPaymentDate: "9999-12-31T00:00:00+00:00", //far future
    nextOccurance: props?.results?.paymentStart, //need a date, backend updaets this based on dates selected
    paymentFrequencyId: 1,
    bankAccountId: 0,
    isEnabled: true,
    enrollmentId: props?.enrollmentId || 0,
  }

  const [bank, setBank] = useState({})
  const [bankArr, setBankArr] = useState([])
  const [paySchedule, setPaySchedule] = useState({})

  //get bank - if one exists, does not mean a schedule automatically exists so check for schedule
  const getBank = async () => {
    let _bank
    let _bankArr = [] //for agent side
    let _schedule
    //member side
    if (!props?.agentEnroller) {
      try {
        _bank = await walletService.getBankAccount()
      } catch (error) {
        console.error("error getting primary bank account", error)
        _bank = emptyBank
      }
      //if a bank exists
      if (_bank?.id) {
        try {
          _schedule = await walletService.getEnrollmentScheduledPayment(
            props?.enrollmentId
          )
        } catch (error) {
          console.error("error getting pay schedule", error)
        }
      }
    } else if (props?.agentEnroller) {
      //agent side
      try {
        _bankArr = await walletService.getBankAccountForEnrollmentForMember(
          props.fullMemberObj.id
        ) 
      } catch (error) {
        console.error("error getting member bank accounts", error)
        _bank = emptyBank
      }

      //if bank exists, get primary bank and payment scheulde - use enrollmentId to get payment schedule during enrollment
      // will return single object in array if schedule exists
      if (_bankArr.length > 0 && _bankArr[0]?.id) {
        _bank = _bankArr?.find((bank) => bank.isPrimary) //setting state to primary bank
        try {
          _schedule = await walletService.getPaymentScheduleForMember(
            props.fullMemberObj.id,
            props?.enrollmentId
          )
        } catch (error) {
          console.error("error getting pay schedule for member", error)
        }

        //agent side returns an array w/ schedule object
        if (_schedule?.length > 0) {
          _schedule = _schedule[0]
        }
      }
      console.log("bankArr", _bankArr)
    }

    //Check payment schedule dates and set, consider BLET/METRA rules
    if (_bank?.id) {
      if (_schedule?.scheduledPaymentDates && _schedule?.id !== 0) {
        //check if blet and has previous pay schedule outside date range of 10-20th - reset to 10th if outside the 10-20 range
        _bank.paymentOne =
          bletGroupPlanId18 &&
          (_schedule.scheduledPaymentDates[0]?.dayOfMonth < 10 ||
            _schedule.scheduledPaymentDates[0]?.dayOfMonth > 20)
            ? 10
            : _schedule.scheduledPaymentDates[0]?.dayOfMonth
        // only alow second payment date for plans that are not blet gp 18
        if (_schedule.scheduledPaymentDates?.length > 1 && !bletGroupPlanId18) {
          _bank.paymentTwo = _schedule.scheduledPaymentDates[1]?.dayOfMonth
        }
      } else {
        //set paymentOne to 10 to start for BLET
        _bank.paymentOne = bletGroupPlanId18 ? 10 : 1
        _bank.paymentTwo = ""

        _schedule = emptySchedule
      }
      //set frequency id to 1 if not part of a schedule and always set to 1 if blet gp 18
      if (_schedule?.paymentFrequencyId) {
        _bank.paymentFrequencyId = bletGroupPlanId18
          ? 1
          : _schedule?.paymentFrequencyId
      } else {
        _bank.paymentFrequencyId = 1
      }
      setPaySchedule(_schedule)
    }

    if (_bank?.id === 0) _bank = emptyBank
    setBank(_bank)

    //filter out archived bank accounts before creating bankArr
    let _filteredAccounts = _bankArr?.filter((account) => {
      //return accounts that are deleted
      if(!account.isDeleted) return account
    })

    if(_filteredAccounts?.length > 0){
      setBankArr(_filteredAccounts)
    } else setBankArr([])
  }

  useEffect(() => {
    getBank()
  }, [props?.fullMemberObj?.id])

  //verbal or mail in
  // const [verbalAuth, setVerbalAuth] = useState(false)
  // const handleVerbalAuthChange = (e) => {
  //    setVerbalAuth(prev => !prev)
  // }

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Box
        className="row"
        sx={{
          height: props.agentEnroller ? "calc(100vh - 276px)" : "100vh",
          maxWidth: props.agentEnroller ? "100%" : "600px",
          margin: props.agentEnroller ? "" : "0 auto",
          padding: props.agentEnroller ? "20px" : "40px 20px",
          width: "100%",
        }}
      >
        {!props.agentEnroller && (
          <ExternalEnrollmentHeader
            fullMemberObj={props.thisMember}
            agentEnroller={props.agentEnroller}
            selectedEnrollment={props.results}
            basicHeader={true}
          />
        )}

        {props.agentEnroller && (
          <>
            <Box
              className="flex-row"
              sx={{
                textAlign: "left",
                // paddingLeft: "5px",
                marginTop: "5px",
                marginBottom: "0px",
                marginLeft: "-7px",
              }}
            >
              <Typography variant="h5" fontFamily={"Poppins"}>
                Payment Summary
              </Typography>
            </Box>

            <Box
              className="flex-row"
              sx={{
                textAlign: "left",
                // paddingLeft: "5px",
                marginTop: "5px",
                // marginBottom: "38px",
                marginLeft: "-7px",
              }}
            >
              <Typography variant="body2" color="text.gray">
                Review selections and finalize payment information.
              </Typography>
            </Box>
          </>
        )}

        <Box className="row" sx={{ margin: "auto" }}>
          <EnrollmentSummaryBlock
            agentEnrollmentPageState={props.agentEnrollmentPageState}
            setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
            agentEnroller={props.agentEnroller}
            results={props.results}
            selections={props.selections}
            expanded={props.expanded}
            setExpanded={props.setExpanded}
            showPayment={props.showPayment}
            setShowPayment={props.setShowPayment}
          />
        </Box>

        <EnrollmentBankBlock
          agentEnroller={props.agentEnroller}
          fullMemberObj={props.fullMemberObj}
          runningTotal={props.runningTotal}
          handleOpen={handleOpen}
          handleOpenLegal={handleOpenLegal}
          enrollmentResults={props.results}
          results={props.results}
          UsdFormatterDec={UsdFormatterDec}
          centCalc={centCalc}
          getBank={getBank}
          bank={bank}
          setBank={setBank}
          bankArr={bankArr}
          setBankArr={setBankArr}
          paySchedule={paySchedule}
          setPaySchedule={setPaySchedule}
          save={props.save}
          setSave={props.setSave}
          setOpenEnrollment={props.setOpenEnrollment}
          paper={paper}
          setPaper={setPaper}
          verbalAuth={verbalAuth}
          setVerbalAuth={setVerbalAuth}
          setLoading={props.setLoading}
          bletGroupPlanId18={bletGroupPlanId18}
        />

        <SignatureModal
          agentEnroller={props.agentEnroller}
          setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
          fullMemberObj={props.fullMemberObj}
          guid={props.guid}
          setGuid={props.setGuid}
          results={props.results}
          open={open}
          handleClose={handleClose}
          handleOpen={handleOpen}
          setEnrollmentSelected={props.setEnrollmentSelected}
          signClick="Submit"
          paper={paper}
          verbalAuth={verbalAuth}
          setLoading={props.setLoading}
          getThisMember={props.getThisMember}
          // displayPay={displayPay}
          refreshEnrollments={props.refreshEnrollments}
          refreshEnrollment={props.refreshEnrollment}
          nawReason={props.nawReason}
          getPaymentSchedule={props?.getPaymentSchedule} //call from member profile to refresh payment schedule in payments tab
        />
        <LegalDrawer
          open={legalObj.open}
          handleClose={handleCloseLegal}
          handleOpen={handleOpenLegal}
          document={legalObj.document}
          results={props.results}
          fullMemberObj={props.fullMemberObj}
          selections={props.selections}
          runningTotal={props.runningTotal}
          bank={bank}

        />
      </Box>
    </Box>
  )
}

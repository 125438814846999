import React from 'react';

import TextField from '@mui/material/TextField';

export default function PortalPersonalSummary(props) {
   return (
      <>
         <TextField className="col-12" variant="standard" label='Name' value={'John Smith'}
            fullWidth
         />

         <TextField className="col-12" variant="standard" label='Mobile Phone Number' value={'7203031234'}
            fullWidth
         />

         <TextField className="col-12" variant="standard" label='Email Address' value={'johnsmith@email.com'}
            fullWidth
         />

         <TextField className="col-12" variant="standard" label='Working Status' value={'1'}
            fullWidth
         />
      </>
   );
};


import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { format, differenceInCalendarYears } from "date-fns";

//services
import searchService from "../../../../services/search-service";
import optionService from "../../../../services/optionService";

// Assets

//Mui Components
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

//Mui icons
import AddIcon from "@mui/icons-material/Add";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined";
import StarIcon from "@mui/icons-material/Star";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

//Our Components
import OurDataGrid from "../../DataGrid/OurDataGrid";
import CreateMemberDrawer from "./CreateMemberDrawer";
import WorkingStatusChips from "../DisplayChips/WorkingStatusChips";
import { Box } from "@mui/system";
import { Icon, Typography } from "@mui/material";

export default function AllMembersView(props) {
  let history = useHistory();
  const [employmentStatuses, setEmploymentStatuses] = useState();
  let getEmploymentStatuses = async () => {
    let _statuses = await optionService.getWorkStatus();
    setEmploymentStatuses(_statuses);
    // console.log(_statuses)
  };

  //date formatter - takes in date string
  const dateFormatter = (date) => {
    let dateObj = new Date(date);

    let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    let day = dateObj.getDate().toString().padStart(2, "0");
    let year = dateObj.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  let formatPhoneNumber = (str) => {
    let noCountryMatch = str.match(/^(\d{3})(\d{3})(\d{4})$/);
    // let countryMatch = str.match(/^(\d{1-3})(\d{3})(\d{3})(\d{4})$/);

    if (noCountryMatch) {
      return `(${noCountryMatch[1]}) ${noCountryMatch[2]}-${noCountryMatch[3]}`;
    }
    // else if (countryMatch) {
    //   return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    // }
    return str;
  };

  const [searchState, setSearchState] = useState({
    display: false,
    terms: "",
    results: [],
  });

  const [searchedTermSnap, setSearchedTermSnap] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  //TimerId
  const [timer, setTimer] = useState(0);

  // Handle Search Object
  const handleTermsInput = (e) => {
    setSearchState({
      ...searchState,
      terms: e.target.value,
    });
  };

  const handleSearchRecentTerm = (term) => {
    setSearchState({
      ...searchState,
      terms: term,
    });
  };

  const searchAsync = async () => {
    handleAnchor(anchorTarget);
    setSearchedTermSnap(searchState.terms);
    setLoadingState(true);
    try {
      let results = await searchService.search(searchState.terms);
      console.log("SEARCH RESULTS", results);
      if (results) {
        setSearchState({
          ...searchState,
          results: results,
        });
        // console.log(searchState)
      }
      saveSearchHistory();
    } catch (error) {
      console.error("ERROR from member Search", error);
    }
  };

  //When search terms are entered clear prev timer, start new one
  //Keep timerId in state to be able to clear it
  // useEffect(() => {
  //   clearTimeout(timer);
  //   if (searchState.terms?.length > 0) {
  //     let timerId = setTimeout(() => {
  //       searchAsync();
  //     }, 800);
  //     setTimer(timerId);
  //   }
  // }, [searchState.terms]);

  const clearResults = (e) => {
    e.preventDefault();
    setSearchState({
      ...searchState,
      results: [],
    });
  };
  const [noResultFilter, setNoResultFilter] = useState({
    items: [{ columnField: "fullName", operatorValue: "contains", value: "" }],
  });
  useEffect(() => {
    if (searchState.results) {
      setLoadingState(false);
      if (searchState.terms.length > 0 && searchState.results.length < 1) {
        // console.log('No Results now')
        setNoResultFilter({
          items: [
            { columnField: "fullName", operatorValue: "contains", value: "!" },
          ],
        });
      } else {
        setNoResultFilter({
          items: [
            { columnField: "fullName", operatorValue: "contains", value: "" },
          ],
        });
        buildRows();
      }
    }
  }, [searchState.results]);

  const [searchHistory, setSearchHistory] = useState([]);
  // Local Storage for Search History

  // Returns all history from the local object
  const getSearchHistory = () => {
    let _searchHistory = localStorage.getItem("memberSearchHistory");
    if (!_searchHistory) {
      setSearchHistory([]);
    } else {
      _searchHistory = JSON.parse(_searchHistory);
      setSearchHistory(_searchHistory);
    }
  };

  // Adds new item to local object (term and timestamp)
  const saveSearchHistory = () => {
    let _searchHistory;
    let newSearch = {
      term: searchState?.terms,
      timestamp: new Date(),
    };
    if (searchHistory.length <= 0) {
      _searchHistory = [];
      _searchHistory.push(newSearch);
      localStorage.setItem(
        "memberSearchHistory",
        JSON.stringify(_searchHistory)
      );
    } else {
      _searchHistory = searchHistory?.map((x) => x);
      if (newSearch.term && newSearch.term != searchHistory[0]?.term) {
        _searchHistory.unshift(newSearch);
        if (_searchHistory.length > 10) {
          let shortSearchHistory = _searchHistory.slice(0, 10);
          localStorage.setItem(
            "memberSearchHistory",
            JSON.stringify(shortSearchHistory)
          );
        } else {
          localStorage.setItem(
            "memberSearchHistory",
            JSON.stringify(_searchHistory)
          );
        }
      }
    }

    getSearchHistory();
  };

  const handleDeleteHistory = (index) => {
    let _searchHistory = searchHistory.filter((history, i) => index !== i);
    localStorage.setItem("memberSearchHistory", JSON.stringify(_searchHistory));
    getSearchHistory();
  };

  //THIS OVERRIDES THE NO ROWS DATAGRID COMPONENT
  function NoMemberRows(props) {
    return (
      <div style={{ backgroundColor: "", height: "100%", textAlign: "center" }}>
        <Box
          sx={{ backgroundColor: "primary.light" }}
          style={{
            margin: "54px auto 13px",
            width: "58px",
            height: "58px",
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PersonSearchIcon
            sx={{ width: "28px", height: "28px", color: "primary.main" }}
          />
        </Box>
        <div style={{ marginBottom: "19px" }}>
          Members will show up here once you type in your search
        </div>
        <Box sx={{ paddingBottom: "20px" }}>
          <Typography variant="h5">Recent Searches</Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            sx={{ paddingTop: "20px", display: "block" }}
          >
            {searchHistory?.length > 0 &&
              searchHistory.map((search, index) => {
                return (
                  <Chip
                    sx={{ zIndex: 4, margin: "10px" }}
                    role="button"
                    label={search.term}
                    onClick={() => handleSearchRecentTerm(search.term)}
                    onDelete={() => handleDeleteHistory(index)}
                    deleteIcon={
                      <IconButton>
                        <CancelRoundedIcon role="button" sx={{ zIndex: 5 }} />
                      </IconButton>
                    }
                  />
                );
              })}
          </Stack>
          {searchHistory?.length <= 0 && (
            <Typography variant="body2">
              Start searching to see search history here
            </Typography>
          )}
        </Box>

        {/* <div> */}
        <Button
          variant="contained"
          role="button"
          sx={{ height: 40, zIndex: 5 }}
          startIcon={<AddIcon />}
          onClick={() => setNewMemberDrawerOpen(true)}
        >
          Add Member
        </Button>
        {/* </div> */}
      </div>
    );
  }
  //THIS OVERRIDES THE NO RESULTS DATAGRID COMPONENT
  function NoMemberResults(props) {
    return (
      <div
        style={{
          backgroundColor: "",
          height: "100%",
          textAlign: "center",
          zIndex: 5,
        }}
      >
        <Box
          sx={{ backgroundColor: "primary.light" }}
          style={{
            margin: "54px auto 13px",
            width: "58px",
            height: "58px",
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PersonSearchIcon
            sx={{ width: "28px", height: "28px", color: "primary.main" }}
          />
        </Box>
        <div style={{ marginBottom: "19px" }}>
          Member '{searchedTermSnap}' not found.
          <br />
          Please try another search
        </div>

        <div>
          {/* <Button variant='contained' 
            sx={{height:40,borderRadius:100,width:'152px',fontWeight:'500!important'}}
            startIcon={<AddIcon/>}
            onClick={handleAddMemberClick}
            >
               Add Member
            </Button> */}
        </div>
      </div>
    );
  }

  const searchAdornment = (
    <PersonSearchIcon sx={{ color: "#CCCCCC", margin: "0px 10px" }} />
  );

  //Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleAnchor = (target) => {
    // console.log(e)
    setAnchorEl(target);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  let anchorTarget = document.getElementById("member-search");

  const setCoveredStatus = (enrollments) => {
    let status = []
    enrollments.map(enrollment => {
      if (enrollment?.enrollmentStateId == 2 && enrollment?.isEnrollmentOpen == false){
        status.push(1)
      }
    })
    if (status.length > 0){
      return 'Active'
    } else return 'Inactive'
  }

  
  // This state object determines which columns are hidden on page load.  The default setting if the user has no past interactions is below.  The name in the grid col MUST match the "Field" property within the row definitions in each individual grid.
  const [gridCols, setGridCols] = useState({})

  // Returns all filters from local storage or builds new
  const getGridCols = () => {
     let _gridCols = localStorage.getItem("memberSearchGridCols")
     if (_gridCols) {
      console.log(_gridCols)
      _gridCols = JSON.parse(_gridCols)
        setGridCols(_gridCols)
     } else {
        setGridCols({
          id: false,
          isVIP: true,
          fullName: true,
          association: true,
          phoneNumber: true,
          dob: true,
          email: true,
          enrollmentStatus: true,
          activelyCovered: true,
          workingStatus: true,
        })
     }
  }

  // Updates local storage with current settings
  const saveGridCols = () => {
     localStorage.setItem('memberSearchGridCols', JSON.stringify(gridCols))
     getGridCols()
  }


  useEffect(() => {
    if (Object.keys(gridCols).length != 0){
      console.log("changes")
      saveGridCols()}
  }, [JSON.stringify(gridCols)])


  
  const [rows, setRows] = useState([]);
  const buildRows = () => {
    let _rows = [];
    searchState.results.forEach((resultObj) => {
      // console.log('resultObj in all members', resultObj)
      let {
        id,
        enrollmentId,
        fullName,
        organizations,
        phoneNumbers,
        dob,
        primaryEmail,
        enrollments,
        workStatusId,
        isVip,
        activelyCovered
      } = resultObj.result;
      // let result = resultObj.result

      // console.log(resultObj.result)
      let currentOpenEnrollment;
      if (enrollments.length > 0) {
        currentOpenEnrollment = enrollments.find(
          (enrollment) => enrollment.isEnrollmentOpen
        );
      }

      let builtRow = {
        id: id,
        enrollmentId: enrollmentId,
        isVip: isVip,
        fullName: fullName,
        email: primaryEmail,
        phoneNumber:
          phoneNumbers.length > 0 &&
          formatPhoneNumber(
            phoneNumbers.find((number) => number.isPreferred)?.phoneNumber ||
              "No info"
          ),
        // address: `${member.street1} ${member.street2 || ''}`,
        dob: dob,
        association: organizations.find((org) => org.isPrimary)
          ?.organizationName,
        associationId: organizations.find((org) => org.isPrimary)
          ?.organizationId,
        enrollmentStatus: currentOpenEnrollment
          ? currentOpenEnrollment?.enrollmentStateId
          : 0,
        workingStatus: workStatusId,
        activelyCovered: setCoveredStatus(enrollments)
      };
      _rows.push(builtRow);
    });
    setRows(_rows);
  };
  useEffect(() => {
    if (searchState.results.length > 0) {
      // console.log(props.allMembers)
      buildRows();
    } else if (searchState.terms.length > 0 && searchState.results.length < 1) {
      setRows([{ id: 0 }]);
    }
    getGridCols()
  }, [searchState.results.length]);

  const columns = [
    { field: "id", headerName: "ID", flex: 90, hide: true },
    {
      field: "isVip",
      headerName: "",
      flex: 20,
      headerClassName: "hide-right-sep",
      renderCell: (params) => {
        return <div>{params.value && <StarIcon color="warning" />}</div>;
        // console.log('renderparam',params)
      },
    },
    {
      field: "fullName",
      headerName: "Name",
      flex: 200,
      // valueGetter: (params) => {
      //    console.log(params)
      //    return params.row.enrollmentId
      // },
      renderCell: (params) => {
        // console.log(params)
        return (
          <div style={{ display: "block" }}>
            <Typography
              variant="body1"
              color="primary.accent"
              fontWeight="600"
              style={{ fontSize: 16 }}
            >
              {params.value}
            </Typography>
            <Typography variant="overline" style={{ fontSize: 13 }}>
              {params.row.enrollmentId}
            </Typography>
          </div>
        );
      },
    },
    // {
    //    field: 'enrollmentId',
    //    headerName: 'Enrollment ID',
    //    flex: 120,
    // },
    {
      field: "association",
      headerName: "Association",
      flex: 150,
      renderCell: (params) => {
        console.log('assoc params BOGGS',params.row)
        return (
          <>
          {params?.row?.associationId > 0 &&
            <img
              style={{ height: "30px", width: "30px", marginRight: "7px" }}
              src={`/api/image/organization/${params.row.associationId}`}
              alt='organization logo'
              onError={(e) => {e.target.style.display = 'none'}}
            />
          }
          {params.value}
          </>
        );
      },
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      flex: 180,
      renderCell: (params) => {
        return <>{params.value != "No info" && params.value}</>;
      },
    },
    // {
    //    field: 'address',
    //    headerName: 'Address',
    //    flex: 220,
    // },
    {
      field: "dob",
      headerName: "Date of Birth",
      // valueFormatter: ({ value }) => {
      //   if (value) {
      //     return format(
      //       new Date(`${value.slice(0, 10)}T00:00:00`),
      //       "MM/dd/yyyy"
      //     );
      //   }
      // },
      renderCell: (props, params) => {
        if (props.formattedValue) {
          let currentDate = dateFormatter(new Date());
          let currentYear = Number(currentDate.slice(-4));
          let birthDate = dateFormatter(props.formattedValue);
          let birthYear = Number(birthDate.slice(-4));
          // console.log("birthYear", birthYear, currentYear);
          //avoid rendering 9999, 0001, or similar birthdates that can occur from missing data
          if (birthYear <= 1800 || birthYear >= currentYear || isNaN(birthYear)) {
            return "N/A";
          } else return birthDate;
        } else return "N/A";
      },
      type: "dateTime",
      flex: 150,
    },
    {
      field: "email",
      headerName: "Email",
      renderCell: (params) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              // console.log("email");
              return false;
            }}
          >
            {params.value}
          </a>
        );
      },
      flex: 250,
    },
    {
      field: "enrollmentStatus",
      headerName: "Application Status",
      flex: 180,
      renderCell: ({ value }) => {
        // If Coverage Effective date in past and No cancellation date, Actively Enrolled
        // If No Coverage Effective and No Enrollment Status, Not Actively Enrolled
        // If No Coverage Effective and Enrollment Status of 2, Submitted Application
        // If No Coverage Effective and Enrollment Status 1, In Progress Application
        // If Cancellation Date, Cancelled
        // If Enrollment ID in Open Enrollment, Open Enrollment
        switch (value) {
          case null:
            return (
              <Chip
                sx={{
                  backgroundColor: "action.selected",
                  color: "text.primary",
                  fontWeight: "500",
                  fontFamily: "Archivo",
                  fontSize: ".9rem",
                }}
                label="No Enrollment"
              />
            );
            break;
          case 0:
            return (
              <Chip
                sx={{
                  backgroundColor: "action.selected",
                  color: "text.primary",
                  fontWeight: "500",
                  fontFamily: "Archivo",
                  fontSize: ".9rem",
                }}
                label="No Enrollment"
              />
            );
            break;
          case 1:
            return (
              <Chip
                sx={{
                  backgroundColor: "background.inProgressChip",
                  color: "primary.inProgressChip",
                  fontWeight: "500",
                  fontFamily: "Archivo",
                  fontSize: ".9rem",
                }}
                label="In Progress Enrollment"
              />
            );
            break;
          case 2:
            return (
              <Chip
                sx={{
                  backgroundColor: "background.enrollmentSubmittedChip",
                  color: "primary.enrollmentSubmittedChip",
                  fontWeight: "500",
                  fontFamily: "Archivo",
                  fontSize: ".9rem",
                }}
                label="Submitted Enrollment"
              />
            );
            break;
          case 3:
            return (
              <Chip
                sx={{
                  backgroundColor: "background.enrollmentCancelledChip",
                  color: "primary.enrollmentCancelledChip",
                  fontWeight: "500",
                  fontFamily: "Archivo",
                  fontSize: ".9rem",
                }}
                label="Canceled Enrollment"
              />
            );
            break;
          default:
            console.warn("No matching chip found");
        }
      },
    },
    {
      field: "activelyCovered",
      headerName: "Coverage Status",
      flex: 150,
      renderCell: (params) => {
        if (params.value){
          return (
            <Chip
              sx={{
                backgroundColor: params.value == 'Active' ? "background.activeWorking" : "action.selected",
                color: params.value == 'Active' ? "text.activeWorking" : "text.primary",
                fontWeight: "500",
                fontFamily: "Archivo",
                fontSize: ".9rem",
              }}
              label={params.value}
            />
          );
        } 
      },
    },                
    {
      field: "workingStatus",
      headerName: "Working Status",
      flex: 180,
      renderCell: ({ value }) => {
        return <WorkingStatusChips workingStatusId={value} employmentStatuses={employmentStatuses}/>;
      },
    },
  ];

  // Event Handlers
  const handleRowClick = (e) => {
    console.log(e);
    history.push(`/members/${e.id}`, { profile: true, profileId: e.id });
  };

  //NEW MEMBER DRAWER
  const [newMemberDrawerOpen, setNewMemberDrawerOpen] = useState(false);

  const checkPrepopulateLastSearch = () => {
    if (history.location.state?.useLastHistory) {
      setSearchState({
        ...searchState,
        terms: searchHistory[0]?.term,
      });
    }
  };

  useEffect(() => {
    getEmploymentStatuses();
    getSearchHistory();
  }, []);

  useEffect(() => {
    checkPrepopulateLastSearch();
  }, [history.location.state]);

  const handleClearSearch = () => {
    setSearchState({
      display: false,
      terms: "",
      results: [],
    });
  };

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: []
 })

  return (
    <Box
      sx={{
        bgcolor: "background.background",
        color: "text.primary",
      }}
      style={{ padding: "20px", height: "calc(100vh - 56px)" }}
    >
      <style>
        {`
            .popperOverride li, .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
            font-family:Poppins;
            font-size:.92rem;
            // color:red;
            }
            .hide-right-sep .MuiDataGrid-columnSeparator {
               visibility:hidden
            }
            `}
      </style>

      <Box
        className="flex-row"
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          border: "solid 1px",
          borderColor: "background.border",
          borderBottom: 0,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
          flexWrap: "wrap",
        }}
      >
        <div
          className="col-6"
          style={{ display: "flex", padding: "23px 35px 0px" }}
        >
          <span
            style={{ fontSize: "32px", fontWeight: 500, fontFamily: "Poppins" }}
          >
            Members
          </span>
        </div>

        <div
          className="col-6"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "23px 57px 0px",
          }}
        >
          <Button
            variant="contained"
            sx={{ height: 40 }}
            startIcon={<AddIcon />}
            onClick={() => setNewMemberDrawerOpen(true)}
          >
            Add Member
          </Button>
        </div>
        <div
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "5px 57px 11px",
          }}
        >
          <>
            <TextField
              id="member-search"
              variant="outlined"
              value={searchState.terms}
              onChange={handleTermsInput}
              onKeyPress={(e) => e.key === "Enter" && searchAsync()}
              // label=" " InputLabelProps={{sx:{color:"#CCCCCC",fontFamily:'Poppins',fontSize:14}}}
              placeholder="Search member"
              spellCheck={false}
              size="small"
              fullWidth
              sx={{
                display: "block",
                marginLeft: "auto",
                width: "50%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                "& input::placeholder": {
                  fontFamily: "Poppins",
                  fontSize: "14px",
                },
              }}
              InputProps={{
                // endAdornment:searchAdornment,
                startAdornment: searchAdornment,
                sx: { paddingLeft: "0px" },
                endAdornment: searchState.terms && (
                  <IconButton
                    aria-label="clear-search"
                    onClick={() => handleClearSearch()}
                  >
                    <CancelRoundedIcon />
                  </IconButton>
                ),
              }}
            />
          </>
        </div>
        <CreateMemberDrawer
          newMemberDrawerOpen={newMemberDrawerOpen}
          setNewMemberDrawerOpen={setNewMemberDrawerOpen}
        />
      </Box>
      <div style={{ height: "calc(100% - 125px)" }}>
        <OurDataGrid
          manualHeight
          rows={rows}
          columnVisibilityModel={gridCols}
          setGridCols={setGridCols}
          columns={columns}
          handleRowClick={handleRowClick}
          manualStyle={{
            borderRadius: "15px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
          }}
          noRowsComp={NoMemberRows}
          noResultsComp={NoMemberResults}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          loadingState={loadingState}
          showSaveFilterModel={false}
        />
      </div>
    </Box>
  );
}

import React, { useEffect,useLayoutEffect, useState } from "react";
import { Switch, useHistory, useLocation, Route } from 'react-router-dom';

//Assets
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg'


export default function PoweredBy (props) {


   return (
      <>
         <div className="flex-row" style={{ fontSize: '13px', fontWeight: 800, position:'inherit', bottom:0, justifyContent: 'space-around', padding: '20px 0px', width:'100%', margin:'auto', minHeight:'50px'}} >
            <span style={{margin: 'auto',display:'flex',alignItems:'center'}}>POWERED BY &nbsp; <img style={{ height: '21px' }} src={FullColorBlackText} /></span>
         </div>
      </>
    
   )
}
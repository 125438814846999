import React, { useEffect, useState, useRef } from "react"

//MuiComponents
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import Drawer from "@mui/material/Drawer"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import Paper from "@mui/material/Paper"
import Alert from "@mui/material/Alert"
import Link from "@mui/material/Link"

//MuiIcons
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"

//our components
import InfinityLoader from "../../../../../../UH-loading-animation/InfinityLoader"

export default function CancelAllCoverageModal(props) {
  const componentStyles = {
    modalBody: {
      position: "absolute",
      borderRadius: "12px 12px 12px 12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "580px",
      margin: "0 auto"
    },
    messageContainer: {
      borderRadius: "12px 12px 12px 12px",
      padding: "16px 16px 24px 36px",
      bgcolor: "background.default",
      color: "text.primary",
      minWidth: "425px",
      minHeight: "152px",
    },
    headerContainer: {
      color: "primary.main",
      marginBottom: "10px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      color: 'primary.main',
      marginTop: '20px',
    },
  }
  return (
    <Modal
      open={props.open}
      onClose={props.handleCloseCancelAllModal}
      sx={componentStyles.modalBody}
      disableScrollLock={true}
    >
      <Paper sx={componentStyles.messageContainer}>
        <Box
          className="flex-row"
          sx={componentStyles.headerContainer}
        >
          <Typography sx={{fontSize: '24px'}}>Cancel All Coverage</Typography>
          <IconButton onClick={props.handleCloseCancelAllModal} sx={{}}>
            <CloseIcon sx={{ color: "primary.main" }} />
          </IconButton>
        </Box>
        <Box sx={{margin: '0 auto'}}>
          <Typography sx={{width: '90%'}}>
          Are you sure you want to cancel all active coverage for this member? Existing payment schedules will stop at the end of the month of cancelation.  Immediate payment disabling can be done in the{" "} <Link value="5" sx={{cursor: 'pointer'}} onClick={props?.handleActivePageChange} >Payments Tab</Link>.

          </Typography>
        </Box>

        {/* Buttons */}
        <Box sx={componentStyles.buttonContainer}>
          <Button
            sx={{ marginRight: "10px" }}
            onClick={props?.handleCloseCancelAllModal}
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            disabled={props?.loading}
            onClick={() => props?.handleCancelAllCoverage()}
          >
            {props?.loading ?
              <>
                <div style={{width:'100%',position:'absolute'}}>
                    <InfinityLoader style={{
                      zIndex:5,
                      height:'38px',
                    }}/> 
                </div>
                <span style={{opacity:0}}>SUBMIT CANCELATION</span>
              </>
              :
              <>
                SUBMIT CANCELATION
              </>
              }
          </Button>
        </Box>

        {/* Error Message if cancellation is not successful */}
        {props?.cancelAllError && <Box>
          <Alert sx={{width: '100%', marginTop: '20px' }} severity="error">
            An error occurred. Cancelation not submitted.
          </Alert>
        </Box>}

      </Paper>
    </Modal>
  )
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

//Services
import authService from '../../../services/auth-service';
import registerService from '../../../services/register-service';

//MuiComponents
import { TextField, Button, Typography } from "@mui/material";

//MuiIcons
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

//Our Components
import PasswordStrength from '../password-strength/PasswordStrength';
import DuplicateEmailDrawer from './DuplicateEmailDrawer'

function ExternalRegisterSetup(props) {
    const [values, setValues] = useState({
        email: '',
        password: '',
        ageCheck: true,
        firstName: props.member.firstName,
        lastName: props.member.lastName,
        memberId: props.member.id,
        organizationId: props?.organization?.id,
    });
    // const fullName = `${props.member.firstName} ${props.member.lastName}`;

    const history = useHistory();

    //stop loading screen on page load
    useEffect(() => {
      props.setLoading(false)

    }, [])
    
    //Change handlers
    const handleValueChange = (e) => {
        let inputVal = e.target.value
        let inputProperty = e.target.getAttribute('data-property')
        setValues({
            ...values,
            [inputProperty]: inputVal
        })

        if(inputProperty === "email"){
            checkEmailError(inputVal)
        }   
    }

    const passwordKeyPressHandler = (e) => {
        if (e.key === 'Enter' && !disableButton) finishRegistration()
        if (e.key === ' ') e.preventDefault()
    }

    //Password visibility
    const [displayPassword, setDisplayPassword] = useState(false);
    const swapIcon = (e) => {
        //e.preventDefault();

        let inputElement = e.target.parentNode.children[0];
        if (displayPassword) {
            inputElement.setAttribute("type", "password");
            setDisplayPassword(false);
        } else {
            inputElement.setAttribute("type", "text");
            setDisplayPassword(true);
        }
    }

    const visibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onMouseDown={swapIcon} onMouseUp={swapIcon} onTouchStart={swapIcon} onTouchEnd={swapIcon}><VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
    const noVisibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onClick={swapIcon}><NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>

    let PasswordIcon = displayPassword ? noVisibilityIcon : visibilityIcon;

    //Password strength
    const [disableButton, setDisableButton] = useState(true)

    const strengthPassed = (e) => {
        setDisableButton(false)
    }
    const strengthNotPassed = (e) => {
        setDisableButton(true)
    }


    const [emailSent, setEmailSent] = useState(false);

    const getCookie = (cookieName) => {
        let name = `${cookieName}=`
        let cookieArray = document.cookie.split(';')
        let value = ''
        cookieArray.forEach(cookie => {
            if (cookie.includes(name)) value = cookie.replace(name, '').trim()
        })
        return value
    }

    const [showLoginButton, setShowLoginButton] = useState(false)
    
    const backToLogin = () => {
        history.push("/login")
    }

    const finishRegistration = async (e) => {
        //e.preventDefault();
        // console.log(values)
        props.setLoading(true)
        let allowContinue = true
        await registerService.register(values).catch(error => {
            console.log('registration error',error.response)
            allowContinue = false
            if (error.response.status == 409) {
                props.setLoading(false)
                setEmailError("This email is already registered with an account")
                setShowEmailError(true)
                setShowLoginButton(true)
            }
        })
        // console.log(response)
        //Send the confirmation email
        // setEmailSent(true);

        //FOR THE DEMO
        // history.push("/calculator")
        if (allowContinue){
            continueToEnrollments()
        } 
        // continueToDashboard()
    }

    const continueToEnrollments = async (e) => {
        let isAuthenticated = false;
        const credentials = {
            username: values.email,
            password: values.password
        }
        isAuthenticated = await authService.authenticate(credentials);

        if (isAuthenticated) {
            let domain = getCookie('subdomain')

            window.location.href = `https://${domain}/enrollments`
        }
        else {
            console.log('not successful');
        }
    }


    // Email Error Handling
    const [showEmailError, setShowEmailError] = useState(false)
    const [emailError, setEmailError] = useState(null)

    const isValidEmail = (email) => {
        if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
            return false
        } else return true
    }

    const checkEmailError = (value = values.email) => {
        if (!isValidEmail(value)) {
            setShowEmailError(true)
            setEmailError('Please provide a valid email address')
        } else {
            setShowEmailError(false)
            setEmailError(null)
        }
    }

       //Duplicate Email Drawer/Modal Logic
       const [open, setOpen] = useState(false);
       const handleOpen = () => {
      //logic need to find if duplicate email found, set open true
       };
       const handleClose = () => setOpen(false);


    return (
        <>

            <style>
                {`
            #member-landing-container > div{
                display:flex;
                justify-content:center;
                text-align:center;
            }
            .continue-register-btn.Mui-disabled {
                color:white;
                background:#646464!important
            }
            `}
            </style>

            <DuplicateEmailDrawer open={open} handleClose={handleClose} handleOpen={handleOpen}/>
            <div className="flex-row" style={{ marginBottom: '20px', justifyContent: 'center', textAlign: 'center' }} >
                <Typography variant="subtitle" fontWeight="600">Create your account</Typography>
            </div>

            <div className="flex-row" style={{ marginBottom: '20px' }} >
                <TextField fullWidth sx={{ maxWidth: '575px' }}
                    type='email'
                    label="Email" variant="outlined"
                    error={showEmailError && emailError}
                    helperText={showEmailError && emailError}
                    value={values.email} 
                    onChange={handleValueChange}
                    inputProps={{ 'data-property': 'email' }}
                />
            </div>
            {showLoginButton &&
                <div className="flex-row" style={{ marginBottom: '20px' }} >
                    <Button fullWidth variant='contained'
                        sx={{ height: 50, maxWidth: '574px' }}
                        onClick={backToLogin}
                    >
                        Take me to login <KeyboardArrowRightIcon />
                    </Button>
                </div>}

            <div className="flex-row" style={{ marginBottom: '20px', justifyContent: 'center', textAlign: 'left' }}>
                <Typography variant="body1">Please create a secure password including the criteria below.</Typography>
            </div>

            <div className="flex-row" style={{ marginBottom: '30px' }}>
                <TextField fullWidth sx={{ maxWidth: '575px' }}
                    label="Password" variant="outlined"
                    value={values.password} 
                    autoComplete='off'
                    onChange={handleValueChange}
                    onKeyPress={passwordKeyPressHandler}
                    inputProps={{ 'data-property': 'password' }}
                />
            </div>

            <div className="flex-row" style={{ marginBottom: '30px', width: '100%' }}>
                <PasswordStrength passwordValue={values.password} strengthPassed={strengthPassed} strengthNotPassed={strengthNotPassed} />
            </div>

            <div className="flex-row" style={{ marginBottom: '150px' }} >
                <Button variant='contained' fullWidth
                    sx={{ height: 50, maxWidth: '575px' }}
                    disabled={disableButton}
                    onClick={finishRegistration}
                >
                    Create Account
                </Button>
            </div>

            
        </>
    );
};

export default ExternalRegisterSetup;
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
// import {RadialBarChart, RadialBar, Legend, Tooltip} from 'recharts'
import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Legend,
  Tooltip,
  PolarAngleAxis,
  RadialBarChart,
  RadialBar,
} from "recharts";

import { Paper, useTheme, Typography, Tabs, Tab, Button } from "@mui/material";
//Services
import dashboardService from "../../../../services/dashboard-service";
import { add } from "date-fns";

export default function EnrollmentReport(props) {
  const [report, setReport] = useState([]);
  const getReport = async () => {
    let _report = await dashboardService.getEnrollmentReport(props.groupPlanId);
    if (_report) setReport(_report);
    console.log(_report);
  };

  useEffect(() => {
    if (props.groupPlanId > 0) getReport();
  }, [props.groupPlanId]);

  const [enrollmentStatus, setEnrollmentStatus] = useState([
    {
      status: 0,
      count: 0,
    },
    {
      status: 1,
      count: 0,
    },
  ]);
  const [enrollDate, setEnrollDate] = useState({});

  const [std, setStd] = useState(0);
  const [ltd, setLtd] = useState(0);
  const [ladd, setLadd] = useState({
    member: 0,
    spouse: 0,
    dependent: 0,
  });

  const [lineOfCoverage, setLineOfCoverage] = useState([]);

  const createReports = () => {
    let submitted = 0;
    let notSubmitted = 0;
    let submittedDates = {};
    let _std = 0;
    let _ltd = 0;
    let _laddMember = 0;
    let _laddSpouse = 0;
    let _laddDependent = 0;
    if (report) {
      for (let member of report) {
        if (member?.enrollmentStateId == 1) notSubmitted += 1;
        else if (member?.enrollmentStateId == 2) {
          let preFormatDate = new Date(
            `${
              member?.submissionDate && member.submissionDate.slice(0, 10)
            }T00:00:00`
          );
          let cleanDate = `${
            preFormatDate.getMonth() + 1
          }/${preFormatDate.getDate()}`;
          if (submittedDates[cleanDate]) {
            submittedDates[cleanDate] += 1;
          } else {
            submittedDates[cleanDate] = 1;
          }

          submitted += 1;
          if (member?.stdPlan != null) {
            _std += 1;
          }
          if (member?.ltdPlan != null) {
            _ltd += 1;
          }
          if (member?.memberCoverageAmount > 0) {
            _laddMember += 1;
          }
          if (member?.spouseCoverageAmount > 0) {
            _laddSpouse += 1;
          }
          if (member?.dependentCoverageAmount > 0) {
            _laddDependent += 1;
          }
        }
      }
    }

    setLadd({
      ...ladd,
      member: _laddMember,
      spouse: _laddSpouse,
      dependent: _laddDependent,
    });
    setStd(_std);
    setLtd(_ltd);
    setLineOfCoverage([
      { lineOfCoverage: "Short Term Disability", count: _std },
      { lineOfCoverage: "Long Term Disability", count: _ltd },
      { lineOfCoverage: "Life & AD&D", count: _laddMember },
      { lineOfCoverage: "Life & AD&D Spouse", count: _laddSpouse },
      { lineOfCoverage: "Life & AD&D Dependent", count: _laddDependent },
    ]);

    setEnrollmentStatus([
      {
        status: "Submitted",
        count: submitted,
      },
      {
        status: "In Progress",
        count: notSubmitted,
      },
    ]);

    let _enrollDate = [];
    for (let [key, val] of Object.entries(submittedDates)) {
      let dateObj = {
        date: key,
        count: val,
      };
      _enrollDate.push(dateObj);
    }
    setEnrollDate(_enrollDate);
    console.log(_enrollDate);
  };

  useEffect(() => {
    createReports();
  }, [report]);

  let data = [
    {
      Id: 0,
      MemberId: 0,
      LTDPlan: "",
      STDPlan: "",
      MemberCoverageAmount: 0,
      SpouseCoverageAmount: 0,
      DependentCoverageAmount: 0,
      SubmissionDate: "",
      SubmissionStatusId: 0,
      SubmissionStatus: "Submitted",
    },
    {
      Id: 1,
      MemberId: 1,
      LTDPlan: "",
      STDPlan: "",
      MemberCoverageAmount: 0,
      SpouseCoverageAmount: 0,
      DependentCoverageAmount: 0,
      SubmissionDate: "",
      SubmissionStatusId: 1,
      SubmissionStatus: "In Progress",
    },
  ];
  let theme = useTheme();

  const COLORS = [
    `${theme?.palette?.primary?.main}`,
    `${theme?.palette?.secondary?.main}`,
  ];
  const circleSize = 300;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ padding: "20px" }}>
          <p className="label">{`${label}`}</p>
          <p>{`${payload[0].value}`} Enrollment(s)</p>
        </Paper>
      );
    }

    return null;
  };

  const [enrollmentGoal, setEnrollmentGoal] = useState([]);
  const getGoals = () => {
    let _enrollmentGoal = props?.goals?.find(
      (goal) => goal?.name == "Total Enrollments"
    );
    setEnrollmentGoal(_enrollmentGoal?.goal);
  };

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getGoals();
  }, [props.goals]);

  return (
    <>
      {/* <Box sx={{ color: 'text.primary', fontFamily: 'Archivo' }}>
            <ResponsiveContainer width={"100%"} height={300} min-width={300}>
               <PieChart >
                  <Pie data={enrollmentStatus} dataKey='count' nameKey='status' cx='50%' cy='80%' fill={theme?.palette?.secondary?.main} label='status' outerRadius={90} innerRadius={60} startAngle={180} endAngle={0}>
                     {enrollmentStatus.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />

                     ))}
                  </Pie>
                  <Legend />
               </PieChart>
            </ResponsiveContainer>
            </Box> */}

      <Box
        sx={{
          color: "text.primary",
          fontFamily: "Archivo",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          flexBasis: "content",
          marginBottom: "20px",
        }}
      >
        <ResponsiveContainer
          width={circleSize}
          height={circleSize / 1.5}
          min-width={circleSize}
        >
          <RadialBarChart
            // width={circleSize}
            // height={circleSize}
            cx={circleSize / 2}
            cy={circleSize / 2}
            innerRadius={circleSize / 2.8}
            outerRadius={circleSize / 1.8}
            barSize={25}
            data={enrollmentStatus.filter(
              (enrollment) => enrollment.status == "Submitted"
            )}
            startAngle={180}
            endAngle={0}
          >
            <>
              <PolarAngleAxis
                type="number"
                domain={[0, enrollmentGoal]}
                angleAxisId={0}
                dataKey="count"
                tick={false}
              />
              <RadialBar
                background={{
                  fill:
                    theme?.palette?.mode == "light"
                      ? theme?.palette?.background?.background
                      : theme?.palette?.background?.background,
                }}
                clockWise
                dataKey="count"
                cornerRadius={circleSize / 2}
                key={`cell-${data.status}`}
                fill={theme?.palette?.primary?.main}
              />
            </>

            <text
              x={circleSize / 2}
              y={circleSize / 2.6}
              style={{ fontWeight: "600", fontSize: "25px" }}
              fill={theme?.palette?.secondary?.main}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
            >
              {enrollmentStatus &&
                enrollmentStatus?.find(
                  (enrollment) => enrollment.status == "Submitted"
                )?.count}
            </text>
            <text
              x={circleSize / 2}
              y={circleSize / 2.1}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill={theme?.palette?.text?.primary}
            >
              Submitted Enrollments
            </text>
            <text
              x={circleSize / 7}
              y={circleSize / 1.85}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill={theme?.palette?.text?.primary}
              style={{ fontSize: "12px" }}
            >
              0
            </text>
            <text
              x={circleSize / 1.17}
              y={circleSize / 1.85}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill={theme?.palette?.text?.primary}
              style={{ fontSize: "12px" }}
            >
              {enrollmentGoal}
            </text>
          </RadialBarChart>
        </ResponsiveContainer>
        <Paper elevation={0} sx={{ margin: "auto", padding: "20px" }}>
          <Typography
            textAlign={"center"}
            color={theme?.palette?.secondary?.main}
            fontSize={25}
          >
            {enrollmentStatus &&
              enrollmentStatus?.find(
                (enrollment) => enrollment.status == "In Progress"
              )?.count}
          </Typography>
          <Typography textAlign={"center"}>in progress enrollments</Typography>
          {/* <Button sx={{marginTop:'10px'}} variant='outlined' disabled={enrollmentStatus && enrollmentStatus?.find(enrollment => enrollment.status == 'In Progress')?.count < 1}>Download In Progress Report</Button> */}
        </Paper>
      </Box>
      <Box sx={{ marginBottom: "20px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab value={1} label="Enrollments by Date" />
          <Tab value={2} label="Enrollments by Line of Coverage" />
        </Tabs>
      </Box>

      {value == 1 && (
        <>
          {enrollDate.length > 0 && (
            <Box sx={{ color: "text.primary", fontFamily: "Archivo" }}>
              <ResponsiveContainer width={"100%"} height={300} min-width={600}>
                <BarChart
                  data={enrollDate}
                  margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
                >
                  {/* <CartesianGrid strokeDasharray={'3'} /> */}
                  <XAxis
                    dataKey={"date"}
                    label={{
                      value: "Submission Date",
                      offset: "2",
                      position: "bottom",
                      fill: theme?.palette?.text?.primary,
                    }}
                  ></XAxis>
                  <YAxis
                    interval={0}
                    tickCount={6}
                    label={{
                      value: "Submitted Enrollments",
                      angle: -90,
                      position: "insideBottomLeft",
                      fill: theme?.palette?.text?.primary,
                    }}
                  ></YAxis>
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey={"count"} fill={theme?.palette?.primary?.main}>
                    <LabelList dataKey="count" position="top" />{" "}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </>
      )}
      {value == 2 && (
        <>
          {lineOfCoverage.length > 0 && (
            // <Box sx={{ color: 'text.primary', fontFamily: 'Archivo' }}>
            //    <ResponsiveContainer width={"100%"} height={300} min-width={600}>
            //       <BarChart data={lineOfCoverage} margin={{ top: 20, bottom: 20, left: 20, right: 20 }}>
            //          {/* <CartesianGrid strokeDasharray={'3'} /> */}
            //          <XAxis dataKey={'lineOfCoverage'} label={{ value: 'Line of Coverage', position: 'bottom', fill: theme?.palette?.text?.primary }} >
            //          </XAxis>
            //          <YAxis dataKey={'count'} interval={0} tickCount={6} label={{ value: 'Submitted Enrollments', angle: -90, position: 'insideBottomLeft', fill: theme?.palette?.text?.primary }}>
            //          </YAxis>
            //          <Tooltip content={<CustomTooltip />} />
            //          <Bar dataKey={'count'} fill={theme?.palette?.primary?.main} >
            //             <LabelList dataKey='count' position="top" /> </Bar>
            //       </BarChart>
            //    </ResponsiveContainer>
            // </Box>

            <Box sx={{ color: "text.primary", fontFamily: "Archivo" }}>
              <ResponsiveContainer width={"100%"} height={'90%'} minWidth={600} minHeight={500}>
                <BarChart
                  label={{ fill: theme?.palette?.text?.primary }}
                  layout="vertical"
                  data={lineOfCoverage}
                  margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
                >
                  {/* <CartesianGrid strokeDasharray={'3'} /> */}
                  <XAxis
                    type="number"
                    dataKey={"count"}
                    interval={0}
                    tickCount={6}
                    label={{
                      value: "Submitted Enrollments",
                      angle: 0,
                      position: "bottom",
                      fill: theme?.palette?.text?.primary,
                    }}
                  ></XAxis>
                  <YAxis
                    width={200}
                    type="category"
                    dataKey={"lineOfCoverage"}
                    tick={{ fill: theme?.palette?.text?.primary }}
                  ></YAxis>
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    dataKey={"count"}
                    fill={theme?.palette?.primary?.main}
                  ></Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </>
      )}
    </>
  );
}

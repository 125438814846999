import axios from "axios";
import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalDashboardService extends baseService {
    //options
    getGroupPlans() {
        const url = Settings.API_URL + "dashboard/groupplan";
        return this.get(url);
    }
    getEnrollmentWindows(groupPlanId) {
        const url = Settings.API_URL + "dashboard/windows/" + groupPlanId;
        return this.get(url);
    }

    getEnrollmentReport(groupPlanId) {
        const url = Settings.API_URL + "dashboard/enrollment/" + groupPlanId;
        return this.get(url);
    }
    getEnrollmentMethodsByGroupPlan(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/method/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }
    getPremiumsWithDescription(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/premiums/descriptions/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }
    getPremiumsByType(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/premiums/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }
    getEnrollmentsByState(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/state/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }
    getCsvEnrollmentsByState(groupPlanId, enrollmentWindowId, enrollmentStateId) {
        const url = `${Settings.API_URL}dashboard/enrollment/state/download/${groupPlanId}/${enrollmentWindowId}/${enrollmentStateId}`;
        return this.get(url);
    }
    getEnrollmentGoal(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/goal/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }
    getEnrolledByDate(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/methodbydate/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }
    getCSVEnrolledByDate(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/methodbydate/download/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }
    getPayments(groupPlanId, enrollmentWindowId) {
        const url = `${Settings.API_URL}dashboard/enrollment/payments/${groupPlanId}/${enrollmentWindowId}`;
        return this.get(url);
    }

    //POST ENROLLMENT QUERIES
    getPostEnrollCoverage(groupPlanId) {
        const url = `${Settings.API_URL}dashboard/postenrollment/coverage/${groupPlanId}`;
        return this.get(url);
    }
    getPostEnrollWorkingStatus(groupPlanId) {
        const url = `${Settings.API_URL}dashboard/postenrollment/workingstatus/${groupPlanId}`;
        return this.get(url);
    }
    getPostEnrollTickets(groupPlanId) {
        const url = `${Settings.API_URL}dashboard/postenrollment/tickets/${groupPlanId}`;
        return this.get(url);
    }
    getPostEnrollPayments(groupPlanId) {
        const url = `${Settings.API_URL}dashboard/postenrollment/paymentstats/${groupPlanId}`;
        return this.get(url);
    }

    getCSVPostEnrollPayments(groupPlanId) {
        const url = `${Settings.API_URL}dashboard/postenrollment/paymentstats/download/${groupPlanId}`;
        return this.get(url);
    }
    
    //EDIT GOALS - need to connect to existing backend method
    updateDashboardGoal(goal){
        const url = `${Settings.API_URL}dashboard/enrollment/goal`;
        return this.put(url, goal);
    
    }
}

const dashboardService = new internalDashboardService();
export default dashboardService;
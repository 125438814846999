// import './BenefitsWizardAccident.css';

import React, {useState, useEffect} from 'react';


//Assets
import BletLogo2 from '../../../../../assets/images/BLETLogo 2.png';

//Services
import enrollmentService from '../../../../../services/enrollment-service';

//MuiComponents
import Collapse from "@mui/material/Collapse";
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';

//Mui icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonIcon from "@mui/icons-material/RadioButtonUnchecked";

//Our Components
import OptionsCardMulti from '../../../external-dashboard/dash-benefits/options-card/options-card-multi/OptionsCardMulti';
import AccidentSpouseInputs from './accident-components/AccidentSpouseInputs';
import AccidentDependentsInputs from './accident-components/AccidentDependentsInputs';
import AccidentFamilyInputs from './accident-components/AccidentFamilyInputs';
// import AssistanceFooter from '../assistance-footer/AssistanceFooter';
// import SaveChangesSnackbar from '../../../../save-changes-snackbar/SaveChangesSnackbar'
// import UmpMobileStepper from '../../../mobile-stepper/UmpMobileStepper';
// import BenefitsWizardBeneficiary from '../benefits-wizard-beneficiary/BenefitsWizardBeneficiary';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from "../enrollmentPlanStyles";


export default function EnrollmentAccident(props) {
   let UsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
   let UsdFormatterDec = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });
   let results = props.results;
   let planInfo = results?.accidentPlan

   let options = planInfo?.options;
   let helpText = planInfo?.helpText

   const [spouseOpacity,setSpouseOpacity] = useState(props.recommendations.ShowSpouse ? 1 : .6)
   const [dependentOpacity,setDependentOpacity] = useState(props.recommendations.ShowDependent ? 1 : .6)
   const [familyOpacity,setFamilyOpacity] = useState((props.recommendations.ShowSpouse && props.recommendations.ShowDependent) ? 1 : .6)

   const [expanded,setExpanded] = useState(false) //keeping as closed initially 
   const handleExpandSection = () => {
      setExpanded(!expanded)
    }
  

   let expandIcon = expanded ? (
      <RemoveIcon
        fontSize="large"
        sx={{
          position: "absolute",
          right: "14%",
          top: "20%",
          color: "white",
          backgroundColor: "#195ffb",
          borderRadius: "100px",
          boxShadow: "-1px 1px 4px 0px #00000040",
        }}
      />
   ) : (
   <AddIcon
      fontSize="large"
      sx={{
         position: "absolute",
         right: "14%",
         top: "20%",
         color: "#195ffb",
         borderRadius: "100px",
         boxShadow: "-1px 1px 4px 0px #00000040",
      }}
   />
   );


   const [currRecc, setCurrRec] = useState(0);

   return (
      <>
   
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
         <span
            style={enrollmentPlanStyles.planTitle}
            onClick={handleExpandSection}
         >
         {props?.results?.name?.toLowerCase()?.includes('smart') ? "Accident Indemnity" : "Accident"}
            
         </span>

         <Box
            onClick={handleExpandSection}
            sx={enrollmentPlanStyles.expandAndCollapseButton}
         >
            {expandIcon}
         </Box>
      </Box>
        
      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
         <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: helpText }}
          />
        </Box>

         <RadioGroup
             onChange={props.selectionHandler}
             value={props.selections.accident || currRecc}
         >
         <>
            {options && options
            .sort((a, b) => a.displayOrder - b.displayOrder)
            .map(option => {

               let memberIsSelected = (props.selections.accident == option.memberOptionId)
               let memberAndSpouseIsSelected = (props.selections.accident == option.memberAndSpouseOptionId)
               let memberAndDependentsIsSelected = (props.selections.accident == option.memberAndDependentsOptionId)
               let familyIsSelected = (props.selections.accident == option.familyOptionId)

               if (!option.isDecline){
                  return <>
                     {/* MEMBER */}
                     {planInfo.memberOptionsAreEnabled && option.memberOptionId > 0 &&
                        <Box sx={enrollmentPlanStyles.optionRow}>
                           <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                              <Radio
                                 sx={enrollmentPlanStyles.planSelectRadioButton}
                                 value={option.memberOptionId}
                                 id={`${option.memberOptionId}`}
                                 name="accident"
                                 icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                                 checkedIcon={
                                    <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                 }
                                 onClick={() => {
                                 props.handleEnrollClick(option.memberOptionId);
                     
                                 }}
                                 inputProps={{
                                    "data-amount": option.memberPremiumAmount,
                                 }}
                              />

                              <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                                 <Typography sx={enrollmentPlanStyles.singleOptionText(memberIsSelected)}>
                                 Member
                                 </Typography>
                              </Box>
                           </Box>

                           {/* premium amount */}
                           <Box sx={enrollmentPlanStyles.singleOptionText(memberIsSelected)}
                           >
                              {memberIsSelected && "+"}
                              {UsdFormatterDec.format(
                                 option.memberPremiumAmount
                              )}
                           </Box>
                        </Box>
                     }

                     {/* MEMBER AND SPOUSE */}
                     {planInfo.memberAndSpouseOptionsAreEnabled && option.memberAndSpouseOptionId > 0 &&
                     <Box sx={enrollmentPlanStyles.optionAndRequiredInputsContainer}> 
                           <Box sx={enrollmentPlanStyles.optionRow}>
                                 <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                                    <Radio
                                       sx={enrollmentPlanStyles.planSelectRadioButton}
                                       value={option.memberAndSpouseOptionId}
                                       id={`${option.memberAndSpouseOptionId}`}
                                       name="accident"
                                       icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                                       checkedIcon={
                                          <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                       }
                                       onClick={() => {
                                          props.handleEnrollClick(option.memberAndSpouseOptionId);
                                       }}
                                       inputProps={{
                                          "data-amount": option.memberAndSpousePremiumAmount,
                                       }}
                                    />

                                    <Box 
                                       sx={{...enrollmentPlanStyles.singleOptionTextContainer,          opacity:memberAndSpouseIsSelected ? 1 : spouseOpacity }}
                                    >
                                          <Typography  sx={enrollmentPlanStyles.singleOptionText(memberAndSpouseIsSelected)}>
                                             Member and spouse
                                          </Typography>
                                          
                                    </Box>
                                 </Box> 

                                 {/* premium amount */} 
                                 <Box 
                                    sx={enrollmentPlanStyles.singleOptionText(memberAndSpouseIsSelected)}  
                                 >
                                    {memberAndSpouseIsSelected && "+"}
                                    {UsdFormatterDec.format(
                                       option.memberAndSpousePremiumAmount
                                    )}
                                 </Box>
                           </Box>

                           {/* spouse inputs */}
                              {memberAndSpouseIsSelected && 
                                 <AccidentSpouseInputs
                                    spouse={props.spouse} 
                                    setSpouse={props.setSpouse} 
                                    genderTypes={props?.genderTypes}
                                 />
                              }
                     </Box>
                     }

                     {/* MEMBER AND DEPENDENTS  */}
                     {planInfo.memberAndDependentsAreEnabled && option.memberAndDependentsOptionId > 0 &&
                        <Box sx={enrollmentPlanStyles.optionAndRequiredInputsContainer}> 
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                 <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                                    <Radio
                                       sx={enrollmentPlanStyles.planSelectRadioButton}
                                       value={option.memberAndDependentsOptionId}
                                       id={`${option.memberAndDependentsOptionId}`}
                                       name="accident"
                                       icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                                       checkedIcon={
                                          <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                       }
                                       onClick={() => {
                                          props.handleEnrollClick(option.memberAndDependentsOptionId);
                                       }}
                                       inputProps={{
                                          "data-amount": option.memberAndDependentsPremiumAmount,
                                       }}
                                    />

                                    <Box sx={{...enrollmentPlanStyles.singleOptionTextContainer, opacity:memberAndDependentsIsSelected ? 1 : dependentOpacity }}>
                                       <Typography sx={enrollmentPlanStyles.singleOptionText(memberAndDependentsIsSelected)}>
                                          Member and dependents
                                       </Typography>
                                    </Box>
                                 </Box>

                                 {/* premium amount */}
                                 <Box
                                    sx={enrollmentPlanStyles.singleOptionText(memberAndDependentsIsSelected )} 
                                 >
                                    {memberAndDependentsIsSelected && "+"}
                                    {UsdFormatterDec.format(
                                       option.memberAndDependentsPremiumAmount
                                    )}
                                 </Box>
                              </Box>
                              {/* dependent inputs */}
                                 {memberAndDependentsIsSelected &&
                                    <AccidentDependentsInputs 
                                       dependents={props.dependents} 
                                       setDependents={props.setDependents}
                                       genderTypes={props?.genderTypes}
                                    />
                                 }
                        </Box>
                     }

                     {/* FAMILY */}
                     {planInfo.familyOptionsAreEnabled && option.familyOptionId > 0 &&
                      <Box sx={enrollmentPlanStyles.optionAndRequiredInputsContainer}> 
                        <Box sx={enrollmentPlanStyles.optionRow}>
                           <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                              <Radio
                                 sx={enrollmentPlanStyles.planSelectRadioButton}
                                 value={option.familyOptionId}
                                 id={`${option.familyOptionId}`}
                                 name="accident"
                                 icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                                 checkedIcon={
                                    <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                                 }
                                 onClick={() => {
                                    props.handleEnrollClick(option.familyOptionId);
                                 }}
                                 inputProps={{
                                    "data-amount": option.familyPremiumAmount,
                                 }}
                              />

                              <Box
                                 sx={{...enrollmentPlanStyles.singleOptionTextContainer, opacity: familyIsSelected ? 1 : familyOpacity }}
                              >
                                 <Typography  sx={enrollmentPlanStyles.singleOptionText(familyIsSelected)}>
                                    Member and family
                                 </Typography>
                              </Box>
                           </Box>

                           {/* premium amount */} 
                           <Box
                              sx={enrollmentPlanStyles.singleOptionText(familyIsSelected)} 
                           >
                              {familyIsSelected && "+"}
                              {UsdFormatterDec.format(
                                 option.familyPremiumAmount
                              )}
                           </Box>
                        </Box>

                        {/* family inputs */}
                        {familyIsSelected &&
                           <AccidentFamilyInputs 
                              spouse={props.spouse} setSpouse={props.setSpouse}
                              dependents={props.dependents} setDependents={props.setDependents} genderTypes={props?.genderTypes}
                           />
                        }
                     </Box>
                     }
                  </>
               }
               else {
                  return (
                     <Box
                        key={option.memberPremiumAmount}
                        sx={enrollmentPlanStyles.declineOptionRow}
                     >
                        <Radio
                           sx={enrollmentPlanStyles.planSelectRadioButton}
                           value={option.memberOptionId}
                           name="accident"
                           icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                           checkedIcon={
                              <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                           }
                           onClick={() => {
                              props.handleEnrollClick(option.memberOptionId);
                           
                           }}
                           inputProps={{
                              "data-amount": option.memberPremiumAmount,
                           }}
                        />
                        <Box sx={enrollmentPlanStyles.declineOptionText(memberIsSelected)} >
                        Not Now
                        </Box>
                     </Box>
                  )
               }
            })}
         </>
         </RadioGroup>
      </Collapse>

      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.accident && props.selections.accidentVal > 0 && (
            <>
              <Box >
                <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtionIconCollapse} />
                {
                  planInfo.options.map((option) => {
                     if (props.selections.accident == option.memberOptionId) return 'Member'
                     else if (props.selections.accident == option.memberAndSpouseOptionId) return 'Member and Spouse'
                     else if (props.selections.accident == option.memberAndDependentsOptionId) return 'Member and Dependents'
                     else if (props.selections.accident == option.familyOptionId) return 'Family'
                  })
                }
              </Box>

              <Box  sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                + {UsdFormatterDec.format(props.selections.accidentVal)}
              </Box>
            </>
          )}
          {props.selections.accident && props.selections.accidentVal == 0 && (
            <>
              <span>
                <em>not elected</em>
              </span>
              <span style={enrollmentPlanStyles.notElectedZeroAmount}>
                $0.00
              </span>
            </>
          )}
          {!props.selections.accident && "No plan selected"}
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
      
      </>
   )

}


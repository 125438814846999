import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTheme, useMediaQuery, Fade } from "@mui/material";

//Assets
import FullColorBlackText from '../../../../assets/images/FullColorBlackText.svg'
import defaultLogo from '../../../../assets/images/FullColorOnlyLogo.png'

//Services
import enrollmentService from '../../../../services/enrollment-service'

//MuiComponents
import Button from '@mui/material/Button';
import { Checkbox, Divider, Link } from "@mui/material";
import Typography from '@mui/material/Typography'
import Alert from "@mui/material/Alert";

// MUI Icons
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Our components
import PoweredBy from "../PoweredBy";
import authService from "../../../../services/auth-service";
import InfinityLoader from "../../../UH-loading-animation/InfinityLoader";


export default function EnrollmentComplete(props) {
   let history = useHistory()
   const handleHomeClick = () => {
      history.push('/dashboard')
   }

   const handleDownloadPDF = async () => {
      const link = document.createElement("a");
      // link.download = `/api/attachment/${props.guid}`;
      // link.href = `./api/attachment/${props.guid}`;
      if (props.agentEnroller) {
         let attachments = await enrollmentService.getAttachmentsForMember(props.fullMemberObj.id)
         
         link.download = `/api/attachment/${attachments[0]?.uniqueId}`;
         link.href = `./api/attachment/${attachments[0]?.uniqueId}`;
         // console.log('attachments',attachments)
      } else {
         let attachments = await enrollmentService.getAttachments()
         link.download = `/api/attachment/${attachments[0]?.uniqueId}`;
         link.href = `./api/attachment/${attachments[0]?.uniqueId}`;
         // console.log('attachments',attachments)
      }
      link.click();
   }

    const logout = () => {
      authService.signout();
      //localStorage.clear()
      history.push('/login');

   }

   const resumeEnrollment = async () => {
      history.push('/enrollments')
   }


   const [loading,setLoading] = useState(false)
   useEffect(()=> {
      setLoading(true)
      setTimeout(() => {
         setLoading(false)
      }, 5000)
   },[])

   const [ellipsis,setEllipsis] = useState('.')
   let ellipsisAnim = () => {
      setEllipsis(prev=>{
         if (prev == '...') return ''
         else return `${prev}.`
      })
   }

   const [swapFade,setSwapFade] = useState(true)
   let fadeAnim = () => {
      setSwapFade(prev=>!prev)
   }
   
   useEffect(() => {
      let intervalId = setInterval(ellipsisAnim,500)
      let swapIntervalId = setInterval(fadeAnim,1800)
      if (!loading){
          clearInterval(intervalId)
          clearInterval(swapIntervalId)
      }
      // intervalFunc
   },[loading])

   const [imgFetchAttempts,setImgFetchAttempts] = useState(0)
   const handleLogoError = (e) => {
      // console.log('logo img error',FallbackLogo)
      setImgFetchAttempts(prev => prev++)
      if (imgFetchAttempts <= 10) e.target.src = defaultLogo
   }

   return (
      <div className='flex-container' style={{height:props.agentEnroller ? 'calc(100vh - 276px)' : '', maxWidth: props.agentEnroller ? '100%' : "600px", margin: props.agentEnroller ? '' : '0 auto', padding: props.agentEnroller? '20px' : '20px 40px 40px 40px', minHeight: props.agentEnroller ? '': 'calc(100vh - 60px)', width: '100%'}}>
         <div className="flex-row" style={{ marginBottom: '20px', padding: '0px', textAlign: 'center' }} >
            <img src={props.agentEnroller?`api/image/organization/${props.fullMemberObj?.organizationId}`:'/api/image/organization'} style={{ width: '100px', margin: 'auto' }} 
            onError={handleLogoError}
            />
         </div>


         <div className="flex-row" style={{ marginBottom: '20px', padding: '10px', textAlign: 'center', justifyContent:'center' }} >
            <Typography variant='subtitle1' fontWeight='600'>Your enrollment progress has been saved! </Typography>
         </div>

         {props.fullMemberObj?.isEmployed === false || props.fullMemberObj?.isEmployed === null &&
            <div
               className="flex-row"
               style={{ marginBottom: "24px", padding: '0px', textAlign: 'left', justifyContent:'center' }}
            >
               <Alert fullWidth severity="warning"
                  sx={{lineHeight:'20.02px', letterSpacing:'.17px'}}
                  >
                  You must be employed and actively working to be eligible.  Please contact your benefit provider if you have questions.
               </Alert>
            </div>
         }

         <div className="row" style={{ marginBottom: '20px', padding: '10px', textAlign: 'center', justifyContent:'center' }} >
            <Typography variant='body1' fontWeight='500' marginBottom='15px'>Your information has been stored and you can pick back up whenever you'd like to.  Please note, you must be actively working in order to enroll.</Typography>
         </div>

         {!props.agentEnroller &&
            <div className="row" style={{ padding: '0px', textAlign: 'left', marginBottom: '20px'}} >
               <Button variant="contained" sx={{ height: 50 }} onClick={resumeEnrollment}>Back To Enrollments</Button>
            </div>
         }

         {!props.agentEnroller &&
            <div className="row" style={{ padding: '0px', textAlign: 'left'}} >
               <Button variant="outlined" sx={{ height: 50 }} onClick={logout}>Log Out</Button>
            </div>
         }
         {/* <div className="row" style={{ marginBottom: '20px', padding: '0px', textAlign: 'left', margin: 'auto' }} >
            <Button variant="outlined" sx={{height:50}} onClick={handleHomeClick}><ArrowBackIcon/>Go Home</Button>
         </div> */}

         {/* <StickyProgressTotal progress={90} nextClickHandler={handleNextClick} /> */}



      </div>
   )
}
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Services
import enrollmentService from "../../../../services/enrollment-service";
import memberService from "../../../../services/member-service";

// //Mui Components
import Typography from "@mui/material/Typography";
import { Alert, Dialog, IconButton, Snackbar } from "@mui/material";

//Our Components
import EnrollmentStd from "./plan-components/EnrollmentSTD";
import EnrollmentStdIncremental from "./plan-components/EnrollmentSTDIncremental";
import EnrollmentLtd from "./plan-components/EnrollmentLTD";
import EnrollmentLtdIncremental from "./plan-components/EnrollmentLTDIncremental";
import EnrollmentLadd from "./plan-components/EnrollmentLADD";
import EnrollmentPayment from "./payment-components/payScheduleAndBank-components/EnrollmentPayment";
import StickyProgressTotal from "../StickyProgressTotal";
import PoweredBy from "../PoweredBy";
import AgentNavigation from "../../../app-internal/Member/member-profile/MemberTabs/agent-enroll-tab/AgentNavigation";
import DeclineDrawer from "./plan-components/DeclineDrawer";
import ExternalEnrollmentHeader from "../ExternalEnrollmentHeader";
import EnrollmentAccident from "./plan-components/EnrollmentAccident";
import EnrollmentCancer from "./plan-components/EnrollmentCancer";
import EnrollmentADD from "./plan-components/EnrollmentADD";
import EnrollmentHospital from "./plan-components/EnrollmentHospital";

export default function EnrollmentsPlans(props) {

  let history = useHistory();

  // console.log("spouse info in memberPlans", props.spouse)
 
  //temp state conditions to allow modification for incremental disability
  const [practiceIncrementalOptions, setPracticeIncrementalOptions] =
    useState(true);

  const [expanded, setExpanded] = useState([]);

  const [showPayment, setShowPayment] = useState(false);

  
  const handleEnrollClick = async (memberOptId) => {
    let groupPlanId = props.results.groupPlanId;
    console.log('handleEnrollClick', memberOptId, '\n\n')
    // If accident plan option was selected and determine option id number
    // console.log("ID actually selected: \n\n", memberOptId);
    if (props.results?.accidentPlan){
      let _accidentSelection = props.results?.accidentPlan?.options.map(
        (option) => {
          if (memberOptId === option.memberOptionId) return `member`;
          else if (memberOptId === option.memberAndSpouseOptionId)
            return `member and spouse`;
          else if (memberOptId === option.memberAndDependentsOptionId)
            return `member and dependents`;
          else if (memberOptId === option.familyOptionId) return `family`;
          else return null;
        }
      );
  
      props.setAccidentSelection(_accidentSelection);
    }

    if (props.results?.cancerPlan) {
      let _cancerSelection = props.results?.cancerPlan?.options.map(
        (option) => {
          if (memberOptId === option.memberOptionId) return `member`;
          else if (memberOptId === option.memberAndSpouseOptionId)
            return `member and spouse`;
          else if (memberOptId === option.memberAndDependentsOptionId)
            return `member and dependents`;
            else if (memberOptId === option.familyOptionId) {
              let onlyFamilyAndMemberAvailable = (!option.memberAndDependentsOptionId > 0 && !option.memberAndSpouseOptionId > 0 && option.familyOptionId > 0 && option.memberOptionId > 0)
              if (onlyFamilyAndMemberAvailable) return "familyOptional"
              else return "family";
            }
          else return null;
        }
      );
  
      props.setCancerSelection(_cancerSelection);
    }

    if (props.results?.addPlan) {
      let _addSelection = props.results?.addPlan?.options.map(
        (option) => {
          if (memberOptId === option.memberOptionId) return `member`;
          else if (memberOptId === option.memberAndSpouseOptionId)
            return `member and spouse`;
          else if (memberOptId === option.memberAndDependentsOptionId)
            return `member and dependents`;
            else if (memberOptId === option.familyOptionId) {
              let onlyFamilyAndMemberAvailable = (!option.memberAndDependentsOptionId > 0 && !option.memberAndSpouseOptionId > 0 && option.familyOptionId > 0 && option.memberOptionId > 0)
              if (onlyFamilyAndMemberAvailable) return "familyOptional"
              else return "family";
            }
          else return null;
        }
      );
  
      props.setAddSelection(_addSelection);
    }

    if (props.results?.hospitalPlan) {
      let _hospitalSelection = props.results?.hospitalPlan?.options.map(
        (option) => {
          if (memberOptId === option.memberOptionId) return `member`;
          else if (memberOptId === option.memberAndSpouseOptionId)
            return `member and spouse`;
          else if (memberOptId === option.memberAndDependentsOptionId)
            return `member and dependents`;
            else if (memberOptId === option.familyOptionId) {
              let onlyFamilyAndMemberAvailable = (!option.memberAndDependentsOptionId > 0 && !option.memberAndSpouseOptionId > 0 && option.familyOptionId > 0 && option.memberOptionId > 0)
              if (onlyFamilyAndMemberAvailable) return "familyOptional"
              else return "family";
            }
          else return null;
        }
      );
  
      props.setHospitalSelection(_hospitalSelection);
    }

    if (props.agentEnroller) {
      // console.log("is agent full member object info:\n\n", props.fullMemberObj);

      await enrollmentService.saveOptionForMember(
        props.fullMemberObj.id,
        groupPlanId,
        memberOptId
      );

      props.setEnrollmentCreated((prevState) => {
        if (prevState === null || prevState === true) {
          return prevState;
        } else {
          return true;
        }
      });
    } else {
      enrollmentService.saveOption(groupPlanId, memberOptId);
    }
  };

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (props.userInfo && !loaded) {
      props.updateResults();
      setLoaded(true);
    }
  }, [props.userInfo]);

  const [declinedAll, setDeclinedAll] = useState(false);
  const [laddSelected, setLaddSelected] = useState(false);

  const checkDeclineAll = () => {
    // console.log(props.selections, laddSelected);
    let declinedList = [];
    if (props.results?.stdPlan){
      if ((props.selections.std != undefined && props.selections.stdVal == 0)) declinedList[0] = true
      else declinedList[0] = false
    } else declinedList[0] = null

    if (props.results?.ltdPlan){
      if (props.selections.ltd != undefined && props.selections.ltdVal == 0) declinedList[1] = true
      else declinedList[1] = false
    } else declinedList[1] = null

    if (props.results?.lifeADDPlan) {
      if (laddSelected && props.selections.ladd[0] == 0) declinedList[2] = true
      else declinedList[2] = false
    } else declinedList[2] = null

    if (props.results?.accidentPlan) {
      if (props.selections.accident != undefined && props.selections.accidentVal == 0) declinedList[3] = true
      else declinedList[3] = false
    } else declinedList[3] = null

    if (props.results?.cancerPlan) {
      if (props.selections.cancer != undefined && props.selections.cancerVal == 0) declinedList[4] = true
      else declinedList[4] = false
    } else declinedList[4] = null

    if (props.results?.addPlan) {
      if (props.selections.add != undefined && props.selections.addVal == 0) declinedList[5] = true
      else declinedList[5] = false
    } else declinedList[5] = null

    if (props.results?.hospitalPlan) {
      if (props.selections.hospital != undefined && props.selections.hospitalVal == 0) declinedList[5] = true
      else declinedList[5] = false
    } else declinedList[5] = null

    let declinedAll = true
    for (let bool of declinedList){
      if (bool !== null && !bool) declinedAll = false
    }
    
    setDeclinedAll(declinedAll)
  };

  const handleDeclineOpen = (e) => {
    setDeclinedAll(true);
  };
  const handleDeclineClose = (e) => {
    setDeclinedAll(false);
  };

  const handleDecline = async () => {
    let emptyCanvas = document.createElement("canvas");
    let signatureObj = {
      signatureText: "Declined",
      signature: emptyCanvas.toDataURL("image/png"),
      signatureDate: new Date().toLocaleString().slice(0, 8),
      groupPlanId: props?.results?.groupPlanId,
      isMail: false,
      isVerbal: props.agentEnroller ? true : false,
      workStatusId: props?.fullMemberObj?.workStatusId,
      nawReason: props?.fullMemberObj?.workStatusId == 20 ? props?.nawReason : props.fullMemberObj.workStatus,
    };
    let enrollmentResponse;
    try {
      await props.saveLadd()
    } catch (error) {
      console.log('error saving ladd in decline',error)
    }
    if (props.agentEnroller) {
      enrollmentResponse = await enrollmentService.submitEnrollmentForMember(
        signatureObj,
        props.fullMemberObj.id
      );
    } else {
      enrollmentResponse = await enrollmentService.submitEnrollment(
        signatureObj
      );
    }
    props.setGuid(enrollmentResponse);
    handleDeclineClose();
    if (props.agentEnroller) {
      props.refreshEnrollments();
      props.setAgentEnrollmentPageState({ complete: true });
    } else {
      history.push("success", { ...history.location.state });
    }
    localStorage.clear("recommendations");
    localStorage.clear("laddDecline");
  };

  useEffect(() => {
    checkDeclineAll();
  }, [props.selections]);

  // useEffect(() => {
  //    if(!expanded.std && !expanded.ltd && !expanded.ladd){
  //       props.setPosition("fixed")
  //    } else {
  //       props.setPosition("sticky")
  //    }
  // },[expanded])

  const scrollListener = (e) => {
    // console.log('scroll listener',e)
    // let _scrollAtBottom = Math.abs(e.target?.scrollHeight - e.target?.scrollTop - e.target?.clientHeight) < 1
    // props.setScrollAtBottom(_scrollAtBottom)
  };

  const [planList, setPlanList] = useState([]);
  const [laddLastPlan, setLaddLastPlan] = useState(true)
  
  const buildOrderedPlanList = () => {
    let results = props.results;
    //Build array of nodes with sort order, then map over to display
    let unorderedList = [
      // {plan: ReactNode, order: int}
    ];
    if (results?.stdPlan && !results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentStd
            key={'std'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.stdPlan && results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentStdIncremental
          key={'stdInc'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.ltdPlan && !results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentLtd
          key={'ltd'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.ltdPlan && results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentLtdIncremental
            key={'ltdInc'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.accidentPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentAccident
            key={'accident'}
            // medBreakpointHit={props?.medBreakpointHit}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
          />
        ),
        order: results.accidentPlan.sortOrder,
        planId: results.accidentPlan.planId,
      });
    }
    if (results?.lifeADDPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentLadd
            key={'ladd'}
            // medBreakpointHit={props?.medBreakpointHit}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            setLaddSelected={setLaddSelected}
            setLaddSelectionMade={props.setLaddSelectionMade}
            laddLastPlan={laddLastPlan}
            saveLadd={props.saveLadd}
          />
        ),
        order: results.lifeADDPlan.sortOrder,
        planId: results.lifeADDPlan.planId,
      });
    }
    if (results?.cancerPlan) {
      unorderedList.push({
        plan:(
          <EnrollmentCancer
            key={'cancer'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            spouseRatesLoading={props.spouseRatesLoading}
            genderTypes={props?.genderTypes}
            fullMemberObj={props.fullMemberObj}
            agentEnroller={props.agentEnroller}
          />
        ),
        order:results.cancerPlan.sortOrder,
        planId:results.cancerPlan.planId
      })
    }
    if (results?.addPlan) {
      unorderedList.push({
        plan:(
          <EnrollmentADD
            key={'add'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            // medBreakpointHit={props?.medBreakpointHit}
          />
        ),
        order:results.addPlan.sortOrder,
        planId:results.addPlan.planId
      })
    }

    if (results?.hospitalPlan) {
      unorderedList.push({
        plan:(
          <EnrollmentHospital
            key={'hospital'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
          />
        ),
        order:results.hospitalPlan.sortOrder,
        planId:results.hospitalPlan.planId
      })
    }

    let orderedList = unorderedList.sort((a, b) => a.order - b.order);
    setPlanList(orderedList);
    

    //find index of ladd is in the list of plans
    let laddLocation = orderedList.findIndex(planObj => planObj.planId === props?.results?.lifeADDPlan?.planId);
    
    
    //if it is the last plan, set state to true, else false
    if(laddLocation === orderedList.length - 1){
      setLaddLastPlan(true);
    }else {
      setLaddLastPlan(false);
    }

    let _expanded = [];
    let _i = 0;
    for (let planObj of orderedList) {
      if (_i == 0) {
        _expanded.push({ [planObj.planId]: true });
      } else {
        _expanded.push({ [planObj.planId]: false });
      }
      _i++;
    }
    let newPlansDetected = false;
    for (let existingPlanId of Object.keys(expanded)) {
      let found = false;
      for (let newPlanId of Object.keys(_expanded)) {
        if (existingPlanId == newPlanId) {
          found = true;
        }
      }
      if (!found) {
        newPlansDetected = true;
      }
    }
    if (expanded?.length <= 0 || newPlansDetected) {
      if (history.location.state && history.location.state?.planId) {
        let expandedWithHistory = [];
        for (let expandObj of _expanded) {
          let planId = Object.keys(expandObj)[0];
          expandObj = { [planId]: false };
          if (planId == history.location.state.planId) {
            expandObj = { [planId]: true };
          }
          expandedWithHistory.push(expandObj);
        }
        _expanded = expandedWithHistory;
      }
      setExpanded(_expanded);
    }
  };

  useEffect(() => {
    if (props.results && history.location) buildOrderedPlanList();
  }, [
    props.results,
    history.location,
    expanded,
    props.selections,
    props.spouse,
    props.dependents,
  ]);

  const closeSnackbar = () => {
    props.setBackendValidation(true)
  }


  return (
    <>
      <div
        style={{
          overflowY: "auto",
          height: props.agentEnroller ? "calc(100vh - 276px)" : "",
        }}
        onScroll={!props.agentEnroller ? scrollListener : null}
      >
        <div
          className="flex-container"
          style={{
            height: "calc(100vh - 170px)",
            overflowY: "none",
            maxWidth: props.agentEnroller ? '100%' : "600px",
            margin: props.agentEnroller ? "0 auto" : "0 auto",
            padding: props.agentEnroller ? "20px" : "30px",
            width: "100%",
          }}
        >
          {/* <div className="flex-row" style={{ marginBottom: '20px', padding: '0px', textAlign: 'center' }} >
                  <div className='col-3'>
                     <img src={props.agentEnroller ? `api/image/organization/${props.fullMemberObj?.organizationId}` : '/api/image/organization'} style={{ width: '70px' }} />
                  </div>
                  <div className='col-9' style={{ paddingLeft: '15px', textAlign: 'start', margin: 'auto' }}>
                     <Typography variant='subtitle1' fontWeight='600'>{props?.results?.name}</Typography>
                  </div>
               </div> */}
          {!props.agentEnroller && (
            <ExternalEnrollmentHeader
              fullMemberObj={props.thisMember}
              agentEnroller={props.agentEnroller}
              selectedEnrollment={props.results}
              basicHeader={true}
            />
          )}
          {props.backendValidation === false && 
            <Alert severity="error">Changes in your personal information have affected some selections, please reselect your coverage options</Alert>
          }

          {props.agentEnroller && (
            <>
              <div
                className="flex-row"
                style={{
                  textAlign: "left",
                  // paddingLeft: "5px",
                  marginTop: "5px",
                  marginBottom: "0px",
                  marginLeft: "-7px",
                }}
              >
                <Typography variant="h5" fontFamily={'Poppins'}>
                  Benefit Options
                </Typography>
              </div>

              <div
                className="flex-row"
                style={{
                  textAlign: "left",
                  // paddingLeft: "5px",
                  marginTop: "5px",
                  // marginBottom: "38px",
                  marginLeft: "-7px",
                }}
              >
                <Typography variant="body2" color='text.gray'>
                  Select benefit options for the member.
                </Typography>
              </div>
            </>
          )}

          {!showPayment ? (
            <>
              <div style={{ margin: "0" }}>
                {/* {
                        props.results?.stdPlan && !props.results.stdPlan?.isIncremental &&
                        // props.results?.stdPlan && !practiceIncrementalOptions &&
                           <EnrollmentStd handleEnrollClick={handleEnrollClick}
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           />
                        }
                        {
                        props.results?.stdPlan && props.results.stdPlan?.isIncremental &&
                        // props.results?.stdPlan && practiceIncrementalOptions &&
                           <EnrollmentStdIncremental handleEnrollClick={handleEnrollClick}
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           />
                        }
                        
                        {
                        props.results?.accidentPlan &&
                           <EnrollmentAccident
                           handleEnrollClick={handleEnrollClick}
                           results={props.results} recommendations={props.recommendations}
                           selections={props.selections} setSelections={props.setSelections}
                           selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                           dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                           calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           dependents={props.dependents} setDependents={props.setDependents}
                           spouse={props.spouse} setSpouse={props.setSpouse}
                           />
                        }
                        
                        {props.results?.ltdPlan &&
                           <EnrollmentLtd handleEnrollClick={handleEnrollClick}
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           />
                        }
                        {props.results?.lifeADDPlan &&
                           <EnrollmentLadd
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                              dependents={props.dependents} setDependents={props.setDependents}
                              spouse={props.spouse} setSpouse={props.setSpouse} setLaddSelected={setLaddSelected}
                           />
                        } */}

                {planList.map((planObj) => planObj.plan)}
              </div>

              {/* <StickyProgressTotal runningTotal={props.runningTotal} nextClickHandler={handleNexlick} selectiotCns={props.selections}/> */}
            </>
          ) : (
            <>
              <EnrollmentPayment
                results={props.results}
                selections={props.selections}
                expanded={expanded}
                setExpanded={setExpanded}
                showPayment={showPayment}
                setShowPayment={setShowPayment}
                fullMemberObj={props.fullMemberObj}
              />
              {/* <StickyProgressTotal progress={100}/> */}
            </>
          )}
          {/* <PoweredBy /> */}

          <DeclineDrawer
            open={declinedAll}
            handleClose={handleDeclineClose}
            handleOpen={handleDeclineOpen}
            handleDecline={handleDecline}
          />
          {props.agentEnroller && (
            <AgentNavigation
              nextLocation={"beneficiaries"}
              allowBack={false}
              nextValidation={props.nextValidation}
              nextClickHandler={props.nextClickHandler}
            />
          )}
        </div>
      </div>
    </>
  );
}

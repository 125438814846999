import Settings from "../settings";
import { baseService } from "./baseService";

class internalUserService extends baseService {
    updatePassword(password, oldPassword) {
        const passwordChange = { password: password, oldPassword: oldPassword };
        return this.put(Settings.API_URL + "user/password", passwordChange);
    }
    getMe() {
        return this.get(Settings.API_URL + "user/me");
    }
    getByUserId(userId) {
        return this.get(Settings.API_URL + "user/" + userId);
    }
    updateUser(userId, user) {
        return this.put(Settings.API_URL + "user/" + userId, user);
    }
    updateMe(user) {
        const url= Settings.API_URL + "user/" + user.id;
        return this.put(url, user)
    }
    insertUser(user) {
        return this.post(Settings.API_URL + "user", user);
    }
    getNotificationSettings() {
        return this.get(Settings.API_URL + "user/notification");
    }
    /*
     * Takes the form of below:
     *{
        "notificationTypeId": 4,
        "notificationModeId": 2,
        "isEnabled": false
       }
     */
    updateNotificationSetting(notificationSetting) {
        return this.put(Settings.API_URL + "user/notification");
    }

    /* Takes the form of below
 * {
 *   "image": "base64"
 * } 
 */
    updateUserImage(userId, userImage) {
        const url = Settings.API_URL + "user/" + userId + "/image";
        return this.put(url, userImage);
    }
    // This method gets all users (that aren't members) by account id
    getAll() {
        return this.get(Settings.API_URL + "user/account");
    }
}

const userService = new internalUserService();
export default userService;
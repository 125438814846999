import React, { useEffect, useState, useMemo } from "react"
import { format } from "date-fns"

//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

//MuiComponents
import { useTheme, useMediaQuery, Alert } from "@mui/material"
import {
  Autocomplete,
  Box,
  Button,
  Link,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Select,
  Slider,
  TextField,
  Typography,
  RadioGroup,
  Radio,
} from "@mui/material"

//Mui Icons
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import RadioButtonIcon from "@mui/icons-material/RadioButtonUnchecked"
import ClearIcon from "@mui/icons-material/Clear"

//Our components
import RecommendedBenefitDisplay from "../../../RecommendedBenefitDisplay"
import SaveChangesSnackbar from "../../../../../save-changes-snackbar/SaveChangesSnackbar"

//Our icons
import CustomCheckIcon from "../../../../../../assets/images/CustomCheckIcon.svg"
import memberService from "../../../../../../services/member-service"

//Our Enrollment Plan Styles
import enrollmentPlanStyles from "../../enrollmentPlanStyles"

//utils
import { checkMobileDevice } from "../../../../../utils"

export default function EnrollmentLaddDependent(props) {
  const isMobileDevice = checkMobileDevice()
  const componentStyles = {
    radio: {
      padding: "0px",
      marginRight: "8px",
    },
    radioContainer: {
      padding: "0px",
      marginBottom: "8px",
    },
    dependentTitleRowContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }

  let UsdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  })
  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })
  let percentFormatter = new Intl.NumberFormat("en-US", { style: "percent" })

  let results = props.results
  let lifeADDPlan = results?.lifeADDPlan

  let today = new Date()
  let day = today.getDate()
  let month = today.getMonth() + 1
  let year = today.getFullYear()
  day = day < 10 ? "0" + day : day
  month = month < 10 ? "0" + month : month
  let dateMax = `${year - 18}-${month}-${day}`
  let dateMaxObj = new Date(dateMax)
  let dateMin = `${year - 100}-${month}-${day}`
  let dateMinObj = new Date(dateMin)
  let startYear = dateMinObj
  let endYear = today
  if (results.maxDependentAge != 0) {
    startYear = new Date(
      startYear.setFullYear(today.getFullYear() - results.maxDependentAge)
    )
  }
  const [opacity, setOpacity] = useState(1) //temp opacity for now

  useEffect(() => {
    handleOpacity()
  }, [props.checkboxes?.dependent, props.checkboxes?.member])

  const handleOpacity = () => {
    //not using for now
    // if((!props.checkboxes.dependent && !props.recommendations.showDependent && Object.keys(props.recommendations).length > 0) || !props.checkboxes.member) setOpacity(.6)
    // else setOpacity(1)
    setOpacity(1)
  }

  const [displayAlert, setDisplayAlert] = useState(false)
  const checkMemberIsSelected = () => {
    if (props.checkboxes.member !== true) {
      setDisplayAlert(true)
    }
  }

  const [dependents, setDependents] = useState([])
  const [dependentDobErrors, setDependentDobErrors] = useState([])

  useEffect(() => {
    if (props.dependents && props.dependents.length > 0)
      setDependents(props.dependents)
  }, [props.dependents])

  const handleDeleteClick = (e) => {
    let value = e.target.getAttribute("value")
    // console.log(value)
    let _dependents = dependents.map((x) => x)
    // console.log(_dependents)
    _dependents.find((dependent) => {
      if (dependent?.id) {
        return dependent.id == value
      } else return dependent.uid == value
    }).isDeleted = true
    props.setDependents(_dependents)
  }

  const handleDependentChange = (e, i, key) => {
    let value = e.target.value
    let index = i
    let _dependents = [...dependents].filter(
      (dependent) => !dependent.isDeleted
    )
    _dependents[index][key] = value
    _dependents[index].isDeleted = false
    props.setDependents(_dependents)
  }

  useEffect(() => {
    if (results.maxDependentAge == 0) {
      return
    }
    console.log("called.....", dependentDobErrors, dependents)
    var today = new Date()
    let _dependents = [...dependents]
    _dependents.forEach((dependent, i) => {
      let dependentDob = new Date(dependent.dob)
      if (dependentDob > today) {
        dependent.dobError = true
      } else {
        if (dependentDobErrors[i]) {
          var maxAgePossibleInYears = dependentDob.setFullYear(
            dependentDob.getFullYear() + results.maxDependentAge
          )
          console.log(
            "in_useeffect, maxAgePossibleInYears, today->>",
            maxAgePossibleInYears,
            today,
            "\n maxAgeconverted to date",
            new Date(maxAgePossibleInYears)
          )
          if (maxAgePossibleInYears >= today) {
            dependent.dobError = false
          } else {
            dependent.dobError = true
          }
        }
      }
    })
    setDependents(_dependents)
    console.log("in_useeffect", dependentDobErrors, _dependents)
  }, [dependentDobErrors])

  //DOB handler for all devices
  const handleMobileDobChange = (value, i, key) => {
    let index = i
    let _dependents = [...dependents]
    _dependents[index][key] = value
    props.setDependents(_dependents)
  }

  const setDobErrorInDependents = (index, error) => {
    let _dependents = [...dependents]
    console.log(
      "in setDobErrorInDependents",
      index,
      error,
      "result of bool",
      error && error.length > 0,
      "currdependents",
      _dependents
    )
    _dependents[index].dobError = !!(error && error.length > 0) //if mui sends a error string then assume it is an error else no error
    setDependents(_dependents)
    //set value of dobError in dependent it applies to - used in wrapper validations
    setDependentDobErrors(_dependents.map((d) => d.dobError))
    //set message to display as helper text
    setDobError(error)
  }

  const addNewDependent = () => {
    let _dependent = {
      uid: new Date().getTime(),
      firstName: "",
      lastName: "",
      dob: null,
      gender: 0,
      isDeleted: false,
      dobError: false,
    }
    let _dependents = [...dependents]
    _dependents.push(_dependent)
    props.setDependents(_dependents)
  }

  const [fNameError, setFNameError] = useState(false)
  const [lNameError, setLNameError] = useState(false)
  const [dobError, setDobError] = useState(false)

  // console.log('props.checkboxes', props.checkboxes)
  // console.log('props.dependents.localDecline', props.dependentLocalDecline)
  const dobErrorMessage = useMemo(() => {
    console.log("in usememo", dobError, dateMaxObj, dateMinObj)

    switch (dobError) {
      case "maxDate": {
        return "Must be older than 18 years old"
      }
      case "minDate": {
        return "Must be older than 0 years old"
      }
      case "disableFuture": {
        return "Must be less than 100 years old"
      }
      case "invalidDate": {
        return "Your date is not valid"
      }
      default: {
        return ""
      }
    }
  }, [dobError])

  //SLIDER AND AUTOCOMPLETE SELECT LOGIC
  const dependentValueText = (value) =>
    `${UsdFormatter.format(
      lifeADDPlan?.dependentOptions[value].coverageAmount
    )}`
  const maxCoverage =
    lifeADDPlan?.dependentOptions[lifeADDPlan?.dependentOptions.length - 1]
      ?.coverageAmount

  //dropdown select display value logic
  let benefitSelectionValue = () => {
    if (
      lifeADDPlan.dependentOptions[props.dependentSlider]?.coverageAmount ===
        maxCoverage &&
      maxCoverage !== props?.currDepRec
    ) {
      //last but not rec amount
      return `${UsdFormatter.format(
        lifeADDPlan.dependentOptions[props.dependentSlider]?.coverageAmount
      )} (max)`
    } else if (
      lifeADDPlan.dependentOptions[props.dependentSlider]?.coverageAmount ===
        maxCoverage &&
      maxCoverage === props?.currDepRec
    ) {
      //last and rec amount
      return `${UsdFormatter.format(
        lifeADDPlan.dependentOptions[props.dependentSlider]?.coverageAmount
      )} (recommended)`
    } else if (
      lifeADDPlan.dependentOptions[props.dependentSlider]?.coverageAmount ===
        props?.currDepRec &&
      props?.currDepRec !== maxCoverage
    ) {
      //rec amount but not last
      return `${UsdFormatter.format(
        lifeADDPlan.dependentOptions[props.dependentSlider]?.coverageAmount
      )} (recommended)`
    } else {
      return UsdFormatter.format(
        lifeADDPlan.dependentOptions[props.dependentSlider]?.coverageAmount
      )
    }
  }

  const autoCompleteHandler = (e) => {
    let tagName = e.target.tagName.toLowerCase()
    if (tagName == "li") {
      let value = e.target.innerHTML.slice(1).replace(",", "")
      if (value.includes("(max)")) value = value.replace("(max)", "")
      if (value.includes("(recommended)"))
        value = value.replace("(recommended)", "")
      for (let i = 0; i < lifeADDPlan?.dependentOptions.length; i++) {
        let coverageAmount = lifeADDPlan?.dependentOptions[i].coverageAmount
        if (coverageAmount == value) {
          if (i <= props.dependentMaxIndex) props.setDependentSlider(i)
          else props.setDependentSlider(props.dependentMaxIndex)
        }
      }
    } else if (tagName == "input") {
      let value = e.target.value.slice(1).replace(",", "")
      if (value.includes("(max)")) value = value.replace("(max)", "")
      if (value.includes("(recommended)"))
        value = value.replace("(recommended)", "")
      for (let i = 0; i < lifeADDPlan?.dependentOptions.length; i++) {
        let coverageAmount = lifeADDPlan?.dependentOptions[i].coverageAmount
        if (coverageAmount == value) {
          if (i <= props.dependentMaxIndex) props.setDependentSlider(i)
          else props.setDependentSlider(props.dependentMaxIndex)
        }
      }
    }
  }

  return (
    <>
      <SaveChangesSnackbar
        displayAlert={displayAlert}
        setDisplayAlert={setDisplayAlert}
        text="Member must be selected to enroll dependent coverage"
        severity="error"
        backgroundColor="#CD2026"
      />

      <Box sx={enrollmentPlanStyles.componentContentContainer}>
        {props.checkboxes.member !== true && (
          <Box
            sx={enrollmentPlanStyles.sectionClickFieldForSnackbarDisplay}
            onClick={checkMemberIsSelected}
          />
        )}

        {/* <RadioGroup sx={{ padding: "0px 10px 0px 0px", position: "relative", marginBottom: '15px', flexDirection: 'column!important' }} 
        value={props.checkboxes.dependent} > */}

        <Box sx={enrollmentPlanStyles.optionRow}>
          <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
            <Radio
              sx={enrollmentPlanStyles.planSelectRadioButton}
              onClick={() => {
                props.dependents?.length == 0
                  ? addNewDependent()
                  : console.log("not adding a new dep")
              }}
              onChange={props.laddSelectionHandler}
              icon={
                <RadioButtonIcon
                  sx={enrollmentPlanStyles.radioButtonIcon(
                    props.checkboxes.member !== true
                  )}
                />
              }
              checkedIcon={
                <RadioButtonCheckedIcon
                  sx={enrollmentPlanStyles.radioButtonIcon(
                    props.checkboxes.member !== true
                  )}
                />
              }
              value={props.checkboxes.dependent}
              checked={
                props.checkboxes.dependent &&
                !props?.dependentLocalDecline &&
                props?.checkboxes?.dependent !== "decline"
              }
              name={"2"}
              inputProps={{
                "data-amount":
                  results?.lifeADDPlan.dependentOptions[props?.dependentSlider].premiumAmount,
                "data-person": "dependent",
                "data-coverage": props.dependentMax,
                "data-property": "laddDependentTrue_radio",
              }}
              disabled={props.checkboxes.member !== true}
            />

            <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
              <Typography
                sx={enrollmentPlanStyles.singleOptionText(
                  props.checkboxes.dependent &&
                    props.checkboxes.dependent !== "decline"
                )}
              >
                Dependent{" "}
                {`(${UsdFormatter.format(
                  results?.lifeADDPlan?.dependentOptions[props?.dependentSlider]
                    .coverageAmount
                )})`}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={enrollmentPlanStyles.singleOptionText(
              props.checkboxes.dependent &&
                props.checkboxes.dependent !== "decline"
            )}
          >
            {props.checkboxes.dependent &&
              props.checkboxes.dependent !== "decline" &&
              "+"}
            {!props.dummyDisplay &&
              props.dependentMax !== 0 &&
              UsdFormatterDec.format(
                results?.lifeADDPlan.dependentOptions[props?.dependentSlider]
                  ?.premiumAmount
              )}
            {(props.dummyDisplay || props.dependentMax == 0) && "$0.00"}
          </Box>
        </Box>

        <Box sx={enrollmentPlanStyles.blurbTextContainer}>
          {results && results?.maxDependentAge != 0 && (
            <Typography sx={enrollmentPlanStyles.blurbText}>
              Coverage does not apply to dependents over age of{" "}
              {results?.maxDependentAge}.
            </Typography>
          )}
        </Box>

        <RecommendedBenefitDisplay currDepRec={props?.currDepRec} />

        {/* Dependent Coverage Amount Dropdown - only display dropdown and slider if more than one dep option */}
        {props?.results?.lifeADDPlan?.dependentOptions?.length > 1 && (
          <>
            <Box sx={enrollmentPlanStyles.benefitAmountDropdownContainer}>
              <Autocomplete
                disablePortal
                fullWidth
                autoComplete
                options={lifeADDPlan.dependentOptions?.map((option, i) => {
                  let isLast = i == lifeADDPlan.dependentOptions.length - 1
                  if (isLast && props?.currDepRec !== option?.coverageAmount) {
                    // last but not rec amount
                    return `${UsdFormatter.format(option.coverageAmount)} (max)`
                  } else if (
                    isLast &&
                    props?.currDepRec === option?.coverageAmount
                  ) {
                    //last and = rec amount
                    return `${UsdFormatter.format(
                      option.coverageAmount
                    )} (recommended)`
                  } else if (
                    !isLast &&
                    props?.currDepRec === option?.coverageAmount
                  ) {
                    //not last and = rec amount
                    return `${UsdFormatter.format(
                      option.coverageAmount
                    )} (recommended)`
                  } else {
                    return UsdFormatter.format(option.coverageAmount)
                  }
                })}
                value={benefitSelectionValue() || 0}
                onChange={autoCompleteHandler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      sx: {
                        color:
                          props.checkboxes.member !== true
                            ? ""
                            : "primary.main",
                      },
                    }}
                    sx={enrollmentPlanStyles.activeBlueDropdownField(
                      props.checkboxes.member !== true
                    )}
                    label="Coverage Amount"
                  />
                )}
                // InputLabelProps={enrollmentPlanStyles.inputLabelProps}
                componentsProps={{
                  popupIndicator: {
                    sx: {
                      color:
                        props.checkboxes.member !== true ? "" : "primary.main",
                    },
                  },
                }}
              />
            </Box>

            {/* SLIDER */}
            <Box sx={enrollmentPlanStyles.incSliderSection}>
              {/* Slider min */}
              <Box sx={enrollmentPlanStyles.incSliderLowAmount}>
                {`$${
                  results?.lifeADDPlan.dependentOptions[0].coverageAmount / 1000
                }K`}
              </Box>

              {/* Slider container */}
              <Box sx={enrollmentPlanStyles.incSliderContainer}>
                <Slider
                  onChange={props.dependentSliderChange}
                  value={props.dependentSlider}
                  step={1}
                  min={0}
                  max={results?.lifeADDPlan.dependentOptions.length - 1}
                  sx={enrollmentPlanStyles.incSlider}
                  valueLabelDisplay="auto"
                  valueLabelFormat={dependentValueText(props?.dependentSlider)}
                />
              </Box>

              {/* Slider max */}
              <Box sx={enrollmentPlanStyles.incSliderHighAmount}>
                {`$${
                  results?.lifeADDPlan.dependentOptions[
                    results?.lifeADDPlan.dependentOptions.length - 1
                  ].coverageAmount / 1000
                }K`}
              </Box>
            </Box>
          </>
        )}

        {/* Display all dependents IF dependents are selected */}
        {props.checkboxes.dependent &&
          props?.checkboxes?.dependent !== "decline" &&
          !props.dependentLocalDecline &&
          dependents
            .filter((dependent) => !dependent.isDeleted)
            .map((dependent, i) => {
              return (
                <Box key={dependent?.id || dependent?.uid}>
                  {/* show dependent header AND number ONLY if there is more than one dependent */}
                  {i > 0 && (
                    <Box sx={enrollmentPlanStyles.optionRow}>
                      <Box sx={componentStyles.dependentTitleRowContainer}>
                        <Typography
                          sx={{
                            ...enrollmentPlanStyles.singleOptionText(
                              props.checkboxes.dependent
                            ),
                            paddingLeft: "10px",
                          }}
                        >
                          Dependent {i > 0 && `${i + 1}`}
                        </Typography>
                        <IconButton
                          value={dependent?.id || dependent?.uid}
                          onClick={handleDeleteClick}
                          sx={{ borderRadius: "100%" }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  )}

                  <Box sx={enrollmentPlanStyles.blurbTextContainer}>
                    {i == 0 && results && results?.maxDependentAge != 0 && (
                      <Typography sx={enrollmentPlanStyles.blurbText}>
                        Coverage does not apply to dependents over age of{" "}
                        {results?.maxDependentAge}.
                      </Typography>
                    )}
                  </Box>

                  <Box sx={enrollmentPlanStyles.inputSection}>
                    {/* Dep first name */}
                    <Box sx={enrollmentPlanStyles.inputField}>
                      <TextField
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        error={fNameError[dependent?.id || dependent?.uid]}
                        required
                        value={dependent.firstName || ""}
                        onChange={(e) => {
                          handleDependentChange(e, i, "firstName")
                          setFNameError({
                            ...fNameError,
                            [dependent?.id || dependent?.uid]: false,
                          })
                        }}
                        onBlur={(e) =>
                          !e.target.value &&
                          setFNameError({
                            ...fNameError,
                            [dependent?.id || dependent?.uid]: true,
                          })
                        }
                        InputLabelProps={{ sx: { color: "primary.main" } }}
                        inputProps={{
                          "data-property":
                            "laddDependent" + i + "FirstName_textField",
                        }}
                      />
                    </Box>
                    {/* Dep last name */}
                    <Box sx={enrollmentPlanStyles.inputField}>
                      <TextField
                        fullWidth
                        error={lNameError[dependent?.id || dependent?.uid]}
                        label="Last Name"
                        variant="outlined"
                        required
                        value={dependent.lastName || ""}
                        onChange={(e) => {
                          handleDependentChange(e, i, "lastName")
                          setLNameError({
                            ...lNameError,
                            [dependent?.id || dependent?.uid]: false,
                          })
                        }}
                        onBlur={(e) =>
                          !e.target.value &&
                          setLNameError({
                            ...lNameError,
                            [dependent?.id || dependent?.uid]: true,
                          })
                        }
                        InputLabelProps={{ sx: { color: "primary.main" } }}
                        inputProps={{
                          "data-property":
                            "laddDependent" + i + "LastName_textField",
                        }}
                      />
                    </Box>

                    {/* Dep Gender */}
                    <Box sx={enrollmentPlanStyles.inputField}>
                      <FormControl fullWidth>
                        <InputLabel sx={{ color: "primary.main" }}>
                          Dependent Gender
                        </InputLabel>
                        <Select
                          variant="outlined"
                          label="Dependent Gender"
                          InputProps={{
                            sx: {
                              "&.MuiOutlinedInput-root.Mui-focused > fieldset":
                                {
                                  border: "2px solid primary.main",
                                },
                            },
                          }}
                          value={dependent?.gender || ""}
                          onChange={(e) => {
                            handleDependentChange(e, i, "gender")
                          }}
                          inputProps={{
                            "data-property":
                              "laddDependent" + i + "Gender_select",
                          }}
                        >
                          {props?.genderTypes?.length > 0 &&
                            props?.genderTypes.map((option) => {
                              return (
                                <MenuItem value={option.id} key={option.id}>
                                  {option.name}
                                </MenuItem>
                              )
                            })}
                        </Select>
                      </FormControl>
                    </Box>

                    {/* Dep DOB */}
                    <Box sx={enrollmentPlanStyles.inputField}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disableFuture
                          openTo="year"
                          views={["year", "month", "day"]}
                          format="MM/dd/yyyy"
                          label="Date of birth *"
                          value={dependent.dob || null}
                          onChange={(e) => handleMobileDobChange(e, i, "dob")}
                          minDate={startYear}
                          maxDate={endYear}
                          // onAccept={}
                          onError={(error) => setDobErrorInDependents(i, error)}
                          sx={enrollmentPlanStyles.datePickerInput}
                          slotProps={{
                            textField: {
                              helperText: dependent.dobError
                                ? dobErrorMessage
                                : "",
                              InputLabelProps:
                                enrollmentPlanStyles.inputLabelProps,
                            },
                            openPickerButton: { sx: { visibility: "hidden" } }, //hide calendar icon calandar
                          }}
                        />
                      </LocalizationProvider>
                    </Box>

                    {/* alert for exceeding age limit */}
                    {/* commenting because dobError is used in multiple places. will uncomment it after deployment confirmation */}
                    {results.maxDependentAge != 0 && dependent.dobError && (
                      <Alert
                        severity="error"
                        sx={{ marginBottom: "10px", whiteSpace: "pre-line" }}
                      >
                        Dependent {i + 1} exceeds the age of eligibility.
                      </Alert>
                    )}
                  </Box>
                </Box>
              )
            })}

        {!props?.dependentLocalDecline && (
          <Box
            className="flex-row"
            sx={{
              marginBottom: "35px",
              justifyContent: "center",
              display: props.checkboxes.dependent ? "flex" : "none",
              pointerEvents: dobError ? "none" : "initial",
              opacity: dobError ? 0.5 : 1,
            }}
          >
            <Button
              variant="text"
              sx={{ fontSize: "16px", color: "primary.main" }}
              onClick={addNewDependent}
            >
              + add another dependent
            </Button>
          </Box>
        )}

        {/* Decline Option */}
        <Box sx={enrollmentPlanStyles.declineOptionRow}>
          <Radio
            value={true}
            onClick={(e) => {
              props?.handleLaddDeclineSelection(e, "dependent")
            }}
            checked={props?.dependentLocalDecline}
            name={"2"}
            sx={enrollmentPlanStyles.planSelectRadioButton}
            icon={
              <RadioButtonIcon
                sx={enrollmentPlanStyles.radioButtonIcon(
                  props.checkboxes.member !== true
                )}
              />
            }
            checkedIcon={
              <RadioButtonCheckedIcon
                sx={enrollmentPlanStyles.radioButtonIcon(
                  props.checkboxes.member !== true
                )}
              />
            }
            inputProps={{
              "data-amount": 0,
              "data-person": "dependent",
            }}
            disabled={props.checkboxes.member !== true}
          />
          <Box
            sx={enrollmentPlanStyles.declineOptionText(
              props?.dependentLocalDecline
            )}
          >
            Decline Dependent Life Coverage
          </Box>
        </Box>
        {/* </RadioGroup> */}
      </Box>
    </>
  )
}

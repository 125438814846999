import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { format, differenceInCalendarYears } from "date-fns";

//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { areDayPropsEqual } from "@mui/x-date-pickers/PickersDay/PickersDay";

//Assets
import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";

//Muicons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

//MuiComponents
import { useTheme, useMediaQuery, Drawer } from "@mui/material";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

//Services
import calculatorService from "../../../services/calculator-service";
import organizationService from "../../../services/organization-service";
import memberService from "../../../services/member-service";

// Our Components
import ExternalEnrollmentHeader from "./ExternalEnrollmentHeader";
import RecommendedBenefitDisplay from "./RecommendedBenefitDisplay";
import PoweredBy from "./PoweredBy";

//utils
import { checkMobileDevice } from "../../utils";

//styles
import enrollmentPlanStyles from "./enrollment-plans/enrollmentPlanStyles";

export default function ExternalEnrollmentCalculator(props) {

  const componentStyles = {
    surveyQuestions: {
      marginBottom: "13px",
      fontSize: "15px",
      fontWeight: "500"
    },
    recommendationContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: "0px auto 20px"
    },
    recommendedForYouRow: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0px auto 13px'
    },
    sparkleIcon: {
      height: '24px',
      width: '24px',
      color: '#FFD600',
      margin: '0px 9px'
    },
    recommendedForYouTitle: {
      fontSize: '16px',
      linheHight: '24px',
      fontFamily: 'Poppins',
      fontWeight: '400px'
    }
  }



  const UsdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  const history = useHistory();
  const btnStyle = {
    borderRadius: "100px",
    height: "50px",
    fontWeight: "700",
    maxWidth: props.agentEnroller ? "100%" : "275px",
    width: "95%!important",
  };

    //logic for checking blet groupPlanId = 18 - this group requires restricted payment date options
    const [bletGroupPlanId18, setBletOrgTrue] = useState(null)
    useEffect(() => {
  
      if(props?.results?.groupPlanId){
        setBletOrgTrue(props?.results?.groupPlanId && parseInt(props?.results?.groupPlanId) === 18 ? true : false)
      }
    }, [props?.results?.groupPlanId])


  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;
  let dateMax = `${year - 18}-${month}-${day}`;
  let dateMaxObj = new Date(dateMax);
  let dateMin = `${year - 100}-${month}-${day}`;
  let dateMinObj = new Date(dateMin);

  const [dobError, setDobError] = useState(false);

  // useEffect(() => {
  //   // console.log('dob check',props.userInfo.dob <= dateMin,dateMax <= props.userInfo.dob)
  //   if (props.userInfo.dob) {
  //     // if (!isValidDate(props.userInfo.dob)) {
  //     //     setDobError('Date of Birth must be a valid date')
  //     // }
  //     if (props.userInfo.dob <= dateMin) {
  //       setDobError("Must be less than 100 years old");
  //     } else if (dateMax <= props.userInfo.dob) {
  //       setDobError("Must be older than 18 years old");
  //     } else setDobError(null);
  //   }
  // }, [props.userInfo.dob]);

  const dobErrorMessage = useMemo(() => {

    switch (dobError) {
      case 'maxDate': {
        return 'Must be older than 18 years old';
      }
      case 'minDate': {
        return 'Must be less than 100 years old';
      }
      case 'disableFuture': {
        return 'Must be less than 100 years old';
      }
      case 'invalidDate': {
        return 'Your date is not valid';
      }
      default: {
        return '';
      }
    }
  }, [dobError])

  const isValidDate = (date) => {
    // console.log(date)
    // First check for the pattern
    if (!/^\d{4}-\d{1,2}-\d{1,2}/.test(date)) return false;

    // Parse the date parts to integers
    var parts = date.split("-");
    var day = parseInt(parts[2], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[0], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  const handleDobChangeMobile = (e) => {
    let inputVal = e;
    // inputVal = format(new Date(e), 'yyyy-MM-dd')
    // let dateWithinRange = ((new Date(inputVal).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(inputVal).getFullYear() <= new Date(dateMax).getFullYear()))

    // if (dateWithinRange) {
    props.setUserInfo({
      ...props.userInfo,
      dob: inputVal,
    });
    // }
    // checkAllow();
  };

  const handleDobChange = (e) => {
    let inputVal = e.target.value;
    // console.log(inputVal)
    // let dateWithinRange = ((new Date(inputVal).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(inputVal).getFullYear() <= new Date(dateMax).getFullYear()))

    // if (dateWithinRange) {
    props.setUserInfo({
      ...props.userInfo,
      dob: inputVal,
    });
    // }
    // checkAllow();
  };

  const [salaryError, setSalaryError] = useState(false);
  const handleSalaryChange = (e) => {
    let newValue = e.target.value;

    if (
      newValue !== "" &&
      newValue !== NaN &&
      newValue !== "$Na" &&
      newValue !== "$"
    ) {
      newValue = newValue.replace("$", "").replaceAll(",", "");
      newValue = parseInt(newValue);
    } else if (
      newValue == "" ||
      newValue == NaN ||
      newValue == "$Na" ||
      newValue == "$"
    ) {
      newValue = 0;
    }

    //if salary value is less than 10,000, set error to display
    if (newValue < 10000) {
      setSalaryError(true);
    } else setSalaryError(false);

    //update salary amount
    props.setUserInfo({
      ...props.userInfo,
      Salary: newValue,
    });
  };
  // const handleSalaryChange = (e) => {
  //    let inputVal = e.target.value;
  //     //console.log(inputVal.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,"));
  //     //const formatter = new Intl.NumberFormat('en-US', {
  //     //    style: 'currency',
  //     //    currency: 'USD',
  //     //});
  //     //if (inputVal) {
  //     //    let maskedValue = formatter.format(inputVal);
  //     //    maskedValue = maskedValue.replace("$", "");
  //     //    console.log(maskedValue);
  //     //    inputVal = maskedValue;
  //     //    console.log(inputVal);
  //     //}

  //    props.setUserInfo({
  //       ...props.userInfo,
  //       Salary: inputVal
  //    })
  //    checkAllow()
  // }

  const checkAllow = () => {
    let allow = true;

    for (let [key, val] of Object.entries(survey)) {
      if (props.userInfo.GroupPlanId != 7 && key == "isFullTime") {
        //continue
      }
      else if (!props?.results?.name?.includes("IUPAT") && key == "familyPlanning") {
        //continue
      }
      else if (key == "isEmployed" && val != true) {
        allow = false;
      }
      else if (val === null) allow = false;
    }
    if (!props.userInfo.dob || props.userInfo.Salary <= 0) {
      allow = false;
    }
    console.log('dobError in check', dobError)
    if (dobError) allow = false;
    if (salaryError) allow = false;

    //if blet, require employer to be selected from dropdown
    if (employerSelectionNeeded && !employerSelected?.id) allow = false
    setAllowCalculate(allow);
  };


  const [allowCalculate, setAllowCalculate] = useState(false);

  const getStoredSurvey = async (e) => {
    console.log('getting stored survey')
    let storedSurvey;
    // let storedSurvey = localStorage.getItem('recommendations')
    if (props.agentEnroller && props.fullMemberObj?.id > 0) {
      storedSurvey =
        await calculatorService.getRecommendationResponsesForMember(
          props.fullMemberObj.id
        );
    } else if (!props.agentEnroller) {
      storedSurvey = await calculatorService.getRecommendationResponses();
    }

    if (storedSurvey) {
      if (storedSurvey?.policyCount == null) storedSurvey.policyCount = 0;
      // let recommendationsObj = JSON.parse(storedSurvey)

      setSurvey(storedSurvey);
    }
  };

  // const storeRecommendations = (e) => {
  //    // let surveyString = JSON.stringify(survey)
  //    // localStorage.setItem('recommendations', surveyString)
  // }
  useEffect(() => {
    if (props?.fullMemberObj?.id) getStoredSurvey();
  }, [props.fullMemberObj]);

  const [survey, setSurvey] = useState({
    isEmployed: null,
    isMarried: null,
    isPrimary: null,
    haveDependents: null,
    mortgage: null,
    policyCount: 0,
  });
  let surveyHandler = (question, answer) => {
    if (survey[question] == answer) {
      setSurvey({
        ...survey,
        [question]: null,
      });
    } else {
      setSurvey({
        ...survey,
        [question]: answer,
      });
    }
  };
  // console.log('survey', survey)

  useEffect(() => {
    //HARDCODE
    if (
      survey.hasOwnProperty("isFullTime") &&
      props.results?.name &&
      (props.results.name.includes("Teamsters") ||
        props.results.name.includes("IBT"))
    ) {
      // console.log("full time question");
      if (survey.isFullTime === true) {
        handleFullTime(true, 12);
      } else if (survey.isFullTime === false) {
        handleFullTime(false, 13);
      } else {
        handleFullTime(null, 0);
      }
    }
  }, [survey?.isFullTime]);

  const [isFullTime, setIsFullTime] = useState({
    boolean: null,
    organizationId: 0,
  });

  const handleFullTime = (boolean, organizationId) => {
    setIsFullTime({
      ...isFullTime,
      boolean: boolean,
      organizationId: organizationId,
    });
  };

  //originally setting organizationId in userInfo here, needed tweak with way orgs are set up
  // useEffect(() => {
  //   if (isFullTime.organizationId > 0) {
  //     props.setUserInfo({
  //       ...props.userInfo,
  //       OrganizationId: isFullTime.organizationId,
  //     });
  //   }
  //   // console.log(props.userInfo);
  // }, [isFullTime.organizationId]);

  // useEffect(() => {
  //    surveyHandler('isFullTime',isFullTime.boolean)
  // },[isFullTime.boolean])

  useEffect(() => {
    checkAllow();
  }, [survey, props?.userInfo?.Salary, props.userInfo.dob, props?.fullMemberObj?.employerId, dobError]);

  const calculateNewRates = async () => {
    // storeRecommendations()
    console.log("calculating new rates with this dob, ", props.userInfo.dob);
    props.setLoading(true);
    let _userInfo = {
      GroupPlanId: props.userInfo.GroupPlanId,
      //  Salary: Math.min(props.userInfo.Salary, props.results?.maximumSalary),
      Salary: props.userInfo.Salary,
      dob: new Date(props.userInfo.dob),
      OrganizationId: props.userInfo.OrganizationId,
    };

    //if employer is required at this stage (member is part of BLET) and employerID now exists in fullMemberObj in state, update member in db
    if (employerSelectionNeeded && props.fullMemberObj?.employerId) {
      if (!props.agentEnroller) {
        try {
          await memberService.updateMember(props?.fullMemberObj);
        } catch (error) {
          console.error("error updating member employer in calculator", error);
        }
      } else {
        try {
          await memberService.updateForMember(
            props?.fullMemberObj?.id,
            props?.fullMemberObj
          );
        } catch (error) {
          console.error("error updating member employer in calculator", error);
        }
      }
    }

    //  console.log('Salary:' + props.userInfo.Salary);
    //  let _salary = parseFloat(props.userInfo.Salary);
    //  console.log(_salary);

    // not necessary, we do it on the back end
    //if (props.results && props.results.maximumSalary > _salary) {
    //    _salary = props.results.maximumSalary;
    //}
    //  _userInfo.Salary = _salary;

    // console.log(_userInfo, props.results)
    try {
      let _results;
      if (props.agentEnroller && props.fullMemberObj?.id > 0) {
        _results = await calculatorService.updateMemberCalculateRatesForMember(
          props.fullMemberObj.id,
          _userInfo
        );
      } else if (!props.agentEnroller) {
        _results = await calculatorService.updateMemberCalculateRates(
          _userInfo
        );
      }
      props.setResults(_results);
    } catch (error) {
      console.log("error calculating rates", error);
    }
    // console.log('calc res', _results)
    // console.log("survey were sending", survey);
    try {
      let _surveyResults;
      if (props.agentEnroller && props.fullMemberObj?.id > 0) {
        _surveyResults = await calculatorService.getRecommendationsForMember(
          props.fullMemberObj.id,
          survey
        );
      } else if (!props.agentEnroller) {
        _surveyResults = await calculatorService.getRecommendationsLoggedIn(
          survey
        );
      }
      props.setRecomendations(_surveyResults);
    } catch (error) {
      console.log("error getting recommendations", error);
    }
    // console.log('rec res', _surveyResults)
    window.scrollTo(0, 0);

    if (props.results?.useReferralCode) {
      try {
        if (props.agentEnroller) {
          if (props.memberAgent) {
            await memberService.updateMemberAgentForMember(
              props.fullMemberObj.id,
              props.localMemberAgentInfo.agentId
            );
          } else {
            await memberService.insertMemberAgentForMember(
              props.fullMemberObj.id,
              props.localMemberAgentInfo.agentId
            );
          }
        } else {
          if (props.memberAgent) {
            await memberService.updateMemberAgent(
              props.fullMemberObj.id,
              props.localMemberAgentInfo.agentId
            );
          } else {
            await memberService.insertMemberAgent(
              props.fullMemberObj.id,
              props.localMemberAgentInfo.agentId
            );
          }
        }
      } catch (error) {
        console.log("error saving memberAgent", error);
      }
    }

    try {
      if (props.agentEnroller) {
        //HARDCODE
        if (props.userInfo.GroupPlanId != 7) {
          await props.refreshMember(props.fullMemberObj.id);
        }
      } else {
        await props.getFullMemberObj();
      }
    } catch (error) {
      console.log("error refreshing member", error);
    }
    props.setLoading(false);

    if (props.agentEnroller) {
      props.setAgentEnrollmentPageState({ plans: true });
    } else {
      history.push("plans");
    }
  };

  const handleDobFocus = (e) => {
    e.target.setAttribute("type", "date");
  };
  const handleDobBlur = (e) => {
    // console.log(props.userInfo.dob)
    if (!props.userInfo.dob) e.target.setAttribute("type", "");
    else {
      // let dateWithinRange = ((new Date(inputVal).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(inputVal).getFullYear() <= new Date(dateMax).getFullYear()))
      // if (!dateWithinRange){
      // set error message
      // }
    }
  };

  const saveSurvey = async () => {
    // calculateNewRates()
    props.setSave(false);
    props.setOpenEnrollment(false);
  };

  // let theme = useTheme();
  //true if <= md
  // let medBreakpointHit = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    // console.log("props.save", props.save);
    if (props.save) {
      saveSurvey();
    }
  }, [props.save]);

  //GET EMPLOYER LIST from OrganizationId = 18 for BLET only

  const [employersFromOrgId, setEmployersFromOrgId] = useState(null);

  const getEmployersFromOrgId = async () => {
    let employer;
    //HARDCODE for BLET only
    let orgId = 18
    try {
      employer = await organizationService.getEmployersByOrganization(orgId);
    } catch (error) {
      console.error("error getting employer from org id", error);
    }
    if (employer) {
      //sort employers alphabetically 
      let sortedEmployers = employer?.sort((a, b) => {
        const nameA = a.name.replace(/"/g, '').toLowerCase(); // Remove quotes and convert to lowercase
        const nameB = b.name.replace(/"/g, '').toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
      setEmployersFromOrgId(sortedEmployers);
    } else setEmployersFromOrgId([]);
  };

  //Employer list autocomplete
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    setOpen(true);
  };

  useEffect(() => {
    if (props?.userInfo?.OrganizationId) {
      getEmployersFromOrgId();
    } else {
      console.log("no org id found!!!");
    }
  }, [props?.userInfo?.OrganizationId]);

  const [orgSelectionNeeded, setOrgSelectionNeeded] = useState(false);
  const [employerSelectionNeeded, setEmployerSelectionNeeded] = useState(false);
  const [employerSelected, setEmployerSelected] = useState({});
  const [employerError, setEmployerError] = useState(false);
  //if member has employer already seleted, set employerSelected to display in dropdown
  useEffect(() => {
    if (props?.fullMemberObj?.employerId) {
      let _employer = employersFromOrgId?.find(employer => employer.id == props?.fullMemberObj?.employerId);
      setEmployerSelected(_employer);
    }
  }, [employersFromOrgId, props?.fullMemberObj?.employerId]);

  // console.log("orgSTUFF", props.selectedEnrollment);
  useEffect(() => {
    if (props.selectedEnrollment) {
      let haveOrgFromCensus = false;
      if (
        props.selectedEnrollment?.groupPlanId != 7 &&
        props.selectedEnrollment?.groupPlanId != 9 &&
        props.selectedEnrollment?.organizations?.length > 0
      ) {
        let selectionsAvailable = 0;

        console.log(props?.selectedEnrollment)
        for (let org of props?.selectedEnrollment?.organizations) {
          if (org["isSelected"]) haveOrgFromCensus = true;
          else if (!org["disableSelect"]) selectionsAvailable++;

          //FOR BLET ONLY
          //if org name includeds BLET do NOT want to show org selection dropdown, set haveOrgFromCensus = true and employerSectionNeeded= true
          if (bletGroupPlanId18) {
            haveOrgFromCensus = true;
            setEmployerSelectionNeeded(true);
          } else {
            setEmployerSelectionNeeded(false);
          }
        }

        let _orgSelectionNeeded = !haveOrgFromCensus && selectionsAvailable > 0;
        setOrgSelectionNeeded(_orgSelectionNeeded);


      }

      //FOR BLET ONLY & if no org list present
      //check individual org on member object for org name also and require employer for BLET
      if (bletGroupPlanId18) {
        haveOrgFromCensus = true;
        setEmployerSelectionNeeded(true);
      }

      if (props?.selectedEnrollment?.name?.includes('Teamsters') || props?.selectedEnrollment?.name?.includes('IUEC') || props?.selectedEnrollment?.name?.includes('IUPAT')) {
        setOrgSelectionNeeded(false)
      }
    }
  }, [props.selectedEnrollment, props?.fullMemberObj?.organizationName]);

  const handleOrgSelect = (e) => {
    // console.log("handleOrg", e.target);
    let newOrgId = e.target.value;
    props.setUserInfo({
      ...props.userInfo,
      OrganizationId: newOrgId,
    });
  };

  const handleEmployerSelect = (e, newValue) => {
    if (newValue) {
      let newEmployerId = newValue.id;
      //update full member Object 
      props?.setFullMemberObj({
        ...props.fullMemberObj,
        employerId: newEmployerId,
      });

      //update local state
      setEmployerSelected(newValue);
    }
  };

  const checkEmployerOnBlur = () => {
    if (!employerSelected?.id) setEmployerError(true);
    else setEmployerError(false);
  }


  const handleMemberAgentChange = (e) => {
    let value = e.target.value;
    props.setLocalMemberAgentInfo({
      ...props.localMemberAgentInfo,
      agentId: value,
    });
  };

  const handleLocalNawExit = async () => {
    if (props.agentEnroller) {
      await calculatorService.updateMemberCalculateRatesForMember(
        props.fullMemberObj.id,
        props.userInfo
      );
      await calculatorService.getRecommendationsForMember(props.fullMemberObj.id, survey);
    } else {
      await calculatorService.updateMemberCalculateRates(props.userInfo);
      await calculatorService.getRecommendationsLoggedIn(survey);
    }
    await props.handleNawExit();
  }
  const [nawDrawerOpen, setNawDrawerOpen] = useState(false);
  useEffect(() => {
    if (survey.isEmployed != null && survey.isEmployed === false) {
      setNawDrawerOpen(true);
    } else {
      setNawDrawerOpen(false);
    }
  },[survey.isEmployed])

  const handleDrawerYesClick = () => {
    setSurvey({
      ...survey,
      isEmployed: true
    })
    setNawDrawerOpen(false);
  }

  const [salaryDrawerOpen, setSalaryDrawerOpen] = useState(false);

  const [familyPlanningDrawerOpen, setFamilyPlanningDrawerOpen] = useState(false);

  return (
    <>
      <style>
        {`
         .Mui-disabled.MuiButton-root.enroll-survey-btn {
            color:#195FFB;
            background-color: white;
            border: #195FFB 1px solid;
         }
         `}
      </style>
      <Box sx={{ overflowY: "auto", padding: '0 auto' }}>
        <Box
          className="row"
          sx={{
            height: props.agentEnroller ? "calc(100vh - 276px)" : "100vh",
            width: "100%",
            maxWidth: props.agentEnroller ? "100%" : "600px",
            margin: props.agentEnroller ? "" : "0 auto",
            padding: props.agentEnroller ? "20px" : "30px",
          }}
        >
          {!props.agentEnroller && (
            <>
              <ExternalEnrollmentHeader
                fullMemberObj={props.fullMemberObj}
                agentEnroller={props.agentEnroller}
                selectedEnrollment={props.selectedEnrollment}
              />

              <div
                className="flex-row"
                style={{
                  textAlign: "left",
                  paddingLeft: "5px",
                  marginTop: "5px",
                  marginBottom: "20px",
                }}
              >
                <Typography variant="body1" fontSize="15px" fontWeight="600">
                  Answer these quick questions to generate your benefit options
                  and recommendations.
                </Typography>
              </div>
            </>
          )}
          {props.agentEnroller && (
            <>
              <div
                className="flex-row"
                style={{
                  textAlign: "left",
                  paddingLeft: "5px",
                  marginTop: "5px",
                  marginBottom: "0px",
                }}
              >
                <Typography variant="h5" fontFamily={"Poppins"}>
                  Benefit Calculator
                </Typography>
              </div>

              <div
                className="flex-row"
                style={{
                  textAlign: "left",
                  paddingLeft: "5px",
                  marginTop: "5px",
                  marginBottom: "38px",
                }}
              >
                <Typography variant="body2" color="text.gray">
                  Answer these quick questions to generate benefit options and
                  recommendations for the member.
                </Typography>
              </div>
            </>
          )}

          <Box
            className="flex-row"
            sx={{
              padding: "0px",
              marginBottom: "0px",
              flexWrap: "wrap",
              textAlign: "unset",
            }}
          >
            <Typography
              variant="body1"
              className="col-12"
              style={componentStyles.surveyQuestions}
            >
              Please enter your income:
                <Box sx={{display:'inline', alignItems:'center'}} type='button' onClick={() => setSalaryDrawerOpen(true)}>
                  <InfoOutlinedIcon
                    sx={{...enrollmentPlanStyles.toolTipIcon, color:'#195ffb', height:'24px', width:'24px'}}
                  />
                </Box>
              {/* </Tooltip> */}
            </Typography>
          </Box>
          <div
            className="flex-row"
            style={{ marginBottom: "5px", padding: "0px" }}
          >

            <TextField
              fullWidth
              label="Income"
              variant="outlined"
              helperText={
                salaryError
                  ? "Please enter a valid salary"
                  : "*Include bonus and overtime"
              }
              FormHelperTextProps={{
                sx: { textAlign: "right!important", marginRight: "0px" },
              }}
              value={
                props.userInfo.Salary !== ""
                  ? UsdFormatter.format(props.userInfo.Salary)
                  : ""
              }
              onChange={handleSalaryChange}
              type="tel"
              error={salaryError}
              inputProps={{
                "data-property": "estimated-annual-earnings",
              }}
            // InputProps={{
            //    startAdornment: <InputAdornment position="start">$</InputAdornment>,
            //    type: 'number',
            // }}
            />
          </div>

          {/* {isMobileDevice ? (
            <div
              className="flex-row"
              style={{ marginBottom: "15px", padding: "0px" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  views={["year", "month", "day"]}
                  minDate={dateMinObj}
                  maxDate={dateMaxObj}
                  value={props.userInfo.dob || ""}
                  onChange={handleDobChangeMobile}
                  inputFormat="MM/dd/yyyy"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Date of birth"
                      placeholder="mm/dd/yyyy"
                      fullWidth
                      error={dobError}
                      helperText={dobError}
                      // type='date'
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          ) : (
            <div
              className="flex-row"
              style={{ marginBottom: "25px", padding: "0px" }}
            >
              <TextField
                fullWidth
                type={props.userInfo.dob ? "date" : ""}
                label="Date of Birth"
                variant="outlined"
                value={props.userInfo.dob || ""}
                error={dobError}
                helperText={dobError}
                onChange={handleDobChange}
                onFocus={handleDobFocus}
                onBlur={handleDobBlur}
                inputProps={{
                  min: dateMin,
                  max: dateMax,
                }}
              />
            </div>
              )} */}
          <Box
            className="flex-row"
            sx={{
              padding: "0px",
              marginBottom: "0px",
              flexWrap: "wrap",
              textAlign: "unset",
            }}
          >
            <Typography
              variant="body1"
              className="col-12"
              style={componentStyles.surveyQuestions}
            >
              Please enter your date of birth:
            </Typography>
          </Box>

          <Box sx={{ marginBottom: "15px", padding: "0px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableFuture
                label="Date of Birth"
                openTo="year"
                views={["year", "month", "day"]}
                format="MM/dd/yyyy"
                minDate={dateMinObj}
                maxDate={dateMaxObj}
                value={props.userInfo.dob || null}
                onChange={handleDobChangeMobile}
                // onAccept={handleDobBlur}
                onError={(error) => setDobError(error)}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    helperText: dobErrorMessage,
                  },
                  openPickerButton: { sx: { visibility: 'hidden' } } //hide calendar icon calandar 
                }}
              />
            </LocalizationProvider>
          </Box>

          <CardContent
            className="flex-row"
            style={{
              padding: "0px",
              marginBottom: "25px",
              flexWrap: "wrap",
              textAlign: "unset",
            }}
          >
            {/* Org Input */}
            {orgSelectionNeeded && (
              <>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  value={props.userInfo?.OrganizationId}
                  label="Your Organization"
                  onChange={handleOrgSelect}
                  sx={{ marginBottom: "13px" }}
                >
                  {props.selectedEnrollment?.organizations?.length > 0 &&
                    props.selectedEnrollment?.organizations.map(
                      (organization) => {
                        return (
                          <MenuItem value={organization.id}>
                            {organization.name}
                          </MenuItem>
                        );
                      }
                    )}
                </TextField>
              </>
            )}

            {/* Employer Input */}
            {employerSelectionNeeded && (
              <>

                <Autocomplete
                  disablePortal
                  fullWidth
                  sx={{ marginBottom: "13px" }}
                  // loading={loading}
                  options={employersFromOrgId || []}
                  open={open}
                  onOpen={handleOpen}
                  onClose={() => setOpen(false)}
                  onClick={(e) => setOpen(true)}
                  forcePopupIcon={true}
                  popupIcon={<ArrowDropDownIcon />}
                  value={employerSelected || {}}
                  onChange={handleEmployerSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Employer"
                      variant="outlined"
                      error={employerError}
                      helperText={employerError ? "Please select an employer" : ""}
                      onBlur={checkEmployerOnBlur}
                    />
                  )}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  renderOption={(params) => {
                    return (
                      <Stack
                        direction="row"
                        spacing={1}
                        {...params}
                        key={params["data-option-index"] + 1}
                      >
                        <span
                          style={{
                            fontFamily: "Archivo",
                            paddingLeft: "10px",
                            margin: "auto 0px",
                          }}
                        >
                          {params.key}
                        </span>
                      </Stack>
                    );
                  }}
                />
              </>
            )}

            <Typography
              variant="body1"
              className="col-12"
              style={componentStyles.surveyQuestions}
            >
              Are you currently employed?
              {/* <Tooltip
                title={
                  "Not currently employed means you do not have an employer. If you have an employer but are not actively working, select Yes"
                }
                placement="bottom-end"
                arrow
                enterTouchDelay={100}
              > */}
                <Box sx={{display:'inline', alignItems:'center'}} type='button' onClick={() => setNawDrawerOpen(true)}>
                  <InfoOutlinedIcon
                    sx={{...enrollmentPlanStyles.toolTipIcon, color:'#195ffb', height:'24px', width:'24px'}}
                  />
                </Box>
              {/* </Tooltip> */}
            </Typography>

            <div
              className="col-6"
              onClick={() => surveyHandler("isEmployed", true)}
              id="isEmployedTrue_Div"
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.isEmployed == null || survey.isEmployed == false}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              style={{ textAlign: "end" }}
              onClick={() => surveyHandler("isEmployed", false)}
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.isEmployed == null || survey.isEmployed == true}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                NO
              </Button>
            </div>

            {/* {props.userInfo.GroupPlanId == 7 && ( */}
            {props.results?.name &&
              (props.results.name.includes("Teamsters") ||
                props.results.name.includes("IBT")) && (
                <>
                  <Typography
                    variant="body1"
                    className="col-12"
                    style={{ marginBottom: "13px" }}
                  >
                    Are you a full time or part time employee?
                  </Typography>
                  <div
                    className="col-6"
                    onClick={() => {
                      // handleFullTime(true, 12)
                      surveyHandler("isFullTime", true);
                    }}
                    id="fullTimeDiv"
                  >
                    <Button
                      variant="contained"
                      className="enroll-survey-btn"
                      fullWidth
                      disabled={
                        survey.isFullTime == null ||
                        survey.isFullTime == false
                      }
                      sx={btnStyle}
                      style={{ marginBottom: "13px" }}
                    >
                      Full Time
                    </Button>
                  </div>
                  <div
                    className="col-6"
                    style={{ textAlign: "end" }}
                    onClick={() => {
                      // handleFullTime(false, 13)
                      surveyHandler("isFullTime", false);
                    }}
                  >
                    <Button
                      variant="contained"
                      className="enroll-survey-btn"
                      fullWidth
                      disabled={
                        survey.isFullTime == null || survey.isFullTime == true
                      }
                      sx={btnStyle}
                      style={{ marginBottom: "13px" }}
                    >
                      Part Time
                    </Button>
                  </div>
                </>
            )}

            


            <Typography
              variant="body1"
              className="col-12"
              style={componentStyles.surveyQuestions}
            >
              Are you married?
            </Typography>
            <div
              className="col-6"
              onClick={() => surveyHandler("isMarried", true)}
              id="isMarriedTrue_Div"
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.isMarried == null || survey.isMarried == false}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              style={{ textAlign: "end" }}
              onClick={() => surveyHandler("isMarried", false)}
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.isMarried == null || survey.isMarried == true}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                NO
              </Button>
            </div>

            <Typography
              variant="body1"
              className="col-12"
              style={componentStyles.surveyQuestions}
            >
              Do you have any dependents?
            </Typography>
            <div
              className="col-6"
              onClick={() => surveyHandler("haveDependents", true)}
              id="haveDependentsTrue_div"
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={
                  survey.haveDependents == null ||
                  survey.haveDependents == false
                }
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              style={{ textAlign: "end" }}
              onClick={() => surveyHandler("haveDependents", false)}
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={
                  survey.haveDependents == null || survey.haveDependents == true
                }
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                NO
              </Button>
            </div>

            <Typography
              variant="body1"
              className="col-12"
              style={componentStyles.surveyQuestions}
            >
              Are you the primary household income?
            </Typography>
            <div
              className="col-6"
              onClick={() => surveyHandler("isPrimary", true)}
              id="isPrimaryTrue_div"
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.isPrimary == null || survey.isPrimary == false}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              style={{ textAlign: "end" }}
              onClick={() => surveyHandler("isPrimary", false)}
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.isPrimary == null || survey.isPrimary == true}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                NO
              </Button>
            </div>

            <Typography
              variant="body1"
              className="col-12"
              style={componentStyles.surveyQuestions}
            >
              Do you have a mortgage or rent?
            </Typography>
            <div
              className="col-6"
              styles={{ marginBottom: "13px" }}
              onClick={() => surveyHandler("mortgage", true)}
              id="mortgageTrue_div"
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.mortgage == null || survey.mortgage == false}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                YES
              </Button>
            </div>
            <div
              className="col-6"
              style={{ textAlign: "end" }}
              onClick={() => surveyHandler("mortgage", false)}
            >
              <Button
                variant="contained"
                className="enroll-survey-btn"
                fullWidth
                disabled={survey.mortgage == null || survey.mortgage == true}
                sx={btnStyle}
                style={{ marginBottom: "13px" }}
              >
                NO
              </Button>
            </div>

            {props.results?.name &&
              (props.results.name.includes("IUPAT")) && (
                <>
                  <Typography
                    variant="body1"
                    className="col-12"
                    style={componentStyles.surveyQuestions}
                  >
                    Are you a female wanting coverage for pregnancy?
                    <Box sx={{display:'inline', alignItems:'center'}} type='button' onClick={() => setFamilyPlanningDrawerOpen(true)}>
                      <InfoOutlinedIcon
                        sx={{...enrollmentPlanStyles.toolTipIcon, color:'#195ffb', height:'24px', width:'24px'}}
                      />
                    </Box>
                  </Typography>
                  <div
                    className="col-6"
                    onClick={() => {
                      surveyHandler("familyPlanning", true);
                    }}
                    id="familyPlanningTrueDiv"
                  >
                    <Button
                      variant="contained"
                      className="enroll-survey-btn"
                      fullWidth
                      disabled={
                        survey.familyPlanning == null ||
                        survey.familyPlanning == false
                      }
                      sx={btnStyle}
                      // style={{ marginBottom: "13px" }}
                    >
                      Yes
                    </Button>
                  </div>
                  <div
                    className="col-6"
                    style={{ textAlign: "end" }}
                    onClick={() => {
                      // handleFullTime(false, 13)
                      surveyHandler("familyPlanning", false);
                    }}
                  >
                    <Button
                      variant="contained"
                      className="enroll-survey-btn"
                      fullWidth
                      disabled={
                        survey.familyPlanning == null || survey.familyPlanning == true
                      }
                      sx={btnStyle}
                      // style={{ marginBottom: "13px" }}
                    >
                      No
                    </Button>
                  </div>
                </>
              )
            }

            {props.results?.useReferralCode && (
              <>
                <Typography
                  variant="body1"
                  className="col-12 flex-row"
                  style={{ margin: "36px 0px 13px 0px" }}
                >
                  Receive an agent referral code? (Optional)
                  <Tooltip
                    title="Agents at UTUIA are assigned a unique code to provide members they assist during open enrollment"
                    placement="bottom"
                    enterTouchDelay={50}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <InfoOutlinedIcon
                        sx={{ height: "20px", color: "#00000038" }}
                      />
                    </span>
                  </Tooltip>
                </Typography>
                <div className="col-12">
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Enter agent referral code"
                    sx={{
                      "& fieldset > legend": { width: 0 },
                    }}
                    value={props.localMemberAgentInfo?.agentId || ""}
                    onChange={handleMemberAgentChange}
                  />
                </div>
              </>
            )}
          </CardContent>

          <Box
            className="flex-row"
            style={{
              margin: "15px 0px 30px 0px",
              padding: "0px",
              justfiyContent: "center",
            }}
          >
            <Button
              variant="contained"
              fullWidth
              sx={{ height: 50, borderRadius: 100 }}
              disabled={!allowCalculate}
              onClick={calculateNewRates}
            >
              Calculate
            </Button>
          </Box>

          <Box
            sx={componentStyles.recommendationContainer}
          >
            <Box sx={componentStyles.recommendedForYouRow}>
              <AutoAwesomeIcon sx={componentStyles.sparkleIcon} />
              <Typography variant="body1" sx={componentStyles.recommendedForYouTitle} >
                Recommended For You
              </Typography>
              <AutoAwesomeIcon sx={componentStyles.sparkleIcon} />
            </Box>
            <RecommendedBenefitDisplay calculatorDisplay={true} />
          </Box>

          {!props?.agentEnroller && <PoweredBy />}
        </Box>

        <Drawer 
          // open={props.open} onClose={props.handleClose}
          open={nawDrawerOpen} onClose={() => setNawDrawerOpen(false)}
          anchor='bottom' sx={{ maxHeight: '200px' }}
            PaperProps={{
                sx: {
                    borderRadius: '12px 12px 0px 0px', padding: '0px 16px 24px', bgcolor: 'background.default',
                    color: 'text.primary', maxHeight: '75%'
                }
            }} disableScrollLock={true}
        >
            <Box sx={{
                bgcolor: 'paper.background.default',
                color: 'text.primary',
                maxWidth: '600px',
                margin: 'auto'
            }}>
                <div className="row" style={{ marginBottom: '20px',backgroundColor:'white',position:'sticky',top:0,paddingTop:'16px' }}>
                    <Typography className="col-10" variant='blueAccent' sx={{fontSize:'24px'}}>Not Currently Employed</Typography>
                    <div className="col-2" style={{textAlign:'end'}}>
                        <IconButton onClick={()=>setNawDrawerOpen(false)} >
                            <CloseIcon sx={{ color: '#195ffb' }} />
                        </IconButton>
                    </div>
                </div>
                <div className='col-12' style={{ marginBottom: "20px" }}>
                    <Typography variant='body1' sx={{fontSize:'14px'}}>
                    "Not currently employed" means you do not have an employer. If you have an employer but are not actively working, please select "yes."
                    </Typography>
                </div>
                {survey.isEmployed != null && survey.isEmployed === false &&
                  <CardContent
                    className="flex-row"
                    style={{
                      padding: "0px",
                      marginBottom: "25px",
                      flexWrap: "wrap",
                      textAlign: "unset",
                    }}
                  >
                    <div
                      className="col-6"
                      onClick={handleDrawerYesClick}
                      id="isEmployedTrue_Div"
                    >
                      <Button
                        variant="contained"
                        className="enroll-survey-btn"
                        fullWidth
                        // disabled={survey.isEmployed == null || survey.isEmployed == false}
                        disabled={true}
                        sx={{...btnStyle, fontSize:'11px', fontWeight:500, letterSpacing:'.46px'}}
                        style={{ marginBottom: "13px" }}
                      >
                        YES, I AM EMPLOYED
                      </Button>
                    </div>
                    <div
                      className="col-6"
                      style={{ textAlign: "end" }}
                      onClick={handleLocalNawExit}
                    >
                      <Button
                        variant="contained"
                        className="enroll-survey-btn"
                        fullWidth
                        // disabled={survey.isEmployed == null || survey.isEmployed == true}
                        disabled={true}
                        sx={{...btnStyle, fontSize:'14px', fontWeight:500, letterSpacing:'.46px'}}
                        style={{ marginBottom: "13px" }}
                      >
                        SAVE & EXIT
                      </Button>
                    </div>
                  </CardContent>
                }
            </Box>
        </Drawer>

        <Drawer 
          // open={props.open} onClose={props.handleClose}
          open={salaryDrawerOpen} onClose={() => setSalaryDrawerOpen(false)}
          anchor='bottom' sx={{ maxHeight: '200px' }}
            PaperProps={{
                sx: {
                    borderRadius: '12px 12px 0px 0px', padding: '0px 16px 24px', bgcolor: 'background.default',
                    color: 'text.primary', maxHeight: '75%'
                }
            }} disableScrollLock={true}
        >
            <Box sx={{
                bgcolor: 'paper.background.default',
                color: 'text.primary',
                maxWidth: '600px',
                margin: 'auto'
            }}>
                <div className="row" style={{ marginBottom: '20px',backgroundColor:'white',position:'sticky',top:0,paddingTop:'16px' }}>
                    <Typography className="col-10" variant='blueAccent' sx={{fontSize:'24px'}}>Income</Typography>
                    <div className="col-2" style={{textAlign:'end'}}>
                        <IconButton onClick={()=>setSalaryDrawerOpen(false)} >
                            <CloseIcon sx={{ color: '#195ffb' }} />
                        </IconButton>
                    </div>
                </div>
                <div className='col-12' style={{ marginBottom: "20px" }}>
                    <Typography variant='body1' sx={{fontSize:'14px'}}>
                      Your income determines what benefit you qualify for at time of claim.  We will use last years W2 or your most recent pay stubs to verify this.
                    </Typography>
                </div>

            </Box>
        </Drawer>

        <Drawer 
          // open={props.open} onClose={props.handleClose}
          open={familyPlanningDrawerOpen} onClose={() => setFamilyPlanningDrawerOpen(false)}
          anchor='bottom' sx={{ maxHeight: '200px' }}
            PaperProps={{
                sx: {
                    borderRadius: '12px 12px 0px 0px', padding: '0px 16px 24px', bgcolor: 'background.default',
                    color: 'text.primary', maxHeight: '75%'
                }
            }} disableScrollLock={true}
        >
            <Box sx={{
                bgcolor: 'paper.background.default',
                color: 'text.primary',
                maxWidth: '600px',
                margin: 'auto'
            }}>
                <div className="row" style={{ marginBottom: '20px',backgroundColor:'white',position:'sticky',top:0,paddingTop:'16px' }}>
                    <Typography className="col-10" variant='blueAccent' sx={{fontSize:'24px'}}>Family Planning</Typography>
                    <div className="col-2" style={{textAlign:'end'}}>
                        <IconButton onClick={()=>setFamilyPlanningDrawerOpen(false)} >
                            <CloseIcon sx={{ color: '#195ffb' }} />
                        </IconButton>
                    </div>
                </div>
                <div className='col-12' style={{ marginBottom: "20px" }}>
                    <Typography variant='body1' sx={{fontSize:'14px'}}>
                      This specific IUPAT benefit is designed for females intending to become pregnant in the near future and offers a longer STD duration of 12 months.
                    </Typography>
                </div>

            </Box>
        </Drawer>
      </Box>
    </>
  )
}

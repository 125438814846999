import React, { useEffect, useState, useContext } from "react"
// import { format, differenceInCalendarYears } from "date-fns";

//Assets
// import bankLogo from '../../../../../../assets/images/bankOfAmerica.png'

//Services

//Mui icons
import ManageSearchIcon from "@mui/icons-material/ManageSearch"

//Mui Components
import { Box } from "@mui/system"
import { Divider, Paper, Typography } from "@mui/material"

//Our Components
// import MemberPlansHeader from "./member-plans-header/MemberPlansHeader";
// import MemberPlansStd from "./member-plans-std/MemberPlansStd";
// import MemberPlansLtd from "./member-plans-ltd/MemberPlansLtd";
// import MemberPlansLadd from "./member-plans-ladd/MemberPlansLadd";
// import MemberPlansAccident from "./member-plans-accident/MemberPlansAccident";
// import MemberPlansStdIncremental from "./member-plans-std-incremental/MemberPlansStdIncremental";
// import MemberPlansLtdIncremental from "./member-plans-ltd-incremental/MemberPlansLtdIncremental";
// import MemberPlansCancer from "./member-plans-cancer/MemberPlansCancer";
// import MemberPlansADD from "./member-plans-add/MemberPlansAdd";
// import PlanSelector from "./plan-selector/PlanSelector";
import EnrollmentSummaryCard from "./summary-cards/EnrollmentSummaryCard"
import PlanSummaryCard from "./summary-cards/PlanSummaryCard"
import SignatureModal from "../../../../../app-external/external-enrollments/signature-modal/SignatureModal"

//Our components for R-side of plans tab
import MemberPlanPaymentSummary from "./plan-tab-left-side/MemberPlanPaymentSummary"
import MemberPlanPaymentSchedule from "./plan-tab-left-side/MemberPlanPaymentSchedule"
import MemberPlanDocuments from "./plan-tab-left-side/MemberPlanDocuments"
import PaymentHealthChips from "../../../DisplayChips/PaymentHealthChips"
import NonPeakPayChip from "../../../DisplayChips/NonPeakPayChip"

const componentStyles = {
  divider: {
    width: "98%",
    height: "1px",
    margin: "0 auto",
    color: "background.border",
    opacity: 0.6,
  },
}

export default function MemberPlansTab(props) {

  const memberHasActiveCoverage = props?.paymentSchedule?.length > 0 ? props?.paymentSchedule[0]?.id > 0: props?.enrollments?.length > 0 //will need to update with active coverage work  and replace props.enrollments with AC
  const [permissionsEnabler, setPermissionsEnabler] = useState({})
  useEffect(() => {
    if (props.permissionsEnabler) {
      setPermissionsEnabler(props.permissionsEnabler)
    }
  }, [props.permissionsEnabler])

  const [currentEdit, setCurrentEdit] = useState({})
  let planCosts = props?.planCosts

  const [paymentSchedule, setPaymentSchedule] = useState()

  useEffect(() => {
    setPaymentSchedule(props.paymentSchedule)
  }, [props?.paymentSchedule])

  //make sure a user can edit a wallet from the plans tab
  useEffect(() => {
    props.setEditWalletFromPlans(true)
  }, [])

  //format date to be used in other components throughout plans summary
  const formatDate = (rawDate) => {
    // console.log('formatDate',rawDate)
    // if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/)
    let date = new Date()
    date.setUTCFullYear(+yyyy)
    date.setUTCDate(+dd)
    date.setUTCMonth(mm - 1)
    date.setUTCHours(+hh)
    date.setUTCMinutes(+m + date.getTimezoneOffset())
    date.setUTCSeconds(+s)
    // console.log('formatDate after',date)
    return date
  }

  //SigModal
  // State starts closed
  const [openEnrollment, setOpenEnrollment] = useState(false)
  const [modalConfig, setModalConfig] = useState("")

  const handleEnrollmentClick = (enrollmentStateId, cancelEnrollment = false) => {
    if (enrollmentStateId == 2) {
      if (cancelEnrollment) {
        //Show sig modal for cancel
        setModalConfig("cancelEnrollment")
        setSigModalOpen(true)
        //  setEnrollmentMenu(null)
      } else {
        //Show sig modal for reopen
        setModalConfig("Reopen")
        setSigModalOpen(true)
        //  setEnrollmentMenu(null)
      }
    } else if (enrollmentStateId == 3) {
      setSigModalOpen(true)
      setModalConfig("Cancel")
      //  setEnrollmentMenu(null)
    } else {
      setOpenEnrollment(true)
      //  setTabValue(4)
    }
  }

  const [sigModalOpen, setSigModalOpen] = useState(false)

  return (
    <Box
      className="member-router-container"
      sx={{
        bgcolor: "background.background",
        color: "text.primary",
        height: "calc(100vh - 104px)",
        position: "relative",
      }}
    >
      {props.enrollments?.length < 1 ||
        (props.enrollments?.length == 1 &&
          props.singleEnrollment?.enrollmentStateId == 0 && (
            <>
              <Box
                sx={{
                  backgroundColor: "",
                  height: "80%",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    margin: "54px auto 13px",
                    width: "58px",
                    height: "58px",
                    backgroundColor: "#195FFB12",
                    borderRadius: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ManageSearchIcon
                    sx={{ width: "28px", height: "28px", color: "#195FFB" }}
                  />
                </Box>
                <Box sx={{ marginBottom: "19px" }}>
                  Member does not currently have plan information
                </Box>
              </Box>
            </>
          ))}
      {/* {props.availableEnrollments?.length > 1 && !props.singleEnrollment &&
                // <Paper elevation={0} sx={{
                //     border: '1px solid',
                //     borderColor: 'background.border', backgroundColor: 'background.default'
                // }}>
                //     {props.enrollments.map(enrollment => {
                //         return (
                //             <PlanSelector
                //                 enrollments={props.enrollments} 
                //                 member={props.member} 
                //                 UsdFormatterDec={props.UsdFormatterDec} 
                //                 singleEnrollment={enrollment} 
                //                 planCosts={planCosts} paymentSchedule={paymentSchedule}
                //                 selectPlan={selectPlan}
                //             />
                //         )
                //     })}
                // </Paper>
                <>
                    {props.availableEnrollments.map(enrollment => {
                        return (
                            <PlanSelector
                                enrollments={props.enrollments} 
                                member={props.member} 
                                UsdFormatterDec={props.UsdFormatterDec} 
                                singleEnrollment={enrollment} 
                                planCosts={planCosts} paymentSchedule={paymentSchedule}
                                selectPlan={selectPlan}
                            />
                        )
                    })}
                </>
            } */}
      {/* {props.singleEnrollment?.enrollmentStateId > 0 && */}
      <Paper
        elevation={0}
        sx={{
          height: "100% ",
          border: "0px solid",
          borderRadius: "0px",
          borderColor: "background.border",
          backgroundColor: "background.default",
        }}
      >
        <Box
          className="flex-row"
          sx={{
            height: "95px",
            padding: "17px 9px 8px",
            flexWrap: "wrap",
          }}
        >
          <Box className="col-12">
            <Typography variant="h4">
              All Plans
              <Box
                sx={{
                  display: props.permissionsEnabler?.viewPayments
                    ? "inline"
                    : "none",
                  marginLeft: "10px",
                }}
              >
                {/* Peak pay member balance chip */}
                {props.availablePlans?.length > 0 &&
                  props?.paymentSchedule[0]?.isPeakPay && (
                    <PaymentHealthChips
                      paymentHealthStatus={props.topOutstandingBalance}
                      paymentSchedule={props?.paymentSchedule}
                    />
                  )}
                {/* Non-Peak Pay chip - if enrolled w/ valid pay schedule but not PeakPay */}
                {memberHasActiveCoverage &&
                !props?.paymentSchedule[0]?.isPeakPay ? (
                  <NonPeakPayChip />
                ) : (
                  ""
                )}
              </Box>
            </Typography>
          </Box>
          <Box
            className="col-12"
            sx={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSie: "16px",
              lineHeight: "28px",
              letter: ".15px",
            }}
          >
            Overview of all plans that a member is participating in
          </Box>
        </Box>
        <Divider sx={componentStyles.divider} />
        <Box className="flex-row">
          {/* PLAN INFO */}
          <Box
            sx={{
              borderRight: "1px solid",
              borderColor: "background.border",
              height: "calc(100vh - 213px)",
              overflowX: "hidden",
              overflowY: "auto",
            }}
            className="col-7"
          >
            
            {/* OPEN ENROLLMENTS */}
            {props.availableEnrollments?.length > 0 && 
            <>
            <Box
              className="flex-row"
              sx={{
                flexWrap: "wrap",
                padding: "16px 16px 0px",
              }}
            >
              <Box className="col-12">
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "32px",
                    letterSpacing: ".15px",
                    color: "light",
                  }}
                >
                  IN OPEN-ENROLLMENT
                </Typography>
              </Box>

              <Box className="col-12">
                <Typography
                  sx={{
                    fontFamily: "Archivo",
                    fontWeight: 400,
                    fontSie: "16px",
                    lineHeight: "28px",
                    letter: ".15px",
                    color: "light",
                  }}
                >
                  View applications that are in open-enrollment or in-progress
                </Typography>
              </Box>
            </Box>
            {props.availableEnrollments?.length > 0 ? (
              <>
                {props.availableEnrollments.map((enrollment) => (
                  <EnrollmentSummaryCard
                    member={props.member}
                    UsdFormatterDec={props.UsdFormatterDec}
                    singleEnrollment={enrollment}
                    planCosts={planCosts}
                    paymentSchedule={paymentSchedule}
                    enrollmentStates={props.enrollmentStates}
                    dependents={props.dependents}
                    spouse={props.spouse}
                    relationshipTypes={props.relationshipTypes}
                    setSelectedEnrollment={props.setSelectedEnrollment}
                    setActivePage={props.setActivePage}
                    handleEnrollmentClick={handleEnrollmentClick}
                    documents={props.documents}
                    getAllDocuments={props.getAllDocuments}
                    formatDate={formatDate}
                    updateSinglePlan={props.updateSinglePlan}
                    cancellationReasons={props.cancellationReasons}

                  />
                ))}
              </>
            ) : (
              <Box
                className="flex-row"
                sx={{ padding: "8px 18px", flexWrap: "wrap" }}
              >
                No Open Enrollments
              </Box>
            )}
            <Divider sx={componentStyles.divider} />
            </>
            }
            {/* Active Coverages */}
            <Box
              sx={{
                display: "felx",
                flexDirecton: "row",
                flexWrap: "wrap",
                padding: "16px 16px 0px",
                borderBottom: "0px",
              }}
            >
              <Box className="col-12">
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "32px",
                    letterSpacing: ".15px",
                    color: "light",
                  }}
                >
                  Active Coverage
                </Typography>
              </Box>

              <Box className="col-12">
                <Typography
                  sx={{
                    fontFamily: "Archivo",
                    fontWeight: 400,
                    fontSie: "16px",
                    lineHeight: "28px",
                    letter: ".15px",
                    color: "text.gray",
                  }}
                >
                  View current plan coverage
                </Typography>
              </Box>
            </Box>
            <Divider sx={componentStyles.divider} />

            {props.availablePlans?.length > 0 ? (
              <>
                {props.availablePlans.map((plan) => (
                  <PlanSummaryCard
                    key={plan?.enrollmentId}
                    member={props.member}
                    UsdFormatterDec={props.UsdFormatterDec}
                    singlePlan={plan}
                    planCosts={planCosts}
                    paymentSchedule={paymentSchedule}
                    enrollmentStates={props.enrollmentStates}
                    relationshipTypes={props.relationshipTypes}
                    updateSinglePlan={props.updateSinglePlan}
                    setSelectedPlan={props.setSelectedPlan}
                    selectedPlan={props.selectedPlan}
                    dependents={props.dependents}
                    spouse={props.spouse}
                    currentEdit={currentEdit}
                    setCurrentEdit={setCurrentEdit}
                    allowModify={permissionsEnabler.modifyEnrollment}
                    allowEditEnrollment={permissionsEnabler.editEnrollment}
                    documents={props.documents}
                    getAllDocuments={props.getAllDocuments}
                    activeCoveragePlanSummary={true}
                    cancellationReasons={props.cancellationReasons}
                    getPaymentSchedule={props.getPaymentSchedule}
                    formatDate={formatDate}
                    getOneEnrollment={props.getOneEnrollment}
                    getEnrollments={props.getEnrollments}
                    handleActivePageChange={props?.handleActivePageChange}
                  />
                ))}
              </>
            ) : (
              <Box
                sx={{ display: "flex", padding: "8px 18px", flexWrap: "wrap" }}
              >
                <Typography>No Active Coverages</Typography>
                {/* display cancel message if enrollment is cancelled */}
                {props?.selectedPlan?.enrollmentStateId &&
                  props?.selectedPlan?.enrollmentStateId === 3 && (
                    <Typography>- Plan Canceled</Typography>
                  )}
              </Box>
            )}
          </Box>

          {/* RIGHT SIDE - PAYMENT INFO */}
          <Box
            className="col-5"
            sx={{
              height: "calc(100vh - 213px)",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <MemberPlanPaymentSummary
              totalPlanCosts={props.totalPlanCosts}
              UsdFormatterDec={props.UsdFormatterDec}
              paymentSchedule={props.paymentSchedule}
              enrollments={props.enrollments}
              memberHasActiveCoverage={memberHasActiveCoverage}
            />

            {/* display payment method section if member onn peakPay and has active coverage */}
            {props?.paymentSchedule[0]?.isPeakPay ? (
              <MemberPlanPaymentSchedule
                member={props.member}
                UsdFormatterDec={props.UsdFormatterDec}
                bankAccount={props.bankAccount}
                paymentSchedule={props.paymentSchedule}
                paymentFrequencies={props.paymentFrequencies}
                walletService={props.walletService}
                getPaymentSchedule={props.getPaymentSchedule}
                getBankAccount={props.getBankAccount}
                permissionsEnabler={permissionsEnabler}
                setBankAccount={props.setBankAccount}
                bankAccountTypes={props.bankAccountTypes}
                editWalletFromPlans={props.editWalletFromPlans}
                memberHasActiveCoverage={memberHasActiveCoverage}
              />
            ) : (
              <></>
            )}
            <MemberPlanDocuments
              member={props.member}
              permissionsEnabler={permissionsEnabler}
              documents={props.documents}
              getAllDocuments={props.getAllDocuments}
              attachmentTypes={props.attachmentTypes}
            />
          </Box>
        </Box>
      </Paper>
      {/* } */}

      <SignatureModal
        agentEnroller={true}
        setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
        fullMemberObj={props.member}
        guid={props.guid}
        setGuid={props.setGuid}
        results={props.singleEnrollment}
        open={sigModalOpen}
        handleClose={() => setSigModalOpen(false)}
        handleOpen={() => setSigModalOpen(false)}
        setEnrollmentSelected={props.setSelectedEnrollment}
        signClick={modalConfig}
        setOpenEnrollment={setOpenEnrollment}
        // setTabValue={setTabValue}
        enrollmentId={props?.singleEnrollment?.enrollmentId}
        setSigModalOpen={setSigModalOpen}
        // displayPay={displayPay}
        refreshEnrollments={props.getEnrollments}
        refreshEnrollment={props.getOneEnrollment}
        setActivePage={props.setActivePage}
      />
    </Box>
  )
}

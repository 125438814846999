import axios from "axios";
import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalEnrollmentService extends baseService {
    getMe() {
        const url = Settings.API_URL + "enrollment";
        return this.get(url);
    }
    getEnrollmentsByMemberId(memberId) {
        const url = Settings.API_URL + "enrollment/member/" + memberId;
        return this.get(url);
    }
    getEnrollmentById(enrollmentId) {
        const url = Settings.API_URL + "enrollment/" + enrollmentId;
        return this.get(url);
    }
    saveLADD(groupPlanId, planId, memberCoverageAmount, spouseCoverageAmount, dependentCoverageAmount) {
        const url = Settings.API_URL + "enrollment/ladd";
        const laddData = {
            groupPlanId: groupPlanId,
            planId: planId,
            memberCoverageAmount: memberCoverageAmount,
            spouseCoverageAmount: spouseCoverageAmount,
            dependentCoverageAmount: dependentCoverageAmount
        };
        return this.post(url, laddData);
    }
    saveLADDForMember(memberId, groupPlanId, planId, memberCoverageAmount, spouseCoverageAmount, dependentCoverageAmount) {
        const url = Settings.API_URL + "enrollment/member/" + memberId + "/ladd";
        const laddData = {
            groupPlanId: groupPlanId,
            planId: planId,
            memberCoverageAmount: memberCoverageAmount,
            spouseCoverageAmount: spouseCoverageAmount,
            dependentCoverageAmount: dependentCoverageAmount
        };
        return this.post(url, laddData);
    }
    editLADDForMember(memberId, groupPlanId, planId, enrollmentId, cancellationReasonId, memberCoverageAmount, spouseCoverageAmount, dependentCoverageAmount) {
        const url = Settings.API_URL + "enrollment/member/" + memberId + "/ladd/edit";
        const laddData = {
            groupPlanId: groupPlanId,
            planId: planId,
            enrollmentId: enrollmentId,
            cancellationReasonId: cancellationReasonId,
            memberCoverageAmount: memberCoverageAmount,
            spouseCoverageAmount: spouseCoverageAmount,
            dependentCoverageAmount: dependentCoverageAmount
        };
        return this.post(url, laddData);        
    }
    saveOptionForMember(memberId, groupPlanId, optionId) {
        console.log('Save Option for member!' + memberId, "groupPlanId", groupPlanId, "optionId", optionId);
        const url = Settings.API_URL + "enrollment/member/" + memberId + "/option";
        const saveOption = {
            optionId: optionId,
            groupPlanId: groupPlanId
        }

        return this.post(url, saveOption);
    }
    editOptionForMember(memberId, groupPlanId, planId, optionId, enrollmentId, reasonId) {
        const url = Settings.API_URL + "enrollment/member/" + memberId + "/option/edit";
        const saveOption = {
            optionId: optionId,
            groupPlanId: groupPlanId,
            planId: planId,
            enrollmentId: enrollmentId,
            cancellationReasonId: reasonId
        }
        return this.post(url, saveOption)
    }
    getEnrollmentPlanCancellations(enrollmentId) {
        const url = Settings.API_URL + "enrollment/" + enrollmentId + "/cancellations";
        return this.get(url);
    }
    saveOption(groupPlanId, optionId) {
        console.log('Save Option!');
        const url = Settings.API_URL + "enrollment/option"
        const option = {
            optionId: optionId,
            groupPlanId: groupPlanId
        }

        return this.post(url, option);
    }

    //obsolete method r/t no planId required so allocations not sent in response
    //only being used in BenefitsWizardBeneficiary.js
    getBeneficiaries() {
        const url = Settings.API_URL + "enrollment/beneficiary";
        return this.get(url);
    }
    getBeneficiariesForMember(memberId, planId) {
        const url = Settings.API_URL + "enrollment/beneficiary/" + memberId + "/" + planId;
        return this.get(url);
    }
    getBeneficiaryAllocations(planId) {
        const url = Settings.API_URL + "enrollment/beneficiary/allocation/" + planId;
        return this.get(url);
    }
    getBeneficiaryAllocationsForMember(planId, memberId) {
        const url = Settings.API_URL + "enrollment/beneficiary/" + memberId + "/" + planId;
        return this.get(url);
    }
    getPotentialBeneficiaries() {
        const url = Settings.API_URL + "enrollment/beneficiary/potential";
        return this.get(url);
    }
    getPotentialBeneficiariesForMember(memberId) {
        const url = Settings.API_URL + "enrollment/beneficiary/potential/" + memberId;
        return this.get(url);
    }
    submitEnrollment(signatureObj) {
        const url = Settings.API_URL + "enrollment/";
        return this.post(url, signatureObj);
    }
    // Remember to populate the IsMail and IsVerbal properties. They are booleans.
    // The IsVerbal should be set to true for nearly all agent enrollments
    submitEnrollmentForMember(signatureObj, memberId) {
        const url = Settings.API_URL + "enrollment/enrollment/" + memberId;
        return this.post(url, signatureObj);
    }
    openEnrollment(signatureObj) {
        const url = Settings.API_URL + "enrollment/open";
        return this.post(url, signatureObj);
    }
    openEnrollmentForMember(signatureObj, memberId) {
        const url = Settings.API_URL + "enrollment/open/" + memberId;
        return this.post(url, signatureObj);
    }
    getEnrollmentTerms(groupPlanId) {
        const url = Settings.API_URL + "enrollment/terms/" + groupPlanId;
        return this.get(url);
    }
    getACHTerms(groupPlanId) {
        const url = Settings.API_URL + "enrollment/terms/ach/" + groupPlanId;
        return this.get(url);
    }
    getPolicyInformation(groupPlanId) {
        const url = Settings.API_URL + "enrollment/terms/policy/" + groupPlanId;
        return this.get(url);
    }
    getVerbalAgreement(groupPlanId) {
        const url = Settings.API_URL + "enrollment/terms/verbal/" + groupPlanId;
        return this.get(url);
    }
  
    // Beneficiary should be:
    // {
    //      memberId: 1,
    //      firstName: "Matthew",
    //      lastName: "Bowden",
    //      dob: "8/10/1984",
    //      relationshipTypeId: 1
    // }

    // I still need to add allocations
    addBeneficiary(beneficiary) {
        const url = Settings.API_URL + "enrollment/beneficiary";
        return this.post(url, beneficiary);
    }
    addBeneficiaryForMember(memberId, beneficiary) {
        const url = Settings.API_URL + "enrollment/beneficiary/" + memberId;
        return this.post(url, beneficiary);
    }
    updateBeneficiary(beneficiary) {
        const url = Settings.API_URL + "enrollment/beneficiary";
        return this.put(url, beneficiary);
    }
    updateBeneficiaryForMember(memberId, beneficiary) {
        const url = Settings.API_URL + "enrollment/beneficiary/" + memberId;
        return this.put(url, beneficiary);
    }
  
    deleteBeneficiary(beneficiaryId) {
        const url = Settings.API_URL + "enrollment/beneficiary/" + beneficiaryId;
        return this.delete(url);
    }
    deleteBeneficiaryByMemberId(memberId, beneficiaryId) {
        const url = Settings.API_URL + "enrollment/beneficiary/" + memberId + "/" + beneficiaryId;
        return this.delete(url);
    }
    saveBeneficiaryAllocation(beneficiaryAllocations) {
        const url = Settings.API_URL + "enrollment/beneficiary/allocation";
        return this.post(url, beneficiaryAllocations);
    }
    saveBeneficiaryAllocationForMember(beneficiaryAllocations, memberId) {
        const url = Settings.API_URL + "enrollment/beneficiary/" + memberId + "/allocation";
        return this.post(url, beneficiaryAllocations);
    }
    getAttachments() {
        const url = Settings.API_URL + "attachment";
        return this.get(url);
    }
    getAttachmentsForMember(memberId) {
        const url = Settings.API_URL + "attachment/" + memberId;
        return this.get(url);
    }
    cancelEnrollment(enrollmentId, memberId) {
        const url = Settings.API_URL + "enrollment/cancelenrollment/" + enrollmentId + "/" + memberId;
        return this.put(url);
    }
    generateEnrollmentReport(enrollmentId, sendEmail) {
        const url = Settings.API_URL + "enrollment/report";
        const request = {
            enrollmentId: enrollmentId,
            sendEmail: sendEmail
        }

        return this.post(url, request);
    }
    validateEnrollment() {
       const url = Settings.API_URL + "enrollment/validate";
       return this.get(url);
    }
    validateEnrollmentForMember(memberId) {
        const url = Settings.API_URL + "enrollment/member/" + memberId + "/validate";
        return this.get(url);
    }
}

const enrollmentService = new internalEnrollmentService();

export default enrollmentService;
import "./NavContainer.css";

import React, {
  cloneElement,
  createContext,
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { formatDistance, set } from "date-fns";

//Services
import optionService from "../../../services/optionService";
import authService from "../../../services/auth-service";
import userService from "../../../services/user-service";
import notificationService from "../../../services/notification-service";
import permissionService from "../../../services/permission-service";

//Assets
import fullColorLogo from "../../../assets/images/FullColorOnlyLogo.png";
import fullColorLogoDarkMode from "../../../assets/images/FullColorOnlyLogoDarkMode.png";
import trayArrow from "../../../assets/images/trayArrow.svg";
import pieChart from '../../../assets/images/pieChart.svg'

//MuiIcons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreateIcon from "@mui/icons-material/Create";
import CottageIcon from "@mui/icons-material/CottageOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";

//MuiComponents
import {
  Paper,
  Box,
  Button,
  TextField,
  Typography,
  Select,
  Tooltip,
  Divider,
  Fade,
  Snackbar,
  Alert,
  Slide,
  useTheme,
  SvgIcon,
  createSvgIcon,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";


//Our Components
import NavSearch from "./nav-search/NavSearch";
import NotificationsTray from "./notification-tray/NotificationsTray";
import signalRConnection from "../../../signalr";

//Constants
import { permissionConstants as _permissionsConstants } from "../../../constants/permissionConstants";

export let thisUserContext = createContext();

export default function NavContainer({ children }, props) {
  let history = useHistory();
  let location = useLocation();
  let route = location.pathname;

  let theme = useTheme();

  const notificationPopupStyles = {
    notificationBodyDisplay: {
      fontSize: "13px!important",
      marginRight: "8px",
    },
  };

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted && signalRConnection) {
      signalRConnection.connect(() => {
        // send message after connecting
        // commented out for now, no need to send messages
        //signalRConnection.sendMessage();
      });
      setMounted(true);
    }
  }, [signalRConnection]);

  //ROUTE LOGIC
  useEffect(() => {
    if (route) {
      if (
        !authService.isAuthenticated() &&
        route.slice(0, 10) != "/resetlink"
      ) {
        history.push("/login");
      } else {
      }
    }
    console.log('user is authenticated', authService.isAuthenticated());
  }, [route]);

  const [hideNav, setHideNav] = useState(false);
  // const [ticketBgColor,setTicketBgColor] = useState('')

  //When the route changes, trigger a rerender
  useEffect(() => {
    if (route) {
      if (route == "/login" || route.slice(0, 10) == "/resetlink") {
        setHideNav(true);
      } else {
        setHideNav(false);
      }
    }
  }, [route]);

  //This should be our only getMe form userService, everything else will import thisUserContext
  const [thisUser, setThisUser] = useState();
  let getThisUser = async () => {
    // let _thisUser = await memberService.getMe()
    try {
      let _thisUser = await userService.getMe();

      setThisUser(_thisUser);
    } catch (error) {
      history.push("/login");
    }
  };
  const [allUsers, setAllUsers] = useState();
  const getAllUsers = async () => {
    let _allUsers = await userService.getAll();
    setAllUsers(_allUsers);
  };
  const [permissions, setPermissions] = useState();
  const [permissionsConstants, setPermissionsConstants] = useState({});
  useEffect(() => {
    if (_permissionsConstants) {
      setPermissionsConstants(_permissionsConstants);
    }
  }, [_permissionsConstants]);

  const getMyPermissions = async () => {
    let _permissions = await permissionService.getMyPermissions();
    setPermissions(_permissions);
    console.log('_permissions',_permissions)
  };

  useLayoutEffect(() => {
    if (!thisUser) {
      getThisUser();
    }
    if (!allUsers) {
      getAllUsers();
    }
    if (!permissions) {
      getMyPermissions();
    }
    getMyNotifications(notificationLoadCount);
  }, []);

  const [permissionsEnabler, setPermissionsEnabler] = useState({});
  useEffect(() => {
    if (permissionsConstants && permissions) {
      // let foundPermissions = []
      // console.log('permissionsConstants',permissionsConstants)
      // for (let [key,value] of Object.entries(permissionsConstants)){
      //     console.log('permission',key,value)
      //     let permissionObject =
      // }
      let modifyEnrollmentId = permissionsConstants.ModifyEnrollment;
      let editEnrollmentId = permissionsConstants.EditEnrollment;
      let viewPaymentsId = permissionsConstants.ViewPayments;
      let editPaymentsId = permissionsConstants.EditPayments;
      let viewScheduledPaymentsId = permissionsConstants.ViewScheduledPayments;
      let editScheduledPaymentsId = permissionsConstants.EditScheduledPayments;
      let viewBankAccountsId = permissionsConstants.ViewBankAccounts;
      let editBankAccountsId = permissionsConstants.EditBankAccounts;
      let viewFullBankAccounts = permissionsConstants.ViewFullBankAccounts;
      let viewGroupPlans = permissionsConstants.ViewGroupPlans;
      let editGroupPlans = permissionsConstants.EditGroupPlans;
      let viewUsers = permissionsConstants.ViewUsers;
      let viewFullSocial = permissionsConstants.ViewFullSocial;

      let allowModifyEnrollment = permissions.find(
        (permission) => permission.id == modifyEnrollmentId
      );
      let allowEditEnrollment = permissions.find(
        (permission) => permission.id == editEnrollmentId
      );
      let allowViewPayments = permissions.find(
        (permission) => permission.id == viewPaymentsId
      );
      let allowEditPayments = permissions.find(
        (permission) => permission.id == editPaymentsId
      );
      let allowViewScheduled = permissions.find(
        (permission) => permission.id == viewScheduledPaymentsId
      );
      let allowEditScheduled = permissions.find(
        (permission) => permission.id == editScheduledPaymentsId
      );
      let allowViewBankAccounts = permissions.find(
        (permission) => permission.id == viewBankAccountsId
      );
      let allowEditBankAccounts = permissions.find(
        (permission) => permission.id == editBankAccountsId
      );

      let allowViewFullBankAccounts = permissions.find(
        (permission) => permission.id == viewFullBankAccounts
        );
      let allowViewGroupPlans = permissions.find((permission) => permission.id == viewGroupPlans);
      let allowEditGroupPlans = permissions.find((permission) => permission.id == editGroupPlans);
      let allowViewUsers = permissions.find((permission) => permission.id == viewUsers);
      let allowViewFullSocial = permissions.find((permission) => permission.id == viewFullSocial);

      setPermissionsEnabler({
        ...permissionsEnabler,
        modifyEnrollment: allowModifyEnrollment,
        editEnrollment: allowEditEnrollment,
        viewPayments: allowViewPayments,
        editPayments: allowEditPayments,
        viewScheduledPayments: allowViewScheduled,
        editScheduledPayments: allowEditScheduled,
        viewBankAccounts: allowViewBankAccounts,
        editBankAccounts: allowEditBankAccounts,
        viewFullBankAccounts: allowViewFullBankAccounts,
        viewGroupPlans: allowViewGroupPlans,
        editGroupPlans: allowEditGroupPlans,
        viewUsers: allowViewUsers,
        viewFullSocial: allowViewFullSocial,
      });
    }
  }, [permissions, permissionsConstants]);

  //Notifications Logic
  const [notifications, setNotifications] = useState([]);
  const [unreadMessageExist, setUnreadMessageExist] = useState(false);
  //update this count to load more notifications on scroll and build the current list until someone closes the notifications tray - then reset to 0 (newest 20 notifications)
  const [notificationLoadCount, setNotificationLoadCount] = useState(0);
  const [loadMoreNotifications, setLoadMoreNotifications] = useState(true);


  const getMyNotifications = async (totalNotificationsToLoad) => {
    try {
      let _notifications = await notificationService.getMyNotifications(
        totalNotificationsToLoad
      );

      //if no notification exist already in state but notifications from the response are >= 0, set notifications to response
      if (notifications?.length === 0 && _notifications?.length >= 0) {
        //if a user has no 
        setNotifications(_notifications); 
      } else if (notifications?.length > 0 && _notifications?.length > 0){
        //build upon currently visible notifications
        setNotifications([...notifications, ..._notifications]);
      }

      //reset loader to true after each successful request (after 3 sec delay) if there are more notifications to load
      if (_notifications.length > 0) {
        console.log("resetting loader");
        setTimeout(() => {
          setLoadMoreNotifications(true);
        }, 2000);
      }
    } catch (error) {
      console.log("error getting notifications", error);
      setLoadMoreNotifications(false);
    }
  };
  
  useEffect(() => {
    //reset the load count to 0 to display only the newest 20 notifications when user changes/logs out
    setNotificationLoadCount(0);
  }, [thisUser]);

  //update single notification as 'read' - struggling to update local state of setNotifications if updating multiples 
  const markNotificationAsRead = async (notificationId) => {
    if (notificationId) {
      try {
          await notificationService.markNotificationAsViewed(notificationId);
       
      } catch (error) {
        console.log("error updating notifications", error);
      }
    }
  };


  //update single notification to 'unread'
  const markNotificationAsUnread = async (notificationId) => {
    let unreadDate = "0001-01-01T00:00:00";
    if (notificationId) {
      console.log("marking as unread", notificationId);
      try {
        await notificationService.markNotificationUnread(notificationId);
        //add unread generic date string to notification and update state
        let updatedNotifications = notifications.map((notification) => {
          if (notification.id == notificationId) {
            return { ...notification, dateViewed: unreadDate };
          } else {
            return notification;
          }
        });
        setNotifications(updatedNotifications);
      } catch (error) {
        console.log("error updating notifications", error);
      }
    }
  };

  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const handleNotificationsOpen = (e) => {
    // Disabling Notifications
    setNotificationsAnchor(e.currentTarget);
  };
  const handleNotificationsClose = (e) => {
    setNotificationsAnchor(null);
  };

  //////// Create CONTEXT for notifications ////////

  // New Notification Message Alerts (to display critical priority notifications in snackbar popup)
  const [newNotificationAlerts, setNewNotificationAlerts] = useState([]);

  //dismiss popup notification manually
  const handleNewNotificationMessageClose = (event, notificationId) => {
    //remove notification selected from array
    let _updatedNotificationPopUpList = newNotificationAlerts?.filter(
      (notification) => notification.notificationId !== notificationId
    );
    setNewNotificationAlerts(_updatedNotificationPopUpList);
  };

  //TICKET LOGIC
  //get ticket priority list
  const [ticketPriorityList, setTicketPriorityList] = useState([]);
  const getTicketPriority = async () => {
    try {
      let ticketPriority = await optionService.getTicketPriority();
      setTicketPriorityList(ticketPriority);
    } catch (error) {
      console.log("error getting ticket priority list", error);
    }
  };

  useEffect(() => {
    if (ticketPriorityList?.length == 0) {
      getTicketPriority();
    }
    if (ticketStatusList?.length == 0) {
      getTicketStatus();
    }
  }, []);

  //determine single notification ticket priority
  const determineTicketPriority = (priorityId) => {
    let findMatchingPriority = ticketPriorityList?.find((priority) => {
      return priority.id === priorityId;
    });

    //return only the name of the matching priority exists
    if (findMatchingPriority?.name) {
      return findMatchingPriority?.name;
    } else return "Critical";
  };

  //get ticket status list
  const [ticketStatusList, setTicketStatusList] = useState([]);
  const getTicketStatus = async () => {
    try {
      let ticketStatus = await optionService.getTicketStatus();
      setTicketStatusList(ticketStatus);
    } catch (error) {
      console.log("error getting ticket status list", error);
    }
  };

  //determine single notification ticket status
  const determineTicketStatus = (statusId) => {
    let findMatchingStatus = ticketStatusList?.find((status) => {
      return status.id === statusId;
    });
    //return only the name of the matching status exists
    if (findMatchingStatus?.name) {
      return findMatchingStatus?.name;
    } else return "N/A";
  };

  //specific structure for notification popup data
  const buildNotificationPopup = (notification) => {
    let builtNotification;
    // changes made to tickets based on
    switch (notification?.notificationTypeId) {
      case 0:
        return "Notification error";

      case 1:
        //new ticket created
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            A new ticket was created{" "}
            {(notification?.lastModifiedByDescription && "by") || ""}{" "}
            <strong>
              {notification?.lastModifiedByDescription
                ? notification?.lastModifiedByDescription
                : ""}{" "}
            </strong>
            Ticket #: {notification?.entityId || "N/A"}
            {" | "}
            <strong>
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 2:
        //modigied ticket/generic ticket
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            <strong>
              {notification?.changeDescription ||
                notification?.lastModifiedByDescription + " modified"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 3:
        //added communitation to ticket
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            {notification?.lastModifiedByDescription +
              " added a new communication to " ||
              "New communication added to "}{" "}
            Ticket # <strong> {notification?.entityId || "N/A"}</strong>
            {" | "}
            <strong>
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 4:
        //priority updated
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            <strong>
              {notification?.changeDescription || "Ticket priority updated"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 5:
        //ticket asignee changed
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            {notification?.changeDescription || "Assignee updated"} for Ticket #
            <strong> {notification?.entityId || "N/A"}</strong>
            {" | "}
            <strong
              style={{
                color: notification?.priorityId <= 3 ? "inherit" : "red",
              }}
            >
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 6:
        //ticket due date changed
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            <strong>
              {notification?.changeDescription || "Due date updated"}{" "}
            </strong>
            Ticket #<strong> {notification?.entityId || "N/A"}</strong>
            <strong>
              {" | "}
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 7:
        //ticket status changed
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            Ticket status changed{" "}
            {notification?.statusId === 1 || notification.statusId === 2
              ? "to "
              : ""}
            <strong>
              {notification?.statusId === 1 || notification.statusId === 2
                ? determineTicketStatus(notification?.statusId)
                : ""}
            </strong>
            {notification?.lastModifiedByDescription ? " by " : ""}
            <strong>
              {notification?.lastModifiedByDescription
                ? notification?.lastModifiedByDescription
                : ""}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            {" | "}
            <strong>
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 8:
        //entry added
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            <strong>
              {notification?.changeDescription || "New entry added"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 9:
        //ticket type changed
        builtNotification = (
          <Typography
            variant="body2"
            sx={notificationPopupStyles.notificationBodyDisplay}
          >
            <strong>
              {notification?.changeDescription || "Ticket type changed"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      default:
        builtNotification = (
          <Typography variant="body2" sx={notificationPopupStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "New notification"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
    }
    return builtNotification;
  };

  //Profile dropdown
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleMenuOpen = (e) => {
    setMenuAnchor(e.currentTarget);
  };
  const handleMenuClose = (e) => {
    setMenuAnchor(null);
  };
  //Profile > Status dropdown
  const [statusAnchor, setStatusAnchor] = useState(null);
  const handleStatusOpen = (e) => {
    setStatusAnchor(e.currentTarget);
  };
  const handleStatusClose = (e) => {
    setStatusAnchor(null);
  };

  const goToSettings = () => {
    handleMenuClose();
    history.push("/settings", { settings: true });
  };
  const goToMembers = () => {
    handleMenuClose();
    history.push("/members", { allView: true });
  };

  const signOut = () => {
    authService.signout();
    handleMenuClose();
    history.push("/login");
  };

  //this is whats being passed with thisUserContext
  let contextProps = {
    thisUser: thisUser,
    refreshUser: () => getThisUser(),
    allUsers: allUsers,
    permissions: permissions,
    allPermissions: permissionsConstants,
    permissionsEnabler: permissionsEnabler,
  };

  // Avatar Presence
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const CustomPieSvg = createSvgIcon(
      <image href={pieChart} x='0' y='0' height='24' width='24' />,
      'Pie'
    )

  return (
    <div
      width="100%"
      height="100%"
      className="flex-row"
      style={{ backgroundColor: "#e5e5e5", overflowX: 'auto'}}
    >
      {/* SIDEBAR */}
      <Box
        className="col-4"
        style={{
          width: "71px",
          minHeight: "100vh",
          position: "relative",
          display: hideNav ? "none" : "",
        }}
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          borderRight: "solid 1px",
          borderColor: "background.border",
          flexWrap: "wrap",
        }}
      >
        <div
          role="button"
          onClick={() => history.push("/")}
          style={{
            fontFamily: "Poppins",
            fontWeight: "800",
            fontSize: "24px",
            color: "#195ffb",
            fontStyle: "normal",
            lineHeight: "24px",
            marginBottom: "45px",
            marginTop: "10px",
            textAlign: "center",
          }}
        >
          {/* UH */}
          <img
            src={
              theme?.palette?.mode == "light"
                ? fullColorLogo
                : fullColorLogoDarkMode
            }
            style={{ width: "60px" }}
          />
        </div>
        {/* <div style={{textAlign:"center",marginBottom:"40px"}}>
                    <CircleIcon sx={{width:"15px",color:"#19FB2D"}} />
                </div> */}
        <IconButton
          onClick={() => history.push("/")}
          sx={{
            display: "flex",
            height: "56px",
            minWidth: "50px",
            margin: "auto auto 20px auto",
            backgroundColor: route == "/" ? "primary.light" : "",
            borderRadius: "5px",
          }}
        >
          <CottageIcon
            sx={{
              color: route == "/" ? "primary.main" : "text.primary",
              verticalAlign: "middle",
            }}
          />
        </IconButton>

        <IconButton
          onClick={goToMembers}
          sx={{
            display: "flex",
            height: "56px",
            minWidth: "50px",
            margin: "auto auto 20px auto",
            backgroundColor: route.includes("members") ? "primary.light" : "",
            borderRadius: "5px",
          }}
        >
          <SupervisorAccountOutlinedIcon
            sx={{
              color: route.includes("members")
                ? "primary.main"
                : "text.primary",
              verticalAlign: "middle",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => history.push("/tickets")}
          sx={{
            display: "flex",
            height: "56px",
            minWidth: "50px",
            margin: "auto auto 20px auto",
            backgroundColor: route.includes("tickets") ? "primary.light" : "",
            borderRadius: "5px",
          }}
        >
          <ConfirmationNumberOutlinedIcon
            sx={{
              color: route.includes("tickets")
                ? "primary.main"
                : "text.primary",
              verticalAlign: "middle",
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => history.push("/dashboard-reports")}
          sx={{
            display: "flex",
            height: "56px",
            minWidth: "50px",
            margin: "auto auto 20px auto",
            backgroundColor: route.includes("dashboard-reports") ? "primary.light" : "",
            borderRadius: "5px",
          }}
        >
          <CustomPieSvg
            sx={{
              color: route.includes("dashboard-reports")
                ? "primary.main"
                : "text.primary",
              verticalAlign: "middle",
              filter: route.includes("dashboard-reports")
              ? ''
              : 'grayscale(1) contrast(3)'
            }}

          />
        </IconButton>
        {/* <IconButton onClick={() => history.push('/associations')} sx={{ display: "flex", height: "56px", minWidth: "50px", margin: "auto auto 20px auto", backgroundColor: route.includes('associations') ? '#195FFB15' : '', borderRadius: '5px' }}>
                    <WorkspacesIcon sx={{ color: route.includes('associations') ? '#195FFB' : 'black', verticalAlign: "middle" }} />
                </IconButton> */}

        {/* <IconButton onClick={() => history.push('/agents')} sx={{ display: "flex", height: "56px", minWidth: "50px", margin: "auto auto 60px 10px", position: "absolute", bottom: "0px", borderRadius: 0, backgroundColor: route.includes('agents') ? '#195FFB15' : '', borderRadius: '5px' }}>
                    <SupportAgentIcon sx={{ color: route.includes('agents') ? '#195FFB' : 'black', verticalAlign: "middle" }} />
                </IconButton> */}

        <IconButton
          onClick={goToSettings}
          sx={{
            display: "flex",
            height: "56px",
            minWidth: "50px",
            margin: "auto auto auto 10px",
            position: "absolute",
            bottom: "0px",
            backgroundColor: route.includes("settings") ? "primary.light" : "",
            borderRadius: "5px",
          }}
        >
          <SettingsIcon
            sx={{
              color: route.includes("settings")
                ? "primary.main"
                : "text.primary",
              verticalAlign: "middle",
            }}
          />
        </IconButton>
      </Box>

      {/* MAIN */}
      <Box
        className="col"
        style={{ minHeight: "100vh" }}
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{ borderBottom: "solid 1px", borderColor: "background.border" }}
          style={{
            height: "56px",
            display: hideNav ? "none" : "",
          }}
          className="flex-row"
        >
          <div
            className="col-6"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* <NavSearch /> */}
          </div>

          <div
            className="col-3"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              role="button"
              onClick={handleNotificationsOpen}
              disabled={true}
              style={{
                display: "inline-block",
                marginRight: "6%",
                position: "relative",
                zIndex: 4,
              }}
            >
              {unreadMessageExist && (
                <CircleIcon
                  sx={{
                    height: "8px",
                    width: "8px",
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                    color: "rgba(211, 47, 47, 1)",
                  }}
                />
              )}
              <NotificationsNoneIcon
                id="notifications-bell"
                sx={{ width: "20px", height: "20px", color: "#828282" }}
              />
            </div>

            {/* DISABLING NOTIFICATIONS */}

            {/*NOTIFICATION TRAY */}
            <NotificationsTray
              notificationsAnchor={notificationsAnchor}
              notifications={notifications}
              setNotifications={setNotifications}
              notificationLoadCount={notificationLoadCount}
              setNotificationLoadCount={setNotificationLoadCount}
              loadMoreNotifications={loadMoreNotifications}
              setLoadMoreNotifications={setLoadMoreNotifications}
              handleNotificationsClose={handleNotificationsClose}
              StyledBadge={StyledBadge}
              getMyNotifications={getMyNotifications}
              markNotificationAsRead={markNotificationAsRead}
              markNotificationAsUnread={markNotificationAsUnread}

              unreadMessageExist={unreadMessageExist}
              setUnreadMessageExist={setUnreadMessageExist}
              ticketPriorityList={ticketPriorityList}
              ticketStatusList={ticketStatusList}
              getTicketPriority={getTicketPriority}
              getTicketStatus={getTicketStatus}
              newNotificationAlerts={newNotificationAlerts}
              setNewNotificationAlerts={setNewNotificationAlerts}
              handleNewNotificationMessageClose={
                handleNewNotificationMessageClose
              }
              allUsers={allUsers}
            />

            <div
              role="button"
              onClick={handleMenuOpen}
              style={{ display: "inline-block", paddingRight: "10%" }}
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  sx={{
                    bgcolor: "primary.light",
                    color: "primary.main",
                    fontFamily: "Archivo",
                    fontWeight: "600",
                    width: 32,
                    height: 32,
                    fontSize: 18,
                  }}
                  alt={`${thisUser?.firstName} ${thisUser?.lastName}`}
                  src={thisUser?.id ? `api/image/user/${thisUser?.id}` : ""}
                />
              </StyledBadge>
            </div>

            {/* PROFILE MENU */}
            <Menu
              anchorEl={menuAnchor}
              open={menuAnchor ? true : false}
              onClose={handleMenuClose}
              PaperProps={{
                style: {
                  borderRadius: "15px",
                },
              }}
            >
              <MenuItem
                value="Profile"
                onClick={goToSettings}
                sx={{
                  color: "text.primary",
                  fontFamily: "Archivo",
                  fontWeight: "500",
                  padding: "10px",
                  "&:hover": { backgroundColor: "primary.light" },
                }}
              >
                <SettingsOutlinedIcon />
                <span style={{ paddingLeft: "10px" }}>Settings</span>
              </MenuItem>
              <MenuItem
                value="Settings"
                onClick={handleStatusOpen}
                divider
                disabled
                sx={{
                  color: "text.primary",
                  fontFamily: "Archivo",
                  fontWeight: "500",
                  padding: "10px",
                  "&:hover": { backgroundColor: "primary.light" },
                }}
              >
                <PersonOutlineIcon />
                <span style={{ paddingLeft: "10px" }}>Status</span>
              </MenuItem>
              <MenuItem
                value="Settings"
                onClick={signOut}
                sx={{
                  color: "primary.main",
                  fontFamily: "Archivo",
                  fontWeight: "500",
                  padding: "10px",
                  "&:hover": { backgroundColor: "primary.light" },
                }}
              >
                <LogoutOutlinedIcon />
                <span style={{ paddingLeft: "10px" }}>Log Out</span>
              </MenuItem>
            </Menu>

            {/* STATUS MENU */}
            <Menu
              anchorEl={statusAnchor}
              open={statusAnchor ? true : false}
              onClose={handleStatusClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  borderRadius: "0px",
                },
              }}
            >
              <MenuItem value={0} sx={{ textAlign: "center" }}>
                <CircleIcon
                  sx={{
                    marginRight: "12px",
                    height: "8px",
                    width: "8px",
                    color: "#00c814",
                  }}
                />{" "}
                <span style={{ verticalAlign: "middle" }}>Available</span>
              </MenuItem>
              <MenuItem value={1} sx={{ textAlign: "center" }}>
                <CircleIcon
                  sx={{
                    marginRight: "12px",
                    height: "8px",
                    width: "8px",
                    color: "#E9BB42",
                  }}
                />{" "}
                <span style={{ verticalAlign: "middle" }}>Busy</span>
              </MenuItem>
              <MenuItem value={2} sx={{ textAlign: "center" }}>
                <CircleIcon
                  sx={{
                    marginRight: "12px",
                    height: "8px",
                    width: "8px",
                    color: "#A93D4E",
                  }}
                />{" "}
                <span style={{ verticalAlign: "middle" }}>On a call</span>
              </MenuItem>
              <MenuItem value={3} sx={{ textAlign: "center" }}>
                <CircleIcon
                  sx={{
                    marginRight: "12px",
                    height: "8px",
                    width: "8px",
                    color: "blue",
                  }}
                />{" "}
                <span style={{ verticalAlign: "middle" }}>
                  Available for [variable]
                </span>
              </MenuItem>
              <MenuItem value={3} sx={{ textAlign: "center" }}>
                <CircleIcon
                  sx={{
                    marginRight: "12px",
                    height: "8px",
                    width: "8px",
                    color: "gray",
                  }}
                />{" "}
                <span style={{ verticalAlign: "middle" }}>Offline</span>
              </MenuItem>
            </Menu>
          </div>
        </Box>
        {/* New notification pop-up displays list of new critical priority notification*/}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Snackbar
            open={newNotificationAlerts?.length > 0}
            // open={true}
            // autoHideDuration={newNotificationAlerts.autoHideDuration}
            TransitionComponent={TransitionComponent}
            onClose={handleNewNotificationMessageClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            sx={{
              width: "20%",
              position: "fixed",
              bottom: "16px",
              right: "16px",
              zIndex: 1,
              // opacity: newNotificationAlerts?.length > 0 ? 1 : 0,
            }}
          >
            {/* Display array of critical notification alert popups */}

            <>
              {newNotificationAlerts?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column-reverse",
                    alignItems: "flex-end",
                  }}
                >
                  {newNotificationAlerts?.map((notification) => {
                    return (
                      <Alert
                        key={notification?.notificationId}
                        elevation={6}
                        variant="filled"
                        severity={notification?.severity || ""}
                        sx={{
                          width: "100%",
                          backgroundColor: notification?.backgroundColor,
                          color:
                            notification?.severity === "warning"
                              ? "primary.notification.critical"
                              : "primary.notification.regular",
                          position: "relative",
                          marginBottom: "10px",

                          // opacity: 1,
                        }}
                      >
                        {/* display the user who modified and notificaiton message if they exist */}

                        {buildNotificationPopup(notification)}

                        {/* display the date created, and type */}
                        <Typography sx={{ fontSize: "12px!important" }}>
                          {(notification?.dateCreated &&
                            formatDistance(
                              new Date(),
                              new Date(notification?.dateCreated)
                            )) ||
                            ""}
                          {(notification?.dateCreated && " ago ") || " "}
                          {notification?.dateCreated ? (
                            <CircleIcon
                              sx={{
                                width: "5px",
                                margin: "0 5px",
                                padding: "0px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {notification.messageDisplay && "Ticket"}
                        </Typography>

                        <IconButton
                          id="exit-notification-popup"
                          onClick={(e) =>
                            handleNewNotificationMessageClose(
                              e,
                              notification?.notificationId
                            )
                          }
                          sx={{
                            position: "absolute",
                            height: "10px",
                            width: "10px",
                            top: "10px",
                            right: "10px",
                          }}
                        >
                          <CloseIcon
                            sx={{
                              height: "16px",
                              color:
                                notification?.severity === "warning"
                                  ? "primary.notification.critical"
                                  : "primary.notification.regular",
                            }}
                          />
                        </IconButton>
                      </Alert>
                    );
                  })}
                </div>
              )}
            </>
          </Snackbar>
        </div>

        {/* CHILDREN */}

        <thisUserContext.Provider value={contextProps}>
          {children}
        </thisUserContext.Provider>
      </Box>
    </div>
  );
}

// Transition component for new notification pop-up stacking in the Snackbar

function TransitionComponent(props) {
  const { children, in: open, onEnter, onExited, ...other } = props;

  // Handle null case gracefully
  if (!open) {
    return null;
  }

  return (
    <Slide direction="up" {...other}>
      {children}
    </Slide>
  );
}

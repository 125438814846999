import React, { useState, useEffect } from 'react';


//MuiIcons
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//MuiComponents
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider, { dividerClasses } from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RadioGroup from '@mui/material/RadioGroup';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

//Our icons
import CustomCheckIcon from '../../../assets/images/CustomCheckIcon.svg'

//Our Services
import optionService from '../../../services/optionService';

import enrollmentPlanStyles from '../../app-external/external-enrollments/enrollment-plans/enrollmentPlanStyles';



export default function CalculatorLtd(props) {

    

    let results = props.results
    let ltdPlan = results?.ltdPlan
    let ltdHelpText = ltdPlan?.helpText

    const [expanded, setExpanded] = useState(true)
    const handleExpandClick = () => {
        setExpanded(prev => !prev)
    };
    // useEffect(() => {
    //     if (props.scrollClose) {
    //         setExpanded(false)
    //     }
    // },[props.scrollClose])
    useEffect(() => {
        if (!expanded) {
            // document.getElementById('ltd-calc-wrapper').scrollIntoView()
        }
    }, [expanded])
    let expandIcon = expanded ? <RemoveCircleIcon fontSize="large" sx={{ position: "absolute", right: "14%", top: "20%", color: "#195ffb" }} /> : <AddCircleIcon fontSize="large" sx={{ position: "absolute", right: "14%", top: "20%", color: "#195ffb" }} />;

    const [currRecc, setCurrRec] = useState(0)

    // Incremental

    //Component Styling
    const componentStyles = {
        ltdSelectInput: {
            width: "48%",
        },
        radioButtonItem: {
            margin: "20px 0px 0px",
            padding: "0px",
            paddingRight: "10px",
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            width: "100%",
        },
        radioButtonSingle: {
            paddingBottom: "5px",
            marginLeft: "-10px",
            marginRight: "8px",
        },
    };

    /////USD formatting
    let UsdFormatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });
    let UsdFormatterDec = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });

    let ltdDeclinePlan = ltdPlan?.options?.find((option) => option.isDecline);
    let previousPlanChosen =
        props.selections.ltdVal > 0
            ? ltdPlan?.options?.find(
                (plan) => plan.memberOptionId == props.selections.ltd
            )
            : null;


    ///// ELIMINATION PERIOD & BENEFIT DURATION LOGIC /////

    //Data from services
    const [eliminationPeriodTypes, setEliminationPeriodTypes] = useState();
    const [benefitDurTypes, setBenefitDurTypes] = useState();
    // calling to services for elim period and benefit option type ids
    const getElimPeriodList = async () => {
        let elimPeriodTypeList;
        try {
            elimPeriodTypeList = await optionService.getEliminationPeriodTypes();
        } catch (error) {
            console.log("error getting elimination periods", error);
        }

        // console.log("ElilminationPeriod List \n\n", elimPeriodTypeList);
    };

    const getBenefitDurationList = async () => {
        let benefitDurTypeList;
        try {
            benefitDurTypeList = await optionService.getBenefitDurationTypes();
        } catch (error) {
            console.log("error getting elimination periods", error);
        }

        // console.log("BenefitDuration List \n\n", benefitDurTypeList);
    };

    useEffect(() => {
        //getting lists from option service
        getElimPeriodList();
        getBenefitDurationList();
    }, []);

    //Data from a member's options

    //selected elimination period
    const [selectedEliminationPeriod, setSelectedEliminationPeriod] =
        useState(null);

    //get only unique values of elimination period from member options excluding duplicates
    const getUniqueEliminationPeriodList = () => {
        let elimPeriodInfo = [];

        for (let option of ltdPlan?.options) {
            if (option.eliminationPeriod > 0 && option.benefitTypeId != 2) {
                let object = {
                    eliminationPeriod: option.eliminationPeriod,
                    type: option.eliminationPeriodType,
                    typeId: option.eliminationPeriodTypeId,
                };
                elimPeriodInfo.push(object);
            }
        }

        let uniqueElimPeriodArr = [
            ...new Set(elimPeriodInfo.map((obj) => obj.eliminationPeriod)),
        ];

        let uniqueElimPeriodOptions = uniqueElimPeriodArr.map(
            (eliminationPeriod) => {
                return elimPeriodInfo.find(
                    (obj) => obj.eliminationPeriod === eliminationPeriod
                );
            }
        );

        setEliminationPeriodTypes(uniqueElimPeriodOptions);
        setSelectedEliminationPeriod(uniqueElimPeriodOptions[0]?.eliminationPeriod);
    };

    ////// selected benefit duration /////
    const [selectedBenefitDuration, setSelectedBenefitDuration] = useState();

    const getUniqueBenefitDurationList = () => {
        let benefitDurationInfo = [];

        for (let option of ltdPlan?.options) {
            if (option.benefitDuration > 0 && option.benefitTypeId != 2) {
                let object = {
                    duration: option.benefitDuration,
                    type: option.benefitDurationType,
                    typeId: option.benefitDurationTypeId,
                };
                benefitDurationInfo.push(object);
            }
        }

        let uniqueBenefitDurList = [
            ...new Set(benefitDurationInfo.map((obj) => obj.duration)),
        ];

        let uniqueBenefitDurOptions = uniqueBenefitDurList.map((duration) => {
            return benefitDurationInfo.find((obj) => obj.duration === duration);
        });

        //set member benefit options off of unique options only
        setBenefitDurTypes(uniqueBenefitDurOptions);
        setSelectedBenefitDuration(uniqueBenefitDurOptions[0]?.duration);
    };

    const handleSelectBenefitDuration = (e) => {
        // console.log("benefit e.target", e.target);
        setSelectedBenefitDuration(e.target.value);
        setRadioSelected(null);
    };

    useEffect(() => {
        if (ltdPlan?.options?.length > 0) {
        getUniqueEliminationPeriodList();
        getUniqueBenefitDurationList();
        }
    }, [ltdPlan.options]);

    const [ltdOptionsExcludingDecline, setLtdOptionsExcludingDecline] = useState(
        []
    );


    useEffect(() => {
        if (ltdOptionsExcludingDecline && expanded) {
            initialPlanAmountDisplay();
        }
    }, [props.recommendations, props.results, expanded, ltdOptionsExcludingDecline]);
    // console.log('props.results in ltd',props.results, props.selections)

    useEffect(() => {
        if (ltdPlan?.options?.length > 0) {
            //array of all options excluding the $0 decline amount & benefit duration selected
            let filteredLtdOptions = ltdPlan?.options.filter((option) => {
                return (
                    option.isDecline === false &&
                    option.benefitDuration === selectedBenefitDuration &&
                    option.eliminationPeriod === selectedEliminationPeriod &&
                    option.benefitTypeId != 2
                );
            });

            setLtdOptionsExcludingDecline(filteredLtdOptions);
        }
    }, [ltdPlan.options, selectedBenefitDuration, selectedEliminationPeriod]);

    //change value of elim period w/ select drop down
    const handleSelectEliminationPeriod = (e) => {
        // console.log("elimination period clicked", e.target.value);
        //update value w/ selected elimination period value
        setSelectedEliminationPeriod(e.target.value);
    };
    const [loading, setLoading] = useState(false);
    const [ltdPlanChosen, setLtdPlanChosen] = useState(previousPlanChosen);
    const [ltdSlider, setLtdSlider] = useState(0);
    const [radioSelected, setRadioSelected] = useState(null);

    useEffect(() => {
        if (ltdSlider > ltdOptionsExcludingDecline?.length - 1 && ltdOptionsExcludingDecline?.length > 0) {
            setLtdSlider(ltdOptionsExcludingDecline?.length - 1);
        }
    }, [ltdOptionsExcludingDecline?.length, ltdSlider])

    // console.log("SELECTED PLAN \n\n", ltdPlanChosen);
    // console.log("Slider POsition \n\n", ltdSlider);
    // console.log("Benefit Duration \n\n", selectedBenefitDuration);

    //set initial rec of either lowest amount above $0 or recommended amount based on calculator

    const initialPlanAmountDisplay = () => {
        //IF a user has already saved a selection and is going back to this section, display saved selection
        let previousSelectedOption;
        let previousSelectedOptionValid = false;
        if (props.selections.ltdVal > 0) {
            //need to look at all options, and not just filtered
            previousSelectedOption = ltdPlan?.options?.find(
                (option) => option.memberOptionId === props.selections.ltd
            );
            previousSelectedOptionValid = ltdOptionsExcludingDecline.includes(previousSelectedOption)
            if (previousSelectedOptionValid) {
                setSelectedBenefitDuration(previousSelectedOption?.benefitDuration);

            }
            //filter by benefit duration before displaying previous option selected
        }
        if (previousSelectedOptionValid) {
            if (previousSelectedOption?.benefitAmount > 0) {
                for (let i = 0; i < ltdOptionsExcludingDecline.length; i++) {
                    let optionId = ltdOptionsExcludingDecline[i]?.memberOptionId;
                    if (optionId == previousSelectedOption.memberOptionId) {
                        setLtdSlider(i);
                        setLtdPlanChosen(ltdOptionsExcludingDecline[i]);
                        setRadioSelected(ltdOptionsExcludingDecline[i].memberOptionId);
                    }
                }
            }
        } else {
            let recommendationPosition;
            if (ltdOptionsExcludingDecline.length > 1) {
                props.recommendations.Lowest
                    ? (recommendationPosition = 1)
                    : (recommendationPosition = ltdOptionsExcludingDecline.length - 2);
            }
            //set recommendation to display green check
            setCurrRec(recommendationPosition);
            setLtdSlider(recommendationPosition);
            // }
        }

    };

    ////// SLIDER LOGIC

    const handleLtdSlider = (e) => {
        setLtdSlider(e.target.value);
        setLtdPlanChosen(ltdOptionsExcludingDecline[e.target.value]);
        setRadioSelected(ltdOptionsExcludingDecline[e.target.value].memberOptionId);
        //update sticky progress total\
        if (ltdOptionsExcludingDecline[e.target.value].memberPremiumAmount && ltdOptionsExcludingDecline[e.target.value].memberOptionId) {
            props.setSelections({
                ...props.selections,
                ltdVal: ltdOptionsExcludingDecline[e.target.value].memberPremiumAmount,
                ltd: ltdOptionsExcludingDecline[e.target.value].memberOptionId,
            });

        }
    };

    /////// RADIO BUTTON LOGIC

    const handleRadioSelection = (e) => {
        //handle selection to update sticky progress bar
        props.selectionHandler(e);
        //check if selection is the recommended or 'not now' option w/ the e.target
        // console.log("RADIO e.target.value", e.target.value);
        //set value of radioButton state to update checked value
        let radioAmount = Number(e.target.getAttribute("data-amount"));
        let radioValue = Number(e.target.value);
        setRadioSelected(Number(e.target.value));

        //LOOP TO TEST E.TARGET.VALUE?
        let chosenPlan = ltdPlan?.options?.find((option) => {
            return option.memberOptionId == radioValue;
        });

        //set chosen plan to selected
        setLtdPlanChosen(chosenPlan);

        //find index of chosenPlan to update slider value
        if (radioAmount > 0) {
            let index = ltdOptionsExcludingDecline.indexOf(chosenPlan);

            setLtdSlider(index);
        } else {
            let index = ltdPlan?.options?.indexOf(chosenPlan);
            setLtdSlider(index);
        }
    };

    ///// BENEFIT DROPDOWN SELECT LOGIC

    const handleDropdownSelection = (e) => {
        //set radio button state to true if slider is moved - radioSelected value is only false if Not Now radio is clicked

        //handle the string value from dropdown list
        let tagName = e.target.tagName.toLowerCase();
        if (tagName === "li") {
            let value = e.target.innerHTML.slice(1).replace(",", "");
            if (value.includes('(max)')) value = value.replace('(max)', '')
            for (let i = 0; i < ltdOptionsExcludingDecline.length; i++) {
                let benefitAmount = ltdOptionsExcludingDecline[i]?.benefitAmount;
                if (benefitAmount == value) {
                    setLtdSlider(i);
                    setLtdPlanChosen(ltdOptionsExcludingDecline[i]);
                    setRadioSelected(ltdOptionsExcludingDecline[i].memberOptionId);
                    //update sticky progress total
                    if (ltdOptionsExcludingDecline[i].memberPremiumAmount && ltdOptionsExcludingDecline[i].memberOptionId) {
                        props.setSelections({
                            ...props.selections,
                            ltdVal: ltdOptionsExcludingDecline[i].memberPremiumAmount,
                            ltd: ltdOptionsExcludingDecline[i].memberOptionId,
                        });

                    }
                }
            }
        } else if (tagName === "input") {
            let value = e.target.value.slice(1).replace(",", "");
            if (value.includes('(max)')) value = value.replace('(max)', '')
            for (let i = 0; i < ltdOptionsExcludingDecline.length; i++) {
                let benefitAmount = ltdOptionsExcludingDecline[i]?.benefitAmount;
                if (benefitAmount == value) {
                    setLtdSlider(i);
                    setLtdPlanChosen(ltdOptionsExcludingDecline[i]);
                    setRadioSelected(ltdOptionsExcludingDecline[i].memberOptionId);
                    //update sticky progress total
                    if (ltdOptionsExcludingDecline[i].memberPremiumAmount && ltdOptionsExcludingDecline[i].memberOptionId) {
                        props.setSelections({
                            ...props.selections,
                            ltdVal: ltdOptionsExcludingDecline[i].memberPremiumAmount,
                            ltd: ltdOptionsExcludingDecline[i].memberOptionId,
                        });

                    }
                }
            }
        }
    };

    let maxBenefit = ltdOptionsExcludingDecline[ltdOptionsExcludingDecline.length - 1]?.benefitAmount

    const handleNonIncrementalRadioSelection = (e) => {
        //handle selection to update sticky progress bar
        props.selectionHandler(e);
        //check if selection is the recommended or 'not now' option w/ the e.target
        //set value of radioButton state to update checked value
        let radioAmount = Number(e.target.getAttribute("data-amount"));
        let radioValue = Number(e.target.value);
        setRadioSelected(Number(e.target.value));
    
        //LOOP TO TEST E.TARGET.VALUE?
        let chosenPlan = ltdPlan?.options?.find((option) => {
          return option.memberOptionId == radioValue;
        });
    
        //set chosen plan to selected
        setLtdPlanChosen(chosenPlan);
    
        // //find index of chosenPlan to update slider value
        // if (radioAmount > 0) {
        //   let index = ltdOptionsExcludingDecline.indexOf(chosenPlan);
        //   setLtdSlider(index);
        // } else {
        //   let index = ltdPlan?.options?.indexOf(chosenPlan);
        //   setLtdSlider(index);
        // }
    
        //update selection in db
        // props?.handleEnrollClick(chosenPlan?.memberOptionId)
      };


    return (
        <div id='ltd-calc-wrapper'>
            <div className=""
                style={{
                    backgroundColor: "#1B78AF12", minHeight: "57px",
                    fontSize: "17px", fontWeight: 700, padding: "16px 38px 17px 16px",
                    position: "relative"
                }}
            >
                    <Typography variant='h6' fontSize='16px' fontWeight='600' sx={{ cursor: "pointer", position: "relative" }} onClick={handleExpandClick}>

                    {/* {ltdPlan.title}  */}
                    Long-Term Disability
                    <div style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />
                </Typography>

                <div onClick={handleExpandClick}
                    style={{
                        position: "absolute", right: "3%", top: "0%",
                        color: "#195ffb", width: "18%", height: "100%",
                        cursor: "pointer"
                    }}
                >
                    {expandIcon}
                </div>
            </div>
            <Collapse in={expanded}>
            <div style={{ padding: "20px 20px 0px 20px" }}>
                    <span dangerouslySetInnerHTML={{ __html: ltdHelpText }}></span>
                </div>
                {props.calculated &&
                    <RadioGroup onChange={props.selectionHandler} value={props.selections.ltd || currRecc}>
                        {(results && results.ltdPlan.isIncremental != true)
                            ? ltdPlan.options.map((option, index) => {

                                let isSelected = props.selections.ltd == option.memberOptionId || (!props.selections.ltd && currRecc == option.memberOptionId)

                                if (!option.isDecline) {
                                    return (
                                        <>
                                            <div key={option.memberPremiumAmount} className='flex-row container option-container' style={{ padding: "0px 0px 0px 3vw", margin: "15px 0px" }}>
                                                <Radio sx={{ top: "-10px" }} id={option.memberOptionId}
                                                    value={option.memberOptionId}
                                                    name="ltd" className="rate-calculator-radio"
                                                    onClick={() => setExpanded(false)}
                                                    inputProps={{
                                                        'data-amount': option.memberPremiumAmount,
                                                    }}
                                                />

                                                {/* <div className="col-1"></div> */}

                                                <div className='col-10' style={{ fontSize: "16px" }} >
                                                    <span style={{ fontSize: "28px", fontWeight: (isSelected ? 700 : 700), color: "#195ffb" }}>
                                                        {!props.dummyDisplay && option.benefitAmount > 1 && UsdFormatter.format(option.benefitAmount)}
                                                        {!props.dummyDisplay && option.benefitAmount <= 1 && Intl.NumberFormat("en-US", { style: "percent" }).format(option.benefitAmount)}

                                                        {props.dummyDisplay && option.name.replace(" Monthly Benefit", "")}
                                                    </span>
                                                    {props.calculated && props.selectionRec(ltdPlan.options, index) &&
                                                        <img style={{ marginLeft: "5px", position: "relative", top: "-2px" }} src={CustomCheckIcon} />
                                                    }
                                                    <span style={{ fontSize: "18px", fontWeight: (isSelected ? 700 : 500), color: props.dummyFontColor, position: "absolute", top: "1vh", right: "8vw" }}>
                                                        {isSelected && "+"}{!props.dummyDisplay && UsdFormatterDec.format(option.memberPremiumAmount)}{props.dummyDisplay && "$0.00"}
                                                    </span>
                                                    <span className='' style={{ display: "block", fontWeight: (isSelected ? 700 : 500) }}>max monthly benefit</span>
                                                </div>
                                            </div>

                                            {/* <div className='flex-row container' style={{padding:"0px 12px"}}>
                        <ul>
                            <li className='ltd-helptext' key={option.explanationOfBenefits}><span dangerouslySetInnerHTML={{__html:option.explanationOfBenefits}}></span></li>
                            <li className='ltd-helptext' key={option.benefitOffsets}><span dangerouslySetInnerHTML={{__html:option.benefitOffsets}}></span></li>
                        </ul>
                    </div> */}

                                            <div className='flex-row '
                                                style={{ padding: "0px 0px 10px 4vw", paddingLeft: 'clamp(45px,8vw,150px)', paddingRight: 'clamp(45px,8vw,150px)', }}>
                                                {option.explanationOfBenefits}
                                            </div>
                                            {/* <div className='flex-row '
                    style={{ padding: "0px 0px 0px 8vw",paddingLeft:'clamp(45px,8vw,150px)',paddingRight:'clamp(45px,8vw,150px)',}}>
                        <li>
                            {option.benefitOffsets}
                        </li>
                    </div> */}
                                        </>
                                    )
                                } else {
                                    return (
                                        <div key={option.memberPremiumAmount}
                                            className='flex-row container option-container' style={{ padding: "0px 0px 0px 3vw", margin: "15px 0px" }} >
                                            <Radio
                                                value={option.memberOptionId}
                                                name="ltd" className='rate-calculator-radio'
                                                onClick={() => setExpanded(false)}
                                                inputProps={{
                                                    'data-amount': option.memberPremiumAmount,
                                                }}
                                            />
                                            <div style={{ alignSelf: "center", fontWeight: (isSelected ? 700 : 400) }}>Not Now</div>
                                        </div>
                                    )
                                }
                            })
                            :
                            <div style={{padding: '0px 20px 20px 20px'}}>

                                {/* Elimination Period & Benefit Duration select*/}
                                <div
                                    className="flex-row"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "20px 0 0 0",
                                        textAlign: "left",
                                        marginBottom: "15px",
                                    }}
                                >
                                    <TextField
                                        // disabled={eliminationPeriodTypes?.length <= 1}
                                        select
                                        variant="outlined"
                                        label="Elimination Period"
                                        InputLabelProps={{
                                            sx: {
                                                '&.Mui-disabled': {
                                                    color: 'unset'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                pointerEvents: eliminationPeriodTypes?.length <= 1 ? 'none' : ''
                                            }
                                        }}
                                        value={selectedEliminationPeriod || ""}
                                        sx={componentStyles.ltdSelectInput}
                                        onChange={handleSelectEliminationPeriod}
                                        SelectProps={{
                                            IconComponent: () => eliminationPeriodTypes?.length <= 1 ? null : <ArrowDropDownIcon sx={{ position: 'absolute', right: 7 }} />
                                        }}

                                    >
                                        {/* select options base on unique elimination period options */}
                                        {eliminationPeriodTypes?.map((option) => (
                                            <MenuItem
                                                key={option.eliminationPeriod}
                                                value={option.eliminationPeriod}
                                            >
                                                {option.eliminationPeriod} {option.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <TextField
                                        // disabled={benefitDurTypes?.length <= 1}
                                        select
                                        variant="outlined"
                                        label="Benefit Duration"
                                        InputLabelProps={{
                                            sx: {
                                                '&.Mui-disabled': {
                                                    color: 'unset'
                                                }
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                pointerEvents: benefitDurTypes?.length <= 1 ? 'none' : ''
                                            }
                                        }}
                                        value={selectedBenefitDuration || ""}
                                        sx={componentStyles.ltdSelectInput}
                                        onChange={handleSelectBenefitDuration}
                                        SelectProps={{
                                            IconComponent: () => benefitDurTypes?.length <= 1 ? null : <ArrowDropDownIcon sx={{ position: 'absolute', right: 7 }} />
                                        }}
                                    >
                                        {benefitDurTypes?.map((option) => (
                                            <MenuItem key={option.duration} value={option.duration}>
                                                {option.duration} {option.type}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>


                                {/* //////// Benefit amount select and slider /////////// */}

                                <div
                                    className="flex-row container"
                                    style={componentStyles.radioButtonItem}
                                >
                                    <Radio
                                        value={ltdOptionsExcludingDecline[ltdSlider]?.memberOptionId}
                                        name="ltd"
                                        sx={componentStyles.radioButtonSingle}
                                        icon={<RadioButtonIcon sx={{ height: "19px" }} />}
                                        checkedIcon={<RadioButtonCheckedIcon sx={{ height: "19px" }} />}
                                        onClick={handleRadioSelection}
                                        checked={
                                            radioSelected !== ltdDeclinePlan.memberOptionId &&
                                            radioSelected != null &&
                                            radioSelected == ltdOptionsExcludingDecline[ltdSlider]?.memberOptionId
                                        }
                                        inputProps={{
                                            "data-amount":
                                                ltdOptionsExcludingDecline[ltdSlider]?.memberPremiumAmount,
                                        }}
                                    />

                                    <div
                                        className="col"
                                        style={{
                                            fontSize: "15px",
                                            textAlign: "left",
                                            marginLeft: "-10px",
                                            fontWeight: radioSelected == ltdOptionsExcludingDecline[ltdSlider]?.memberOptionId ? 700 : 500,
                                        }}
                                    >
                                        <span>
                                            {`Member (${UsdFormatter.format(
                                                ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount || 0
                                            )})`}
                                        </span>

                                        {currRecc == ltdSlider && (
                                            <img
                                                alt="check mark"
                                                src={CustomCheckIcon}
                                                style={{ marginLeft: "5px" }}
                                            />
                                        )}

                                        <span
                                            style={{
                                                float: "right",
                                                fontWeight: radioSelected == ltdOptionsExcludingDecline[ltdSlider]?.memberOptionId ? 700 : 500,
                                            }}
                                        >
                                            {radioSelected == ltdOptionsExcludingDecline[ltdSlider]?.memberOptionId && "+"}
                                            {!props.dummyDisplay &&
                                                UsdFormatterDec.format(
                                                    ltdOptionsExcludingDecline[ltdSlider]?.memberPremiumAmount ||
                                                    0
                                                )}
                                            {props.dummyDisplay && "$0.00"}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    className="flex-row"
                                    style={{
                                        paddingLeft: "0rem",
                                        fontFamily: "Archivo",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        lineHeight: "20px",
                                        textAlign: "left",
                                        margin: "0 15px 20px 15px",
                                    }}
                                >
                                    {ltdOptionsExcludingDecline[ltdSlider]?.explanationOfBenefits}
                                </div>
                                {ltdOptionsExcludingDecline[ltdSlider]?.benefitOffsets && (
                                    <div
                                        className="flex-row"
                                        style={{
                                            paddingLeft: "0rem",
                                            fontFamily: "Archivo",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            lineHeight: "25px",
                                            textAlign: "left",
                                            marginLeft: "15px",
                                            marginRight: "15px",
                                        }}
                                    >
                                        {ltdOptionsExcludingDecline[ltdSlider]?.benefitOffsets}
                                    </div>
                                )}

                                <div
                                    className="flex-row"
                                    style={{ marginBottom: "10px", padding: "0px" }}
                                >
                                    <Autocomplete
                                        disablePortal
                                        fullWidth
                                        autoComplete
                                        // options={ltdOptionsExcludingDecline?.map((option) =>
                                        //   UsdFormatter.format(option.benefitAmount)
                                        // )}
                                        options={ltdOptionsExcludingDecline.map((option, i) => {
                                            let isLast = i == ltdOptionsExcludingDecline.length - 1
                                            // console.log('isLAst',isLast)
                                            if (isLast) {
                                                return `${UsdFormatter.format(option.benefitAmount)} (max)`
                                            } else {
                                                return UsdFormatter.format(option.benefitAmount)
                                            }
                                        })}
                                        // value={UsdFormatter.format(
                                        //   ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount || 0
                                        // )}
                                        value={ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount == maxBenefit
                                            ? `${UsdFormatter.format(ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount)} (max)`
                                            : UsdFormatter.format(ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount)
                                        }
                                        onChange={handleDropdownSelection}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Benefit Amount" />
                                        )}
                                    />
                                </div>

                                {/* SLIDER */}

                                <div
                                    className="flex-row"
                                    style={{
                                        padding: "0px 0px",
                                        margin: "0px auto",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        // flexWrap: "wrap",
                                        // border: "2px solid red",
                                    }}
                                >
                                    <div
                                        className="col-1"
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            position: "relative",
                                            alignSelf: "center",
                                            top: "-2px",
                                            textAlign: "right",
                                        }}
                                    >
                                        {/* {`$${ltdOptionsExcludingDecline[0]?.benefitAmount}`} */}
                                        {UsdFormatter.format(ltdOptionsExcludingDecline[0]?.benefitAmount || 0)}
                                    </div>
                                    <div
                                        className="col-10"
                                        styles={{
                                            margin: "0 auto",
                                            // display: "flex",
                                            // justifyContent: "center"
                                        }}
                                    >
                                        <Slider
                                            className=""
                                            value={ltdSlider}
                                            onChange={handleLtdSlider}
                                            step={1}
                                            min={0}
                                            max={ltdOptionsExcludingDecline.length - 1}
                                            sx={{
                                                width: "80%",
                                                marginLeft: "10%",
                                            }}
                                            valueLabelDisplay="auto"
                                            valueLabelFormat={UsdFormatter.format(
                                                ltdOptionsExcludingDecline[ltdSlider]?.benefitAmount
                                            )}
                                        />
                                    </div>
                                    <div
                                        className="col-1"
                                        style={{
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            position: "relative",
                                            alignSelf: "center",
                                            top: "-2px",
                                            // border: "1px solid black",
                                        }}
                                    >
                                        {/* {`$${ltdPlan?.options[ltdPlan?.options.length - 1]?.benefitAmount}`} */}
                                        {/* {`$${ltdOptionsExcludingDecline[ltdOptionsExcludingDecline.length - 1]?.benefitAmount}`} */}
                                        {UsdFormatter.format(ltdOptionsExcludingDecline[ltdOptionsExcludingDecline.length - 1]?.benefitAmount || 0)}
                                    </div>
                                </div>

                                <div
                                    className="col-1"
                                    style={{
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        position: "relative",
                                        alignSelf: "center",
                                        top: "-2px",
                                    }}
                                ></div>

                                {ltdPlan.options
                                .sort((a, b) => a.displayOrder - b.displayOrder)
                                .map((option, index) => {
                                let isSelected =
                                    props.selections.ltd == option.memberOptionId ||
                                    (!props.selections.ltd && currRecc == option.memberOptionId);

                                if (!option.isDecline && option.benefitTypeId == 2) {
                                    return (
                                    <React.Fragment key={`${JSON.stringify(option)}`}>
                                        <Box sx={enrollmentPlanStyles.optionRow}>
                                        {/* radio button, benefit amount, type */}
                                        <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                                            <Radio
                                            sx={enrollmentPlanStyles.planSelectRadioButton}
                                            value={option.memberOptionId}
                                            id={`${option.memberOptionId}`}
                                            name="ltd"
                                            icon={<RadioButtonIcon sx={{height: "19px"}} />}
                                            checkedIcon={
                                                <RadioButtonCheckedIcon sx={{height: "19px"}} />
                                            }
                                            onClick={
                                                handleNonIncrementalRadioSelection
                                                //   // props.setExpanded({...props.expanded,  ladd: true })
                                            }
                                            inputProps={{
                                                "data-amount": option.memberPremiumAmount,
                                            }}
                                            />

                                            <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                                            <Typography sx={enrollmentPlanStyles.singleOptionText(isSelected)}>
                                                {option?.name &&
                                                <>
                                                    {option.name} {option.name.includes('%') && option?.benefitAmount && `(Max ${UsdFormatter.format(option.benefitAmount)})`}
                                                </>
                                                }
                                            </Typography>

                                            {option?.benefitAmount == currRecc && <Typography sx={enrollmentPlanStyles.recommendedText}>{` (recommended)`}</Typography>}
                                            </Box>
                                        </Box>

                                        {/* premium amount */}
                                        <Box
                                            sx={enrollmentPlanStyles.singleOptionText(isSelected)}
                                        >
                                            {isSelected && "+"}
                                            {!props.dummyDisplay &&
                                            UsdFormatterDec.format(
                                                option.memberPremiumAmount
                                            )}
                                            {props.dummyDisplay && "$0.00"}
                                        </Box>


                                        </Box>

                                        {/* explanation of benefits */}
                                        {option.explanationOfBenefits && (
                                        <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits}>
                                            {option.explanationOfBenefits}
                                        </Box>
                                        )}

                                        {/* benefit offsets */}
                                        {option.benefitOffsets && (
                                        <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits} >
                                            {option.benefitOffsets}
                                        </Box>
                                        )}
                                    </React.Fragment>
                                    );
                                }
                                })}

                                {/* NOT NOW */}
                                <div
                                    className="flex-row container"
                                    style={componentStyles.radioButtonItem}
                                >
                                    <Radio
                                        value={ltdDeclinePlan?.memberOptionId || 0}
                                        name="ltd"
                                        className=""
                                        checked={radioSelected == ltdDeclinePlan.memberOptionId}
                                        sx={componentStyles.radioButtonSingle}
                                        icon={<RadioButtonIcon sx={{ height: "19px" }} />}
                                        checkedIcon={<RadioButtonCheckedIcon sx={{ height: "19px" }} />}
                                        onClick={handleRadioSelection}
                                        inputProps={{
                                            "data-amount": ltdDeclinePlan?.memberPremiumAmount,
                                        }}
                                    />
                                    <div
                                        className="col"
                                        style={{
                                            fontSize: "15px",
                                            textAlign: "left",
                                            marginLeft: "-10px",
                                            fontWeight:
                                                radioSelected === ltdDeclinePlan.memberOptionId ? 700 : 500,
                                        }}
                                    >
                                        <div
                                            className="flex-row"
                                            style={{
                                                display: "flex",
                                            }}
                                        >
                                            Not Now
                                            <Tooltip
                                                title={
                                                    ltdPlan?.infoTipText ||
                                                    "You will be able to come back and add this later."
                                                }
                                                placement="bottom-end"
                                                arrow
                                                enterTouchDelay={100}
                                            >
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <InfoOutlinedIcon
                                                        sx={{
                                                            height: "20px",
                                                            color: "#717171",
                                                            marginLeft: "5px",
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        }
                    </RadioGroup>
                }
            </Collapse>
            {!expanded &&
                <div style={{ padding: "0px 0px 0px 15px", margin: "15px 0px", fontSize: "18px", fontWeight: "500" }}>
                    {props.selections.ltd && props.selections.ltdVal > 0 &&
                        <>
                            <span>
                                {ltdPlan.options.find(option => option.memberOptionId == props.selections.ltd)?.name}
                            </span>
                            {!props.dummyDisplay &&
                                <span style={{ float: "right", marginRight: "15px", fontWeight: "700" }}>
                                    + {UsdFormatterDec.format(props.selections.ltdVal)}
                                </span>
                            }
                            {props.dummyDisplay &&
                                <span style={{ float: "right", marginRight: "15px", fontWeight: "700", color: "#00000033" }}>
                                    + $0.00
                                </span>
                            }
                        </>
                    }
                    {props.selections.ltd && props.selections.ltdVal == 0 &&
                        <>
                            <span>{ltdPlan.title} <em>waived</em></span>
                            <span style={{ float: "right", marginRight: "15px", color: "#00000033" }}>$0.00</span>
                        </>
                    }
                    {!props.selections.ltd && "No plan selected"}
                </div>
            }
        </div>
    )
}
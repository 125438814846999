import React, { useState, useEffect } from "react";

//MuiIcons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonIcon from "@mui/icons-material/RadioButtonUnchecked";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

//MuiComponents
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider, { dividerClasses } from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { Box, Tooltip, Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";

//Our Components
import InfinityLoader from "../../../../UH-loading-animation/InfinityLoader";
import RecommendedBenefitDisplay from "../../RecommendedBenefitDisplay";

//Our icons
import CustomCheckIcon from "../../../../../assets/images/CustomCheckIcon.svg";

//Our Services
import optionService from "../../../../../services/optionService";
import { is } from "date-fns/locale";
import { max } from "date-fns";

//Our Enrollment Plan Styles
import enrollmentPlanStyles from "../enrollmentPlanStyles";

export default function EnrollmentStdIncremental(props) {

  /////USD formatting
  let UsdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });

  //Expand and Collapse view on button click- get rid of auto-expand collapse
  const [expanded, setExpanded] = useState(true);

  const handleExpandSection = () => {
    setExpanded(!expanded)
  }


  //Data from STD plan from props:

  let results = props.results;
  let stdPlan = results.stdPlan.isIncremental ? results.stdPlan : "";

  let stdDeclinePlan = stdPlan?.options?.find((option) => option.isDecline);

  let previousPlanChosen =
    props.selections.stdVal > 0
      ? stdPlan?.options?.find(
        (plan) => plan.memberOptionId == props.selections.std
      )
      : null;

  let stdHelpText = stdPlan?.helpText ? stdPlan.helpText : "";

  ///// ELIMINATION PERIOD & BENEFIT DURATION LOGIC /////

  //Data from services
  const [eliminationPeriodTypes, setEliminationPeriodTypes] = useState();
  const [benefitDurTypes, setBenefitDurTypes] = useState();
  // calling to services for elim period and benefit option type ids
  const getElimPeriodList = async () => {
    let elimPeriodTypeList;
    try {
      elimPeriodTypeList = await optionService.getEliminationPeriodTypes();
    } catch (error) {
      console.log("error getting elimination periods", error);
    }

    // console.log("ElilminationPeriod List \n\n", elimPeriodTypeList);
  };

  const getBenefitDurationList = async () => {
    let benefitDurTypeList;
    try {
      benefitDurTypeList = await optionService.getBenefitDurationTypes();
    } catch (error) {
      console.log("error getting elimination periods", error);
    }

    // console.log("BenefitDuration List \n\n", benefitDurTypeList);
  };

  useEffect(() => {
    //getting lists from option service
    getElimPeriodList();
    getBenefitDurationList();
  }, []);

  //Data from a member's options

  //selected elimination period
  const [selectedEliminationPeriod, setSelectedEliminationPeriod] =
    useState(null);

  //get only unique values of elimination period from member options excluding duplicates
  const getUniqueEliminationPeriodList = () => {
    let elimPeriodInfo = [];

    for (let option of stdPlan?.options) {
      if (option.eliminationPeriod > 0 && option.benefitTypeId != 3) {
        let object = {
          eliminationPeriod: option.eliminationPeriod,
          type: option.eliminationPeriodType,
          typeId: option.eliminationPeriodTypeId,
        };
        elimPeriodInfo.push(object);
      }
    }

    let uniqueElimPeriodArr = [
      ...new Set(elimPeriodInfo.map((obj) => obj.eliminationPeriod)),
    ];

    let uniqueElimPeriodOptions = uniqueElimPeriodArr.map(
      (eliminationPeriod) => {
        return elimPeriodInfo.find(
          (obj) => obj.eliminationPeriod === eliminationPeriod
        );
      }
    );

    setEliminationPeriodTypes(uniqueElimPeriodOptions);
    setSelectedEliminationPeriod(uniqueElimPeriodOptions[0]?.eliminationPeriod);
  };

  ////// selected benefit duration /////
  const [selectedBenefitDuration, setSelectedBenefitDuration] = useState();

  const getUniqueBenefitDurationList = () => {
    let benefitDurationInfo = [];

    for (let option of stdPlan?.options) {
      if (option.benefitDuration > 0 && option.benefitTypeId != 3) {
        let object = {
          duration: option.benefitDuration,
          type: option.benefitDurationType,
          typeId: option.benefitDurationTypeId,
        };
        benefitDurationInfo.push(object);
      }
    }

    let uniqueBenefitDurList = [
      ...new Set(benefitDurationInfo.map((obj) => obj.duration)),
    ];

    let uniqueBenefitDurOptions = uniqueBenefitDurList.map((duration) => {
      return benefitDurationInfo.find((obj) => obj.duration === duration);
    });

    //set member benefit options off of unique options only
    setBenefitDurTypes(uniqueBenefitDurOptions);
    setSelectedBenefitDuration(uniqueBenefitDurOptions[0]?.duration);
  };

  const handleSelectBenefitDuration = (e) => {
    console.log("benefit e.target", e.target);
    if (eliminationPeriodTypes?.length > 1) {
      let indexOfDuration = 0
      for (let i = 0; i < benefitDurTypes.length; i++) {
        if (benefitDurTypes[i].duration == e.target.value) {
          indexOfDuration = i
        }
      }
      setSelectedEliminationPeriod(eliminationPeriodTypes[indexOfDuration]?.eliminationPeriod);
      setSelectedBenefitDuration(e.target.value);
    } else {
      setSelectedBenefitDuration(e.target.value);
    }

    // setSelectedBenefitDuration(e.target.value);


    //console.log('benefit duration handler', e.target.value, eliminationPeriodTypes, benefitDurTypes )
    setRadioSelected(null);
  };

  useEffect(() => {
    getUniqueEliminationPeriodList();
    getUniqueBenefitDurationList();
  }, [stdPlan.options]);

  const [stdOptionsExcludingDecline, setStdOptionsExcludingDecline] = useState(
    []
  );
  const [nonIncrementalStdOptions, setNonIncrementalStdOptions] = useState([]);

  //does stdPlan list exist? if yes, find index to start amount and slider
  useEffect(() => {
    if (stdOptionsExcludingDecline && expanded) {
      initialPlanAmountDisplay();
    }
  }, [props.recommendations, props.results, expanded, stdOptionsExcludingDecline]);

  useEffect(() => {
    if (stdPlan?.options?.length > 0) {
      //array of all options excluding the $0 decline amount & benefit duration selected
      let filteredStdOptions = stdPlan?.options?.filter((option) => {
        return (
          option.isDecline === false &&
          option.benefitDuration === selectedBenefitDuration &&
          option.eliminationPeriod === selectedEliminationPeriod &&
          option.benefitTypeId != 3
        );
      });

      let filteredNonIncrementStdOptions = stdPlan?.options?.filter((option) => {
        return (
          option.isDecline === false &&
          option.benefitTypeId == 3
        );
      });

      setNonIncrementalStdOptions(filteredNonIncrementStdOptions);
      setStdOptionsExcludingDecline(filteredStdOptions);
    }
  }, [stdPlan.options, selectedBenefitDuration, selectedEliminationPeriod]);

  //AUTO SELECT DECLINE if decline option is the only option that exists
  useEffect(() => {
    if (props?.results && props?.results?.stdPlan?.options?.length === 1 && stdDeclinePlan?.memberOptionId) {
      console.log('selections in stdInc', props.selections)
      setStdPlanChosen(stdDeclinePlan);
      setRadioSelected(stdDeclinePlan.memberOptionId);
      setStdSlider(0);
      //update selection in db
      props?.handleEnrollClick(stdDeclinePlan?.memberOptionId)
    }

  }, [props?.results?.stdPlan?.options?.length, stdOptionsExcludingDecline?.length, stdDeclinePlan?.memberOptionId])


  //change value of elim period w/ select drop down
  const handleSelectEliminationPeriod = (e) => {
    //update value w/ selected elimination period value
    setSelectedEliminationPeriod(e.target.value);
  };
  const [loading, setLoading] = useState(false);
  const [stdPlanChosen, setStdPlanChosen] = useState(previousPlanChosen);
  const [currRec, setCurrRec] = useState(0); //hold the rec position in state to render radio w/ recommended option
  const [stdSlider, setStdSlider] = useState(0);
  const [radioSelected, setRadioSelected] = useState(null);

  //set recommendation amount from rec data
  useEffect(() => {
    if (props?.recommendations) {
      let recommendationPosition;
      if (stdOptionsExcludingDecline.length > 1) {
        props.recommendations.Lowest
          ? (recommendationPosition = 1)
          : (recommendationPosition = stdOptionsExcludingDecline.length - 2);
      } else if (stdOptionsExcludingDecline.length == 1) {
        recommendationPosition = 0
      }
      console.log('rec position in use effect', stdOptionsExcludingDecline[recommendationPosition]?.benefitAmount)
      //set recommendation amount to display 

      setCurrRec(stdOptionsExcludingDecline[recommendationPosition]?.benefitAmount);
    }
  }, [props?.recommendations, stdOptionsExcludingDecline?.length])

  useEffect(() => {
    if (stdSlider > stdOptionsExcludingDecline?.length - 1 && stdOptionsExcludingDecline?.length > 0) {
      setStdSlider(stdOptionsExcludingDecline?.length - 1);
    }
    //if there is only one option, set slider to 0
    if (stdOptionsExcludingDecline?.length === 1) {
      setStdSlider(0);
    }
  }, [stdOptionsExcludingDecline?.length, stdSlider])

  //set initial rec of either lowest amount above $0 or recommended amount based on calculator

  const initialPlanAmountDisplay = () => {
    let previousSelectedOption;
    let previousSelectedOptionValid = false;
    if (props.selections.stdVal > 0) {
      //need to look at all options, and not just filtered
      previousSelectedOption = stdPlan?.options?.find(
        (option) => option.memberOptionId === props.selections.std
      );
      previousSelectedOptionValid = stdOptionsExcludingDecline.includes(previousSelectedOption)
      //filter by benefit duration before displaying previous option selected
      if (previousSelectedOptionValid && benefitDurTypes?.length > 0 && benefitDurTypes.some(benDur => benDur.duration == previousSelectedOption?.benefitDuration)) {
        setSelectedBenefitDuration(previousSelectedOption?.benefitDuration);
      }
    }
    //db selection > 0
    if (previousSelectedOptionValid) {
      if (previousSelectedOption?.benefitAmount > 0) {
        for (let i = 0; i < stdOptionsExcludingDecline.length; i++) {
          let optionId = stdOptionsExcludingDecline[i]?.memberOptionId;
          if (optionId == previousSelectedOption.memberOptionId) {
            setStdSlider(i);
            setStdPlanChosen(stdOptionsExcludingDecline[i]);
            setRadioSelected(stdOptionsExcludingDecline[i].memberOptionId);
          }
        }
      }
      //no value saved in db
    } else if (props?.selections?.stdVal == 0 && !props?.selections?.std) {
      let recommendationPosition;
      if (stdOptionsExcludingDecline?.length > 1) {
        props.recommendations.Lowest
          ? (recommendationPosition = 1)
          : (recommendationPosition = stdOptionsExcludingDecline.length - 2);
      }
      //set recommendation to display

      setCurrRec(stdOptionsExcludingDecline[recommendationPosition]?.benefitAmount);
      setStdSlider(recommendationPosition);
      // }

      //decline saved in db
    } else if (props?.selections?.stdVal == 0 && props?.selections?.std) {
      setStdSlider(0);
      setStdPlanChosen(stdDeclinePlan);
      setRadioSelected(stdDeclinePlan.memberOptionId);
    }

    //IF a user has already saved a selection and is going back to this section, display saved selection
  };

  ////// SLIDER LOGIC

  const handleStdSlider = (e) => {
    setStdSlider(e.target.value);
    setStdPlanChosen(stdOptionsExcludingDecline[e.target.value]);
    setRadioSelected(stdOptionsExcludingDecline[e.target.value].memberOptionId);
    //update sticky progress total
    if (stdOptionsExcludingDecline[e.target.value].memberPremiumAmount && stdOptionsExcludingDecline[e.target.value].memberOptionId) {
      props.setSelections({
        ...props.selections,
        stdVal: stdOptionsExcludingDecline[e.target.value].memberPremiumAmount,
        std: stdOptionsExcludingDecline[e.target.value].memberOptionId,
      });
    }
  };

  const handleSliderComplete = (stdSliderIndex) => {
    //update selection in db with new slider option only when slider is released
    props?.handleEnrollClick(stdOptionsExcludingDecline[stdSliderIndex]?.memberOptionId)
  }

  /////// RADIO BUTTON LOGIC

  const handleRadioSelection = (e) => {
    //handle selection to update sticky progress bar
    props.selectionHandler(e);
    //check if selection is the recommended or 'not now' option w/ the e.target
    //set value of radioButton state to update checked value
    let radioAmount = Number(e.target.getAttribute("data-amount"));
    let radioValue = Number(e.target.value);
    setRadioSelected(Number(e.target.value));

    //LOOP TO TEST E.TARGET.VALUE?
    let chosenPlan = stdPlan?.options?.find((option) => {
      return option.memberOptionId == radioValue;
    });

    //set chosen plan to selected
    setStdPlanChosen(chosenPlan);

    //find index of chosenPlan to update slider value
    if (radioAmount > 0) {
      let index = stdOptionsExcludingDecline.indexOf(chosenPlan);
      setStdSlider(index);
    } else {
      let index = stdPlan?.options?.indexOf(chosenPlan);
      setStdSlider(index);
    }

    //update selection in db
    props?.handleEnrollClick(chosenPlan?.memberOptionId)
  };

  const handleNonIncrementalRadioSelection = (e) => {
    //handle selection to update sticky progress bar
    props.selectionHandler(e);
    //check if selection is the recommended or 'not now' option w/ the e.target
    //set value of radioButton state to update checked value
    let radioAmount = Number(e.target.getAttribute("data-amount"));
    let radioValue = Number(e.target.value);
    setRadioSelected(Number(e.target.value));

    //LOOP TO TEST E.TARGET.VALUE?
    let chosenPlan = stdPlan?.options?.find((option) => {
      return option.memberOptionId == radioValue;
    });

    //set chosen plan to selected
    setStdPlanChosen(chosenPlan);

    // //find index of chosenPlan to update slider value
    // if (radioAmount > 0) {
    //   let index = ltdOptionsExcludingDecline.indexOf(chosenPlan);
    //   setLtdSlider(index);
    // } else {
    //   let index = ltdPlan?.options?.indexOf(chosenPlan);
    //   setLtdSlider(index);
    // }

    //update selection in db
    props?.handleEnrollClick(chosenPlan?.memberOptionId)
  };


  ///// BENEFIT DROPDOWN SELECT LOGIC

  const handleDropdownSelection = (e) => {
    //set radio button state to true if slider is moved - radioSelected value is only false if Not Now radio is clicked
    //handle the string value from dropdown list
    let tagName = e.target.tagName.toLowerCase();
    if (tagName === "li") {
      let value = e.target.innerHTML.slice(1).replace(",", "");
      //remove strings 'max' and '(recommended)' from value
      if (value.includes('(max)')) value = value.replace('(max)', '')
      if (value.includes('(recommended)')) value = value.replace('(recommended)', '')

      for (let i = 0; i < stdOptionsExcludingDecline.length; i++) {
        let benefitAmount = stdOptionsExcludingDecline[i]?.benefitAmount;
        if (benefitAmount == value) {
          setStdSlider(i);
          setStdPlanChosen(stdOptionsExcludingDecline[i]);
          setRadioSelected(stdOptionsExcludingDecline[i].memberOptionId);

          //update selection in db
          props?.handleEnrollClick(stdOptionsExcludingDecline[i]?.memberOptionId)

          //update sticky progress total
          if (stdOptionsExcludingDecline[i].memberPremiumAmount && stdOptionsExcludingDecline[i].memberOptionId) {
            props.setSelections({
              ...props.selections,
              stdVal: stdOptionsExcludingDecline[i].memberPremiumAmount,
              std: stdOptionsExcludingDecline[i].memberOptionId,
            });
          }
        }
      }
    } else if (tagName === "input") {
      let value = e.target.value.slice(1).replace(",", "");
      //remove strings 'max' and '(recommended)' from value
      if (value.includes('(max)')) value = value.replace('(max)', '')
      if (value.includes('(recommended)')) value = value.replace('(recommended)', '')

      for (let i = 0; i < stdOptionsExcludingDecline.length; i++) {
        let benefitAmount = stdOptionsExcludingDecline[i]?.benefitAmount;
        if (benefitAmount == value) {
          setStdSlider(i);
          setStdPlanChosen(stdOptionsExcludingDecline[i]);
          setRadioSelected(stdOptionsExcludingDecline[i].memberOptionId);

          //update selection in db
          props?.handleEnrollClick(stdOptionsExcludingDecline[i]?.memberOptionId)

          //update sticky progress total
          if (stdOptionsExcludingDecline[i].memberPremiumAmount && stdOptionsExcludingDecline[i].memberOptionId) {
            props.setSelections({
              ...props.selections,
              stdVal: stdOptionsExcludingDecline[i].memberPremiumAmount,
              std: stdOptionsExcludingDecline[i].memberOptionId,
            });

          }
        }
      }
    }


  };

  //check that length is > 1 to avoid NaN error if only one option besides decline
  let maxBenefit = stdOptionsExcludingDecline?.length > 1 ? stdOptionsExcludingDecline[stdOptionsExcludingDecline.length - 1]?.benefitAmount : stdOptionsExcludingDecline[0]?.benefitAmount

  //display value logic for dropdown select
  let benefitSelectionValue = () => {
    if (stdOptionsExcludingDecline[stdSlider]?.benefitAmount === maxBenefit && maxBenefit !== currRec) { //last but not rec amount
      return `${UsdFormatter.format(stdOptionsExcludingDecline[stdSlider]?.benefitAmount)} (max)`
    } else if (stdOptionsExcludingDecline[stdSlider]?.benefitAmount === maxBenefit && maxBenefit === currRec) { //last and rec amount
      return `${UsdFormatter.format(stdOptionsExcludingDecline[stdSlider]?.benefitAmount)} (recommended)`
    } else if (stdOptionsExcludingDecline[stdSlider]?.benefitAmount === currRec && currRec !== maxBenefit) { //rec amount but not last
      return `${UsdFormatter.format(stdOptionsExcludingDecline[stdSlider]?.benefitAmount)} (recommended)`
    } else {
      return UsdFormatter.format(stdOptionsExcludingDecline[stdSlider]?.benefitAmount)
    }
  }


  let expandIcon = expanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "white",
        backgroundColor: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        position: "absolute",
        right: "14%",
        top: "20%",
        color: "#195ffb",
        borderRadius: "100px",
        boxShadow: "-1px 1px 4px 0px #00000040",
      }}
    />
  );

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: "absolute",
              zIndex: 5,
              width: "100%",
              height: "100vh",
              backgroundColor: "#00000050",
              backdropFilter: "blur(10px)",
            }}
          />
          <style>
            {`
              div.lottie-div > svg {
                  height:55px!important;
                  position:absolute;
                  top:50%;
              }
              .plan-explanation-innerHTML ul {
                padding-left: 1.3rem;
              }
            `}
          </style>
        </>
      )}
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <span
          style={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          Short-Term Disability
        </span>

        <Box
          onClick={handleExpandSection}
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          {expandIcon}
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: stdHelpText }}
          />
        </Box>



        {/* //////// Benefit amount select and slider - only display if any options besides decline option exist /////////// */}

        
        {stdOptionsExcludingDecline?.length > 0 ?
          (
          <>

            {/* Elimination Period & Benefit Duration select*/}
            {stdOptionsExcludingDecline?.length > 0 &&
              <Box sx={enrollmentPlanStyles.elimAndDurRowContainer}>

                {/* Elimination Period */}
                <TextField
                  disabled={eliminationPeriodTypes?.length <= 1}
                  select
                  variant="outlined"
                  label="Elimination Period"
                  InputLabelProps={{
                    sx: {
                      "&.Mui-disabled": {
                        color: "unset",
                      },
                      color: 'primary.main'
                    },
                  }}
                  InputProps={{
                    sx: {
                      pointerEvents:
                        eliminationPeriodTypes?.length <= 1 ? "none" : "",
                    },
                  }}
                  value={selectedEliminationPeriod || ""}
                  sx={enrollmentPlanStyles.selectInput}
                  onChange={handleSelectEliminationPeriod}
                  SelectProps={{
                    IconComponent: () =>
                      eliminationPeriodTypes?.length <= 1 ? null : (
                        <ArrowDropDownIcon sx={{ position: "absolute", right: 7 }} />
                      ),
                  }}
                >
                  {/* select options base on unique elimination period options */}
                  {eliminationPeriodTypes?.map((option) => (
                    <MenuItem
                      key={option.eliminationPeriod}
                      value={option.eliminationPeriod}
                    >
                      {option.eliminationPeriod} {option.type}
                    </MenuItem>
                  ))}
                </TextField>


                {/* Benefit Duration */}
                <TextField
                  disabled={benefitDurTypes?.length <= 1}
                  select
                  variant="outlined"
                  label="Benefit Duration"
                  InputProps={{
                    sx: {
                      pointerEvents: benefitDurTypes?.length <= 1 ? "none" : "",
                    },
                  }}
                  InputLabelProps={{ sx: { color: 'primary.main' } }}
                  value={selectedBenefitDuration || ""}
                  sx={enrollmentPlanStyles.selectInput}
                  onChange={handleSelectBenefitDuration}
                  SelectProps={{
                    IconComponent: () =>
                      benefitDurTypes?.length <= 1 ? null : (
                        <ArrowDropDownIcon sx={{ position: "absolute", right: 7 }} />
                      ),
                  }}
                >
                  {benefitDurTypes?.map((option) => (
                    <MenuItem key={option.duration} value={option.duration}>
                      {option.duration} {option.type}
                    </MenuItem>
                  ))}
                </TextField>

              </Box>
            }

            <Box sx={enrollmentPlanStyles.optionRow} >
              <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                <Radio
                  sx={enrollmentPlanStyles.planSelectRadioButton}
                  value={stdOptionsExcludingDecline[stdSlider]?.memberOptionId}
                  name="std"
                  icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                  checkedIcon={<RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                  onClick={handleRadioSelection}
                  checked={
                    radioSelected !== stdDeclinePlan.memberOptionId &&
                    radioSelected != null &&
                    radioSelected == stdOptionsExcludingDecline[stdSlider]?.memberOptionId
                  }
                  inputProps={{
                    "data-amount":
                      stdOptionsExcludingDecline[stdSlider]?.memberPremiumAmount,
                    "data-property": "stdMemberTrue_radio",
                  }}
                />

                <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                  <Typography sx={enrollmentPlanStyles.singleOptionText(stdOptionsExcludingDecline.some(opt => opt?.memberOptionId == stdPlanChosen?.memberOptionId))}>
                    {`Member (${UsdFormatter.format(
                      stdOptionsExcludingDecline[stdSlider]?.benefitAmount || 0
                    )})`}
                  </Typography>
                </Box>
              </Box>

              {/* premium amount */}
              <Box sx={enrollmentPlanStyles.singleOptionText(stdOptionsExcludingDecline.some(opt => opt?.memberOptionId == stdPlanChosen?.memberOptionId))}>
                {stdPlanChosen?.benefitAmount > 0 && "+"}
                {!props.dummyDisplay &&
                  UsdFormatterDec.format(
                    stdOptionsExcludingDecline[stdSlider]?.memberPremiumAmount ||
                    0
                  )}
                {props.dummyDisplay && "$0.00"}
              </Box>
            </Box>

            <RecommendedBenefitDisplay currRec={currRec} />

            {/* Explanation of Benefits */}
            <Box sx={enrollmentPlanStyles.explanationOfBenefitsTextIncremental}>
              {stdOptionsExcludingDecline[stdSlider]?.explanationOfBenefits}
            </Box>

            {stdOptionsExcludingDecline[stdSlider]?.benefitOffsets && (
              <Box sx={enrollmentPlanStyles.explanationOfBenefitsTextIncremental}>
                {stdOptionsExcludingDecline[stdSlider]?.benefitOffsets}
              </Box>
            )}

            

            {/* Dropdown - display if more than one option*/}
            {stdOptionsExcludingDecline?.length > 1 &&
              <Box sx={enrollmentPlanStyles.benefitAmountDropdownContainer}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  autoComplete
                  options={stdOptionsExcludingDecline.map((option, i) => {
                    if (stdOptionsExcludingDecline?.length > 1) {
                      let isLast = (i == stdOptionsExcludingDecline.length - 1)
                      if (isLast && currRec !== option?.benefitAmount) { //last but not rec amount
                        return `${UsdFormatter.format(option.benefitAmount)} (max)`
                      } else if (isLast && currRec === option?.benefitAmount) { //last and = rec amount
                        return `${UsdFormatter.format(option.benefitAmount)} (recommended)`
                      } else if (!isLast && currRec === option?.benefitAmount) { //not last and = rec amount
                        return `${UsdFormatter.format(option.benefitAmount)} (recommended)`
                      } else {
                        return UsdFormatter.format(option.benefitAmount)
                      }
                    } else if (stdOptionsExcludingDecline?.length == 1) {
                      return UsdFormatter.format(stdOptionsExcludingDecline[0]?.benefitAmount)
                    }

                  })}
                  value={benefitSelectionValue() || 0}
                  onChange={(e) => handleDropdownSelection(e)}
                  componentsProps={{
                    popupIndicator: { sx: { color: 'primary.main' } }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Benefit Amount"
                      InputLabelProps={{ sx: { color: 'primary.main' } }}
                      sx={enrollmentPlanStyles.activeBlueDropdownField(false)}
                    />
                  )}
                />
              </Box>
            }

            {/* SLIDER - hide if only one option available*/}

            {stdOptionsExcludingDecline?.length > 1 &&
              <Box sx={enrollmentPlanStyles.incSliderSection}>
                {/* slider min */}
                <Box sx={enrollmentPlanStyles.incSliderLowAmount}>
                  {/* {`$${stdOptionsExcludingDecline[0]?.benefitAmount}`} */}
                  {UsdFormatter.format(stdOptionsExcludingDecline[0]?.benefitAmount || 0)}
                </Box>

                {/* slider */}
                <Box sx={enrollmentPlanStyles.incSliderContainer} >
                  <Slider
                    value={stdSlider || 0}
                    onChange={handleStdSlider}
                    onChangeCommitted={() => handleSliderComplete(stdSlider)}
                    step={1}
                    min={0}
                    max={stdOptionsExcludingDecline.length - 1}
                    sx={{ width: '90%', marginLeft: '5%', marginBottom: '0px' }}
                    valueLabelDisplay="auto"
                    valueLabelFormat={UsdFormatter.format(
                      stdOptionsExcludingDecline[stdSlider]?.benefitAmount
                    )}
                  />
                </Box>

                {/* slider max*/}
                <Box sx={enrollmentPlanStyles.incSliderHighAmount}>
                  {/* {`$${stdOptionsExcludingDecline[stdOptionsExcludingDecline.length - 1]?.benefitAmount}`} */}
                  {UsdFormatter.format(stdOptionsExcludingDecline[stdOptionsExcludingDecline.length - 1]?.benefitAmount || 0)}
                </Box>
              </Box>
            }
          </>) : (
            nonIncrementalStdOptions?.length == 0 &&
            <Box sx={enrollmentPlanStyles.noOptionsAvailableMsg}>
              You do not have any Short-Term Disability options available.
            </Box>
          )
        }

        <RadioGroup
          // onChange={handleNonIncrementalRadioSelection}
          value={props.selections.std || currRec}
        >
          {results &&
            stdPlan.options
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((option, index) => {
                let isSelected =
                  props.selections.std == option.memberOptionId ||
                  (!props.selections.std && currRec == option.memberOptionId);

                if (!option.isDecline && option.benefitTypeId == 3) {
                  return (
                    <React.Fragment key={`${JSON.stringify(option)}`}>
                      <Box sx={enrollmentPlanStyles.optionRow}>
                        {/* radio button, benefit amount, type */}
                        <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
                          <Radio
                            sx={enrollmentPlanStyles.planSelectRadioButton}
                            value={option.memberOptionId}
                            id={`${option.memberOptionId}`}
                            name="std"
                            icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
                            checkedIcon={
                              <RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />
                            }
                            onClick={
                              handleNonIncrementalRadioSelection
                              // props.setExpanded({...props.expanded,  ladd: true })
                            }
                            inputProps={{
                              "data-amount": option.memberPremiumAmount,
                            }}
                          />

                          <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
                            <Typography sx={enrollmentPlanStyles.singleOptionText(isSelected)}>
                              {option?.name &&
                                <>
                                  {option.name} {option.name.includes('%') && option?.benefitAmount && `(Max ${UsdFormatter.format(option.benefitAmount)})`}
                                </>
                              }
                            </Typography>

                            {option?.benefitAmount == currRec && <Typography sx={enrollmentPlanStyles.recommendedText}>{` (recommended)`}</Typography>}
                          </Box>
                        </Box>

                        {/* premium amount */}
                        <Box
                          sx={enrollmentPlanStyles.singleOptionText(isSelected)}
                        >
                          {isSelected && "+"}
                          {!props.dummyDisplay &&
                            UsdFormatterDec.format(
                              option.memberPremiumAmount
                            )}
                          {props.dummyDisplay && "$0.00"}
                        </Box>


                      </Box>

                      {/* explanation of benefits */}
                      {option.explanationOfBenefits && (
                        <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits}>
                          {option.explanationOfBenefits}
                        </Box>
                      )}

                      {/* benefit offsets */}
                      {option.benefitOffsets && (
                        <Box sx={enrollmentPlanStyles.optionExplanationOfBenefits} >
                          {option.benefitOffsets}
                        </Box>
                      )}
                    </React.Fragment>
                  );
                }
              })}
        </RadioGroup>


        {/* NOT NOW */}
        <Box sx={enrollmentPlanStyles.declineOptionRow}>
          <Radio
            sx={enrollmentPlanStyles.planSelectRadioButton}
            value={stdDeclinePlan?.memberOptionId || 0}
            name="std"
            className=""
            // disabled={stdOptionsExcludingDecline?.length == 0 && stdDeclinePlan?.memberOptionId} //auto select and disable radio if only decline option exists
            checked={radioSelected == stdDeclinePlan.memberOptionId}
            icon={<RadioButtonIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
            checkedIcon={<RadioButtonCheckedIcon sx={enrollmentPlanStyles.radioButtonIcon(false)} />}
            onClick={handleRadioSelection}
            inputProps={{
              "data-amount": stdDeclinePlan?.memberPremiumAmount,
            }}
          />
          <Box sx={enrollmentPlanStyles.declineOptionText(radioSelected === stdDeclinePlan.memberOptionId)} >
            Not Now
          </Box>

          <Tooltip
            title={
              stdPlan?.infoTipText ||
              "If you decline coverage, you may not be able to enroll in the future."
            }
            placement="bottom-end"
            arrow
            enterTouchDelay={0}
          >
            <Box sx={enrollmentPlanStyles.notNowToolTip}>
              <InfoOutlinedIcon
                sx={enrollmentPlanStyles.toolTipIcon}
              />
            </Box>
          </Tooltip>
        </Box>

      </Collapse>

      {/* Display when not expanded */}

      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.std && props.selections.stdVal > 0 && (
            <>
              <Box >
                <RadioButtonCheckedIcon
                  sx={enrollmentPlanStyles.radioButtionIconCollapse}
                />
                {
                  stdPlan.options.find(
                    (option) => option.memberOptionId == props.selections.std
                  )?.name
                }
              </Box>

              <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                + {UsdFormatterDec.format(props.selections.stdVal)}
              </Box>
            </>
          )}

          {props.selections.std && props.selections.stdVal == 0 && (
            <>
              <span>
                <em>not elected</em>
              </span>
              <span
                style={enrollmentPlanStyles.notElectedZeroAmount}
              >
                $0.00
              </span>
            </>
          )}
          {!props.selections.std && "No plan selected"}
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}

import axios from "axios";
import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalMemberService extends baseService {
    getMe() {
        const url = Settings.API_URL + "member/me";
        return this.get(url)
    }
    getMembersAsync() {
        const url = Settings.API_URL + "member";
        return this.get(url);
    }
    getMemberByIdAsync(memberId) {
        const url = Settings.API_URL + "member/" + memberId;
        return this.get(url);
    }
    getMemberSocialByIdAsync(memberId) {
        const url = Settings.API_URL + "member/" + memberId + "/social";
        return this.get(url);
    }
    getMemberSocial() {
        const url = Settings.API_URL + "member/social";
        return this.get(url);
    }
    searchMemberEnrollment(memberEnrollment) {
        const url = Settings.API_URL + "member/search/enrollment";
        return this.post(url, memberEnrollment);
    }
    insertMember(member) {
        const url = Settings.API_URL + "member";
        return this.post(url, member);
    }
    updateMember(member) {
        const url = Settings.API_URL + "member";
        return this.put(url, member);
    }
    updateForMember(memberId, member) {
        const url = Settings.API_URL + "member/" + memberId;
        return this.put(url, member);
    }
    getSpouse() {
        const url = Settings.API_URL + "member/spouse";
        return this.get(url);
    }
    addSpouse(memberSpouse) {
        if (memberSpouse.dateOfBirth === '') {
            memberSpouse.dateOfBirth = null;
        }
        const url = Settings.API_URL + "member/spouse";
        return this.post(url, memberSpouse);
    }
    updateSpouse(memberSpouse) {
        if (memberSpouse.dateOfBirth === '') {
            memberSpouse.dateOfBirth = null;
        }
        const url = Settings.API_URL + "member/spouse";
        return this.put(url, memberSpouse);
    }
    getSpouseForMember(memberId) {
        const url = Settings.API_URL + "member/" + memberId + "/spouse";
        return this.get(url);
    }
    addSpouseForMember(memberId, memberSpouse) {
        if (memberSpouse.dateOfBirth === '') {
            memberSpouse.dateOfBirth = null;
        }
        const url = Settings.API_URL + "member/" + memberId + "/spouse";
        return this.post(url, memberSpouse);
    }
    updateSpouseForMember(memberId, memberSpouse) {
        if (memberSpouse.dateOfBirth === '') {
            memberSpouse.dateOfBirth = null;
        }
        const url = Settings.API_URL + "member/" + memberId + "/spouse";
        return this.put(url, memberSpouse);
    }
    deleteSpouseForMember(memberId) {
        const url = Settings.API_URL + "member/" + memberId;
        return this.delete(url);
    }
    getDependents() {
        const url = Settings.API_URL + "member/dependent";
        return this.get(url);
    }
    getDependentsForMember(memberId) {
        const url = Settings.API_URL + "member/" + memberId + "/dependent";
        return this.get(url);
    }

    addDependent(dependent) {
        if (dependent.dob === '') {
            dependent.dob = null;
        }
        const url = Settings.API_URL + "member/dependent";
        return this.post(url, dependent);
    }
    addDependentForMember(memberId, dependent) {
        if (dependent.dob === '') {
            dependent.dob = null;
        }
        const url = Settings.API_URL + "member/" + memberId + "/dependent";
        return this.post(url, dependent);
    }
    updateDependent(dependent) {
        if (dependent.dob === '') {
            dependent.dob = null;
        }
        const url = Settings.API_URL + "member/dependent";
        return this.put(url, dependent);
    }
    updateDependentForMember(memberId, dependent) {
        if (dependent.dob === '') {
            dependent.dob = null;
        }
        const url = Settings.API_URL + "member/" + memberId + "/dependent";
        return this.put(url, dependent);
    }
    deleteDependent(dependentId) {
        const url = Settings.API_URL + "member/dependent/" + dependentId;
        return this.delete(url);
    }
    deleteDependentForMember(memberId, dependentId) {
        const url = Settings.API_URL + "member/" + memberId + "/dependent/" + dependentId;
        return this.delete(url);
    }
    getPhonesForMember(memberId) {
        const url = Settings.API_URL + "member/" + memberId + "/phone";
        return this.get(url);
    }
    getPhones() {
        const url = Settings.API_URL + "member/phone";
        return this.get(url);
    }
    insertPhone(phone) {
        const url = Settings.API_URL + "member/phone";
        return this.post(url, phone);
    }
    updatePhone(phone) {
        const url = Settings.API_URL + "member/phone";
        return this.put(url, phone);
    }
    deletePhone(phoneId) {
        const url = Settings.API_URL + "member/phone";
        return this.delete(url);
    }

//    public class MemberOrganizationVM
//    {
//        public int MemberId { get; set; }
//        public int OrganizationId { get; set; }
//        public bool IsPrimary { get; set; }
//}
    addMemberOrganization(memberOrganization) {
        const url = Settings.API_URL + "member/" + memberOrganization.memberId + "/organization";
        return this.post(url, memberOrganization);
    } 

    updateMemberOrganization(memberOrganization) {
        const url = Settings.API_URL + "member/" + memberOrganization.memberId + "/organization";
        return this.put(url,[memberOrganization]); 
    }

    updateMemberOrganizationMember(memberOrganization) {
        const url = Settings.API_URL + "member/organization";
        return this.put(url, [memberOrganization]);
    }

    insertPhoneForMember(phone) {
        /*
         *        public int MemberId { get; set; }
        public int PhoneNumberTypeId { get; set; }
        public bool IsPreferred { get; set; }
        public string Number { get; set; } 
         */
        const url = Settings.API_URL + "member/" + phone.memberId + "/phone";
        return this.post(url, phone);
    }
    deletePhoneForMember(memberId, phoneId) {
        // We may need to modify this at some point. it's hardcoded because we want to have a separate method between internal / external for different permissions.
        const url = Settings.API_URL + "member/" + memberId + "/phone/" + phoneId;
        return this.delete(url);
    }
    updatePhoneForMember(phone) {
        const url = Settings.API_URL + "member/" + phone.memberId + "/phone";
        return this.put(url, phone);
    }
    // member agent has memberId, agentId
    insertMemberAgentForMember(memberId, agentId) {
        const url = Settings.API_URL + "member/" + memberId + "/agent";
        let memberAgent = {
            id: memberId,
            agentId: agentId
        };
        return this.post(url, memberAgent);
    }
    updateMemberAgentForMember(memberId, agentId) {
        const url = Settings.API_URL + "member/" + memberId + "/agent";
        let memberAgent = {
            id: memberId,
            agentId: agentId
        };
        return this.put(url, memberAgent);
    }
    insertMemberAgent(memberId, agentId) {
        const url = Settings.API_URL + "member/agent";
        let memberAgent = {
            id: memberId,
            agentId: agentId
        };
        return this.post(url, memberAgent);
    }
    updateMemberAgent(memberId, agentId) {
        const url = Settings.API_URL + "member/agent";
        let memberAgent = {
            id: memberId,
            agentId: agentId
        };
        return this.put(url, memberAgent);
    }
    getMemberAgent(memberId) {
        const url = Settings.API_URL + "member/agent";
        return this.get(url);
    }
    getMemberAgentForMember(memberId) {
        const url = Settings.API_URL + "member/" + memberId + "/agent";
        return this.get(url);
    }
    getMemberCosts(memberId, enrollmentId) {
        let url = Settings.API_URL + "member/getallcosts" + "?memberId=" + memberId;

        if (enrollmentId > 0) {
            url = url  + "&enrollmentId=" + enrollmentId
        }
        return this.get(url)
    }

    createMemberNote(memberId, note) {
        const url = Settings.API_URL + `member/createnote/${memberId}`
        var postNote = {
            note: note
        }
        return this.post(url, postNote);
    }
    getMemberNotesByMemberId(memberId) {
        const url = Settings.API_URL + `member/note/${memberId}`
        return this.get(url)
    }
    updateMemberNote(note) {
        const url = Settings.API_URL + `member/note`
        return this.put(url, note)
    }
    /*
     * {
    "enrollmentId": "123456",
    "dob": "2012-04-23T18:25:43.511Z"
}
     */
}

const memberService = new internalMemberService();

//const memberService = {
//    async getMembersAsync(){
//        let returnValue;
//        try{
//            console.log('Get Members Async');
//            const result = await axios.get(Settings.API_URL + "member", {headers: authHeader()} )
//            returnValue = await result.data;
//            console.log('Get Members:' + returnValue);
//            return returnValue;
//        }
//        catch(err){

//        }
//        return returnValue;
//    },
//    async getMemberByIdAsync(memberId){
//        let returnValue;
//        try {
//            console.log('Get Member Async');
//            const result = await axios.get(Settings.API_URL + "member" + "/" + memberId, { headers: authHeader() });
//            returnValue = await result.data;
//            console.log('Get Member:' + returnValue);
//            return returnValue;
//        }
//        catch(err){

//        }
//        return returnValue;
//    }
//}

export default memberService;
import React, { useEffect, useState, useRef } from "react";
import { format, differenceInCalendarYears } from "date-fns";


//Assets
import FallbackLogo from '../../../../../../../assets/images/SourceFileonly icon.png'

//Services
import planService from "../../../../../../../services/plan-service";
import enrollmentService from "../../../../../../../services/enrollment-service";


//Mui icons
import EventIcon from '@mui/icons-material/Event';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Mui Components
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

//Our Components
import EnrollmentSummaryCardLTD from "./enrollment-summary-plans/EnrollmentSummaryCardLTD";
import EnrollmentSummaryCardSTD from "./enrollment-summary-plans/EnrollmentSummaryCardSTD";
import EnrollmentSummaryCardLADD from "./enrollment-summary-plans/EnrollmentSummaryCardLADD";
import EnrollmentSummaryCardAccident from "./enrollment-summary-plans/EnrollmentSummaryCardAccident";
import EnrollmentSummaryCardCancer from "./enrollment-summary-plans/EnrollmentSummaryCardCancer";
import EnrollmentSummaryCardADD from "./enrollment-summary-plans/EnrollmentSummaryCardADD";
import EnrollmentSummaryCardSTDIncremental from "./enrollment-summary-plans/EnrollmentSummaryCardSTDIncremental";
import EnrollmentSummaryCardLTDIncremental from "./enrollment-summary-plans/EnrollmentSummaryCardLTDIncremental";
import EnrollmentSummaryCardHospital from "./enrollment-summary-plans/EnrollmentSummaryCardHospital";
import { MoreVertOutlined } from "@mui/icons-material";
import CancelCoverageDrawer from "./CancelCoverageDrawer";


export default function EnrollmentSummaryCard(props) {

   let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

   const [enrollmentInfo, setEnrollmentInfo] = useState(null);
   const getThisEnrollment = async () => {
      let _enrollmentInfo;

      try {
         _enrollmentInfo = await enrollmentService.getEnrollmentById(props.singleEnrollment.enrollmentId);
         if (_enrollmentInfo) {
            setEnrollmentInfo(_enrollmentInfo);
         } else {
            setEnrollmentInfo(props.singleEnrollment);
         }
      } catch (error) {
         console.log(`Error grabbing enrollment ${props.singleEnrollment.enrollmentId}`, error)
         setEnrollmentInfo(props.singleEnrollment)
      }
   }

   useEffect(() => {
      if (props.singleEnrollment && !enrollmentInfo) {
         getThisEnrollment()
      }
   }, [props.singleEnrollment])


   //date formatter - takes in date string
   const dateFormatter = (date) => {
      let dateObj = new Date(date);

      let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      let day = dateObj.getDate().toString().padStart(2, "0");
      let year = dateObj.getFullYear();

      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
   };

   const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
   ];

   const formatEffective = (date) => {
      // let fullDate = new Date(props?.enrollmentResults?.coverageEffectiveDate);
      let sliceDate = date.slice(
         0,
         10
      );
      let thisYear = Number(sliceDate.slice(0, 4));
      let thisMonth = Number(sliceDate.slice(5, 7)) - 1;
      // console.log(sliceYear,sliceMonth)
      // let thisMonth = fullDate.getMonth();
      // let thisYear = fullDate.getFullYear();
      return `${months[thisMonth]} ${thisYear} Effective`;
      // setFirstPayment({
      //   ...firstPayment,
      //   month: months[thisMonth],
      //   year: thisYear,
      // });
      // console.log(firstPayment);
   };

   const [nextPayment, setNextPayment] = useState()

   // useEffect(() => {
   //    console.log('NEXT PAY',props.paymentSchedule.nextOccurance)
   //    if(props.paymentSchedule?.nextOccurance){
   //             // let _nextPayment = props.paymentSchedule.nextOccurance
   //             let _nextPayment = format(new Date(`${props?.paymentSchedule?.nextOccurance?.slice(0,10)}T00:00:00`), 'MM-dd-yyyy')
   //    setNextPayment(_nextPayment)
   //    }

   // }, [props.paymentSchedule.nextOccurance])

   const [plans, setPlans] = useState([]);

   useEffect(() => {
      if (enrollmentInfo) {
         let _plans = []
         for (let [key, value] of Object.entries(enrollmentInfo)) {
            if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
               _plans.push({ [key]: value })
            }
         }
         _plans = _plans.sort((a, b) => Object.values(a)[0].sortOrder > Object.values(b)[0].sortOrder ? 1 : -1)
         setPlans(_plans)
      }

   }, [enrollmentInfo])

   //currently NOT USING THIS, LOOK INTO REMOVEING
   // useEffect(() => {
   //    console.log('NEXT PAY',props.paymentSchedule.nextOccurance)
   //    if(props.paymentSchedule?.nextOccurance && props.paymentSchedule?.scheduledPaymentDates?.length > 0){
   //       let _nextOccuranceUnmodified = new Date(`${props?.paymentSchedule?.nextOccurance?.slice(0,10)}T00:00:00`)
   //       console.log('unmodded',_nextOccuranceUnmodified)
   //       _nextOccuranceUnmodified.setDate(props.paymentSchedule?.scheduledPaymentDates[0].dayOfMonth)
   //       _nextOccuranceUnmodified = format(_nextOccuranceUnmodified,'MM-dd-yyyy')
   //             // let _nextPayment = props.paymentSchedule.nextOccurance
   //             // let _nextPayment = format(new Date(`${props?.paymentSchedule?.nextOccurance?.slice(0,10)}T00:00:00`), 'MM-dd-yyyy')
   //    // setNextPayment(_nextPayment)
   //    setNextPayment(_nextOccuranceUnmodified)
   //    }

   // }, [props.paymentSchedule])


   const [resumeAnchorEl, setResumeAnchorEl] = useState(null)


   const [collapse, setCollapse] = useState(false)

   const handleMenuClick = () => {
      setCollapse(!collapse)
      setResumeAnchorEl(null)
   }

   const handleLogoError = (e) => {
      e.target.src = FallbackLogo
   }

   const returnConditionalButton = (enrollmentStateId) => {
      let returnedComponent;
      switch (enrollmentStateId) {
         case undefined:
            returnedComponent = <Button
               // endIcon={<ArrowDropDownIcon  />}
               disabled={!enrollmentInfo}
               variant="contained"
               component='label'
               size='medium'
               sx={{ fontFamily: 'Archivo', fontWeight: 500, fontSize: '14px', lineHeight: '24px', letterSpacing: '.4px', height: '30px', alignSelf: 'flex-end', marginBottom: '8px', marginRight: '8px' }}
               onClick={handleResumeClick}
            >
               BEGIN
            </Button>
            break;
         case 1:
            returnedComponent =
               <Button
                  disabled={!enrollmentInfo}
                  component='label'
                  size='medium'
                  variant="contained"
                  sx={{ fontFamily: 'Archivo', fontWeight: 500, fontSize: '14px', lineHeight: '24px', letterSpacing: '.4px', height: '30px', alignSelf: 'flex-end', marginBottom: '8px', marginRight: '8px' }}
                  onClick={handleResumeClick}
                  ref={resumeAnchorEl}
               >
                  RESUME
               </Button>
            break;
         case 2:
            returnedComponent =
               <Button
                  ref={resumeAnchorEl}
                  disabled={!enrollmentInfo}
                  variant="contained"
                  component='label'
                  size='medium'
                  sx={{ fontFamily: 'Archivo', fontWeight: 500, fontSize: '14px', lineHeight: '24px', letterSpacing: '.4px', height: '30px', alignSelf: 'flex-end', marginBottom: '8px', marginRight: '8px' }}
                  onClick={handleReOpenClick}
               >
                  REOPEN
               </Button>
            break;
         // case 3:
         //    returnedComponent = <ButtonGroup variant="contained" ref={resumeAnchorEl}
         //    disabled={!enrollmentInfo} 
         //    sx={{width:'127px', height:'36px'}}
         //    >
         //       <Button 
         //          sx={{fontFamily:'Archivo',fontWeight:500,fontSize:'14px',lineHeight:'24px',letterSpacing:'.4px'}}
         //          onClick={handleCancelClick}
         //       >
         //          CANCEL
         //       </Button>

         //       <Button size="small"
         //       sx={{minWidth:'32px!important',width:'32px!important',padding:'0px'}}
         //       onClick={(e)=>setResumeAnchorEl(e.currentTarget)}
         //       >
         //          <ArrowDropDownIcon  />
         //       </Button>
         //    </ButtonGroup>
         // break;
         default:
      }



      return returnedComponent;
   }

   const handleResumeClick = (e) => {
      props.setSelectedEnrollment(enrollmentInfo)
      props.setActivePage(4)
   }

   const handleReOpenClick = () => {
      props.setSelectedEnrollment(enrollmentInfo)
      props.handleEnrollmentClick(enrollmentInfo.enrollmentStateId)
   }

   const handleCancelClick = () => {
      //this is true here cause EnrollmentSummaryCard only deals with currently open enrollments, not active coverages
      //hence, canceling an enrollment will reopen it

      // let cancelEnrollment = true
      setResumeAnchorEl(null)
      // props.setSelectedEnrollment(enrollmentInfo)
      // props.handleEnrollmentClick(enrollmentInfo.enrollmentStateId, cancelEnrollment)
      setCancelCoverageDrawerOpen(true)
      console.log(props.singleEnrollment)
   }

   const handleRegenerateClick = async () => {
      console.log('regenerating enrollment report', enrollmentInfo.enrollmentId)
      let sendEmail = false
      try {
         await enrollmentService.generateEnrollmentReport(enrollmentInfo.enrollmentId, sendEmail)
      } catch (error) {
         console.log('error generating enrollment report', error)
      }
      setTimeout(async () => {
         await props.getAllDocuments()

      }, 200);
      setResumeAnchorEl(null)
   }
   const [error, setError] = useState(false)


   const handleMoveToInProgress = async () => {
      console.log("move me to in progress")
      let memberId = props?.member?.id
      let signatureObj = {
         signatureText: '',
         signature: '',
         signatureDate: Date.toLocaleString().slice(0, 9),
         groupPlanId: props?.singleEnrollment?.groupPlanId,
         isMail: false,
         isVerbal: false
       };
       
      try {
         let moveToInProgress = enrollmentService.openEnrollmentForMember(signatureObj, memberId)
         getThisEnrollment()

      } catch (error) {
         console.error('error canceling all coverage',error)
         //local error value for conditional rendering of modal
      }
      if (!error){
         setResumeAnchorEl(null)
      }
   }

   const setChip = (value) => {
      switch (value) {
         case null:
            return <Chip sx={{ backgroundColor: 'background.enrollmentNotStartedChip', color: 'primary.enrollmentNotStartedChip', fontWeight: '500', fontFamily: 'Archivo', fontSize: '.9rem', marginLeft: '10px', }} label='Not Started' />
            break;
         case 0:
            return <Chip sx={{ backgroundColor: 'background.enrollmentNotStartedChip', color: 'primary.enrollmentNotStartedChip', fontWeight: '500', fontFamily: 'Archivo', fontSize: '.9rem', marginLeft: '10px' }} label='Not Started' />
            break;
         case 1:
            return <Chip sx={{ backgroundColor: 'background.inProgressChip', color: 'primary.inProgressChip', fontWeight: '500', fontFamily: 'Archivo', fontSize: '.9rem', marginLeft: '10px' }} label='In Progress' />
            break;
         case 2:
            return <Chip sx={{ backgroundColor: 'background.enrollmentSubmittedChip', color: 'primary.enrollmentSubmittedChip', fontWeight: '500', fontFamily: 'Archivo', fontSize: '.9rem', marginLeft: '10px' }} label='Submitted' />
            break;
         case 3:
            return <Chip sx={{ backgroundColor: 'background.enrollmentCancelledChip', color: 'primary.enrollmentCancelledChip', fontWeight: '500', fontFamily: 'Archivo', fontSize: '.9rem', marginLeft: '10px' }} label='Canceled' />
            break;
         default:
            console.warn("No matching chip found");

      }
      if (props.newEnrollment) {
         return <Chip sx={{ backgroundColor: '#F2F4F7', color: '#3D485B', fontWeight: '500', fontFamily: 'Archivo', fontSize: '.9rem' }} label='Not Started' />
      }
   }

   // Cancel Enrollment Drawer
   const [cancelCoverageDrawerOpen, setCancelCoverageDrawerOpen] = useState(false);
   const [cancelAllError, setCancelAllError] = useState(false)


   return (
      <>
         <Box className="flex-row" style={{ minHeight: '119px', padding: '8px 18px' }}>

            <CancelCoverageDrawer
               cancelCoverageDrawerOpen={cancelCoverageDrawerOpen}
               setCancelCoverageDrawerOpen={setCancelCoverageDrawerOpen}
               planEnrollmentId={props?.singleEnrollment?.enrollmentId}
               formatDate={props?.formatDate}
               cancellationReasons={props?.cancellationReasons}
               updateSinglePlan={props?.updateSinglePlan}
               member={props?.member}
               singleEnrollment={props?.singleEnrollment}
               getThisPlan={getThisEnrollment}
               groupPlanId={props?.singleEnrollment?.groupPlanId}
               cancelEnrollment={true}
               cancelAllError={cancelAllError}
               setCancelAllError={setCancelAllError}
            />
            <div className="" style={{ textAlign: 'left', flexDirection: 'column', display: 'flex', alignSelf: 'baseline' }}>
               <Fade in={props.member.organizationId ? true : false}>
                  <img src={props.member && `api/image/organization/${props.member?.organizationId}`} onError={handleLogoError} style={{ maxWidth: '150px', height: 'auto', alignSelf: 'center' }} />
               </Fade>
            </div>

            <div className=""
               style={{ position: 'relative', paddingLeft: '8px', width: '100%', flexDirection: 'column' }}
            >
               <div className="flex-row" style={{ marginBottom: '15px' }}
               >
                  <Typography variant="h6">
                     {enrollmentInfo?.name ? enrollmentInfo.name : <Skeleton width='16rem' />}
                     {enrollmentInfo?.name ? setChip(enrollmentInfo?.enrollmentStateId) : <Skeleton width='8rem' />}
                  </Typography>
               </div>

               {/* For rolling enrollments, use isLastWindow to determine if the enrollment is in last window. If it is, then hide the coverage effective dates */}
               {!props?.singleEnrollment?.isLastWindow && <div className="flex-row">
                  <div className="col-12" style={{ paddingBottom: '4px' }}>
                     <Typography component={'div'}
                        sx={{ fontFamily: 'Archivo', fontSize: '16px', fontWeight: 400, lineHeight: '28px', letterSpacing: '.15px', color: 'text.gray' }}
                     >
                        {props.singleEnrollment && props.singleEnrollment?.policyNumber?.length > 0 &&
                           `Policy #${props.singleEnrollment?.policyNumber}`
                           || "Policy Number"
                        }
                     </Typography>
                     {/* <br/> */}
                     <Collapse in={plans.length > 0}>
                        {plans.length > 0 && plans.map(plan => {
                           let planKey = Object.keys(plan)[0]
                           let planValue = Object.values(plan)[0]


                           let planName = planKey.slice(0, planKey.length - 4)
                           switch (planName.toUpperCase()) {
                              case 'LIFEADD':
                                 planName = 'Life & AD&D'
                                 break;

                           }

                           //find any declined plan options elected, otherwise this value is false
                           //check for declined ladd plan in return below w/ planValue?.totalPremiumAmount == 0 since ladd plan has this value on object and will be > 0 if anything is elected
                           // let declinePlanOptionSelected = false
                           // if(planValue?.options){
                           //    declinePlanOptionSelected = planValue?.options?.find(option => {
                           //       if(option?.isDecline && option?.memberOptionId === planValue?.selectedOptionId) {
                           //          return option
                           //       }
                           //    })
                           // }

                           return (

                              <Typography component={'div'}
                                 sx={{ fontFamily: 'Archivo', fontSize: '14px', fontWeight: 500, lineHeight: '22px', letterSpacing: '.1px', color: 'text.gray' }}
                              >
                                 {planName.toUpperCase()}: {planValue?.effectiveDate && formatEffective(planValue?.effectiveDate) || ""}
                              </Typography>

                           )


                        })}
                     </Collapse>
                  </div>
               </div>}
               {/* <Typography variant="body2" sx={{marginTop: "10px"}}>
                     Coverage Effective Date: {(props?.singleEnrollment?.coverageEffectiveDate && dateFormatter(props?.singleEnrollment?.coverageEffectiveDate)) || ""}
                  </Typography> */}
            </div>

            <div className="col-2"
               style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'space-between', alignContent: 'flex-end', }}
            >
               <IconButton sx={{ alignSelf: 'flex-end', }}
                  onClick={(e) => setResumeAnchorEl(e.currentTarget)}
               >
                  <MoreVertIcon
                     sx={{
                        color: "text.secondary",
                        // float: "right",
                     }}
                  />
               </IconButton>
               <Menu
                  anchorEl={resumeAnchorEl}
                  open={resumeAnchorEl ? true : false}
                  fullWidth
                  onClose={() => setResumeAnchorEl(null)}
                  sx={{ 'ul': { padding: '0px' }, justifyContent: "flex-start" }}
                  PaperProps={{
                     style: {
                        borderRadius: "4px",
                        // width:'127px',
                        padding: '0px'
                     }
                  }}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
               >
                  {(enrollmentInfo?.enrollmentStateId === 2 || enrollmentInfo?.enrollmentStateId === 1) &&
                     <MenuItem value={!collapse}
                        onClick={handleMenuClick}
                        disabled={!enrollmentInfo}
                        sx={{
                           color: "text.primary", fontFamily: "Archivo", fontWeight: 400, fontSize: '14px', lineHeight: '24px', letterSpacing: '.17px', height: '48px',
                           '&:hover': { backgroundColor: 'primary.light' }
                        }} >
                        {!collapse ? 'Show Preview' : 'Hide Preview'}
                     </MenuItem>
                  }
                  {(enrollmentInfo?.enrollmentStateId === 2 || enrollmentInfo?.enrollmentStateId === 1) &&
                     <MenuItem
                        onClick={handleCancelClick}
                        sx={{
                           color: "text.primary", fontFamily: "Archivo", fontWeight: 400, fontSize: '14px', lineHeight: '24px', letterSpacing: '.17px', height: '48px',
                           '&:hover': { backgroundColor: 'primary.light' }
                        }} >
                        Cancel Enrollment
                     </MenuItem>
                  }
                  {(enrollmentInfo?.enrollmentStateId === 2) &&
                     <MenuItem
                        onClick={handleRegenerateClick}
                        sx={{
                           color: "text.primary", fontFamily: "Archivo", fontWeight: 400, fontSize: '14px', lineHeight: '24px', letterSpacing: '.17px', height: '48px',
                           '&:hover': { backgroundColor: 'primary.light' }
                        }} >
                        Regenerate PDF
                     </MenuItem>
                  }
                  {(enrollmentInfo?.enrollmentStateId === 3) &&
                     <MenuItem value={!collapse}
                        onClick={handleMoveToInProgress}
                        disabled={!enrollmentInfo}
                        sx={{
                           color: "text.primary", fontFamily: "Archivo", fontWeight: 400, fontSize: '14px', lineHeight: '24px', letterSpacing: '.17px', height: '48px',
                           '&:hover': { backgroundColor: 'primary.light' }
                        }} >
                           Re-Open Application
                     </MenuItem>
                  }
                  {/* <MenuItem value='Settings' 
                        // onClick={handleStatusOpen}
                        divider disabled
                           sx={{
                              color: "text.primary",  fontFamily: "Archivo", fontWeight: "500", padding: "10px",
                              '&:hover': { backgroundColor: 'primary.light' }
                           }}>
                           HIDE
                        </MenuItem> */}
               </Menu>
               {returnConditionalButton(enrollmentInfo?.enrollmentStateId)}

            </div>
         </Box>
         <Collapse in={collapse}>
            {plans.length > 0 && plans.map(plan => {
               let planKey = Object.keys(plan)[0]
               let planValue = Object.values(plan)[0]

               // let selectedOptionId = planValue?.selectedOptionId
               let returnedPlanComponent;


               let planName = planKey.slice(0, planKey.length - 4)
               switch (planName.toUpperCase()) {
                  case 'LIFEADD':
                     planName = 'Life & AD&D'
                     break;

               }

               // switch (planKey.slice(0,planKey.length-4).toUpperCase()){
               //    case 'LTD':
               //       if (planValue?.isIncremental){
               //          // need incremental
               //          returnedPlanComponent = <EnrollmentSummaryCardLTD plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}/>
               //       } else {
               //          returnedPlanComponent = <EnrollmentSummaryCardLTD plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}/>
               //       }
               //    break;
               //    case 'STD':
               //       if (planValue?.isIncremental){
               //          //need incremental
               //          returnedPlanComponent = <EnrollmentSummaryCardSTD plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}/>
               //       } else {
               //          returnedPlanComponent = <EnrollmentSummaryCardSTD plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}/>
               //       }
               //    break;
               //    case 'LIFEADD':
               //       returnedPlanComponent = <EnrollmentSummaryCardLADD plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}/>
               //    break;
               //    case 'ACCIDENT':
               //       returnedPlanComponent = <EnrollmentSummaryCardAccident plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}/>
               //    break;
               //    case 'CANCER':
               //       returnedPlanComponent = <EnrollmentSummaryCardCancer plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}/>
               //    break;
               //    case 'ADD':
               //       returnedPlanComponent = <EnrollmentSummaryCardADD plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}/>
               //    break;
               // }
               switch (planKey.slice(0, planKey.length - 4).toUpperCase()) {
                  case 'LTD':
                     if (planValue?.isIncremental) {
                        // need incremental
                        returnedPlanComponent = <EnrollmentSummaryCardLTDIncremental
                           plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                           updateSinglePlan={props.updateSinglePlan}
                           currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit} formatDate={props?.formatDate}
                        />
                     } else {
                        returnedPlanComponent = <EnrollmentSummaryCardLTD
                           plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                           updateSinglePlan={props.updateSinglePlan}
                           currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                           formatDate={props?.formatDate}
                        />
                     }
                     break;
                  case 'STD':
                     if (planValue?.isIncremental) {
                        //need incremental
                        returnedPlanComponent = <EnrollmentSummaryCardSTDIncremental
                           plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                           updateSinglePlan={props.updateSinglePlan}
                           currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                           formatDate={props?.formatDate}
                        />
                     } else {
                        returnedPlanComponent = <EnrollmentSummaryCardSTD
                           plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts}
                           updateSinglePlan={props.updateSinglePlan}
                           currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                           formatDate={props?.formatDate}
                        />
                     }
                     break;
                  case 'LIFEADD':
                     returnedPlanComponent = <EnrollmentSummaryCardLADD
                        plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                        currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                        groupPlanId={enrollmentInfo.groupPlanId}
                        formatDate={props?.formatDate}
                     />
                     break;
                  case 'ACCIDENT':
                     returnedPlanComponent = <EnrollmentSummaryCardAccident
                        plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                        currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                        formatDate={props?.formatDate}
                     />
                     break;
                  case 'CANCER':
                     returnedPlanComponent = <EnrollmentSummaryCardCancer
                        plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                        currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                        formatDate={props?.formatDate}
                     />
                     break;
                  case 'ADD':
                     returnedPlanComponent = <EnrollmentSummaryCardADD
                        plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                        currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                        formatDate={props?.formatDate}
                     />
                     break;
                  case 'HOSPITAL':
                     returnedPlanComponent = <EnrollmentSummaryCardHospital
                        plan={planValue} planName={planName} UsdFormatterDec={UsdFormatterDec} planCosts={props.planCosts} member={props.member} relationshipTypes={props.relationshipTypes}
                        currentEdit={props.currentEdit} setCurrentEdit={props.setCurrentEdit}
                        formatDate={props?.formatDate}
                     />
                     break;
                  default: console.log('no plan found')
               }
               return (
                  returnedPlanComponent
               )
            })}
         </Collapse>
         {!collapse &&
            <>
               {/* Collapsed */}
            </>
         }
      </>

   )
}
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addWeeks, addYears, addBusinessDays } from "date-fns";

//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";


//services
import searchService from '../../../../../../services/search-service';
import userService from '../../../../../../services/user-service';
import ticketService from '../../../../../../services/ticket-service';

// Assets
import fancyCheckmark from '../../../../../../assets/images/fancyCheckmark.svg'
import fancyRectangle from '../../../../../../assets/images/fancyRectangle.svg'

//Mui Components
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';


//Mui icons
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';


//Our Components
import InfinityLoader from '../../../../../UH-loading-animation/InfinityLoader';

//utils
import { formatDateObjYYYYMMDD } from '../../../../../utils'


//component styles
const componentStyles = {
   inputField: { marginBottom: '20px', width: '100%' },
}

export default function CreateTicketDrawer(props) {
   let history = useHistory()

   //Ticket Logic
   const [assigneeObj, setAssigneeObj] = useState({})
   const [memberObj, setMemberObj] = useState({})
   const [ticketDescription, setTicketDescription] = useState('')
   const [createdTicket, setCreatedTicket] = useState({
      subject: '',
      memberId: props.member?.id,
      groupPlanId: props.singleEnrollment?.groupPlanId,
      requester: `${props.member?.firstName} ${props.member?.lastName}`,
      // assignee:'',
      ticketStatusId: 1,
      ticketPriorityId: 2,
      ticketOriginId: 6,
      ticketTypeId: 1,
      ticketSLAId: 1,
      userId: props.user?.id,
      uniqueId: '',
      dateCreated: new Date(),
      lastModifiedDate: new Date(),
      dueDate: new Date()
   })
   useEffect(() => {
      setCreatedTicket({
         ...createdTicket,
         uniqueId: ``
      })
   }, [props.origins])

   const handleTicketChange = (e, property) => {
      let value = e.target.value;
      setCreatedTicket({
         ...createdTicket,
         [property]: value
      })
   }
   const handleAssigneeChange = (e, newValue) => {
      // console.log('handle assignee change',e,newValue)
      if (newValue?.id) {
         setCreatedTicket({
            ...createdTicket,
            userId: newValue.id
         })
         setAssigneeObj(newValue)
         console.log(assigneeObj)
      }
      else {
         // console.log("hitting the else")
         setCreatedTicket({
            ...createdTicket,
            userId: undefined
         })
         setAssigneeObj({})
      }
   }

   const [sendTicketLoading,setSendTicketLoading] = useState(false)
   const sendCreatedTicket = async () => {
      setSendTicketLoading(true)
      //We do work to a copy to prevent a flash when changing duedate here
      let _createdTicket = {...createdTicket}
      //already has timezone, so new Date()'ing it doesnt change it, but lets us use format
      _createdTicket.dueDate = formatDateObjYYYYMMDD(_createdTicket.dueDate)
      let newTicketId = await ticketService.insertTicket(_createdTicket)
      
      await ticketService.assignTicket(newTicketId, createdTicket.userId)
      let newEntry = {
         message: ticketDescription,
         ticketEntryTypeId: 4, //note
         subject: createdTicket.subject,
         userId: props.user?.id, //Created by user, not assignee
         memberId: createdTicket.memberId
         // destination:''
         // cc:''
      }
      await ticketService.insertTicketEntry(newTicketId, newEntry)

      await props.refreshTickets()
      setSendTicketLoading(false)
      props.setTicketDrawerOpen(false)
      history.push(`/tickets/${newTicketId}`)
   }



   //Assignee AutoComplete Logic
   const [open, setOpen] = useState(false)
   const [allUsers, setAllUsers] = useState([])
   const loading = open && allUsers.length === 0;
   useEffect(()=> {
      if (props.user){
         setAssigneeObj(props.user)
         setCreatedTicket({
            ...createdTicket,
           userId:props.user.id 
         })
      }
   },[props.user])

   const getAllUsers = async () => {
      let allUsers = await userService.getAll()
      setAllUsers([...allUsers])
      console.log(allUsers)
   }

   useEffect(() => {
      if (!loading) return undefined
       else getAllUsers()
   }, [loading])




   //Drawer Logic
   const handleDrawerClose = (e) => {
       props.setTicketDrawerOpen(false)     
   }


   //Member search
   const [searchState, setSearchState] = useState({
      display: false,
      terms: "",
      results: []
   })

   const [searchedTermSnap, setSearchedTermSnap] = useState('')
   const [loadingState, setLoadingState] = useState(false)

   //TimerId
   const [timer, setTimer] = useState(0)

   const [anchorEl, setAnchorEl] = useState(null);
   const searchOpen = Boolean(anchorEl);
   const handleAnchor = (target) => {
      // console.log(e)
      setAnchorEl(target);
   };
   const handleClose = (e) => {
      setAnchorEl(null);
   };
   let anchorTarget = document.getElementById('member-search')

   const handleTermsInput = (e) => {
      // console.log(e.target)
      setSearchState({
         ...searchState,
         terms: e.target.value
      })
   }

   const searchAsync = async () => {
      handleAnchor(anchorTarget)
      //console.log(searchState)
      setSearchedTermSnap(searchState.terms)
      setLoadingState(true)
      let results = await searchService.search(searchState.terms)
      console.log('searchResults',results)
      if (results) {
         setSearchState({
            ...searchState,
            results: results
         })
         // console.log(searchState)
      }
   }

   //Member AutoComplete Logic
   const [memberOpen, setMemberOpen] = useState(false)
   // const [allUsers, setAllUsers] = useState([])
   const memberLoading = memberOpen && searchState.results.length === 0;

   // const getAllUsers = async () => {
   //    let allUsers = await userService.getAll()
   //    setAllUsers([...allUsers])
   //    console.log(allUsers)
   // }

   // useEffect(() => {
   //    if (!loading) return undefined
   //    else getAllUsers()
   // }, [memberLoading])

   const handleMemberChange = (e,newValue) => {
      console.log('member change',e,newValue)
      if (newValue?.result?.id) {
         setCreatedTicket({
            ...createdTicket,
            memberId: newValue.result.id,
            requester: `${newValue.result?.firstName} ${newValue.result?.lastName}`
         })
         setMemberObj(newValue)
         setHaveMemberInfo(true)
      }
      else {
         // console.log("hitting the else")
         setCreatedTicket({
            ...createdTicket,
            memberId: undefined
         })
         setMemberObj({})
         setHaveMemberInfo(false)
      }
   }

   useEffect(() => {
      let priority = createdTicket.ticketPriorityId
      let newDueDate;
      switch (priority){
         case 1:
            newDueDate = addWeeks(new Date(),3)
         break;
         case 2:
            newDueDate = addWeeks(new Date(),2)
         break;
         case 3:
            newDueDate = addBusinessDays(new Date(),3)
         break;
         case 4:
            newDueDate = addBusinessDays(new Date(),1)
         break;
         default:

         break;
      }
      setCreatedTicket({
         ...createdTicket,
         dueDate: newDueDate
      })
   },[createdTicket.ticketPriorityId])

   let dateMinObj = new Date();
   let dateMaxObj = addYears(new Date(),1)

   const handleDueDateChange = (newDate) => {
      // newDate in date ojb format from DatePicker
      if ((newDate >= dateMinObj) && (newDate <= dateMaxObj)){
         setCreatedTicket({
            ...createdTicket,
            dueDate:newDate
         })
      }
   }

   const [haveMemberInfo, setHaveMemberInfo] = useState(false)
   useEffect(() => {
      if (props.member && props.member.id > 0){
         setHaveMemberInfo(true)
      }
      else {
         setHaveMemberInfo(false)
   }
   },[props.member, props.showMemberInput])


   return <>
      <Drawer
         PaperProps={{
            sx: { marginTop: '56px!important' }
            
         }}
         hideBackdrop={true}
         anchor='right'
         open={props.ticketDrawerOpen}
         onClose={handleDrawerClose}
         >
         {sendTicketLoading &&
            <>
               <InfinityLoader style={{
                  position: 'absolute',
                  zIndex: 5,
                  width: '-webkit-fill-available',
                  height: '-webkit-fill-available',
                  backgroundColor: '#00000005',
                  // backdropFilter: 'blur(5px)',
               }} />
               <style>
                  {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:relative;
                     margin-top: 200px;
                  }
               `}
               </style>
            </>
         }
         <div style={{ width: '560px', padding: '19px 38px' }}>
            <div className='flex-row' >
               <div className='col-6' style={{ fontFamily: 'Poppins', fontWeight: 600}}>
               <Typography variant="body1" fontFamily="Poppins" fontWeight="600">New Ticket</Typography>
               </div>

               <div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end' }} role='button' onClick={handleDrawerClose}>
                  <CloseIcon />
               </div>
            </div>
            <hr style={{ margin: '17px -38px 30px', color: '#195ffb', opacity: 1, height: '2px' }} />

            {props.showMemberInput &&
               <Autocomplete disablePortal id='member-search'
               loading={memberLoading} options={searchState.results}
               open={memberOpen} onOpen={() => setMemberOpen(true)} onClose={() => setMemberOpen(false)}
               sx={componentStyles.inputField}
               popupIcon={<KeyboardArrowDownIcon />}
               value={memberObj} onChange={handleMemberChange}
               renderInput={(params) => 
                  <TextField {...params} variant='outlined'
                  value={searchState.terms} 
                  onChange={handleTermsInput}
                  onKeyPress={(e) => e.key === 'Enter' && searchAsync()}
                  label="Member" 
                  placeholder='Search member'
                  />
               }
               getOptionLabel={option => option.result?.firstName ? `${option.result?.firstName} ${option.result?.lastName}` : ''}
               renderOption={(params,option) => {
                  console.log('params in render',params,option)
                  return (
                     <Stack direction="row" spacing={1} {...params} key={params['data-option-index']}>
                        <Avatar sx={{ bgcolor: "#195FFB17", color: "#195FFB", fontFamily: "Archivo", fontWeight: "600", width: 28, height: 28, fontSize: 14 }} src={option?.result?.id > 0 ? `api/image/member/${option.result?.id}` : ''}>
                        {`${option.result?.firstName[0].toUpperCase()}${option.result?.lastName[0].toUpperCase()}`}
                        </Avatar>
                        <span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}>{params.key}</span>
                     </Stack>


                  )
               }}
               disabled={sendTicketLoading}
               />

            }

            {/* Title*/}

            <TextField fullWidth
               variant='outlined' label='Title'
               sx={componentStyles.inputField}
               value={createdTicket.subject} onChange={e => handleTicketChange(e, 'subject')}
               disabled={sendTicketLoading}
            />

            {/* Ticket Type */}

            <TextField fullWidth
               variant='outlined' label='Ticket Type'
               sx={componentStyles.inputField}
               select SelectProps={{
                  IconComponent: KeyboardArrowDownIcon
               }}
               value={createdTicket.ticketTypeId} onChange={e => handleTicketChange(e, 'ticketTypeId')}
               disabled={sendTicketLoading}
            >
               {props.types && props.types.map(type => {
                  return (
                     <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                  )
               })}
            </TextField>

            {/* Asignee */}

            <Autocomplete disablePortal id='assignee-search'
               loading={loading} options={allUsers}
               open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}
               sx={componentStyles.inputField}
               popupIcon={<KeyboardArrowDownIcon />}
               value={assigneeObj} onChange={handleAssigneeChange}
               renderInput={(params) => <TextField {...params} label='Assignee' />}
               getOptionLabel={option => option.firstName ? `${option.firstName} ${option.lastName}` : ''}
               renderOption={(params,option) => {
                  // console.log('params in render',props,option)
                  return (
                     <Stack direction="row" spacing={1} {...params} key={params['data-option-index']}>
                        <Avatar sx={{ bgcolor: "#195FFB17", color: "#195FFB", fontFamily: "Archivo", fontWeight: "600", width: 28, height: 28, fontSize: 14 }} src={option?.id > 0 ? `api/image/user/${option.id}` : ''}>
                        {`${params?.key[0].toUpperCase()}${params?.key[1].toUpperCase()}`}
                        </Avatar><span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}>{params.key}</span>
                     </Stack>


                  )
               }}
               disabled={sendTicketLoading}
            />

            {/* Ticket Priority */}

            <TextField fullWidth
               variant='outlined' label='Priority'
               sx={componentStyles.inputField}
               select SelectProps={{
                  IconComponent: KeyboardArrowDownIcon
               }}
               value={createdTicket.ticketPriorityId} onChange={e => handleTicketChange(e, 'ticketPriorityId')}
               disabled={sendTicketLoading}
            >
               {props.priorities && props.priorities.map(priority => {
                  return (
                     <MenuItem key={priority.id} value={priority.id}>{priority.name}</MenuItem>
                  )
               })}
            </TextField>
            
            {/* Ticket Due Date */}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
             <DatePicker
               disabled={sendTicketLoading}
               label="Due date"
               maxDate={dateMaxObj}
               minDate={dateMinObj}
               views={["year", "month", "day"]}
               value={createdTicket.dueDate || null}
               onChange={handleDueDateChange}
               format="MM/dd/yyyy"
               sx={componentStyles.inputField}
             />
           </LocalizationProvider>
{/* 
            <div className='flex-row' style={{ marginBottom: '40px', alignItems: 'center' }}>
               <Checkbox
                  defaultChecked={true}
                  // checkedIcon={<CheckBoxOutlinedIcon />
                  // }
               />

               <span style={{ fontWeight: 700, fontFamily: 'archivo', marginRight: '5px' }}>Send ticket confirmation email to member</span>

               <Tooltip title='If this is checked, member will receive an email that a ticket has been opened' style={{ display: 'flex' }}>
                  <div >
                     <InfoOutlinedIcon sx={{ height: '15px', color: '#C4C4C4' }} />
                  </div>
               </Tooltip>
            </div> */}

            {/* Description */}

            <TextField fullWidth multiline minRows={5}
               variant='standard' placeholder='Add a description for this ticket, only to be seen by other agents'
               InputProps={{ disableUnderline: true }}
               value={ticketDescription} onChange={e => setTicketDescription(e.target.value)}
               sx={{
                  marginBottom: '40px', border: '1px solid #79747E', borderRadius: '4px', padding: '16px',
                  // input:{'&::placeholder':{fontFamily:'Archivo'}}
               }}
               disabled={sendTicketLoading}
            />

            <Box className='flex-row' sx={{ justifyContent: 'center', marginBottom: '50px' }}>
               <Button variant='contained' disableRipple
                  sx={{ height: 40, minWidth: 200, borderRadius: 100, width: '152px', fontWeight: '500!important' }}
                  onClick={sendCreatedTicket}
               // startIcon={<AddIcon/>}
                  disabled={sendTicketLoading || !haveMemberInfo}
               >
                  Create Ticket
               </Button>
            </Box>
         </div>
      </Drawer>

   </>;
}